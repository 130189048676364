import React, { useState, useEffect, useCallback, useRef } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { get, post } from '../../services/apiService';
import { SetPageTitle } from '../../js/helpers.js';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

export const Settings = () => {
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [searchText, setSearchText] = useState('');
    const [editingRows, setEditingRows] = useState(new Set());

    const gridRef = useRef();
    const createColumnDefs = (stores) => {
        const customComparator = (valueA, valueB, nodeA, nodeB, isDescending) => {
            const inactiveA = nodeA.data.inactive;
            const inactiveB = nodeB.data.inactive;

            if (inactiveA !== inactiveB) {
                return inactiveA ? 1 : -1; // Active (false) comes first
            }
            if (valueA === valueB) {
                return 0;
            }
            return valueA > valueB ? 1 : -1;
        };

        return [
            {
                headerName: "ID",
                field: "settingId",
                hide: true,
            },
            {
                headerName: 'Name',
                field: 'settingName',
                editable: true,
                // sortable: true,
                // filter: true,
                flex: 1,
                comparator: customComparator
            },
            {
                headerName: 'Value',
                field: 'settingValue',
                editable: true,
                // sortable: true,
                // filter: true,
                flex: 1,
                comparator: customComparator
            },
            {
                headerName: 'Type',
                // field: 'settingType',
                editable: true,
                // sortable: true,
                comparator: customComparator,
                cellRenderer: params => {
                    const defaultValue = params.data.settingType ?? '1'; // Assign default value 
                    return (
                        <select
                            className="w-full border border-gray-300 rounded-lg bg-transparent text-gray-700 focus:outline-none p-2 text-sm hover:border-gray-400 transition-colors"
                            defaultValue={defaultValue}
                            onChange={(event) => {
                                params.setValue(event.target.value);
                                params.data.settingType = Number(event.target.value);
                                onCellValueChanged(params);
                            }}
                        >
                            <option value="0">None</option>
                            <option value="1">Admin</option>
                            <option value="2">Accounting</option>
                        </select>
                    )
                }
            },
            {
                headerName: '',
                field: 'actions',
                width: 100,
                cellRenderer: params => {
                    if (params.data.settingId === 0) {
                        return (
                            <button
                                onClick={() => handleSaveNewSetting(params)}
                                className="btn btn-primary btn-xs"
                            >
                                Save
                            </button>
                        );
                    }
                    return null;
                }
            }
        ];
    }

    const [columnDefs, setColumnDefs] = useState(createColumnDefs([]));

    const defaultColDef = {
        resizable: true,
        suppressMovable: true,
    };

    const loadData = async () => {
        SetPageTitle("Settings");
        setMessage('');
        setError('');

        try {
            const settings = await get('/setting/GetAllSettings')

            if (gridRef.current.api) {
                gridRef.current.api.setRowData(settings.value.data);
                gridRef.current.api.refreshCells({force: true});
            }
        } catch (err) {
            setMessage('');
            setError('Failed to load data');
            console.error('Error loading data:', err);
        }
    };

    useEffect(() => {
        loadData();

    }, []);

    const createDefaultSettings = async () => {
        try {
            const settingDefualt = await get('/setting/GetByName/GlobalRemittanceNote');
            console.log('exist')

        } catch (e) {
            console.log('not exist', e)
            const settings =
                {
                    settingName: 'GlobalRemittanceNote',
                    settingValue: 'Commission message',
                    settingType: 2
                }
            await post('/setting/CreateSetting', settings);
            loadData();
        }
    }

    useEffect(() => {
        // create defaut Settings
        createDefaultSettings().then();
    }, []);

    const onCellValueChanged = async (params) => {
        console.log(params.data)
        const isNewRow = params.data.settingId === 0;
        if (isNewRow) {
            setEditingRows(prev => new Set(prev).add(params.data.settingId));
        } else {
            try {
                await post('/setting/UpdateSetting', params.data);
                await loadData();
                setError('');
                setMessage('Updated successfully');
            } catch (err) {
                setMessage('');
                setError('Update failed');
                console.error('Error:', err);
                loadData();
            }
        }
    };

    const handleRowFocused = async (params) => {
        if (!params.previousNode) return;

        const previousRowId = params.previousNode.data.settingId;
        if (editingRows.has(previousRowId)) {
            try {
                const rowData = params.previousNode.data;

                const body = {
                    settingName: rowData.settingName,
                    settingValue: rowData.settingValue,
                    settingType: Number(rowData.settingType)
                }
                if (rowData.settingName !== '' || rowData.settingValue !== '' || rowData.settingType !== '') {
                    await post('/setting/CreateSetting', body);
                    setEditingRows(prev => {
                        const newSet = new Set(prev);
                        newSet.delete(previousRowId);
                        return newSet;
                    });
                    await loadData();
                    setError('');
                    setMessage('Setting added successfully');
                }
            } catch (err) {
                setMessage('');
                setError('Creation failed');
                console.error('Error:', err);
                loadData();
            }
        }
    };

    const handleSaveNewSetting = async (params) => {
        try {
            const rowData = params.data;
            console.log({rowData})

            if (rowData.settingName === '' || rowData.settingValue === '' || rowData.settingType === '') {
                setMessage('');
                setError('Please fill in all required fields');
                return;
            }

            var body = {
                settingName: rowData.settingName,
                settingValue: rowData.settingValue,
                settingType: Number(rowData.settingType)
            }
            await post('/setting/CreateSetting', body);
            setEditingRows(prev => {
                const newSet = new Set(prev);
                newSet.delete(rowData.settingId);
                return newSet;
            });
            await loadData();
            setError('');
            setMessage('Created successfully');
        } catch (err) {
            setError('Creation failed');
            setMessage('');
            console.error('Error:', err);
        }
    };

    const handleAddSetting = () => {
        const existingNewRow = gridRef.current.api.getModel().rowsToDisplay
            .find(row => row.data.settingId === 0);

        if (existingNewRow) {
            setError('Please save or complete the current new entry first');
            setMessage('');
            return;
        }

        const newSetting = {
            settingId: 0,
            settingName: '',
            settingValue: "",
            settingType:  0
        };

        const currentRows = gridRef.current.api.getModel().rowsToDisplay;
        const updatedRows = [newSetting, ...currentRows.map(row => row.data)];
        gridRef.current.api.setRowData(updatedRows);

        setTimeout(() => {
            const rowNode = gridRef.current.api.getRowNode(0);
            if (rowNode) {
                gridRef.current.api.startEditingCell({
                    rowIndex: 0,
                    colKey: 'name'
                });
            }
        }, 100);
    };

    const getRowClass = (params) => {
        return params.data.inactive ? 'inactive-row' : '';
    };

    const postSort = (params) => {
        const nodes = params.nodes;
        nodes.sort((a, b) => {
            if (a.data.inactive !== b.data.inactive) {
                return a.data.inactive ? 1 : -1; // Active first
            }
            return 0; // Existing sort
        });
    };

    const onQuickFilterChanged = useCallback(() => {
        gridRef.current.api.setQuickFilter(searchText);
    }, [searchText]);

    return (
        <div className="p-4">
            <div className="mb-4">
                <div className="flex justify-between items-center mb-4">
                    <div className="flex items-center">
                        <input
                            type="text"
                            placeholder="Quick search..."
                            value={searchText}
                            onChange={e => setSearchText(e.target.value)}
                            onInput={onQuickFilterChanged}
                            className="input input-bordered w-64"
                        />
                    </div>

                    <div className="flex items-center gap-4">
                        {error && <div className="text-red-500">{error}</div>}
                        {message && <div className="text-green-500">{message}</div>}
                        <button
                            onClick={handleAddSetting}
                            className="btn btn-submit btn-sm"
                        >
                            Add new setting
                        </button>
                    </div>
                </div>
            </div>

            <div className="ag-theme-alpine h-[600px]">
                <AgGridReact
                    ref={gridRef}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    suppressRowClickSelection={true}
                    stopEditingWhenCellsLoseFocus={true}
                    onCellValueChanged={onCellValueChanged}
                    defaultSortModel={[
                        {colId: 'inactive', sort: 'asc'}
                    ]}
                    getRowClass={getRowClass}
                    postSort={postSort}
                    onRowFocused={handleRowFocused}
                />
            </div>
        </div>
    );
};