import React, { useState, useMemo } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { Exists, GetDisplayCurrencyStr, GetDisplayDateStr, NotStringEmpty } from '../../js/formHelpers';
import { Equals } from '../../js/calculationHelpers';

export const PaymentInvoiceDetailsModal = ({ paymentData, onClose }) => {
    const [searchTerm, setSearchTerm] = useState('');
    console.log('PaymentData : ', paymentData)
    //console.log('PaymentDetails : ', paymentData.paymentDetails)

    const [columnDefs] = useState([
        {
            field: 'invoiceNumber',
            headerName: 'Invoice #',
            flex: 1,
            valueGetter: params => params.data.invoiceNumber || ''
        },
        {
            field: 'orderNumber',
            headerName: 'Order #',
            flex: 1,
            valueGetter: params => params.data.invoiceNumber ? '' : (params.data.orderNumber || '')
        },
        {
            field: 'amountPaid',
            headerName: 'Amount Paid',
            flex: 1,
            valueFormatter: params => GetDisplayCurrencyStr(params.value)
        },
        {
            field: 'balanceDue',
            headerName: 'Balance Due',
            flex: 1,
            valueFormatter: params => GetDisplayCurrencyStr(params.value)
        },
    ]);

    const gridOptions = {
        defaultColDef: {
            flex: 1,
            minWidth: 100,
            sortable: true,
            resizable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: false,
            cellClass: ["no-border"],
            enableRangeSelection: true,
            menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab"],
        },
        autoSizeColumns: true,
        suppressColumnVirtualisation: true
    };

    //const invoicePayments = useMemo(() => {
    //    if (Array.isArray(paymentData.invoicePayments)) {
    //        return paymentData.invoicePayments.map(ip => ({
    //            invoiceNumber: ip.invoice.invoiceNumber,
    //            accountName: ip.invoice.accountName,
    //            amountPaid: ip.amountPaid,
    //            invoiceId: ip.invoiceId
    //        }));
    //    }
    //    console.warn('invoicePayments is not an array or is undefined');
    //    return [];
    //}, [paymentData]);

    //const filteredInvoicePayments = useMemo(() => {
    //    return invoicePayments.filter(payment =>
    //        Object.values(payment).some(value =>
    //            value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    //        )
    //    );
    //}, [invoicePayments, searchTerm]);

    //const paymentDetails = useMemo(() => {
    //    const invoicePayments = Array.isArray(paymentData.invoicePayments)
    //        ? paymentData.invoicePayments.map(ip => ({
    //            type: 'Invoice',
    //            number: ip.invoice.invoiceNumber,
    //            accountName: ip.invoice.accountName,
    //            amountPaid: ip.amountPaid,
    //            balanceDue: ip.invoice.balanceDue,
    //            id: ip.invoiceId
    //        }))
    //        : [];

    //    const orderPayments = Array.isArray(paymentData.orderPayments)
    //        ? paymentData.orderPayments.map(op => ({
    //            type: 'Order',
    //            number: op.order.orderNumber,
    //            accountName: op.order.accountName,
    //            amountPaid: op.amountPaid,
    //            balanceDue: op.order.balance,
    //            id: op.orderId
    //        }))
    //        : [];

    //    return [...invoicePayments, ...orderPayments];
    //}, [paymentData]);

    //const filteredPaymentDetails = useMemo(() => {
    //    return paymentData.paymentDetails.filter(payment =>
    //        Object.values(payment).some(value =>
    //            value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    //        )
    //    );
    //}, [paymentData.paymentDetails, searchTerm]);

    return (
        <div className="modal-wrapper">
            <div className="modal-content !max-w-[1000px]">
                <div className="px-2">
                    <h3 className="text-center text-xl font-bold underline">Payment Details</h3>
                    <div className="mt-4">
                        <div className="mb-4">
                            <p className="mb-1">
                                <strong className="pr-1">Account Name:</strong>
                                {paymentData.accountName}
                            </p>
                            <p className="mb-1">
                                <strong className="pr-1">Payment Date:</strong>
                                {GetDisplayDateStr(paymentData.paymentDate)}
                            </p>
                            <p className="mb-1">
                                <strong className="pr-1">Total Payment:</strong>
                                {GetDisplayCurrencyStr(paymentData.amount)}
                            </p>
                            <p className="mb-1">
                                <strong className="pr-1">Payment Type:</strong>
                                {paymentData.paymentType}
                            </p>
                            {/*{paymentData.paymentType === "Card" && paymentData.identifier && (*/}
                            {/*    <p className="mb-1">*/}
                            {/*        <strong className="pr-1">Card Last 4:</strong>*/}
                            {/*        {paymentData.identifier}*/}
                            {/*    </p>*/}
                            {/*)}*/}
                            {/*{paymentData.paymentType === "Check" && paymentData.checkNumber && (*/}
                            {/*    <p className="mb-1">*/}
                            {/*        <strong className="pr-1">Check Number:</strong>*/}
                            {/*        {paymentData.checkNumber}*/}
                            {/*    </p>*/}
                            {/*)}*/}
                        </div>
                        <div className="ag-theme-alpine ag-grid-act w-full" style={{ height: '300px' }}>
                            <AgGridReact
                                rowData={paymentData.paymentDetails}
                                columnDefs={columnDefs}
                                gridOptions={gridOptions}
                            />
                        </div>
                    </div>
                    <div className="text-right pt-2">
                        <button onClick={onClose} className="btn-close-modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    );
};