import React, { useEffect, useState } from 'react';

import {
    IsEmail, IsPhone, formatToPhone, enforcePhoneFormat, NotEmpty, IsTrue, 
    IsEmpty, IsFalse, Exists, IdExists, ParseIntSafe, PreventDefault, defaultErrMsg,
    NotExists
} from '../../../js/formHelpers.js';


import {
    GetStoreSelectList, GetUserSelectList, IsSuccessStandardResponseAndDataPOST,
} from "../../../js/dataHelpers";
import { SaveOrderPost } from '../_js/_orderDataHelpers.js';

import { ClickById } from "../../../js/helpers";
import { Equals, GreaterThanEq, NotEquals } from '../../../js/calculationHelpers.js';


export const OrderJobModal = (props) => {
    //const navigate = useNavigate();

    const onCloseModal = props.onCloseModal;
    const orderId = props.orderId ?? 0;
    const orderStatus = props.orderStatus ?? 0;
    const orderType = props.orderType ?? "";
    const orderTypeStr = props.orderTypeStr ?? "";
    const billingGroupId = props.billingGroupId ?? 0;

    const [jobData, setJobData] = useState({ 
        jobStoreId: 0,
        jobName: '', 
        jobAddress1: '',
        jobCity: '',
        jobState: '',
        jobZip: '',
        jobNotes: '',
        jobPlacedByName: '',
        jobPlacedByEmail: '',
        jobPlacedByPhone: '',
        jobMaterialNotificationEmails: [],
        jobMaterialNotificationPhones: []
    });

    const [accountData, setAccountData] = useState({
        accountId: 0,
        accountName: '',
        accountAddress1: '',
        accountCity: '',
        accountState: '',
        accountZip: '',
    });

    const [vendorData, setVendorData] = useState({
        vendorId: 0,
        vendorName: '',
        vendorAddress1: '',
        vendorCity: '',
        vendorState: '',
        vendorZip: '',
    });

    const [orderData, setOrderData] = useState(null)

    const [materialEmailInput, setMaterialEmailInput] = useState('');
    const [materialPhoneInput, setMaterialPhoneInput] = useState('');
    
    const [materialEmailsToShow, setMaterialEmailsToShow] = useState([]);
    const [materialPhonesToShow, setMaterialPhonesToShow] = useState([]);
    
    const [message, setMessage] = useState("");

    const [userList, setUserList] = useState([]);
    const [storeList, setStoreList] = useState([]);

    async function createUserList() {
        let list = [];
        //list.push(
        //    <option key="0" value="0">None</option>
        //);

        const dataList = await GetUserSelectList("sales");
        dataList.forEach((item, index) => {
            list.push(
                <option key={index + 1} value={item.value}>{item.text}</option>
            );
        });
        setUserList(list);
    }

    async function createStoreList() {
        var list = [];

        var dataList = await GetStoreSelectList(1);
        dataList.forEach((item, index) => {
            list.push(
                <option key={index + 1} value={item.value}>{item.text}</option>
            );
        });
        setStoreList(list);
    }

    function loadDataLists() {
        createUserList();

        // only set warehouse store on load
        if (!IdExists(orderId)) {
            createStoreList();

            if (isInventory()) {
                saveAndCloseModal();
            }
            else if (isClaim()) {
                ClickById("openAddVendorModal");
            }
            else {
                if (!IdExists(props?.accountData?.accountId)) {
                    ClickById("openAddAccountModal");
                }
            }
        }
    }

    useEffect(() => {
        if (props.jobData) {
            setJobData(prevData => ({
                ...prevData,
                ...props.jobData,
                jobMaterialNotificationEmails: props.jobData.jobMaterialNotificationEmails || [],
                jobMaterialNotificationPhones: props.jobData.jobMaterialNotificationPhones || [],
            }));
        }

      setMaterialEmailsToShow([
        ...new Set([props.accountData?.materialNotificationEmail,
          ...props.jobData.jobMaterialNotificationEmails,
          jobData.jobPlacedByEmail])]
        .filter(e => e != ''));

      setMaterialPhonesToShow([
        ...new Set([props.accountData?.materialNotificationPhone,
          ...props.jobData.jobMaterialNotificationPhones,
          jobData.jobPlacedByPhone])]
        .filter(e => e != ''));
      
    }, [props.jobData]);

    useEffect(() => {
        if (props.orderData) {
            setOrderData(prevData => ({
                ...prevData,
                ...props.orderData
            }));
        }
    }, []);

    useEffect(() => {
        if (props.accountData) {

            if (!IdExists(orderData?.orderId) && GreaterThanEq(props.accountData.defaultPriceLevelId, 0)) {
                let value = props.accountData.defaultPriceLevelId;
                setOrderData(prevData => ({ ...prevData, priceLevelId: value }));
            }

            setAccountData(prevData => ({
                ...prevData,
                ...props.accountData,
                accountAddress1: props.accountData.address1,
                accountCity: props.accountData.city,
                accountState: props.accountData.state,
                accountZip: props.accountData.zip
            }));
        }
    }, [props.accountData, orderData?.orderId])

    useEffect(() => {
        if (props.vendorData) {

            setVendorData(prevData => ({
                ...prevData,
                ...props.vendorData,
                vendorId: props.vendorData.vendorId,
                vendorName: props.vendorData.name,
                vendorAddress1: props.vendorData.address1,
                vendorCity: props.vendorData.city,
                vendorState: props.vendorData.state,
                vendorZip: props.vendorData.zip
            }));
        }
    }, [props.vendorData])

    useEffect(() => {
        loadDataLists();
    }, []);

    const handleAddEmail = (email) => {
        var isOpb = true;
        if (NotExists(email)) {
            isOpb = false;
            email = materialEmailInput;
        }

        if (IsEmail(email)) {
            if (!jobData.jobMaterialNotificationEmails.includes(email)) {
                setJobData(prevData => ({
                    ...prevData,
                    jobMaterialNotificationEmails: [...prevData.jobMaterialNotificationEmails, email]
                }));
                
                setMaterialEmailsToShow(prevData => ([
                  ...prevData,
                  email
                ]))
                
                setMaterialEmailInput('');
                setMessage("");
            } else {
                if (IsFalse(isOpb)) {
                    setMessage("This email is already in the list.");
                }
            }
        } else {
            if (IsTrue(isOpb)) {
                setMessage("OPB email must be valid.");
            } else {
                setMessage("Please enter a valid email address.");
            }
        }
    };

    const handleAddPhone = () => {
        if (IsPhone(materialPhoneInput)) {
            if (!jobData.jobMaterialNotificationPhones.includes(materialPhoneInput)) {
                setJobData(prevData => ({
                    ...prevData,
                    jobMaterialNotificationPhones: [...prevData.jobMaterialNotificationPhones, materialPhoneInput]
                }));
                
                setMaterialPhonesToShow(prevData => ([
                  ...prevData,
                  materialPhoneInput
                ]))
                
                setMaterialPhoneInput('');
                setMessage("");
            } else {
                setMessage("This phone number is already in the list.");
            }
        } else {
            setMessage("Please enter a valid phone number.");
        }
    };

    const handleRemoveEmail = (email) => {

      console.log(email)
      
        setJobData(prevData => ({
            ...prevData,
            jobMaterialNotificationEmails: prevData.jobMaterialNotificationEmails.filter((v, _) => v !== email)
        }));
      
        const newEmailsToShow = materialEmailsToShow.filter(e => e !== email) 
      
        setMaterialEmailsToShow(newEmailsToShow)
    };

    const handleRemovePhone = (phone) => {

      console.log(materialPhonesToShow)
      console.log(phone)
      
        setJobData(prevData => ({
            ...prevData,
            jobMaterialNotificationPhones: prevData.jobMaterialNotificationPhones.filter((p, _) => p !== phone)
        }));
        
        const newPhonesToShow = materialPhonesToShow.filter(p => p !== phone)
      
        setMaterialPhonesToShow(newPhonesToShow)
    };

    const handleChangeJobData = (e) => {
        const { name, value } = e.target;
        setJobData(prevData => ({ ...prevData, [name]: value }));
    };

    const handleChangeOrderData = (e) => {
        const { name, value } = e.target;
        setOrderData(prevData => ({ ...prevData, [name]: value }));
    };

    function validateData() {
        let valid = true;

        if (IsFalse(isInventory())) {

            if (accountData.accountId === 0) {
                valid = false;

                if (IsTrue(isClaim())) {
                    setMessage("Claim For Account is required.");
                }
                else {
                    setMessage("Account is required.");
                }
            }

            if (IsFalse(isClaim())) {

                if (IsEmpty(jobData.jobName)) {
                    valid = false;
                    setMessage("Job Name is required.");
                }
                if (IsEmpty(jobData.jobPlacedByName)) {
                    valid = false
                    setMessage("OPB Name is required.");
                }
                if (NotEmpty(jobData.jobPlacedByEmail) && !IsEmail(jobData.jobPlacedByEmail)) {
                    setMessage("OPB email must be valid.");
                    valid = false;
                }
                if (NotEmpty(jobData.jobPlacedByPhone) && !IsPhone(jobData.jobPlacedByPhone)) {
                    setMessage("OPB phone must be valid.");
                    valid = false;
                }
            }
        }
        return valid;
    }

    function isQuote(isQuoteType) {
        let isQuote = false;
        if (Exists(isQuoteType)) {
            isQuote = IsTrue(isQuoteType);
        }
        else if (orderType === 2) {
            isQuote = true;
        }
        else if (orderData?.orderType === 2) {
            isQuote = true;
        }
        else if (orderData) {
            isQuote = IsTrue(orderData.isQuoteType);
        }
        return isQuote;
    }

    function isClaim(isClaimType) {
        let isClaim = false;
        if (Exists(isClaimType)) {
            isClaim = IsTrue(isClaimType);
        }
        else if (orderType === 3) {
            isClaim = true;
        }
        else if (orderData?.orderType === 3) {
            isClaim = true;
        }
        else if (orderData) {
            isClaim = IsTrue(orderData.isClaimType);
        }
        return isClaim;
    }

    function isInventory(isInventoryType) {
        let isInventory = false;
        if (Exists(isInventoryType)) {
            isInventory = IsTrue(isInventoryType);
        }
        else if (orderType === 5) {
            isInventory = true;
        }
        else if (orderData?.orderType === 5) {
            isInventory = true;
        }
        else if (orderData) {
            isInventory = IsTrue(orderData.isInventoryType);
        }
        return isInventory;
    }

    async function saveJobData() {
        var success = false;
        var response = null;

        try {
            const postModel = {
                userId: props?.userId,
                orderId: orderId,
                orderType: orderType,
                storeId: jobData.jobStoreId,
                accountId: accountData.accountId,
                billingGroupId: billingGroupId,
                jobModel: jobData,
                jobNotes: orderData?.jobNotes,
                priceLevelId: orderData?.priceLevelId,
                placedByName: jobData.jobPlacedByName,
                placedByEmail: jobData.jobPlacedByEmail,
                placedByPhone: jobData.jobPlacedByPhone,
                poNumber: orderData?.poNumber,
                termsId: orderData?.termsId,
                materialNotificationEmails: jobData.jobMaterialNotificationEmails,
                materialNotificationPhones: jobData.jobMaterialNotificationPhones,
                salesperson1Id: orderData?.salesperson1Id,
                salesperson2Id: orderData?.salesperson2Id,
                taxTypeId: orderData?.taxTypeId,
                claimAgainstVendorId: vendorData?.vendorId,
                // TODO: dev - remove
                rfmsOrderNumber: orderData?.rfmsOrderNumber,
            }

            if (!IdExists(orderId)) {
                if (isClaim()) {
                    //set for POST validation
                    postModel.jobModel.jobName = "Claim";
                }
                if (isInventory()) {
                    //set for POST validation
                    postModel.jobModel.jobName = "Inventory";
                    postModel.salesperson1Id = props?.orderData?.salesperson1Id ?? 0;
                }
            }

            response = await SaveOrderPost(postModel);
            if (IsSuccessStandardResponseAndDataPOST(response)) {
                const iOrderId = ParseIntSafe(response.orderId);
                if (IdExists(iOrderId)) {
                    postModel.orderId = iOrderId
                    success = true;
                    return postModel;
                }
            }
        }
        catch (error) {
            console.log('Error: ' + error);
        }

        if (success === false) {
            console.error("saveJobData() - error: ", JSON.stringify(response));
        }
        return null;
    }

    async function saveAndCloseModal(e) {
        PreventDefault(e);
        setMessage("");

        let valid = validateData();
        if (valid === true) {
            let postModel = await saveJobData();
            if (IdExists(postModel?.orderId)) {
                onCloseModal(e, postModel);
            }
            else {
                alert(defaultErrMsg());
            }
        }
    }

    return (
        <div className="w-[800px] py-0 px-4">
            <span className="font-bold text-xl text-secondary underline">{orderTypeStr} Information</span>

            <div className="grid grid-cols-1 gap-6 mt-3">
                <div className="w-full">
                    <div className="flex gap-4 w-full">
                        <div className="w-1/2">
                            <div className="flex gap-2 pb-1">
                                <h2 className="font-bold text-lg text-secondary">
                                    {IsFalse(isClaim()) ? "Account" : "Claim For"}
                                </h2>

                                <button type="button" id="openAddAccountModal"
                                    onClick={() => props.setOpenAddAccountModal(prev => !prev)}
                                    disabled={orderStatus === 2}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                        stroke="currentColor" className="w-5 h-5 font-bold text-[#3c5ca8]">
                                        <path strokeLinecap="round" strokeLinejoin="round"
                                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                    </svg>
                                </button>
                            </div>

                            <div className="grid grid-cols-1 gap-2">
                                <input
                                    readOnly
                                    type="text"
                                    name="accountName"
                                    className="w-full sales-input"
                                    value={accountData.accountName}
                                    placeholder="Account Name"
                                />

                                <input
                                    readOnly
                                    type="text"
                                    name="accountAddress1"
                                    className="w-full sales-input"
                                    value={accountData.accountAddress1}
                                    placeholder="Account Address"
                                />

                                <div className="flex gap-2">
                                    <input
                                        readOnly
                                        type="text"
                                        name="accountCity"
                                        className="w-full sales-input"
                                        value={accountData.accountCity}
                                        placeholder="City"
                                    />

                                    <input
                                        readOnly
                                        type="text"
                                        name="accountState"
                                        className="w-24 sales-input"
                                        value={accountData.accountState}
                                        placeholder="State"
                                    />

                                    <input
                                        readOnly
                                        type="text"
                                        name="accountZip"
                                        className="w-16 sales-input"
                                        value={accountData.accountZip}
                                        placeholder="Zip"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="w-1/2">
                            {IsFalse(isClaim()) &&
                                <div>
                                    <h2 className="font-bold text-lg text-secondary pb-1">Pricing</h2>
                                    <select className="w-full border-[#cccccc] rounded px-2 py-1 cursor-pointer"
                                        name="priceLevelId"
                                        disabled={orderStatus === 2}
                                        value={orderData?.priceLevelId}
                                        onChange={handleChangeOrderData}>
                                        <option value={0}>BC</option>
                                        <option value={1}>BC1 (10%)</option>
                                        <option value={2}>BC2 (15%)</option>
                                        <option value={3}>BC3 (20%)</option>
                                        <option value={5}>BC5 (5%)</option>
                                    </select>

                                    <input id="rfmsOrderNumber" name="rfmsOrderNumber"
                                        type="text" className="sales-input mt-[46px]"
                                        maxLength="20"
                                        placeholder="RFMS Order #"
                                        disabled={orderStatus === 2}
                                        onChange={handleChangeOrderData}
                                        value={orderData?.rfmsOrderNumber}
                                    />
                                </div>
                            }
                            {IsTrue(isClaim()) &&
                                <div>
                                    <div className="flex gap-2 pb-1">
                                        <h2 className="font-bold text-lg text-secondary">Claim Against</h2>

                                        <button type="button" id="openAddVendorModal"
                                            onClick={() => props.setOpenAddVendorModal(prev => !prev)}
                                            disabled={orderStatus === 2}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                                                stroke="currentColor" className="w-5 h-5 font-bold text-[#3c5ca8]">
                                                <path strokeLinecap="round" strokeLinejoin="round"
                                                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                                            </svg>
                                        </button>
                                    </div>

                                    <div className="grid grid-cols-1 gap-2">
                                        <input
                                            readOnly
                                            type="text"
                                            name="accountName"
                                            className="w-full sales-input"
                                            value={vendorData.vendorName}
                                            placeholder="Account Name"
                                        />

                                        <input
                                            readOnly
                                            type="text"
                                            name="accountAddress1"
                                            className="w-full sales-input"
                                            value={vendorData.vendorAddress1}
                                            placeholder="Account Address"
                                        />

                                        <div className="flex gap-2">
                                            <input
                                                readOnly
                                                type="text"
                                                name="accountCity"
                                                className="w-full sales-input"
                                                value={vendorData.vendorCity}
                                                placeholder="City"
                                            />

                                            <input
                                                readOnly
                                                type="text"
                                                name="accountState"
                                                className="w-24 sales-input"
                                                value={vendorData.vendorState}
                                                placeholder="State"
                                            />

                                            <input
                                                readOnly
                                                type="text"
                                                name="accountZip"
                                                className="w-16 sales-input"
                                                value={vendorData.vendorZip}
                                                placeholder="Zip"
                                            />
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>

                    </div>
                </div>

                <div className="w-full">
                    <div className="flex gap-4 w-full">
                        <div className="w-1/2">
                            <h2 className="font-bold text-lg text-secondary pb-1">Job</h2>

                            {IsFalse(isClaim()) &&
                                <div className="grid grid-cols-1 gap-2">
                                    <input
                                        type="text"
                                        name="jobName"
                                        className="w-full sales-input"
                                        disabled={orderStatus === 2}
                                        value={jobData.jobName}
                                        onChange={handleChangeJobData}
                                        placeholder="Job Name"
                                    />

                                    <input
                                        type="text"
                                        name="jobAddress1"
                                        className="w-full sales-input"
                                        disabled={orderStatus === 2}
                                        value={jobData.jobAddress1}
                                        onChange={handleChangeJobData}
                                        placeholder="Address"
                                    />

                                    <div className="flex gap-2">
                                        <input
                                            type="text"
                                            name="jobCity"
                                            className="w-full sales-input"
                                            disabled={orderStatus === 2}
                                            value={jobData.jobCity}
                                            onChange={handleChangeJobData}
                                            placeholder="City"
                                        />

                                        <input
                                            type="text"
                                            name="jobState"
                                            className="w-24 sales-input"
                                            disabled={orderStatus === 2}
                                            value={jobData.jobState}
                                            onChange={handleChangeJobData}
                                            placeholder="State"
                                        />

                                        <input type="number" name="jobZip"
                                            className="w-16 sales-input"
                                            disabled={orderStatus === 2}
                                            value={jobData.jobZip}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 5) {
                                                    handleChangeJobData(e)
                                                }
                                            }}
                                            placeholder="Zip"
                                            maxLength="5"
                                        />
                                    </div>

                                    <input type="text" name="jobNotes"
                                        className="w-full sales-input"
                                        disabled={orderStatus === 2}
                                        value={orderData?.jobNotes}
                                        onChange={handleChangeOrderData}
                                        placeholder="Detail"
                                    />

                                    <input type="text" name="poNumber"
                                        className="w-full sales-input"
                                        disabled={orderStatus === 2}
                                        value={orderData?.poNumber}
                                        onChange={handleChangeOrderData}
                                        placeholder="PO"
                                    />
                                </div>
                            }
                            {IsTrue(isClaim()) &&
                                <div className="grid grid-cols-1 gap-2">
                                    <input type="text" name="jobNotes"
                                        className="w-full sales-input"
                                        disabled={orderStatus === 2}
                                        value={orderData?.jobNotes}
                                        onChange={handleChangeOrderData}
                                        placeholder="Detail"
                                    />
                                </div>
                            }
                        </div>

                        <div className="w-1/2">
                            <h2 className="font-bold text-lg text-secondary pb-1">Details</h2>
                            <div className="grid grid-cols-1 gap-2">

                                {IsFalse(isClaim()) &&
                                    <input type="text" name="jobPlacedByName"
                                    className="w-full sales-input"
                                    disabled={orderStatus === 2}
                                        value={jobData.jobPlacedByName}
                                        onChange={handleChangeJobData}
                                        placeholder="Placed by Name"
                                    />
                                }
                                {IsFalse(isClaim()) &&

                                    <input type="text" name="jobPlacedByPhone"
                                    className="w-full sales-input"
                                    disabled={orderStatus === 2}
                                        onKeyDown={e => enforcePhoneFormat(e)}
                                        value={jobData.jobPlacedByPhone}
                                        onChange={(e) => setJobData(prevData => ({
                                            ...prevData,
                                            jobPlacedByPhone: formatToPhone(e)
                                        }))}
                                        placeholder="Placed by Phone"
                                    />
                                }
                                {IsFalse(isClaim()) &&
                                    <input type="email" name="jobPlacedByEmail"                                    
                                    className="w-full sales-input"
                                    disabled={orderStatus === 2}
                                        value={jobData.jobPlacedByEmail}
                                        onChange={handleChangeJobData}
                                        onBlur={(e) => handleAddEmail(e.target.value)}
                                        placeholder="Placed by Email"
                                    />
                                }

                                <select name="salesperson1Id"
                                    className="w-full border-[#cccccc] rounded px-2 py-1 cursor-pointer"
                                    disabled={orderStatus === 2}
                                    value={orderData?.salesperson1Id}
                                    onChange={handleChangeOrderData}
                                >
                                    <option value="0">-- Salesperson 1 --</option>
                                    {userList}
                                </select>

                                <select name={"salesperson2Id"}
                                    className="w-full border-[#cccccc] rounded px-2 py-1 cursor-pointer"                                    
                                    disabled={orderStatus === 2}
                                    value={orderData?.salesperson2Id}
                                    onChange={handleChangeOrderData}
                                >
                                    <option value="0">-- Salesperson 2 --</option>
                                    {userList}
                                </select>


                                {(Equals(orderId, 0) && IsFalse(isClaim())) &&
                                    <select id="jobStoreId" name="jobStoreId"
                                        className="w-full border-[#cccccc] rounded px-2 py-1 cursor-pointer"
                                        disabled={orderStatus === 2}
                                        value={jobData.jobStoreId}
                                        onChange={handleChangeJobData}
                                    >
                                        <option value="">-- Ship to Store --</option>
                                        {storeList}
                                    </select>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {IsFalse(isClaim()) &&
                    <div className="w-full">
                        <h2 className="font-bold text-lg text-secondary pb-1">Material Arrival Notifications</h2>
                        <div className="flex gap-4 w-full">

                            <div className="w-1/2">
                                <div className="grid grid-cols-1 gap-2 w-full">
                                    <div className="flex items-center gap-2">
                                        <input type="email"
                                            className="w-full sales-input"
                                            disabled={orderStatus === 2}
                                            value={materialEmailInput}
                                            onChange={(e) => setMaterialEmailInput(e.target.value)}
                                            placeholder="Email"
                                        />
                                        <button type="button"
                                            className="px-2 py-1 bg-secondary text-white rounded text-sm"
                                            disabled={orderStatus === 2}
                                            onClick={() => handleAddEmail()}
                                        >+</button>
                                    </div>

                                    <div className="text-primary pl-[1px] pr-[7px]">
                                        {materialEmailsToShow.map((email, index) => (
                                            <div key={index} className="flex items-center justify-between">
                                                <span>{email}</span>
                                                {props.accountData?.materialNotificationEmail !== email && (
                                                    <button type="button"
                                                        className="text-red text-sm font-bold"
                                                        disabled={orderStatus === 2}
                                                        onClick={() => handleRemoveEmail(email)}
                                                    >✕</button>
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>

                          <div className="w-1/2">
                            <div className="grid grid-cols-1 gap-2 w-full">
                                    <div className="flex items-center gap-2">

                                        <input type="text" className="w-full border-[#cccccc] rounded px-2 py-1"
                                            disabled={orderStatus === 2}
                                            onKeyDown={e => enforcePhoneFormat(e)}
                                            value={materialPhoneInput}
                                            onChange={(e) => setMaterialPhoneInput(formatToPhone(e))}
                                            placeholder="Phone / Text"
                                        />
                                        <button type="button"
                                            className="px-2 py-1 bg-secondary text-white rounded text-sm"
                                            disabled={orderStatus === 2}
                                            onClick={handleAddPhone}
                                        >+</button>
                                    </div>
                                    <div className="text-primary pl-[1px] pr-[7px]">
                                        {materialPhonesToShow.map((phone, index) => (
                                            <div key={index} className="flex items-center justify-between">
                                                <span>{phone}</span>
                                                {
                                                    props.accountData?.materialNotificationPhone !== phone && (
                                                        <button type="button"
                                                            className="text-red text-sm font-bold"
                                                            disabled={orderStatus === 2}
                                                            onClick={() => handleRemovePhone(phone)}
                                                        >
                                                            ✕
                                                        </button>
                                                    )
                                                }
                                            </div>
                                        ))}
                                    </div>
                            </div>
                          </div>
                        </div>
                    </div>
                }
            </div>

          {/* Error or Success Message */}
            {message && (
                <div className={'p-4 rounded-md bg-red-100 text-red-700 my-4'}>
                    {message}
                </div>
            )}

            <div className="text-right mt-2">
                <button type="button" className="btn-cancel" onClick={props.onCloseModal}>
                    Cancel
                </button>
                {NotEquals(orderStatus, 2) &&
                    <button type="button" className="btn-submit" onClick={(e) => saveAndCloseModal(e)}>
                        Save & Close
                    </button>
                }
            </div>

        </div>
    )
}