import { Exists, IdExists, IsTrue, NotEmpty, NotStringEmpty, StringEmpty } from "./formHelpers";
import { get, post } from '../services/apiService.js';


// Standard GET by Id and return data object ------------------------------------------------------------
export async function StandardIdAndResponseGET(id, endpoint, typeStr) {
    var resp = null;
    if (Exists(id) && Exists(endpoint) && Exists(typeStr)) {

        //var apiUrl = process.env.REACT_APP_API_URL + endpoint + id;
        endpoint += id;
        try {
            const response = await get(endpoint);
            //console.log('Response: ' + JSON.stringify(response));

            if (response.value) {
                var respData = response.value;
                if (respData.success === true) {
                    // TODO: send full data back so can check success in method?
                    //resp = respData.data;

                    // send back data on success, validation in controller
                    // return data needed for page
                    if (Exists(respData.data)) {
                        resp = respData.data;
                    }
                    else {
                        var msg = typeStr + " no data returned for API url: " + endpoint;
                        console.log(msg);
                        resp = msg;
                    }
                }
                else {
                    const errorMsg = typeStr + " apiUrl: " + endpoint + " - success = false: " + respData;
                    console.error(errorMsg);
                    resp = errorMsg + " - " + respData;
                }
            }
            else {
                const errorMsg = typeStr + " apiUrl: " + endpoint + " - error, no response.value: " + response;
                console.error(errorMsg);
                resp = errorMsg + " - " + response;
            }
        } catch (error) {
            var errorMsg = typeStr + " apiUrl: " + endpoint + " - fetch error: ";
            console.error(errorMsg, error);
            resp = errorMsg + " - " + error;
        }
    }
    else {
        resp = "No id, endpoint, or typeStr supplied for POST - " + id + ", " + endpoint + ", " + typeStr;
        console.error(resp);
    }
    return resp;
};


// AgGrid GET and return data object ------------------------------------------------------------
export async function AgGridServerSideResponseGET(endpoint, typeStr) {
    var resp = null;
    if (Exists(endpoint) && Exists(typeStr)) {

        try {
            //const response = get(endpoint);
            //console.log('Response: ' + JSON.stringify(response));
            const response = get(endpoint);
            if (Exists(response)) {
                resp = response;
            }
            else {
                var msg = typeStr + " no data returned for API url: " + endpoint;
                console.log(msg);
                resp = msg;
            }

            //if (response.value) {
            //    var respData = response.value;
            //    if (respData.success === true) {
            //        // TODO: send full data back so can check success in method?
            //        //resp = respData.data;

            //        // send back data on success, validation in controller
            //        // return data needed for page
            //        if (Exists(respData.data)) {
            //            resp = respData.data;
            //        }
            //        else {
            //            var msg = typeStr + " no data returned for API url: " + endpoint;
            //            console.log(msg);
            //            resp = msg;
            //        }
            //    }
            //    else {
            //        const errorMsg = typeStr + " apiUrl: " + endpoint + " - success = false: " + respData;
            //        console.error(errorMsg);
            //        resp = errorMsg + " - " + respData;
            //    }
            //}
            //else {
            //    const errorMsg = typeStr + " apiUrl: " + endpoint + " - error, no response.value: " + response;
            //    console.error(errorMsg);
            //    resp = errorMsg + " - " + response;
            //}
        } catch (error) {
            var errorMsg = typeStr + " apiUrl: " + endpoint + " - fetch error: ";
            console.error(errorMsg, error);
            resp = errorMsg + " - " + error;
        }
    }
    else {
        resp = "No endpoint, or typeStr supplied for POST - " + endpoint + ", " + typeStr;
        console.error(resp);
    }
    return resp;
};


// Standard POST and return data object ------------------------------------------------------------
export async function IsSuccessStandardResponseAndDataPOST(response) {
    if (Exists(response) && IsTrue(response.success) && Exists(response.data)) {
        return true;
    }
    return false;
}

export async function StandardDataAndResponsePOST(postModel, endpoint, typeStr) {
    var resp = null;
    if (Exists(postModel) && Exists(endpoint) && Exists(typeStr)) {
        try {
            const response = await post(endpoint, postModel);
            //console.log('Response: ' + JSON.stringify(response));

            if (response.value) {
                var respData = response.value;
                if (respData.success === true) {
                    // TODO: send full data back so can check success in method?
                    //resp = respData.data;

                    // send back data on success, validation in controller
                    // return data needed for page
                    resp = respData.data;
                }
                else {
                    const errorMsg = typeStr + " apiUrl: " + endpoint + " - success = false: " + respData;
                    console.error(errorMsg);
                    resp = errorMsg + " - " + respData;
                }
            }
            else {
                const errorMsg = typeStr + " apiUrl: " + endpoint + " - error, no response.value: " + response;
                console.error(errorMsg);
                resp = errorMsg + " - " + response;
            }
        } catch (error) {
            var errorMsg = typeStr + " apiUrl: " + endpoint + " - fetch error: ";
            console.error(errorMsg, error);
            resp = errorMsg + " - " + error;
        }
    }
    else {
        resp = "No postModel, endpoint, or typeStr supplied for POST - " + postModel + ", " + endpoint + ", " + typeStr;
        console.error(resp);
    }
    return resp;
}

// Get Stores ------------------------------------------------------------

export async function GetStoreSelectList(orderStores) {
    var items = [];

    var data = await GetStoreData(orderStores);
    if (NotEmpty(data)) {
        data.forEach((store, index) => {
            var item = null;
            if (orderStores === 1 || orderStores === 2) {
                item = {
                    value: store.storeId,
                    text: store.displayName
                };
            }
            else {  // orderStores === 0                
                item = {
                    value: store.storeId,
                    text: store.name
                };
            }
            items.push(item);
        });
    }
    return items;
}

async function GetStoreData(orderStores) {
    var data = [];

    var endpoint = '';
    if (orderStores === 1) {
        endpoint += "data/GetOrderStores";
    }
    else if (orderStores === 2) {
        endpoint += "data/GetWarehouseStores";
    }
    else {  // orderStores === 0
        endpoint += "data/GetAllStores";
    }

    try {
        const response = await get(endpoint);
        //console.log('Response: ' + JSON.stringify(response));

        if (response && response.length > 0) {
            data = response;
        }
        else {
            console.log("No store data returned");
            console.log("apiUrl: " + endpoint);
        }
    } catch (error) {
        console.log("Error:" + error);
    }
    
    return data;
}

// Get Users ------------------------------------------------------------

export async function GetUserSelectList(userType) {
    var items = [];

    var data = await GetUserData(userType);
    if (data && data.length > 0) {
        data.forEach((user, index) => {

            var text = "";
            if (userType === "sales") {
                //text = user.firstName + " " + user.lastName;
                text = user.firstName;      // FullName or UserName set to FirstName
            }

            // set default here
            if (StringEmpty(text)) {
                text = user.userName;
            }

            var item = {
                value: user.userId,
                text: text
            };
            items.push(item);
        });
    }
    return items;
}

async function GetUserData(userType) {
    var data = [];

    var endpoint = '';
    if (userType === "sales") {
        endpoint += "data/GetSalesPersonUsers";
    }
    else {
        endpoint += "data/GetUsers";
    }

    try {
        const response = await get(endpoint);
        //console.log('Response: ' + JSON.stringify(response));

        if (response && response.length > 0) {
            data = response;
        }
        else {
            console.log("No user data returned");
            console.log("apiUrl: " + endpoint);
        }
    } catch (error) {
        console.log('Error: ' + error);
    }

    return data;
}


// Get Enum Select List ------------------------------------------------------------

export async function GetEnumSelectList(type) {
    var items = [];

    var data = await GetEnumSelectListData(type);
    if (data && data.length > 0) {
        data.forEach((li, index) => {            
            var item = {
                value: li.value,
                text: li.text
            };
            items.push(item);
        });
    }
    return items;
}

async function GetEnumSelectListData(type) {
    var data = [];

    const endpoint = 'data/GetEnumSelectList?type=' + type;
    try {
        const response = await get(endpoint);
        //console.log('Response: ' + JSON.stringify(response));

        if (response && response.length > 0) {
            data = response;
        }
        else {
            console.log("GetEnumSelectListData no data returned");
            console.log("apiUrl: " + endpoint);
        }
    } catch (error) {
        console.log('Error: ' + error);
    }

    return data;
}

// Get Vendors  ------------------------------------------------------------

export async function GetVendorSelectList(type) {
    var items = [];

    var data = await GetVendorList(type);
    if (data && data.length > 0) {
        data.forEach((vendor, index) => {
            var item = {
                value: vendor.vendorId,
                text: vendor.name,
                inactive: vendor.inactive
            };
            items.push(item);
        });
    }
    return items;
}

async function GetVendorList(type) {
    var data = [];
    const endpoint = 'data/GetVendorList?type=' + type;
    try {
        const response = await get(endpoint);
        //console.log('Response: ' + JSON.stringify(response));

        if (response && response.length > 0) {
            //console.log('store data: ' + JSON.stringify(respData));
            data = response;
        }
        else {
            console.log("GetVendorData no data returned");
            console.log("apiUrl: " + endpoint);
        }
    } catch (error) {
        console.log('Error: ' + error);

    }

    return data;
}


// Get Product Categories  ------------------------------------------------------------

export async function GetProductCategorySelectList(type) {
    var items = [];

    var data = await GetProductCategoryList(type);
    if (data && data.length > 0) {
        data.forEach((pc, index) => {
            var item = {
                value: pc.productCategoryId,
                text: pc.name
            };
            items.push(item);
        });
    }
    return items;
}

async function GetProductCategoryList(type) {
    var data = [];

    const endpoint = 'data/GetProductCategoryList?type=' + type;
    try {
        const response = await get(endpoint);
        //console.log('Response: ' + JSON.stringify(response));

        if (response && response.length > 0) {
            data = response;
        }
        else {
            console.log("GetProductCategoryData no data returned");
            console.log("apiUrl: " + endpoint);
        }
    } catch (error) {
        console.log('Error: ' + error);
    }

    return data;
}

// Get Units

export async function GetUnitsSelectList() {
    var items = [];

    // matches Units.cs enum - [0,1,2,3,4,5,6,7]    
    const data = ["CT", "EA", "LF", "RL", "SF", "SH", "SY"]; // "None" = 0
    if (NotEmpty(data)) {
        data.forEach((unit, index) => {
            var item = {
                value: unit,
                text: unit
            };
            items.push(item);
        });
    }
    return items;
}


export async function GetUserRoles() {
    var roles = [];
    try {
        const data = await StandardIdAndResponseGET("", '/user/getroles', "User - loadRoles()");
        if (Exists(data)) {
            if (Array.isArray(data)) {
                roles = data;
                //console.log("GetUserRoles:", data);
            } else {
                throw new Error('unexpected data structure received');
            }
        }
        else {
            throw new Error('no data');
        }
    } catch (error) {
        console.error("GetUserRoles() error:", error);
    }
    return roles;
}