import React from 'react';

export const ECheckContent = ({
    accountType,
    setAccountType,
    bankName,
    setBankName,
    accountNumber,
    setAccountNumber,
    confirmAccountNumber,
    setConfirmAccountNumber,
    routingNumber,
    setRoutingNumber,
    confirmRoutingNumber,
    setConfirmRoutingNumber,
    nameOnAccount,
    setNameOnAccount
}) => {
    return (
        <div className="space-y-4">
            {/* Account Type */}
            <div className="grid grid-cols-2 gap-4">
                <div className="form-control">
                    <select
                        value={accountType}
                        onChange={(e) => setAccountType(e.target.value)}
                        className="select select-bordered w-full select-sm !h-8 p-0 pl-3"
                        required
                    >
                        <option value="checking">Checking</option>
                        <option value="savings">Savings</option>
                    </select>
                </div>

                <div className="form-control">
                    <input
                        type="text"
                        value={bankName}
                        onChange={(e) => setBankName(e.target.value)}
                        className="input input-bordered w-full h-8"
                        placeholder="Bank Name"
                        required
                    />
                </div>
            </div>

            {/* Account Number */}
            <div className="grid grid-cols-2 gap-4">
                <div className="form-control">
                    <input
                        type="password"
                        value={accountNumber}
                        onChange={(e) => setAccountNumber(e.target.value.replace(/\D/g, ''))}
                        className="input input-bordered w-full h-8"
                        placeholder="Account #"
                        required
                    />
                </div>

                <div className="form-control">
                    <input
                        type="text"
                        value={confirmAccountNumber}
                        onChange={(e) => setConfirmAccountNumber(e.target.value.replace(/\D/g, ''))}
                        className={`input input-bordered w-full h-8 ${confirmAccountNumber && accountNumber !== confirmAccountNumber
                            ? 'border-red-500'
                            : ''
                            }`}
                        placeholder="Confirm Account #"
                        required
                    />
                </div>
            </div>
            {confirmAccountNumber && accountNumber !== confirmAccountNumber && (
                <div className="text-red-500 text-sm mt-1">Account numbers do not match</div>
            )}

            {/* Routing Number */}
            <div className="grid grid-cols-2 gap-4">
                <div className="form-control">
                    <input
                        type="text"
                        value={routingNumber}
                        onChange={(e) => setRoutingNumber(e.target.value.replace(/\D/g, ''))}
                        maxLength={9}
                        className="input input-bordered w-full h-8"
                        placeholder="9-digit Routing #"
                        required
                    />
                </div>

                <div className="form-control">
                    <input
                        type="text"
                        value={confirmRoutingNumber}
                        onChange={(e) => setConfirmRoutingNumber(e.target.value.replace(/\D/g, ''))}
                        maxLength={9}
                        className={`input input-bordered w-full h-8 ${confirmRoutingNumber && routingNumber !== confirmRoutingNumber
                            ? 'border-red-500'
                            : ''
                            }`}
                        placeholder="Confirm Routing #"
                        required
                    />
                </div>
            </div>
            {confirmRoutingNumber && routingNumber !== confirmRoutingNumber && (
                <div className="text-red-500 text-sm mt-1">Routing numbers do not match</div>
            )}

            {/* Name */}
            <div className="form-control">
                <input
                    type="text"
                    value={nameOnAccount}
                    onChange={(e) => setNameOnAccount(e.target.value)}
                    className="input input-bordered w-full h-8"
                    placeholder="Name on Account"
                    required
                />
            </div>
        </div>
    );
};
