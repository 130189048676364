import { StandardDataAndResponsePOST } from "../../../js/dataHelpers";
import { Exists, IdExists, NotEmpty } from "../../../js/formHelpers";

import { post } from '../../../services/apiService.js';

// Save Order POST ------------------------------------------------------------

export async function SaveOrderPost(model) {
    var response = null;

    var postModel = JSON.stringify(model);
    var endpoint = "orders/SaveOrder";
    var typeStr = "SaveOrderPost";
    response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
    return response;
}

// Close Order POST ------------------------------------------------------------

export async function ChangeOrderStatusPost(model) {
    var response = null;

    var postModel = JSON.stringify(model);
    var endpoint = "orders/ChangeOrderStatus";
    var typeStr = "ChangeOrderStatusPost";
    response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
    return response;
}

// Delete OrderLines ------------------------------------------------------------

export async function DeleteOrderLinesPost(olIds) {
    var response = null;

    if (NotEmpty(olIds)) {
        var postModel = JSON.stringify(olIds);
        var endpoint = "orders/DeleteOrderLines";
        var typeStr = "DeleteOrderLinesPost";
        response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
    }
    else {
        response = "No orderId for DeleteOrderLinesPost().";
    }
    return response;
}

// Save OrderLines Display Order ------------------------------------------------------------

export async function SaveOlDisplayOrder(orderLines) {
    var olData = [];
    var response = null;

    orderLines.forEach((ol, index) => {
        var item = {
            orderLineId: ol.orderLineId,
            displayOrder: index
        };
        olData.push(item);
    });

    if (olData && olData.length > 0) {
        response = await SaveOlDisplayOrderData(olData);
    }

    if (response === true) {
        return true;
    }
    else {
        //return error msg
        return response;
    }

}

async function SaveOlDisplayOrderData(olData) {
    var resp = null;

    const endpoint = 'orders/SaveOrderLinesDisplayOrder';
    try {
        const result = await post(endpoint, JSON.stringify(olData));
        resp = result;
    } catch (error) {
        console.error("SaveOlDisplayOrderData fetch error: ", error);
        console.error("apiUrl: " + endpoint);
        resp = error;
    }

    return resp;
}

// Create Claim from OrderLines ------------------------------------------------------------
export async function CreateBillingGroupDb(orderId) {
    var response = null;

    if (IdExists(orderId)) {
        var postModel = JSON.stringify(orderId);
        var endpoint = "orders/CreateBillingGroup";
        var typeStr = "CreateBillingGroupDb";
        response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
    }
    else {
        response = "No orderId for CreateBillingGroupDb().";
    }
    return response;
}

// Create Claim from OrderLines ------------------------------------------------------------

export async function CreateClaimPost(userId, olIds) {
    var response = null;

    if (NotEmpty(olIds)) {              
        var model = {
            userId: userId,
            orderLineIds: olIds
        };
        var postModel = JSON.stringify(model);
        var endpoint = "orders/CreateClaimForOrderLines";
        var typeStr = "CreateClaimPost";
        response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
    }
    else {
        response = "No orderLineIds for CreateClaimPost().";
    }
    return response;
}

// Create Order from Quote OrderLines ------------------------------------------------------------

export async function CreateOrderForQuoteLinesPost(olIds) {
    var response = null;

    if (NotEmpty(olIds)) {
        var postModel = JSON.stringify(olIds);
        var endpoint = "orders/CreateOrderForQuoteLines";
        var typeStr = "CreateOrderForQuoteLinesPost";
        response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
    }
    else {
        response = "No orderLineIds for CreateOrderForQuoteLinesPost().";
    }
    return response;
}

// Add Quote Lines to Order ------------------------------------------------------------

export async function AddQuoteLinesToOrderPost(userId, orderId, olIds) {
    var response = null;

    if (IdExists(orderId) && NotEmpty(olIds)) {
        var model = {
            userId: userId,
            orderId: orderId,
            orderLineIds: olIds
        };
        var postModel = JSON.stringify(model);

        var endpoint = "orders/AddQuoteLinesToOrder";
        var typeStr = "AddQuoteLinesToOrderPost";
        response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
    }
    else {
        response = "No orderId or orderLineIds for AddQuoteLinesToOrderPost().";
    }
    return response;
}

// Add Quote Lines to Order ------------------------------------------------------------

export async function ConvertOrderToQuotePost(userId, orderId) {
    var response = null;

    if (IdExists(orderId)) {        
        var model = {
            userId: userId,
            orderId: orderId
        };
        var postModel = JSON.stringify(model);
        //var postModel = JSON.stringify(orderId);
        var endpoint = "orders/ConvertOrderToQuote";        
        var typeStr = "ConvertOrderToQuotePost";
        response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
    }
    else {
        response = "No orderId for ConvertOrderToQuotePost().";
    }
    return response;
}

