import { Exists, formatCurrencyDecimal, NotEmpty } from './formHelpers.js';

export const GetQuantityFromWidthLength = (width, length, units) => {    
    if (!width) {
        width = 0;
    }
    if (!length) {
        length = 0;
    }

    var mult = 0;
    if (units === "SY")
        mult = 9;

    var quantity = 0;
    if (mult > 0)
        quantity = length * (width / mult);

    return quantity ?? 0;
}

export const GetLengthFromWidthQuantity = (width, quantity, units) => {    
    if (!width) {
        width = 0;
    }
    if (!quantity) {
        quantity = 0;
    }

    var mult = 0;
    if (units === "SY")
        mult = 9;

    var length = 0;
    if (mult > 0)
        length = quantity / (width / mult);

    return length ?? 0;
}


export const GetRemainder = (num, denom) => {
    if (!num) {
        num = 0;
    }
    if (!denom) {
        denom = 0;
    }
    
    var rem = 0;
    if (denom > 0) {
        rem = num % denom;
    }

    return rem ?? 0;
}

export const GetNewQuantityForMin = (minQuantity, quantity) => {
    if (!minQuantity) {
        minQuantity = 0;
    }
    if (!quantity) {
        quantity = 0;
    }

    var newQuantity = 0;
    if (minQuantity > 0) {        
        var mult = quantity / minQuantity;
        //always round up to next minQuantity multiple
        mult = Math.ceil(mult);
        newQuantity = minQuantity * mult;
    }
    newQuantity = formatCurrencyDecimal(newQuantity);
    return newQuantity;
}

export const GetMultDec = (val, mult) => {
    val = val ?? 0;
    mult = mult ?? 0;    

    var total = val * mult;
    total = formatCurrencyDecimal(total);
    return total;
}


export const GreaterThan = (obj, val) => {
    if (Exists(obj) && obj > val) {
        return true;
    }
    return false;
}

export const GreaterThanEq = (obj, val) => {
    if (Exists(obj) && obj >= val) {
        return true;
    }
    return false;
}


export const LessThan = (obj, val) => {
    if (Exists(obj) && obj < val) {
        return true;
    }
    return false;
}

export const LessThanEq = (obj, val) => {
    if (Exists(obj) && obj <= val) {
        return true;
    }
    return false;
}

export const Equals = (obj, val) => {
    if (Exists(obj) && obj === val) {
        return true;
    }
    return false;
}

export const NotEquals = (obj, val) => {
    if (Equals(obj, val) === true) {
        return false;
    }
    return true;
}

// TODO: implement from Extensions if need real time
//export const GetFeetPartFromInches = (inchesDec) => {
//    if (Exists(inchesDec)) {        
//    }
//    return 0;
//}

//export const GetInchPartFromInches = (inchesDec) => {
//    if (Exists(inchesDec)) {        
//    }
//    return 0;
//}

//export const GetFeetDecFromInches = (inchesDec) => {
//    if (Exists(inchesDec)) {
//    }
//    return 0;
//}