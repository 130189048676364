import React, { useState, useEffect, useMemo, useRef } from 'react';

import { get, post } from '../../services/apiService';

import { SetPageTitle, GetValueById } from '../../js/helpers.js';
import { SetDocumentTitle } from '../_reactHelpers/ReactHelpers';
import {
    AgCurrencyMxWd, AgDateMxWd, defaultErrMsg, GetDisplayCurrencyStr,
    GetDisplayDateStr, GetDisplayDecimalStr, IsTrue, NotEmpty
}
from '../../js/formHelpers';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
 
export function ReadyForPayment() {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Ready for Payment");

    const [rowData, setRowData] = useState([]);
    const [selectedBills, setSelectedBills] = useState([]);

    const gridRef = useRef()
    const columnDefs = useMemo(() => [
        {
            headerName: '', maxWidth: 50, headerCheckboxSelection: true, checkboxSelection: true,
        },
        {
            headerName: 'Vendor', field: 'vendorName',
        },
        {
            headerName: 'Vendor Type', field: 'vendorTypeDesc',
        },
        {
            headerName: 'Invoice #', field: 'billNumber',
        },
        {
            headerName: 'PO #', field: 'poNumber',
        },
        {
            headerName: 'Invoice Date', field: 'billDate', maxWidth: AgDateMxWd(),
            valueFormatter: params => GetDisplayDateStr(params.value)
        },
        {
            headerName: 'Due Date', field: 'dueDate', maxWidth: AgDateMxWd(),
            valueFormatter: params => GetDisplayDateStr(params.value)
        },
        {
            headerName: 'Total', field: 'billTotal', maxWidth: AgCurrencyMxWd(),
            valueFormatter: (params) => GetDisplayCurrencyStr(params.value)
        },
        {
            headerName: 'Disc Rate', field: 'discRate', maxWidth: AgCurrencyMxWd(),
            valueFormatter: (params) => GetDisplayDecimalStr(params.value)
        },
        {
            headerName: 'Disc Amt', field: 'discAmt', maxWidth: AgCurrencyMxWd(),
            valueFormatter: (params) => GetDisplayCurrencyStr(params.value)
        },
        {
            headerName: 'Non Disc Amt', field: 'nonDiscAmt', maxWidth: AgCurrencyMxWd(),
            valueFormatter: (params) => GetDisplayCurrencyStr(params.value)
        },
        {
            headerName: 'Amount Due', field: 'amountDue', maxWidth: AgCurrencyMxWd(),
            valueFormatter: (params) => GetDisplayCurrencyStr(params.value)
        },
        {
            headerName: 'Date Created', field: 'createdDateTime', maxWidth: AgDateMxWd(),
            valueFormatter: params => GetDisplayDateStr(params.value)
        },
    ], []);

    const defaultColDef = useMemo(() => ({
        minWidth: 100,
        flex: 1,
        sortable: true,
        resizable: true,
        filter: true,
        suppressMovable: true,
        suppressMenu: false,
        cellClass: ["no-border"],
        menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
    }), []);

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        setSelectedBills(selectedRows);
    };

    const updateSelectedBills = async (billIds) => {
        setSelectedBills([]);

        try {
            const response = await post('bills/SetBillsPaymentPending', billIds);
            fetchReadyForPaymentBills();
        } catch (error) {
            console.error('Error updating Bills', error);
        }
    };

    function approvePayments() {
        if (NotEmpty(selectedBills)) {
            updateSelectedBills(selectedBills.map(bill => bill.billId));
        }
    }

    const fetchReadyForPaymentBills = async () => {
        setSelectedBills([]);

        try {
            const response = await get(`bills/ReadyForPayment`);
            setRowData(response.bills ?? []);

        } catch (error) {
            console.error('Error fetchReadyForPaymentBills(): ', error);
            alert(defaultErrMsg());
        } finally {
        }
    }

    useEffect(() => {
        SetPageTitle("Ready for Payment");
        fetchReadyForPaymentBills();
    }, []);

    return (
        <div>
            <div className="page-wrapper">
                <div className="flex justify-end">
                    <button onClick={e => approvePayments()} className="btn-small">Approve Payments</button>
                </div>

                <div className="ag-theme-alpine ag-grid-act w-full pt-2" style={{ height: '600px' }}>
                    <AgGridReact
                        ref={gridRef}
                        rowData={rowData}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        pagination={true}
                        paginationPageSize={20}
                        rowSelection={'multiple'}
                        rowMultiSelectWithClick={true}
                        onSelectionChanged={onSelectionChanged}
                    />
                </div>
            </div>
        </div>
    );
}