import React, { useState, useEffect } from 'react';
import { IdExists } from '../../js/formHelpers';
import { post } from '../../services/apiService';
import { EntityAutocomplete } from '../_reactHelpers/EntityAutocomplete';

export default function EditBankAccountForm ({ bankAccountData, onBankAccountUpdated, onClose }) {
    const [formData, setFormData] = useState({
        id: '',
        bankName: '',
        bankAccountName: '',
        chartAccountId: 0,
        bankOpeningBalance: 0
    });

    const [loading, setLoading] = useState(true);
    const [statusMessage, setStatusMessage] = useState('');
    const [isError, setIsError] = useState(false);

    
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSelectGlAccount = (selectedOption) => {
        setFormData(prevData => ({
            ...prevData,
            chartAccountId: selectedOption.chartAccountId,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!formData.id)
        {
            setStatusMessage('Invalid Bank Account ID');
            setIsError(true);
            return;
        }
        if (!IdExists(formData.chartAccountId)) {
            setStatusMessage('GL Acount is required.');
            setIsError(true);
            return;
        }

        try {
            var resp = await post(`bankaccount/UpdateBankAccount/${formData.id}`, formData);

            setStatusMessage('Bank Account Updated Successfully');
            setIsError(false);
            onBankAccountUpdated();
        }
        catch (error) {
            setStatusMessage('Error updating Bank Account');
            setIsError(true);
        }
    };

    useEffect(() => {
        if (bankAccountData) {
            setFormData({
                id: bankAccountData.id || bankAccountData.bankId || '',
                bankName: bankAccountData.bankName || '',
                bankAccountName: bankAccountData.bankAccountName || '',
                chartAccountId: bankAccountData.chartAccountId || 0,
                bankOpeningBalance: bankAccountData.bankOpeningBalance || 0
            });
            setLoading(false);
        }
    }, [bankAccountData]);

    if (loading || !formData.id) {
        return <div className="text-center">Loading...</div>;
    }

    return (
        <div className="">
            <h2 className="text-2xl font-bold mb-4 text-center">Edit Bank Account</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="bankName" className="label-form">Bank Name</label>
                    <input
                        type="text"
                        id="bankName"
                        name="bankName"
                        value={formData.bankName}
                        onChange={handleChange}
                        className="label-form-input"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="bankAccountName" className="label-form">Account Name</label>
                    <input
                        type="text"
                        id="bankAccountName"
                        name="bankAccountName"
                        value={formData.bankAccountName}
                        onChange={handleChange}
                        className="label-form-input"
                        required
                    />
                </div>
                <div>
                    <label htmlFor="chartAccountId" className="label-form">GL Account:</label>
                    <EntityAutocomplete name="chartAccountId" id="chartAccountId"
                        onSelect={handleSelectGlAccount} onChange={null}
                        initialEntityId={formData.chartAccountId}
                        entityType="GL Account"
                        required
                    />
                </div>
                {/** NOT EDITABLE - BankAccount.bankOpeningBalance **/}                

                {statusMessage && (
                    <div className={`p-4 rounded-md ${isError ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
                        {statusMessage}
                    </div>
                )}
                <div className="flex justify-end space-x-2">
                    <button type="button" onClick={onClose} className="btn-cancel">Cancel</button>
                    <button type="submit" className="btn-submit">Update Bank Account</button>
                </div>
            </form>
        </div>
    );
}

