import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import axios from "axios";

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { AddEditInvoiceModal } from './AddEditInvoiceModal'; 
//import { PaymentModalContent } from '../Accounting/PaymentModal';
import { PaymentModal } from '../Payment/_modals/PaymentModal';
import { EntityAutocomplete } from '../_reactHelpers/EntityAutocomplete';
import { DateFilter } from '../_reactHelpers/DateFilter';
import RadioButtonList from '../_reactHelpers/RadioButtonList';

import { get, post } from '../../services/apiService';
import {
    AgCurrencyMxWd, AgDateMxWd, defaultErrMsg,  GetDisplayCurrencyStr,
    GetDisplayDateStr, IdExists, IsFalse, IsTrue, LengthGreaterEqual,
    PreventDefault, StringEmpty
} from '../../js/formHelpers';

import { ClickById, SetPageTitle, StringContains } from '../../js/helpers';
import { SetDocumentTitle } from '../_reactHelpers/ReactHelpers';
import { NotEquals } from '../../js/calculationHelpers';
import {Toast} from "flowbite-react";
import {HiCheck} from "react-icons/hi2";
import {HiX} from "react-icons/hi";

export const SearchReceivables = (props) => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - ");
    const [isPostPayments, setIsPostPayments] = useState(props.isPostPayments);

    const [invoices, setInvoices] = useState([]);
    const [stores, setStores] = useState([]);

    const dateTypes = ["Invoice Date", "Due Date", "Paid Date"];
    const rblClassName = "rblDateType";
    const [dateType, setDateType] = useState("InvoiceDate");

    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [includeOpenOrders, setIncludeOpenOrders] = useState(false);

    const [accountId, setAccountId] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [quickFilterText, setQuickFilterText] = useState('');

    const [invoicesForPayment, setInvoicesForPayment] = useState([]);
    const [selectedInvoices, setSelectedInvoices] = useState([]);

    const [unpaidInvoices, setUnpaidInvoices] = useState([]);
    const [selectedInvoice, setSelectedInvoice] = useState(null);

    const [isModalOpen, setModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);

    const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");

    const gridRef = useRef(null);
    const [gridApi, setGridApi] = useState(null);

    const [countSelectedRows, setCountSelectedRows] = useState(0);
    const [sendingEmail, setSendingEmail] = useState(false);
    const [showToastSuccess, setShowToastSuccess] = useState(false);
    const [showToastError, setShowToastError] = useState(false);

    const columnDefs = [
        {
            headerName: '', field: 'selected',
            checkboxSelection: params => !params.data.isGroup,
            headerCheckboxSelection: true,
            maxWidth: 50,
            //pinned: 'left',
        },
        {
            field: 'accountName', headerName: 'Account',
            rowGroup: true,
            cellRenderer: params => {
                if (params.data.isGroup) {
                    return `${params.value} (${params.data.children.length})`;
                }
                return params.value;
            }
        },
        { field: 'invoiceNumber', headerName: 'Invoice #', },
        //{ field: 'storeName', headerName: 'Store', maxWidth: 120, hide: true },
        //{ field: 'accountName', headerName: 'Account', minWidth:200, rowGroup: true },
        {
            field: 'invoiceDate', headerName: 'Invoice Date', maxWidth: AgDateMxWd(),
            valueFormatter: (params) => GetDisplayDateStr(params.value)
        },
        {
            field: 'dueDate', headerName: 'Due Date', maxWidth: AgDateMxWd(),
            valueFormatter: (params) => GetDisplayDateStr(params.value)
        },
        {
            field: 'paidDate', headerName: 'Paid Date', hide: isPostPayments,
            maxWidth: AgDateMxWd(),
            valueFormatter: (params) => GetDisplayDateStr(params.value)
        },
        {
            field: 'invoiceTotal', headerName: 'Invoice Total', maxWidth: AgCurrencyMxWd(),
            valueFormatter: (params) => NotEquals(params.value, 0) ? GetDisplayCurrencyStr(params.value) : ""
        },
        {
            field: 'statusStr', headerName: 'Invoice Status', maxWidth: 120,
        },
        {
            field: 'orderNumber', headerName: 'Order #', maxWidth: 100, hide: !isPostPayments
        },
        {
            field: 'orderTotal', headerName: 'Order Total', hide: !isPostPayments, maxWidth: AgCurrencyMxWd(),
            valueFormatter: (params) => GetDisplayCurrencyStr(params.value)
        },
        {
            field: 'orderStatusStr', headerName: 'Order Status', maxWidth: 110,
        },
        {
            field: 'balanceDue', headerName: 'Balance Due', maxWidth: AgCurrencyMxWd(),
            valueFormatter: (params) => GetDisplayCurrencyStr(params.value)
        },
        //{
        //    headerName: '', maxWidth: 160,
        //    cellRenderer: params => (
        //        <button
        //            onClick={() => handleApplyPayment(params.data)}
        //            className="btn-grid !bg-green-500 !hover:bg-green-500">
        //            Apply Payment
        //        </button>
        //    ),
        //    rowGroup: false,
        //    hide: !isPostPayments
        //},
        {
            headerName: '', maxWidth: 80, filter: false, sortable: false, hide: true,
            cellRenderer: params => (
                <button onClick={() => handleEditClick(params.data)} className="btn-grid">Edit</button>
            )
        }
    ];

    const autoGroupColumnDef = useMemo(() => ({
        headerName: "Account",
        minWidth: 200,
        field: 'accountName',
        cellRenderer: 'agGroupCellRenderer',
        cellRendererParams: {
            checkbox: true,
            suppressCount: true,
            innerRenderer: params => {
                if (params.node.group) {
                    return `${params.value} (${params.node.allChildrenCount})`;
                }
                return params.value;
            }
        }
    }), []);

    const gridOptions = {
        defaultColDef: {
            minWidth: 100,
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            suppressMovable: true,
            suppressMenu: false,
            cellClass: ["no-border"],
            enableRangeSelection: true,
            menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
        }
    };

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        params.api.getColumnDef('accountName').rowGroup = true;
        params.api.refreshClientSideRowModel('group');
        params.api.sizeColumnsToFit();

        if (isPostPayments) {
            params.api.forEachNode(function (node) {
                if (node.group) {
                    node.setExpanded(false);
                }
            });
        }
    }, []);

    const handleSearch = async () => {
        updateMessage();
        try {

            if (!LengthGreaterEqual(searchText, 2)) {
                updateMessage("Enter at least 2 characters to search.", 'text-red')
                return;
            }
            //console.log('Search unpaid: ' + isPostPayments);
            //console.log('Search open: ' + includeOpenOrders);

            const response = await get('/invoice/SearchInvoices', {
                params: {
                    startDate,
                    endDate,
                    dateType,
                    searchText,
                    accountId: accountId || null,
                    searchUnpaid: isPostPayments
                }
            });
            const transformedData = response.map(invoice => ({
                ...invoice,
                invoiceLineItems: invoice.invoiceLineItems || []
            }));

            if (isPostPayments) {
                setUnpaidInvoices(transformedData);
            } else {
                setInvoices(transformedData);
            }

            if (gridApi) {
                gridApi.setRowData(transformedData);
                gridApi.forEachNode(function (node) {
                    if (node.group) {
                        node.setExpanded(false);
                    }
                });
            }
        } catch (error) {
            console.error('Error searching invoices:', error);
            updateMessage(defaultErrMsg(), 'text-red');
        } finally {
        }
    };

    const updateMessage = (msg, cssClass) => {
        setMessage(msg);
        setMessageClass(cssClass);
    };

    async function getInvoices() {
        updateMessage();
        setInvoices([]);
        setUnpaidInvoices([]);

        try {
            if (IsTrue(isPostPayments)) {
                await fetchUnpaidInvoices();
            } else {
                await fetchPaidInvoices();
            }
        } catch (error) {
            console.error('Error fetching invoices:', error);
        } finally {
        }
    }

    async function fetchPaidInvoices() {
        updateMessage();
        try {

            if (StringEmpty(dateType) || StringEmpty(startDate) || StringEmpty(endDate)) {
                updateMessage('Dates and Date Type are required.', 'text-red');
                return;
            }

            const response = await get('/invoice/GetInvoices', { params: { startDate, endDate, dateType } });
            const transformedData = response.map(invoice => ({
                ...invoice,
                invoiceLineItems: invoice.invoiceLineItems
            }));
            setInvoices(transformedData);

        } catch (error) {
            console.error('Error fetching invoices:', error);
        } finally {
        }
    }

    async function fetchUnpaidInvoices() {
        updateMessage();
        try {

            if (IsTrue(includeOpenOrders) && !IdExists(accountId)) {
                updateMessage("Please select an Account to search Open Orders.", 'text-red')
                return;
            }

            const response = await get('/invoice/GetUnpaidInvoices', {
                params: {
                    dueDate: endDate,
                    accountId: accountId,
                    includeOpenOrders: includeOpenOrders
                }
            });
            const transformedData = response.map(invoice => ({
                ...invoice,
            }));

            if (gridApi) {
                gridApi.setRowData(transformedData);
                gridApi.deselectAll();
            }

            setUnpaidInvoices(transformedData);

            if (gridApi) {
                gridApi.setRowData(transformedData);
                gridApi.forEachNode(function (node) {
                    if (node.group) {
                        node.setExpanded(false);
                    }
                });
            }
        } catch (error) {
            console.error('Error fetching unpaid invoices:', error);
        } finally {
        }
    }

    const fetchStores = async () => {
        try {
            const response = await get("/store/GetAllStores");
            const storeData = response || [];

            setStores(storeData);
        } catch (error) {
            console.error('Error fetching stores:', error);
        }
    };

    const handleDueDateChange = (date) => {
        setEndDate(date);
        if (date) {
            setIncludeOpenOrders(false);
        }
    };

    const handleIncludeOpenOrdersChange = (e) => {
        const isChecked = e.target.checked;
        setIncludeOpenOrders(isChecked);

        if (isChecked) {
            ClickById("btnDateFilterReset");
        }
    };

    const handleInvoiceSubmit = useCallback(async (formData) => {
        try {
            const response = await post(`/invoice/CreateUpdateInvoice`, formData);
            if (IdExists(response)) {
                setModalOpen(false);
                setIsEditModalOpen(false);

                setMessage("Invoice saved successfully.");
                setMessageClass(" text-green ");

                getInvoices();
            }
            else {
                alert(defaultErrMsg());
                //throw defaultErrMsg();
            }

        } catch (error) {
            console.error('Error saving the invoice:', error);
            throw error;
        }
    }, []);

    const handleEditClick = useCallback((invoice) => {
        setSelectedInvoice({
            ...invoice,
            invoiceLineItems: invoice.invoiceLineItems || []
        });
        setIsEditModalOpen(true);
    }, []);

    //const handleAddClick = useCallback(() => {
    //    setModalOpen(true);
    //}, []);

    const handleQuickFilterChange = useCallback((event) => {
        setQuickFilterText(event.target.value);
    }, []);

    const onSelectionChanged = useCallback(() => {
        setCountSelectedRows(gridRef.current.api.getSelectedNodes().length);

        const selectedNodes = gridRef.current.api.getSelectedNodes();
        const selectedData = selectedNodes
            .filter(node => !node.group)
            .map(node => node.data);
        setSelectedInvoices(selectedData);
    }, []);

    const applyPayment = async (paymentModel) => {
        console.log('Applying payments:', paymentModel);

        try {
            const response = await post('payments/CreatePayment', paymentModel);
            console.log('Payment response:', response);

            await fetchUnpaidInvoices();
        }
        catch (error) {
            console.error('Error applying payments:', error);
            throw error;
        }
    };

    const applyPaymentToSelected = () => {
        if (selectedInvoices.length === 0) {
            alert('Please select at least one invoice to apply payment.');
            return;
        }

        const paymentsToApply = selectedInvoices.map(invoice => ({
            invoiceId: invoice.invoiceId,
            orderId: invoice.orderId,
            orderNumber: invoice.orderNumber,
            invoiceNumber: invoice.invoiceNumber,
            invoiceTotal: invoice.invoiceTotal,
            paymentAmount: invoice.invoiceTotal,
            accountName: invoice.accountName,
            depositRegisterId: null,
            paymentType: null,
            paymentTypeId: null,
            balanceDue: invoice.balanceDue,
            totalDue: invoice.balanceDue
        }));
        console.log('Selected Invoices:', selectedInvoices);
        console.log('Payments to apply:', paymentsToApply);

        setInvoicesForPayment(paymentsToApply);
        setIsPaymentModalOpen(true);
    };

    const onSubmitClosePayment = async (message) => {
        setIsPaymentModalOpen(false);

        try {
            setMessage(message);
            setMessageClass(" text-green ");
            if (StringContains(message, "error")) {
                setMessageClass(" text-red ");
            }

            await fetchUnpaidInvoices();
        }
        catch (error) {
            console.error('onSubmitClosePayment() error:', error);
        }
        finally {
        }
    };

    //const acOnChange = () => {
    //    setAccountId(0);
    //};

    const handleSelectAccount = (selectedOption) => {
        setAccountId(selectedOption.accountId);
    };

    function EnterSearchAll(e) {
        if (e.key === "Enter") {
            PreventDefault(e);
            handleSearch();
        }
    }

    async function handleSendEmail() {
        setSendingEmail(true)

        const selectedInvoicesIds = selectedInvoices.map(invoice => invoice.invoiceId);
        const response = await post('invoice/GenerateInvoiceEmails', selectedInvoicesIds);
        console.log(response)

        if (response) {
            setSendingEmail(false)
            setShowToastSuccess(true)
        } else {
            setSendingEmail(false)
            setShowToastError(true)
        }
    }

    async function printReceivables() {
        try {
            const selectedInvoicesIds = selectedInvoices.map(invoice => invoice.invoiceId);

            var response = await post('invoice/GeneratePdfInvoices', selectedInvoicesIds);
            const url = base64ToArrayBuffer(response.fileBytes, "application/zip", response.contentLength);
            
            //const url = window.URL.createObjectURL(new Blob([response.fileBytes]));

            //const response = await axios.post(`invoice/GeneratePdfInvoices`, selectedInvoicesIds, {
            //    responseType: 'blob'
            //});
            //console.log(response);
            //const url = window.URL.createObjectURL(new Blob([response.data]));
                        
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Invoices.zip`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
        catch (error) {
            console.error('printReceivables() error:', error);
        }
    }

    function base64ToArrayBuffer(fBytes, contentType, length) {
        var binary_string = window.atob(fBytes)
        var len = fBytes.length;
        if (length) {
            len = length;
        }
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        let blob = new Blob([bytes.buffer], { type: contentType })
        var url = URL.createObjectURL(blob);
        return url;
    }

    useEffect(() => {
        if (isPostPayments) {
            setDocTitle("Lumina - Post Payments");
            SetPageTitle("Post Payments");
        }
        else {
            setDocTitle("Lumina - Search Receivables");
            SetPageTitle("Search Receivables");
        }
        fetchStores();
    }, []);

    return (
        <div className="page-wrapper !pt-1">
            <div className="flex-wrapper">
                <div className="flex-1 mb-2">
                    <div className="">
                        {IsFalse(isPostPayments) &&
                            <div className="flex-wrapper pt-2">
                                <DateFilter fromDate={startDate} setFromDate={setStartDate} fromDateStr="Start Date:"
                                    toDate={endDate} setToDate={setEndDate} toDateStr="End Date:" enterFunction={getInvoices}
                                />
                                <div className="">
                                    <button onClick={e => getInvoices()} className="btn-submit">
                                        Search By Date
                                    </button>
                                </div>
                                <div className="">
                                    <RadioButtonList rblClassName={rblClassName} valuesList={dateTypes} initialValue={dateType}
                                        setValue={setDateType} />
                                </div>
                            </div>
                        }

                        {IsTrue(isPostPayments) &&
                            <div className="flex-wrapper items-end">
                                <div className="">
                                    <DateFilter
                                        toDate={endDate}
                                        setToDate={handleDueDateChange}
                                        toDateStr="Due Date:"
                                        enterFunction={getInvoices}
                                    />
                                </div>
                                <div className="pl-2">
                                    <label className="label table-cell pl-4 pr-2">
                                        <span className="date-filter-label">Account:</span>
                                    </label>
                                    <div className="table-cell">
                                        <EntityAutocomplete name="accountId" id="accountId"
                                            onSelect={handleSelectAccount}
                                            onChange={null}
                                            initialEntityId={accountId}
                                            entityType="Account"
                                            required
                                        />
                                    </div>
                                </div>
                                <div className="pl-4">
                                    <label htmlFor="includeOpenOrders" className="text-secondary font-bold cursor-pointer">
                                        Include Open Orders
                                    </label>
                                    <input type="checkbox" id="includeOpenOrders"
                                        className="input-cb ml-1 mb-[2px]"
                                        checked={includeOpenOrders}
                                        onChange={handleIncludeOpenOrdersChange}
                                    />
                                </div>
                                <div className="flex-1 pl-6">
                                    <button onClick={e => getInvoices()} className="btn-small pl-4">Search Unpaid Invoices</button>
                                </div>
                                <div className="">
                                    <button className="btn-small-green ml-2"
                                        onClick={applyPaymentToSelected}
                                        disabled={!isPostPayments || selectedInvoices.length === 0}>
                                        Apply Payment to Selected ({selectedInvoices.length})
                                    </button>
                                </div>
                            </div>
                        }

                        <div className="mt-2">
                            <input type="text" placeholder="Search..."
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                onKeyDown={(e) => EnterSearchAll(e)}
                                className="grid-filter !w-[300px]"
                            />
                            <button onClick={handleSearch} className="btn-small ml-2">
                                {isPostPayments ? "Search Unpaid Invoices" : "Search All Invoices"}
                            </button>
                        </div>
                        <div className="clear mt-0 mb-1 text-sm">
                            'Enter' to search with 2 or more characters.
                        </div>
                    </div>
                </div>
            </div>

            <div className="w-full">
                <div className="table-cell pr-2" style={{ width: '1%' }}>
                    <div className="flex justify-between w-full">
                        <input type="text" id="quickFilter" className="grid-filter !w-[300px]"
                            placeholder="Filter..."
                            value={quickFilterText}
                            onChange={handleQuickFilterChange}
                        />

                        <div className="flex justify-end gap-2 pt-2 w-1/4">
                            <button
                                onClick={e => printReceivables(e)}
                                className="w-10 h-auto text-black flex items-center justify-center rounded-md transition-colors">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none"
                                    stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                    className="w-5 h-5">
                                    <path d="M6 9V2h12v7" />
                                    <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2" />
                                    <path d="M6 14h12v8H6z" />
                                </svg>
                            </button>

                            <button
                                //onClick={e => handleSendEmail()}
                                disabled={sendingEmail}
                                className="flex gap-2 items-center h-auto text-center text-black rounded-md transition-colors w-auto">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                                    strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                    className="w-full h-5">
                                    <rect x="2" y="4" width="20" height="16" rx="2" />
                                    <path d="m22 7-8.97 5.7a1.94 1.94 0 0 1-2.06 0L2 7" />
                                </svg>

                                <span className="font-bold">({countSelectedRows})</span>
                            </button>
                        </div>
                    </div>

                </div>

            </div>

            <div className={"table-cell " + messageClass}>{message}</div>

            <div className="ag-theme-alpine ag-grid-act pt-2" style={{ height: 600, width: '100%' }}>
                <AgGridReact
                    ref={gridRef}
                    rowData={isPostPayments ? unpaidInvoices : invoices}
                    columnDefs={columnDefs}
                    gridOptions={gridOptions}
                    onGridReady={onGridReady}
                    quickFilterText={quickFilterText}
                    pagination={true}
                    paginationPageSize={20}
                    rowSelection="multiple"
                    rowMultiSelectWithClick={true}
                    onSelectionChanged={onSelectionChanged}
                    groupRemoveSingleChildren={true}
                    groupSelectsChildren={true}
                    autoGroupColumnDef={autoGroupColumnDef}
                    groupDisplayType={'groupRows'}
                    getRowId={(params) => params.data.invoiceId || `order-${params.data.orderId}`}
                />
            </div>

            {/* ADD */}
            {isModalOpen && (
                <AddEditInvoiceModal
                    initialInvoiceData={null}
                    handleSubmit={handleInvoiceSubmit}
                    stores={stores}
                    onHide={() => setModalOpen(false)}
                />
            )}

            {/* EDIT */}
            {isEditModalOpen && (
                <AddEditInvoiceModal
                    initialInvoiceData={selectedInvoice || {}}
                    handleSubmit={handleInvoiceSubmit}
                    stores={stores}
                    onHide={() => setIsEditModalOpen(false)}
                />
            )}

            {isPaymentModalOpen && invoicesForPayment.length > 0 && (
                <PaymentModal
                    onClose={() => setIsPaymentModalOpen(false)}
                    invoices={selectedInvoices}
                    confirmPayment={applyPayment}
                />
            )}

            {showToastSuccess && (
                <Toast className="absolute top-5 end-5 z-50 shadow">
                    <div
                        className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-green-100 text-green-500 dark:bg-green-800 dark:text-green-200">
                        <HiCheck className="h-5 w-5" />
                    </div>

                    <div className="ml-3 text-sm font-normal">Correctly sent mails</div>
                    <Toast.Toggle onDismiss={() => setShowToastSuccess(false)} />
                </Toast>
            )}

            {showToastError && (
                <Toast className="absolute top-5 end-5 z-50 shadow">
                    <div
                        className="inline-flex h-8 w-8 shrink-0 items-center justify-center rounded-lg bg-red-100 text-red-500 dark:bg-red-800 dark:text-red-200">
                        <HiX className="h-5 w-5" />
                    </div>

                    <div className="ml-3 text-sm font-normal">Error sending mails</div>
                    <Toast.Toggle onDismiss={() => setShowToastError(false)} />
                </Toast>
            )}
        </div>
    );
}