import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import { get, post } from '../../services/apiService.js';
import {
    Exists, handleFieldChange, IdExists, IsEmpty,
    IsTrue, NotEmpty, ParseIntSafe, PreventDefault, SetAndClickById
} from '../../js/formHelpers';
import { GetLocationStateId, SetDocumentTitle } from '../_reactHelpers/ReactHelpers';
import { StandardDataAndResponsePOST, StandardIdAndResponseGET } from '../../js/dataHelpers';
import { SetPageTitle } from '../../js/helpers';

export const UserAddEdit = (props) => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina User");
    const location = useLocation();
    const navigate = useNavigate();

    const [userId, setUserId] = useState(GetLocationStateId("userId"));
    const [userData, setUserData] = useState(null);
    const [stores, setStores] = useState([]);
    const [roles, setRoles] = useState([]);
    const [rolesLoading, setRolesLoading] = useState(true);

    const [redirectSubmit, setRedirectSubmit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    // Helpers

    function backToClick(userMessage) {
        navigate('/Users', {
            replace: true,
            state: { useSavedFiltersVendor: true, userMessage }
        });
    }

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    // Methods
    const loadStores = async () => {
        try {
            const data = await get('/store/GetAllStores');
            if (NotEmpty(data)) {
                // Filter out inactive and deleted stores
                const activeStores = data.filter(store => !store.Inactive && !store.Deleted);
                setStores(activeStores);
                console.log('Stores:', activeStores);
            } else {
                updateMessage("Failed to load stores. Please try again.", "text-red");
            }
        } catch (error) {
            console.error("Error loading stores:", error);
            updateMessage("Failed to load stores. Please try again.", "text-red");
        }
    };

    const handleChange = (e) => {
        const data = handleFieldChange(e);

        if (data) {
            if (data.name === 'roles') {
                const roleId = parseInt(e.target.value, 10);
                const isChecked = e.target.checked;

                setUserData((prevData) => {
                    const updatedUserRoles = isChecked
                        ? [...(prevData.userRoles || []), { roleId, user: prevData, role: { roleId } }]
                        : (prevData.userRoles || []).filter((role) => role.roleId !== roleId);

                    const updatedRoleIds = isChecked
                        ? [...(prevData.roleIds || []), roleId]
                        : (prevData.roleIds || []).filter((id) => id !== roleId);

                    return {
                        ...prevData,
                        userRoles: updatedUserRoles,
                        roleIds: updatedRoleIds,
                    };
                });
            } else {
                setUserData((prevData) => ({
                    ...prevData,
                    [data.name]: data.value,
                }));
            }
        } else {
            updateMessage(defaultErrMsg, 'text-red', 'null handleChange data');
        }
    };

    // Validate and Post Data

    const validateData = () => {
        var valid = true;
        var msg = "";

        if (NotEmpty(msg)) {
            updateMessage(msg, "text-red");
        }
        return valid;
    };

    function saveUserMsg(userId) {
        if (IdExists(userId)) {
            return userData.userName + " updated successfully.";
        }
        else {
            return userData.userName + " created successfully.";
        }
    }

    function btnSubmitClick(e, redirectSubmit) {
        setRedirectSubmit(redirectSubmit);
    };

    const handleUserSubmit = async (e) => {
        PreventDefault(e);

        var valid = validateData();
        if (valid === true) {
            var resp = await UpdateUserPOST();

            var iUserId = ParseIntSafe(resp);
            if (IdExists(iUserId)) {
                var msg = saveUserMsg(userId);
                SetAndClickById("lnkReloadPage", "/Redirect?route=Users&id=" + iUserId + "&message=" + msg);

                //if (redirectSubmit) {
                //    backToClick(msg);
                //}
                //else {
                //    if (IdExists(userId)) {
                //        updateMessage(msg, "text-green");
                //    }
                //    else {
                //        SetAndClickById("lnkReloadPage", "/Redirect?route=User&id=" +
                //            iUserId + "&message=" + msg);
                //    }
                //}
            }
            else {
                console.error("handleUserSubmit(): ", JSON.stringify(resp));
                alert(defaultErrMsg);
            }
        }
    }

    async function UpdateUserPOST() {
        // Get selected RoleIds
        const roleIds = userData.userRoles.map(role => role.roleId) || [];
        const postData = {
            ...userData,
            RoleIds: roleIds
        };

        var postModel = JSON.stringify(postData);
        //console.log('Post Model:', postModel);
        var response = await StandardDataAndResponsePOST(postModel, "user/SaveUser", "UpdateUserPOST()");
        return response;
    }

    // Load Data

    const loadRoles = async () => {

        setRolesLoading(true);
        var data = await StandardIdAndResponseGET("", '/user/getroles', "User - loadRoles()");
        if (NotEmpty(data)) {
            setRoles(data);
        }
        else {
            updateMessage("Failed to load roles. Please try again.", "text-red");
        }
        setRolesLoading(false);
    }

    const loadUserData = async () => {
        var data = await StandardIdAndResponseGET(userId, "user/GetUser/", "loadUserData()");
        console.log('UserData:', data);
        if (Exists(data)) {
            setLoading(false);
            setUserData(data);

            var docT = "Lumina Create User";
            var t = "Create User";

            var iUserId = ParseIntSafe(data.userId);
            if (IdExists(iUserId)) {
                docT = "Lumina User - " + data.userName;
                t = "User - " + data.userName;
            }
            setDocTitle(docT);
            SetPageTitle(t);
        }
        else {
            setError(true);
        }
    }

    function showMessage() {
        if (location.state && NotEmpty(location.state.userMessage)) {
            updateMessage(location.state.userMessage, "text-green");
        }
    }

    useEffect(() => {
        showMessage();
        loadUserData();
        loadRoles();
        loadStores();
    }, []);

    if (IsTrue(loading) || rolesLoading) {
        return (
            <div className="text-center text-xl mt-[10%]">
                <span className="pr-1">Loading...</span>
                <span className="loading loading-spinner text-blue-600"></span>
            </div>
        );
    }

    if (IsTrue(error)) {
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;
    }

    return (
        <div className="container mx-auto p-6">
            {userData && (
                <form onSubmit={handleUserSubmit}>
                    <div className="bg-white rounded-lg shadow-sm">
                        {/* Header */}
                        <div className="px-6 py-4 border-b">
                            <h2 className="text-xl font-semibold text-gray-900">
                                {IdExists(userId) ? 'Edit User' : 'Create User'}
                            </h2>
                        </div>

                        {/* Form Content */}
                        <div className="p-6">
                            <div className="grid md:grid-cols-2 gap-8">

                                {/* Left Column */}
                                <div className="space-y-4">
                                    <div>
                                        <label htmlFor="userName" className="block text-sm font-medium text-gray-700 mb-1">
                                            Username
                                        </label>
                                        <input
                                            type="text"
                                            id="userName"
                                            name="userName"
                                            className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                            value={userData.userName}
                                            onChange={handleChange}
                                            required
                                        />
                                    </div>

                                    <div className="grid grid-cols-2 gap-4">
                                        <div>
                                            <label htmlFor="firstName" className="block text-sm font-medium text-gray-700 mb-1">
                                                First Name
                                            </label>
                                            <input
                                                type="text"
                                                id="firstName"
                                                name="firstName"
                                                className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                                value={userData.firstName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>

                                        <div>
                                            <label htmlFor="lastName" className="block text-sm font-medium text-gray-700 mb-1">
                                                Last Name
                                            </label>
                                            <input
                                                type="text"
                                                id="lastName"
                                                name="lastName"
                                                className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                                value={userData.lastName}
                                                onChange={handleChange}
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>

                                {/* Right Column */}
                                <div className="space-y-4">
                                    <div className="flex gap-8">
                                        <div className="w-64">
                                            <label htmlFor="defaultStoreId" className="block text-sm font-medium text-gray-700 mb-1">
                                                Default Store
                                            </label>
                                            <select
                                                id="defaultStoreId"
                                                name="defaultStoreId"
                                                className="w-full h-9 px-3 rounded border border-gray-300 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                                                value={userData.defaultStoreId || ''}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Store</option>
                                                {stores.map(store => (
                                                    <option key={store.storeId} value={store.storeId}>
                                                        {store.displayName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>

                                        <div>
                                            <label className="block text-sm font-medium text-gray-700 mb-2">
                                                Roles
                                            </label>
                                            <div className="grid grid-cols-2 gap-1">
                                                {roles.length > 0 ? (
                                                    roles.map(role => (
                                                        <label
                                                            key={role.roleId}
                                                            className="inline-flex items-center py-0.5"
                                                        >
                                                            <input
                                                                type="checkbox"
                                                                id={`role-${role.roleId}`}
                                                                name="roles"
                                                                value={role.roleId}
                                                                checked={userData.userRoles?.some(userRole => userRole.roleId === role.roleId) || false}
                                                                onChange={handleChange}
                                                                className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                                            />
                                                            <span className="ml-2 text-sm text-gray-700">{role.roleDescription}</span>
                                                        </label>
                                                    ))
                                                ) : (
                                                    <div className="text-sm text-gray-500">No roles available</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex space-x-6 pt-2">
                                        <label className="inline-flex items-center">
                                            <input
                                                type="checkbox"
                                                id="isRegistered"
                                                name="isRegistered"
                                                checked={userData.isRegistered}
                                                onChange={handleChange}
                                                className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                            />
                                            <span className="ml-2 text-sm font-medium text-gray-700">Registered</span>
                                        </label>

                                        <label className="inline-flex items-center">
                                            <input
                                                type="checkbox"
                                                id="inactive"
                                                name="inactive"
                                                checked={userData.inactive}
                                                onChange={handleChange}
                                                className="h-4 w-4 rounded border-gray-300 text-primary focus:ring-primary"
                                            />
                                            <span className="ml-2 text-sm font-medium text-gray-700">Inactive</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Footer */}
                        <div className="px-6 py-4 bg-gray-50 border-t rounded-b-lg flex items-center justify-between">
                            <div className={messageClass}>{message}</div>
                            <div className="flex space-x-2">
                                <button
                                    type="button"
                                    onClick={() => backToClick()}
                                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="submit"
                                    onClick={(e) => btnSubmitClick(e, true)}
                                    className="px-4 py-2 text-sm font-medium text-white bg-primary border border-transparent rounded hover:bg-primary/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
                                >
                                    Save & Close
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            )}
            <a href="/" id="lnkReloadPage" className="hidden">Reload</a>
        </div>
    );
}