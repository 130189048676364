import axios from 'axios';
import { get, post } from './apiService.js';

//  Move to config 
const TERMINAL_CONFIG = {
    GMID: "1110102975",
    GTID: "GT1120044415",
    GMPW: "GMPW3010022428"
};

const API_BASE_URL = 'https://testlink.c9pg.com:11911/Payment';
const API_ENDPOINTS = {
    SALE: `${API_BASE_URL}/Sale#Sale?TerminalType=None&NeedSwipeCard=N&GMID=${TERMINAL_CONFIG.GMID}`,
    TOKENIZATION: `${API_BASE_URL}/Sale#Tokenization?TerminalType=None&NeedSwipeCard=N&GMID=${TERMINAL_CONFIG.GMID}`,
    ACH: `${API_BASE_URL}/Sale#ACHCredit?TerminalType=None&NeedSwipeCard=N&GMID=${TERMINAL_CONFIG.GMID}`
};

// Credit Card Payment
export const makePayment = async (paymentRequest) => {
    try {
        console.log('Payment Request', paymentRequest);
        const request = {
            ...paymentRequest,
            ...TERMINAL_CONFIG,
            TransType: "Sale"
        };

        const response = await axios.post(API_ENDPOINTS.SALE, request);
        return response.data;
    } catch (error) {
        console.error('Payment failed:', error);
        throw error;
    }
}

// Get Token for Card
export const getCardToken = async (tokenRequest) => {
    try
    {
        const request = {
            ...tokenRequest,
            ...TERMINAL_CONFIG,
            TransType: "CreateCardToken"
        };

        const response = await axios.post(API_ENDPOINTS.TOKENIZATION, request);
        return response.data;
    }
    catch (error) {
        console.error('Token creation failed:', error);
        throw error;
    }
}

// Payment by CardToken
export const makePaymentByToken = async (token, amount) => {
    try {
        // TODO: Remove test amount override for production
        amount = 1;

        const tokenRequest = {
            ...TERMINAL_CONFIG,
            TransType: "Sale",
            Medium: "Credit",
            MainAmt: amount,
            CardToken: token
        };

        const response = await axios.post(API_ENDPOINTS.TOKENIZATION, tokenRequest);
        return response.data;
    } catch (error) {
        console.error('Token payment failed:', error);
        throw error;
    }
}

// ACH Token
export const getAchToken = async (achInfo) => {
    try {
        const tokenRequest = {
            ...TERMINAL_CONFIG,
            TransType: "CreateCardToken",
            Medium: "ElectronicCheck",
            EntryModel: "Manual",
            // Test numbers - TODO: Remove for production
            EleCheckRoutingNumber: "123456780",
            EleCheckAccountNumber: "1071642307",
            AccountType: achInfo.accountType
        };

        const response = await axios.post(API_ENDPOINTS.ACH, tokenRequest);
        return response.data;
    } catch (error) {
        console.error('ACH token creation failed:', error);
        throw error;
    }
}

// ACH Payment
export const makeAchPayment = async (achInfo) => {
    try {
        const request = {
            ...achInfo,
            ...TERMINAL_CONFIG,
            Medium: "ElectronicCheck",
            TransType: "Sale",
            EntryModel: "Manual"
        };

        const response = await axios.post(API_ENDPOINTS.ACH, request);
        return response.data;
    } catch (error) {
        console.error('ACH payment failed:', error);
        throw error;
    }
}

// Database Operations
export const postPaymentToDatabase = async (paymentInfo) => {
    try {
        const response = await post('/payments/CreatePayment', paymentInfo);
        console.log('Payment saved successfully');
        return response;
    } catch (error) {
        console.error('Error posting payment:', error);
        throw error;
    }
}

export const postTransactionToDatabase = async (transactionInfo) => {
    try {
        const response = await post('/payments/SaveTransaction', transactionInfo);
        return response;
    } catch (error) {
        console.error('Error saving transaction:', error);
        throw error;
    }
}

export const getPaymentsForOrder = async (orderId) => {
    try {
        const response = await get(`/payments/GetPaymentsForOrder/${orderId}`);
        return response;
    } catch (error) {
        console.error('Error fetching order payments:', error);
        throw error;
    }
}

export const getPaymentsByLastFour = async (lastFour) => {
    try {
        console.log('Searching payments by last 4:', lastFour);
        const response = await get(`/payments/GetPaymentsByLastFour?lastFour=${lastFour}`);
        return response;
    } catch (error) {
        console.error('Error fetching payments by last four:', error);
        throw error;
    }
}