import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-daisyui'

import {
    Exists, handleFieldChange, IdExists, IsFalse, isSelectedDropdown,
    IsTrue, NotEmpty, ParseIntSafe, PreventDefault,
} from '../../js/formHelpers';
import { GetProductCategorySelectList, GetVendorSelectList, StandardDataAndResponsePOST, StandardIdAndResponseGET } from '../../js/dataHelpers';
import { ClickById, GetSelectItemTextById } from '../../js/helpers';

export const PcvpAddEdit = ({ onClose, id }) => {

    const [isOpen, seIsOpen] = useState(true);
    const [productCategoryVendorRateId, setProductCategoryVendorRateId] = useState(id ?? 0);
    const [data, setData] = useState(null);

    const [categoryList, setCategoryList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [priceType, setPriceType] = useState("BC");
    const [requiredClass, setRequiredClass] = useState(" hidden ");

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const defaultErrMsg = "An error occurred. Please try again or contact support.";


    // Methods

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    async function createProductCategoryList() {
        var list = [];
        list.push(<option key="0" value="0">None</option>);

        var dataList = await GetProductCategorySelectList("order");
        dataList.forEach((item, index) => {
            list.push(<option key={index + 1} value={item.value}>{item.text}</option>);
        });

        setCategoryList(list);
    }

    async function createVendorSupplierList() {
        var list = [];
        list.push(<option key="0" value="0">None</option>);

        var dataList = await GetVendorSelectList(1);
        const onlyActiveVendor = dataList.filter((item) => item.inactive !== true);
        onlyActiveVendor.forEach((item, index) => {
            list.push(<option key={index + 1} value={item.value}>{item.text}</option>);
        });

        setVendorList(list);
    }

    const handleChange = (e) => {
        const data = handleFieldChange(e);

        if (data) {
            setData((prevData) => ({
                ...prevData,
                [data.name]: data.value,
            }));
        } else {
            updateMessage(defaultErrMsg, 'text-red', 'null handleChange data');
        }
    };

    // Validate and Post Data

    function getMsg() {
        var vendorName = GetSelectItemTextById("vendorId");
        var categoryName = GetSelectItemTextById("productCategoryId");

        var msg = vendorName + " - " + categoryName;
        if (IdExists(productCategoryVendorRateId)) {
            return msg + " updated successfully.";
        }
        else {
            return msg + " created successfully.";
        }
    }

    const validateData = () => {
        var valid = true;
        var msg = "";

        if (IsFalse(isSelectedDropdown(data.productCategoryId))) {
            valid = false;
        }

        if (IsFalse(isSelectedDropdown(data.vendorId))) {
            valid = false;
        }

        if (IsFalse(valid)) {
            setRequiredClass("");
            updateMessage("*Required fields", "text-red");
        }
        return valid;
    };

    async function saveData() {
        setRequiredClass(" hidden ");

        var valid = validateData();
        if (valid === true) {
            var resp = await UpdatePOST();

            var iId = ParseIntSafe(resp);
            if (IdExists(iId)) {

                updateMessage(getMsg(), "text-green");

                data.vendorId = 0;
                data.productCategoryId = 0;                
                data.manufacturerName = "";
                data.priceBcMultiplier = 0;
                data.msrpFromPriceBc = true;
                data.msrpMultiplier = 0;
                data.freight = 0;
                data.load = 0;
                data.isPnw = false;
                data.isAz = false;
                data.isWeb = false;

                setPriceType("BC");
                setData(data);

                //onClose(getMsg());
            }
            else {
                console.error("handleUserSubmit(): ", JSON.stringify(resp));
                updateMessage(defaultErrMsg);
            }
        }
    }

    async function UpdatePOST() {

        if (priceType === "BC") {
            data.msrpFromPriceBc = true;
        }
        else {
            data.msrpFromPriceBc = false;
        }

        setData(data);
        var postModel = JSON.stringify(data);
        //console.log('Post Model:', postModel);
        var response = await StandardDataAndResponsePOST(postModel, "product/SaveCategoryVendorRate", "SaveCategoryVendorRate()");
        return response;
    }

    // Load Data

    const loadData = async () => {

        var dbData = await StandardIdAndResponseGET(productCategoryVendorRateId, "product/GetCategoryVendorRate/", "GetCategoryVendorRate()");
        //console.log('data:', data);
        if (Exists(dbData)) {

            if (IdExists(dbData.productCategoryVendorRateId)) {
                if (dbData.msrpFromPriceBc === true) {
                    setPriceType("BC");
                }
                else {
                    setPriceType("COST");
                }
            }
            else {
                // default values
                dbData.msrpFromPriceBc = true;
                setPriceType("BC");
            }

            setData(dbData);
            setLoading(false);
        }
        else {
            setError(true);
        }
    }

    function onChangePriceType(e) {
        if (Exists(e.target) && Exists(e.target.value)) {
            setPriceType(e.target.value);
        }
        else {
            alert(defaultErrMsg);
        }
    }

    function EnterSave(e) {
        if (e.key === "Enter") {
            PreventDefault(e);
            ClickById("btnSubmit");
        }
    }

    useEffect(() => {
        createProductCategoryList();
        createVendorSupplierList();
        loadData();
    }, []);

    if (IsTrue(loading)) {
        return (
            <div className="text-center text-xl mt-[10%]">
                <span className="pr-1">Loading...</span>
                <span className="loading loading-spinner text-blue-600"></span>
            </div>
        );
    }

    if (IsTrue(error)) {
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;
    }

    return (
        <Modal open={isOpen} className="min-w-[300px] py-2">
            <Modal.Header
                className="font-bold mb-0 text-center pb-6 text-secondary">
                {IdExists(productCategoryVendorRateId) ? "Create Rate" : "Edit Rate"}
            </Modal.Header>
            <Modal.Body>
                <div className="formVendorProductRate min-h-[300px]">
                    <div className="details-form-field">
                        <label for="vendorId" className="lbl-text1">Vendor</label>
                        <select id="vendorId" name="vendorId" className="sp-sel"
                            autoFocus                            
                            onKeyDown={(e) => EnterSave(e)}
                            onChange={handleChange}
                            value={data.vendorId}
                        >
                            {vendorList}
                        </select>
                        <span className={"text-red pl-1 " + requiredClass}>*</span>
                    </div>

                    <div className="details-form-field">
                        <label for="productCategoryId" className="lbl-text1">Category</label>
                        <select id="productCategoryId" name="productCategoryId"
                            className="sp-sel"
                            onKeyDown={(e) => EnterSave(e)}
                            onChange={handleChange}
                            value={data.productCategoryId}
                        >
                            {categoryList}
                        </select>
                        <span className={"text-red pl-1 " + requiredClass}>*</span>
                    </div>

                    <div className="details-form-field pb-4">
                        <label for="manufacturerName" className="lbl-text1">Manufacturer</label>
                        <input id="manufacturerName" name="manufacturerName" type="text"
                            className="inp-text"
                            onKeyDown={(e) => EnterSave(e)}
                            value={data.manufacturerName}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="details-form-field pb-4">
                        <label for="priceBcMultiplier" className="lbl-text1">BC Multiplier</label>
                        <input id="priceBcMultiplier" name="priceBcMultiplier" type="number"
                            className="inp-text"
                            onKeyDown={(e) => EnterSave(e)}
                            value={data.priceBcMultiplier}
                            onChange={handleChange}
                        />
                    </div>

                    <div className="details-form-field">
                        <label for="msrpMultiplier" className="lbl-text1">MSRP Multiplier</label>
                        <input id="msrpMultiplier" name="msrpMultiplier" type="number"
                            className="inp-text"
                            onKeyDown={(e) => EnterSave(e)}
                            onChange={handleChange}
                            value={data.msrpMultiplier}
                        />
                    </div>

                    <div className="details-form-field">
                        <label for="msrpMultiplier" className="lbl-text1">MSRP From</label>
                        <div className="table-cell">
                            <span className="pr-1 font-bold text-secondary">BC</span>
                            <input type="radio" value="BC" name="priceType" className="mt-[-3px] cursor-pointer"
                                onKeyDown={(e) => EnterSave(e)}
                                checked={priceType === "BC"}
                                onChange={(e) => onChangePriceType(e)} />

                            <span className="pl-4 pr-1 font-bold text-secondary">COST</span>
                            <input type="radio" value="COST" name="priceType" className="mt-[-5px] cursor-pointer"
                                onKeyDown={(e) => EnterSave(e)}
                                checked={priceType === "COST"}
                                onChange={(e) => onChangePriceType(e)} />
                        </div>
                    </div>

                    <div className="details-form-field">
                        <label for="freight" className="lbl-text1">Freight</label>
                        <input id="freight" name="freight" type="number"
                            className="inp-text"
                            onKeyDown={(e) => EnterSave(e)}
                            onChange={handleChange}
                            value={data.freight}
                        />
                    </div>
                    <div className="details-form-field">
                        <label for="load" className="lbl-text1">Load</label>
                        <input id="load" name="load" type="number"
                            className="inp-text"
                            onKeyDown={(e) => EnterSave(e)}
                            onChange={handleChange}
                            value={data.load}
                        />
                    </div>

                    <div className="details-form-field text-center">
                        <label for="isPnw" className="lbl-cb pl-20 pr-4">
                            PNW
                            <input id="isPnw" name="isPnw" type="checkbox"
                                className="inp-cb"
                                checked={data.isPnw}
                                onChange={handleChange}
                            />
                        </label>
                        <label for="isAz" className="lbl-cb pr-4">
                            AZ
                            <input id="isAz" name="isAz" type="checkbox"
                                className="inp-cb"
                                checked={data.isAz}
                                onChange={handleChange}
                            />
                        </label>
                        <label for="isWeb" className="lbl-cb pr-4">
                            Web
                            <input id="isWeb" name="isWeb" type="checkbox"
                                className="inp-cb"
                                checked={data.isWeb}
                                onChange={handleChange}
                            />
                        </label>
                        <label for="deleted" className="lbl-cb float-right">
                            Deleted
                            <input id="deleted" name="deleted" type="checkbox"
                                className="inp-cb"
                                checked={data.deleted}
                                onChange={handleChange}
                            />
                        </label>
                    </div>

                    <div className={"pt-8 h-[55px] text-center " + messageClass}>
                        {message}
                    </div>
                    <div className="text-right pt-2">
                        <button type="button" id="btnSubmit" className="btn-submit" onClick={(e) => saveData()}>
                            {/*Save & Exit*/}
                            Save
                        </button>
                        <button type="button" className="btn-cancel" onClick={(e) => onClose("")}>
                            Cancel
                        </button>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}