import React, { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import authService from '../../../services/authService.js';
import {
    makePayment,
    getCardToken,
    makePaymentByToken,
    makeAchPayment,
    postPaymentToDatabase,
    postTransactionToDatabase,
    getPaymentsByLastFour
} from '../../../services/paymentService';
import { ManualEntryContent } from '../_forms/ManualEntryContent';
import { OnFileContent } from '../_forms/OnFileContent';
import { SwipeContent } from '../_forms/SwipeContent';
import { CheckContent } from '../_forms/CheckContent';
import { CashContent } from '../_forms/CashContent';
import { ECheckContent } from '../_forms/ECheckContent';

// Manual Card
//const ManualEntryContent = ({
//    cardholderName,
//    setCardholderName,
//    cardNumber,
//    setCardNumber,
//    expiryDate,
//    handleExpiryChange,
//    saveCard,
//    setSaveCard,
//    cvc,
//    setCvc,
//    zipCode,
//    setZipCode,
//    getCardType,
//    formatCardNumber
//}) => (
//    <div className="space-y-3">
//        <div className="flex items-center gap-4">
//            <div className="flex-1">
//                <input
//                    type="text"
//                    placeholder="Cardholder Name"
//                    value={cardholderName}
//                    onChange={(e) => setCardholderName(e.target.value)}
//                    className="payment-input w-3/4"
//                    required
//                />
//            </div>
//            <label className="flex items-center gap-2 cursor-pointer">
//                <input type="checkbox" id="saveCard"
//                    checked={saveCard}
//                    onChange={(e) => setSaveCard(e.target.checked)}
//                    className="input-cb ml-1 mb-[2px]"
//                />
//                <label htmlFor="saveCard" className="text-sm font-semibold cursor-pointer">
//                    Save Card
//                </label>
//            </label>
//        </div>
//        <div className="relative">
//            <input
//                type="text"
//                placeholder="Card Number"
//                value={cardNumber}
//                onChange={(e) => setCardNumber(formatCardNumber(e.target.value))}
//                className="payment-input w-full pr-44"
//                maxLength={getCardType(cardNumber) === 'amex' ? 17 : 19}
//                required
//            />
//            <div className="absolute right-2 top-1/2 -translate-y-1/2 flex gap-1">
//                <div className={`card-icon visa-icon ${getCardType(cardNumber) === 'visa' ? 'opacity-100' : 'opacity-40'}`}></div>
//                <div className={`card-icon mastercard-icon ${getCardType(cardNumber) === 'mastercard' ? 'opacity-100' : 'opacity-40'}`}></div>
//                <div className={`card-icon amex-icon ${getCardType(cardNumber) === 'amex' ? 'opacity-100' : 'opacity-40'}`}></div>
//                <div className={`card-icon discover-icon ${getCardType(cardNumber) === 'discover' ? 'opacity-100' : 'opacity-40'}`}></div>
//            </div>
//        </div>
//        <div className="grid grid-cols-3 gap-3">
//            <input
//                type="text"
//                placeholder="MM / YY"
//                value={expiryDate}
//                onChange={handleExpiryChange}
//                className="payment-input"
//                maxLength={7}
//                required
//            />
//            <input
//                type="text"
//                placeholder="CVV"
//                value={cvc}
//                onChange={(e) => setCvc(e.target.value.replace(/\D/g, '').slice(0, getCardType(cardNumber) === 'amex' ? 4 : 3))}
//                className="payment-input"
//                required
//            />
//            <input
//                type="text"
//                placeholder="Billing Zip"
//                value={zipCode}
//                onChange={(e) => setZipCode(e.target.value.replace(/\D/g, '').slice(0, 5))}
//                className="payment-input"
//                maxLength={5}
//                required
//            />
//        </div>
//    </div>
//);

//// Swipe Card
//const SwipeContent = ({ readerStatus }) => (
//    <div className="text-center py-3"></div>
//);

//const OnFileContent = ({ setCardholderName, setCardNumber, setSelectedCardId }) => {
//    const [searchText, setSearchText] = useState('');
//    const [gridData, setGridData] = useState([]);
//    const [selectedRow, setSelectedRow] = useState(null);
//    const gridRef = useRef();

//    const columnDefs = [
//        {
//            headerName: "ID",
//            field: "id",
//            hide: true
//        },
//        {
//            headerName: "Name",
//            field: "name",
//            flex: 1
//        },
//        {
//            headerName: "Last 4",
//            field: "last4",
//            width: 120
//        },
//        {
//            headerName: "Address",
//            field: "address",
//            flex: 2,
//        },
//        {
//            headerName: "Last Used",
//            field: "lastUsed",
//            width: 120
//        },
//        {
//            headerName: "",
//            field: "",
//            width: 100,
//            cellRenderer: params => {
//                return (
//                    <button
//                        type="button"
//                        className="btn-grid"//{`btn btn-sm ${params.data === selectedRow ? 'btn-primary' : 'btn'}`}
//                        onClick={() => handleRowSelect(params.data)}
//                    >
//                        Select
//                    </button>
//                );
//            }
//        }
//    ];

//    const handleKeyPress = (e) => {
//        if (e.key === 'Enter') {
//            fetchLastFour();
//        }
//    };

//    const fetchLastFour = useCallback(async () => {
//        try {
//            if (searchText.length >= 4) {
//                const response = await getPaymentsByLastFour(searchText);
//                console.log('Payments:', JSON.stringify(response));
//                setGridData(response);

//                if (gridRef.current?.api) {
//                    gridRef.current.api.setQuickFilter(searchText);
//                }
//            }
//        } catch (error) {
//            console.error('Error fetching payments:', error);
//        }
//    }, [searchText]);

//    const handleSearch = (e) => {
//        fetchLastFour();
//        //Test data
//        //setGridData([
//        //    {
//        //        id: "123",
//        //        name: "Adrian Smith",
//        //        last4: "4421",
//        //        address: "7445 SW 84th Ave Portland, OR 97223",
//        //        lastUsed: "09/30/2024"
//        //    },
//        //    {
//        //        id: "456",
//        //        name: "Terese Shoff",
//        //        last4: "4421",
//        //        address: "14160 SW 72nd Ave Tigard, OR 97224",
//        //        lastUsed: "04/22/2023"
//        //    }
//        //]);
//    };

//    const handleRowSelect = (rowData) => {
//        setSelectedRow(rowData);
//        setCardholderName(rowData.name);
//        setCardNumber(rowData.last4);
//        setSelectedCardId(rowData.token);
//    };

//    return (
//        <div className="space-y-4">
//            <div className="flex gap-2">
//                <input
//                    type="text"
//                    placeholder="Last 4 Digits"
//                    value={searchText}
//                    onChange={(e) => setSearchText(e.target.value)}
//                    onKeyPress={handleKeyPress}
//                    className="payment-input w-48"
//                    maxLength={4}
//                />
//                <button
//                    type="button"
//                    onClick={handleSearch}
//                    disabled={searchText.length < 4}
//                    className="btn-grid"
//                >
//                    Search
//                </button>
//            </div>

//            <div
//                className="ag-theme-alpine w-full"
//                style={{ height: '200px' }}
//            >
//                <AgGridReact
//                    ref={gridRef}
//                    rowData={gridData}
//                    columnDefs={columnDefs}
//                    rowSelection="single"
//                    suppressCellFocus={true}
//                    defaultColDef={{
//                        sortable: true,
//                        resizable: true,
//                    }}
//                />
//            </div>
//        </div>
//    );
//};

//// Check
//const CheckContent = ({ checkNumber, setCheckNumber }) => (
//    <div className="space-y-3">
//        <input
//            type="text"
//            placeholder="Check Number"
//            value={checkNumber}
//            onChange={(e) => setCheckNumber(e.target.value)}
//            className="payment-input"
//            required
//        />
//    </div>
//);

//// Cash
//const CashContent = ({ paymentAmount, cashTendered, balanceDue, changeDue }) => {
//    const [balance, setBalance] = useState('0.00');
//    const [change, setChange] = useState('0.00');

//    useEffect(() => {
//        // Remaining balance
//        const payment = parseFloat(paymentAmount) || 0;
//        const remaining = balanceDue - payment;
//        setBalance(Math.max(remaining, 0).toFixed(2));

//        // Change due
//        const tendered = parseFloat(cashTendered) || 0;
//        const changeAmount = tendered - payment;
//        setChange((changeAmount > 0 ? changeAmount : 0).toFixed(2));
//    }, [paymentAmount, cashTendered, balanceDue]);

//    return (
//        <div>
//            <div className="grid grid-cols-3 gap-3">
//                <div className="relative">
//                    <label className="label">
//                        <span className="label-text font-semibold">Balance:</span>
//                    </label>
//                    <span className="absolute left-3 top-[35px] -translate-y-[-5px]">$</span>
//                    <input
//                        type="number"
//                        step="0.01"
//                        value={balance}
//                        className="w-full payment-input !pl-6"
//                        placeholder="0.00"
//                        readOnly
//                    />
//                </div>
//                <div className="relative">
//                    <label className="label">
//                        <span className="label-text font-semibold">Change Due:</span>
//                    </label>
//                    <span className="absolute left-3 top-[35px] -translate-y-[-5px]">$</span>
//                    <input
//                        type="number"
//                        step="0.01"
//                        value={change}
//                        className="w-full payment-input !pl-6"
//                        placeholder="0.00"
//                        readOnly
//                    />
//                </div>
//            </div>
//        </div>
//    );
//};

//const ECheckContent = ({
//    accountType,
//    setAccountType,
//    bankName,
//    setBankName,
//    accountNumber,
//    setAccountNumber,
//    confirmAccountNumber,
//    setConfirmAccountNumber,
//    routingNumber,
//    setRoutingNumber,
//    confirmRoutingNumber,
//    setConfirmRoutingNumber,
//    nameOnAccount,
//    setNameOnAccount
//}) => {
//    return (
//        <div className="space-y-4">
//            {/* Account Type */}
//            <div className="grid grid-cols-2 gap-4">
//                <div className="form-control">
//                    <select
//                        value={accountType}
//                        onChange={(e) => setAccountType(e.target.value)}
//                        className="select select-bordered w-full select-sm !h-8 p-0 pl-3"
//                        required
//                    >
//                        <option value="checking">Checking</option>
//                        <option value="savings">Savings</option>
//                    </select>
//                </div>

//                <div className="form-control">
//                    <input
//                        type="text"
//                        value={bankName}
//                        onChange={(e) => setBankName(e.target.value)}
//                        className="input input-bordered w-full h-8"
//                        placeholder="Bank Name"
//                        required
//                    />
//                </div>
//            </div>

//            {/* Account Number */}
//            <div className="grid grid-cols-2 gap-4">
//                <div className="form-control">
//                    <input
//                        type="password"
//                        value={accountNumber}
//                        onChange={(e) => setAccountNumber(e.target.value.replace(/\D/g, ''))}
//                        className="input input-bordered w-full h-8"
//                        placeholder="Account #"
//                        required
//                    />
//                </div>

//                <div className="form-control">
//                    <input
//                        type="text"
//                        value={confirmAccountNumber}
//                        onChange={(e) => setConfirmAccountNumber(e.target.value.replace(/\D/g, ''))}
//                        className={`input input-bordered w-full h-8 ${confirmAccountNumber && accountNumber !== confirmAccountNumber
//                            ? 'border-red-500'
//                            : ''
//                            }`}
//                        placeholder="Confirm Account #"
//                        required
//                    />
//                </div>
//            </div>
//            {confirmAccountNumber && accountNumber !== confirmAccountNumber && (
//                <div className="text-red-500 text-sm mt-1">Account numbers do not match</div>
//            )}

//            {/* Routing Number */}
//            <div className="grid grid-cols-2 gap-4">
//                <div className="form-control">
//                    <input
//                        type="text"
//                        value={routingNumber}
//                        onChange={(e) => setRoutingNumber(e.target.value.replace(/\D/g, ''))}
//                        maxLength={9}
//                        className="input input-bordered w-full h-8"
//                        placeholder="9-digit Routing #"
//                        required
//                    />
//                </div>

//                <div className="form-control">
//                    <input
//                        type="text"
//                        value={confirmRoutingNumber}
//                        onChange={(e) => setConfirmRoutingNumber(e.target.value.replace(/\D/g, ''))}
//                        maxLength={9}
//                        className={`input input-bordered w-full h-8 ${confirmRoutingNumber && routingNumber !== confirmRoutingNumber
//                            ? 'border-red-500'
//                            : ''
//                            }`}
//                        placeholder="Confirm Routing #"
//                        required
//                    />
//                </div>
//            </div>
//            {confirmRoutingNumber && routingNumber !== confirmRoutingNumber && (
//                <div className="text-red-500 text-sm mt-1">Routing numbers do not match</div>
//            )}

//            {/* Name */}
//            <div className="form-control">
//                <input
//                    type="text"
//                    value={nameOnAccount}
//                    onChange={(e) => setNameOnAccount(e.target.value)}
//                    className="input input-bordered w-full h-8"
//                    placeholder="Name on Account"
//                    required
//                />
//            </div>
//        </div>
//    );
//};

const PaymentStatusModal = ({ isOpen, onClose, success, message }) => {
    if (!isOpen) return null;

    const handleClose = () => {
        onClose();
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[60]">
            <div className="bg-white p-4 pt-2 rounded-lg shadow-lg w-full max-w-sm">
                <div className="relative pb-2 mb-4 flex items-center justify-between">
                    <h3 className="font-bold text-xl text-center flex-grow">
                        Process Payment
                    </h3>
                    <button
                        onClick={handleClose}
                        className="btn btn-sm btn-circle btn-ghost"
                    >
                        ✕
                    </button>
                </div>

                <div className="mb-4 border-b pb-4">
                    {message.invoices.length === 1 ? (
                        // Single invoice display
                        <div className="flex justify-between text-sm">
                            <div>
                                <span className="text-black font-semibold">Order #: </span>
                                <span className="font-bold">{message.invoices[0].orderNumber}</span>
                            </div>
                            <div>
                                <span className="text-black font-semibold">Balance Due: </span>
                                <span className={success ? 'text-black font-bold' : 'text-red-500 font-bold'}>
                                    ${(message.invoices[0].balanceDue - message.invoices[0].paymentAmount).toFixed(2)}
                                </span>
                            </div>
                        </div>
                    ) : (
                        // Multiple invoices display
                        <div className="space-y-2">
                            {message.invoices.map((invoice, index) => (
                                <div key={invoice.orderNumber} className="flex justify-between text-sm">
                                    <div>
                                        <span className="text-black font-semibold">Order #: </span>
                                        <span className="font-bold">{invoice.orderNumber}</span>
                                    </div>
                                    <div>
                                        <span className="text-black font-semibold">Balance Due: </span>
                                        <span className={`font-bold ${invoice.balanceDue - invoice.paymentAmount > 0 ? 'text-red' : 'text-black'}`}>
                                            ${(invoice.balanceDue - invoice.paymentAmount).toFixed(2)}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <hr />
                <button
                    onClick={handleClose}
                    className={`btn btn-sm w-full mt-2 py-2 px-4 rounded-md text-center text-white font-semibold 
                        ${success
                            ? 'bg-green-500 hover:bg-green-600'
                            : 'bg-red-500 hover:bg-red-600'
                        }`}
                >
                    {success ? 'Payment Approved' : 'Unsuccessful - Call Bank!'}
                </button>
            </div>
        </div>
    );
};

export function PaymentModal({
    onClose,
    invoices,
    confirmPayment
}) {
    // Refs
    const gridRef = useRef();

    const [orderNumber, setOrderNumber] = useState(invoices[0]?.orderNumber || '');

    const [invoiceList, setInvoiceList] = useState(invoices.map(invoice => ({
        ...invoice,
        invoiceId: invoice.invoiceId || 0,
        orderId: invoice.orderId || 0,
        orderNumber: invoice.orderNumber || '',
        paymentAmount: invoice.paymentAmount || invoice.balanceDue || 0,
        accountName: invoice.accountName || ''
    })));

    const [totalAmount, setTotalAmount] = useState(
        invoices.reduce((sum, inv) => sum + (inv.paymentAmount || inv.balanceDue || 0), 0)
    );

    const [balanceDue, setBalanceDue] = useState(
        invoices.reduce((sum, inv) => sum + (inv.balanceDue || 0), 0)
    );

    // States
    // Payment state
    const [paymentMethod, setPaymentMethod] = useState('creditCard');
    const [entryMethod, setEntryMethod] = useState('manual');
    const [paymentAmountLabel, setPaymentAmountLabel] = useState('Payment Amount:');
    const [accountName, setAccountName] = useState(invoices[0]?.accountName || '');
    const [paymentAmount, setPaymentAmount] = useState(
        invoices.reduce((sum, inv) => sum + (inv.balanceDue || 0), 0).toFixed(2)
    );
    const [individualPayments, setIndividualPayments] = useState(
        invoices.map(invoice => ({
            invoiceId: invoice.invoiceId,
            orderId: invoice.orderId,
            orderNumber: invoice.orderNumber,
            amount: invoice.balanceDue || 0
        }))
    );

    // Card payment state
    const [selectedCardId, setSelectedCardId] = useState('');
    const [cardNumber, setCardNumber] = useState('');
    const [cardholderName, setCardholderName] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [cvc, setCvc] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [saveCard, setSaveCard] = useState(false);

    // Check payment state
    const [checkNumber, setCheckNumber] = useState('');

    // E-Check payment state
    const [bankName, setBankName] = useState('');
    const [accountType, setAccountType] = useState('checking');
    const [accountNumber, setAccountNumber] = useState('');
    const [confirmAccountNumber, setConfirmAccountNumber] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [confirmRoutingNumber, setConfirmRoutingNumber] = useState('');
    const [nameOnAccount, setNameOnAccount] = useState('');

    // Cash payment state
    const [cashTendered, setCashTendered] = useState('');
    const [balance, setBalance] = useState('');
    const [changeDue, setChangeDue] = useState('');

    // UI state
    const [userData, setUserData] = useState(null);
    const [readerStatus, setReaderStatus] = useState('Reader Active - Awaiting');
    const [selectedRow, setSelectedRow] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState('');
    const [showStatusModal, setShowStatusModal] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState({
        success: false,
        message: {
            orderNumber: '',
            balance: 0,
            amountPaid: 0
        }
    });

    useEffect(() => {
        const getCurrentUser = async () => {
            try {
                const user = await authService.getCurrentUser();
                setUserData(user);
            } catch (error) {
                console.error('Error fetching user data:', error);
                setError('Error fetching user data');
            }
        };

        getCurrentUser();
    }, []);

    // Clear fields on payment method change
    useEffect(() => {        
        setCardNumber('4111111111111111');   // TODO: remove - temp for testing
        //setCardNumber('');
        setCardholderName('');
        setExpiryDate('');
        setCvc('');
        setZipCode('');
        setCheckNumber('');
        setSelectedCardId('');
        setSelectedRow(null);
        setBankName('');
        setAccountNumber('');
        setConfirmAccountNumber('');
        setRoutingNumber('');
        setConfirmRoutingNumber('');
        setNameOnAccount('');
        if (invoices.length < 1) setTotalAmount(balanceDue);
        if (paymentMethod === 'creditCard') {
            setEntryMethod('manual');
        }
    }, [paymentMethod, balanceDue, invoices]);

    useEffect(() => {
        setPaymentAmount(balanceDue.toFixed(2));
        setCashTendered(balanceDue.toFixed(2));
    }, [balanceDue]);

    const invoiceColumnDefs = [
        {
            headerName: "",
            field: "invoiceId",
            flex: 1,
            hide: true
        },
        {
            headerName: "Invoice #",
            field: "orderNumber",
            flex: 1
        },
        {
            headerName: "Balance Due",
            field: "balanceDue",
            flex: 1,
            cellRenderer: params => {
                const balanceDue = params.value;
                return (
                    <div className={`font-semibold ${balanceDue > 0 ? 'text-red-500' : ''}`}>
                        ${formatCurrency(balanceDue)}
                    </div>
                );
            }
        },
        {
            headerName: "Amount Paid",
            field: "paymentAmount",
            flex: 1,
            editable: true,
            cellStyle: { cursor: 'pointer' },
            singleClickEdit: true,
            enableGroupEdit: true,
            cellRenderer: params => `$${formatCurrency(params.value)}`,
            valueParser: params => {
                const newValue = parseFloat(params.newValue);
                return isNaN(newValue) ? params.oldValue : Math.min(newValue, params.data.balanceDue);
            },
            cellEditor: 'agNumberCellEditor',
            cellEditorParams: {
                precision: 2,
            }
        },
        {
            headerName: "",
            width: 50,
            cellRenderer: params => (
                <button
                    onClick={() => handleRemoveInvoice(params.data.invoiceId)}
                    className="text-red-500 hover:text-red-700 font-bold"
                >
                    ✕
                </button>
            )
        }
    ];

    // Card formatting
    const getCardType = useCallback((number) => {
        const cleanNumber = number.replace(/\D/g, '');
        if (cleanNumber.match(/^3[47]/)) return 'amex';
        if (cleanNumber.match(/^4/)) return 'visa';
        if (cleanNumber.match(/^5[1-5]/)) return 'mastercard';
        if (cleanNumber.match(/^6/)) return 'discover';
        return 'unknown';
    }, []);

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(amount);
    };

    const formatCardNumber = useCallback((value) => {
        const cleanValue = value.replace(/\D/g, '');
        const cardType = getCardType(cleanValue);

        if (cardType === 'amex') {
            return cleanValue.replace(/(\d{4})(\d{6})(\d{5})/, '$1 $2 $3');
        }

        return cleanValue.replace(/(\d{4})(\d{4})?(\d{4})?(\d{4})?/, (_, p1, p2, p3, p4) => {
            let output = p1;
            if (p2) output += ' ' + p2;
            if (p3) output += ' ' + p3;
            if (p4) output += ' ' + p4;
            return output;
        });
    }, [getCardType]);

    const handleExpiryChange = useCallback((e) => {
        const { value } = e.target;
        const cleanValue = value.replace(/\D/g, '');
        if (cleanValue.length >= 2) {
            setExpiryDate(cleanValue.slice(0, 2) + ' / ' + cleanValue.slice(2, 4));
        } else {
            setExpiryDate(cleanValue);
        }
    }, []);

    // Payment Amount
    const handlePaymentAmountChange = (e) => {
        const payment = parseFloat(e.target.value) || 0;
        setPaymentAmount(payment);
        setCashTendered(payment);

        if (paymentMethod === 'cash') {
            const change = cashTendered - payment;

            if (change > 0) {
                setChangeDue(change.toFixed(2));
                setBalance('0.00');
            } else {
                setChangeDue('0.00');
                setBalance(Math.abs(change).toFixed(2));
            }

        }
    };
    
    // Cash Tendered
    const handleCashTenderedChange = (e) => {
        const tendered = parseFloat(e.target.value) || 0;
        setCashTendered(tendered);

        if (paymentMethod === 'cash') {
            const newBalance = balance - paymentAmount;
            if (newBalance <= 0) setBalance(Math.abs(0).toFixed(2));
            const change = paymentAmount - cashTendered;

            if (change > 0) {
                setChangeDue(change.toFixed(2));
                setBalance('0.00');
            } else {
                setChangeDue('0.00');
                setBalance(Math.abs(change).toFixed(2));
            }
        }
    };

    // Submit
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError('');
        try {
            let token = '';
            let paymentResponse = null;

            let paymentType = paymentMethod === 'creditCard'
                ? (() => {
                    const firstDigit = cardNumber.replace(/\D/g, '').charAt(0);
                    return firstDigit === '3' ? 'AMEX' :
                        firstDigit === '4' ? 'VISA' :
                            firstDigit === '5' ? 'MC' :
                                firstDigit === '6' ? 'DISCOVER' : 'VISA';
                })()
                : paymentMethod === 'cash' ? 'CASH'
                    : paymentMethod === 'check' ? 'CHECK'
                        : paymentMethod === 'echeck' ? 'ACH'
                            : '';

            // Credit Card
            if (paymentMethod === 'creditCard') {
                if (entryMethod === 'manual') {
                    const paymentRequest = {
                        TransType: "Sale",
                        Medium: "Credit",
                        EntryMode: "Manual",
                        AccountNum: cardNumber.replace(/\D/g, ''),
                        ExpDate: expiryDate.replace(/\D/g, ''),
                        Cvv: cvc,
                        MainAmt: 1, //Number(paymentAmount), Commented, getting invalid amount
                        InvoiceNum: orderNumber,
                    };

                    if (saveCard) {
                        paymentRequest.TransType = "CreateCardToken";
                        const tokenResponse = await getCardToken(paymentRequest);
                        token = tokenResponse.CardToken;

                        if (token) {
                            paymentResponse = await makePaymentByToken(token, parseFloat(paymentAmount));

                            if (paymentResponse) {
                                paymentRequest.token = token;
                                paymentRequest.last4 = cardNumber.slice(-4);
                            }
                        }
                    } else {
                        paymentResponse = await makePayment(paymentRequest);
                    }
                } else if (entryMethod === 'file') {
                    paymentResponse = await makePaymentByToken(selectedCardId, parseFloat(paymentAmount));
                    token = selectedCardId;
                } else if (entryMethod === 'swipe') {
                    console.log('Swipe Payment');
                }

                // Save transaction
                if (paymentResponse) {
                    console.log('Posting to DB', paymentResponse);
                    await postTransactionToDatabase({
                        transactionIdentifier: paymentResponse.TransactionIdentifier,
                        transactionResponse: JSON.stringify(paymentResponse),
                        responseMessage: paymentResponse.ErrorText || '',
                        cardToken: token,
                        status: paymentResponse.ResultText || 'Success',
                        userId: userData?.userId || 0
                    });
                }
            } else if (paymentMethod === 'echeck') {
                const achData = {
                    routingNumber,
                    accountNumber,
                    accountType: accountType.toUpperCase(),
                    nameOnAccount,
                    bankName,
                    userId: userData?.userId || 0,
                    userName: userData?.userName || ''
                };
                paymentResponse = await makeAchPayment(achData, parseFloat(paymentAmount));
            }

            // Payment status
            const isSuccessful = paymentMethod === 'creditCard' || paymentMethod === 'echeck'
                ? paymentResponse && paymentResponse.ErrorCode === '000'
                : true; // Success for cash/check

            console.log('Payment Response', paymentResponse);

            if (isSuccessful) {
                console.log('Success!');
                const paymentModel = {
                    ...createPaymentModel(token, paymentResponse),
                    paymentType
                };

                await confirmPayment(paymentModel);
                setSuccess('Payment processed successfully');
            }

            // Single invoice/order, set paymentAmount
            if (invoiceList.length === 1) invoiceList[0].paymentAmount = paymentAmount;

            // Show status modal
            setPaymentStatus({
                success: isSuccessful,
                message: {
                    invoices: invoiceList.map(invoice => ({
                        orderNumber: invoice.orderNumber,
                        balanceDue: invoice.balanceDue,
                        paymentAmount: invoice.paymentAmount
                    }))
                }
            });
            setShowStatusModal(true);

        } catch (error) {
            console.error('Payment failed:', error);
            setError(error.response?.data?.message || error.message || 'An unknown error occurred');

            // Show error status in modal
            setPaymentStatus({
                success: false,
                message: {
                    invoices: invoiceList.map(invoice => ({
                        orderNumber: invoice.orderNumber,
                        balanceDue: invoice.balanceDue,
                        paymentAmount: invoice.paymentAmount
                    }))
                }
            });
            setShowStatusModal(true);
        } finally {
            setIsSubmitting(false);
        }
    };

    const createPaymentModel = (token = '', paymentResponse = null) => {
        const isOrderOnly = invoices[0].isOrderOnly;

        const paymentInvoices = isOrderOnly
            ? [{
                invoiceId: 0,
                orderId: invoiceList[0].orderId,
                amountPaid: parseFloat(paymentAmount),
                datePaid: new Date().toISOString()
            }]
            : invoiceList.map(invoice => ({
                invoiceId: invoice.invoiceId,
                orderId: invoice.orderId,
                amountPaid: invoices.length === 1 ? parseFloat(paymentAmount) : invoice.paymentAmount,
                datePaid: new Date().toISOString()
            }));

        const paymentType = paymentMethod === 'creditCard'
            ? (() => {
                const firstDigit = cardNumber.replace(/\D/g, '').charAt(0);
                return firstDigit === '3' ? 'AMEX' :
                    firstDigit === '4' ? 'VISA' :
                        firstDigit === '5' ? 'MC' :
                            firstDigit === '6' ? 'DISCOVER' : 'UNKNOWN';
            })()
            : paymentMethod === 'cash' ? 'CASH'
                : paymentMethod === 'check' ? 'CHECK'
                    : paymentMethod === 'echeck' ? 'ACH'
                        : '';

        const model = {
            paymentId: "",
            description: invoiceList.length > 1
                ? invoiceList.map(inv => `#${inv.orderNumber}`).join(', ')
                : `#${invoiceList[0].orderNumber}`,
            paymentType,
            status: paymentResponse ? paymentResponse.ResultText || "Success" : "Success",
            fullName: paymentMethod === 'creditCard' ? cardholderName :
                paymentMethod === 'echeck' ? nameOnAccount : "",
            amount: paymentAmount, //totalAmount,
            checkNumber: paymentMethod === 'check' ? checkNumber : "",
            identifier: paymentMethod === 'creditCard' ?
                (entryMethod === 'manual' ? cardNumber.slice(-4) : "") : "",
            accountNumber: paymentMethod === 'echeck' ? accountNumber : "",
            routingNumber: paymentMethod === 'echeck' ? routingNumber : "",
            bankName: paymentMethod === 'echeck' ? bankName : "",
            accountType: paymentMethod === 'echeck' ? accountType : "",
            token: token,
            orderId: invoiceList[0].orderId,
            orderNumber: invoiceList[0].orderNumber,
            invoiceId: invoiceList[0].invoiceId,
            billingGroupId: 0,
            userId: userData?.userId || 0,
            userName: userData?.userName || "",
            lastUsed: new Date().toISOString(),
            invoices: paymentInvoices,
            transactionInfo: paymentResponse ? {
                transactionIdentifier: paymentResponse.TransactionIdentifier || '',
                transactionResponse: JSON.stringify(paymentResponse),
                responseMessage: paymentResponse.ErrorText || '',
                cardToken: token || '',
                status: paymentResponse.ResultText || 'Success',
                paymentId: '',
                userId: userData?.userId || 0,
                transactionDate: new Date().toISOString()
            } : null
        };
        return model;
    };

    const handleRemoveInvoice = useCallback((invoiceId) => {
        setInvoiceList(prevList => {
            const updatedList = prevList.filter(invoice => invoice.invoiceId !== invoiceId);
            return updatedList;
        });

        setIndividualPayments(prevPayments => {
            const updatedPayments = prevPayments.filter(payment => payment.invoiceId !== invoiceId);

            const newTotal = updatedPayments.reduce((sum, payment) =>
                sum + parseFloat(payment.amount || 0), 0
            );

            setTotalAmount(newTotal);
            setPaymentAmount(newTotal.toFixed(2));
            setCashTendered(newTotal.toFixed(2));
            return updatedPayments;
        });
    }, []);

    const onCellValueChanged = (params) => {
        if (params.colDef.field === 'paymentAmount') {
            const newValue = parseFloat(params.newValue);

            setInvoiceList(currentList =>
                currentList.map(item => {
                    // Match by invoiceId if it exists, otherwise by orderId
                    const isMatch = item.invoiceId
                        ? item.invoiceId === params.data.invoiceId
                        : item.orderId === params.data.orderId;

                    return isMatch ? { ...item, paymentAmount: newValue } : item;
                })
            );

            setIndividualPayments(currentPayments =>
                currentPayments.map(payment => {
                    // Match by invoiceId if it exists, otherwise by orderId
                    const isMatch = payment.invoiceId
                        ? payment.invoiceId === params.data.invoiceId
                        : payment.orderId === params.data.orderId;

                    return isMatch ? { ...payment, amount: newValue } : payment;
                })
            );

            const newTotal = gridRef.current.api.getModel().rowsToDisplay
                .reduce((sum, row) => sum + parseFloat(row.data.paymentAmount || 0), 0);

            setTotalAmount(newTotal);
            setPaymentAmount(newTotal.toFixed(2));
            setCashTendered(newTotal.toFixed(2));
        }
    };

    const isFormValid = useMemo(() => {
        // Payment amount
        const amount = parseFloat(paymentAmount);
        if (isNaN(amount) || amount <= 0) return false;

        // Multiple Invoices
        if (invoices.length > 1) {
            const sumOfInvoices = invoiceList.reduce((sum, invoice) =>
                sum + parseFloat(invoice.paymentAmount || 0), 0
            );

            if (Math.abs(amount - sumOfInvoices) > 0.01) {
                return false;
            }
        }

        // Credit Card 
        if (paymentMethod === 'creditCard') {
            switch (entryMethod) {
                case 'manual':
                    const cleanCardNumber = cardNumber.replace(/\D/g, '');
                    const isAmex = getCardType(cleanCardNumber) === 'amex';
                    return (
                        cardholderName.trim().length > 0 &&
                        (isAmex ? cleanCardNumber.length === 15 : cleanCardNumber.length === 16) &&
                        expiryDate.replace(/\D/g, '').length === 4 &&
                        (isAmex ? cvc.length === 4 : cvc.length === 3) &&
                        zipCode.length === 5
                    );
                case 'file':
                    return selectedCardId !== '';
                case 'swipe':
                    // Add swipe validation
                    return true;
                default:
                    return false;
            }
        }

        // Check 
        if (paymentMethod === 'check') {
            return checkNumber.trim().length > 0;
        }

        // E-Check 
        if (paymentMethod === 'echeck') {
            return (
                bankName.trim().length > 0 &&
                accountType &&
                accountNumber.length > 0 &&
                accountNumber === confirmAccountNumber &&
                routingNumber.length === 9 &&
                routingNumber === confirmRoutingNumber &&
                nameOnAccount.trim().length > 0
            );
        }

        // Cash
        if (paymentMethod === 'cash') {
            if (Math.abs(cashTendered) < Math.abs(paymentAmount)) return false;
            if (invoices.length > 1) {
                    const cashAmount = parseFloat(cashTendered);
                    if (isNaN(cashAmount) || cashAmount < amount) {
                        return false;
                    }
            }
        }

        return true;

    }, [
        invoiceList,
        cashTendered,
        invoices.length,
        paymentMethod,
        paymentAmount,
        entryMethod,
        cardholderName,
        cardNumber,
        expiryDate,
        cvc,
        zipCode,
        selectedCardId,
        checkNumber,
        bankName,
        accountType,
        accountNumber,
        confirmAccountNumber,
        routingNumber,
        confirmRoutingNumber,
        nameOnAccount,
        getCardType
    ]);

    useEffect(() => {
        switch (paymentMethod) {
            case 'check':
                setPaymentAmountLabel('Check Amount:');
                break;
            default:
                setPaymentAmountLabel('Payment Amount:');
                break;
        }
    }, [paymentMethod]);

    const renderPaymentContent = useCallback(() => {
        if (paymentMethod === 'creditCard') {
            switch (entryMethod) {
                case 'manual':
                    return (
                        <ManualEntryContent
                            cardholderName={cardholderName}
                            setCardholderName={setCardholderName}
                            cardNumber={cardNumber}
                            setCardNumber={setCardNumber}
                            expiryDate={expiryDate}
                            handleExpiryChange={handleExpiryChange}
                            cvc={cvc}
                            setCvc={setCvc}
                            zipCode={zipCode}
                            setZipCode={setZipCode}
                            getCardType={getCardType}
                            formatCardNumber={formatCardNumber}
                            saveCard={saveCard}
                            setSaveCard={setSaveCard}
                        />
                    );
                case 'swipe':
                    return <SwipeContent readerStatus={readerStatus} />;
                case 'file':
                    return (
                        <div className="w-full">
                            <OnFileContent setCardholderName={setCardholderName} setCardNumber={setCardNumber} setSelectedCardId={setSelectedCardId} />
                        </div>
                    );
                default:
                    return null;
            }
        }
        else if (paymentMethod === 'check') {
            return <CheckContent
                checkNumber={checkNumber}
                setCheckNumber={setCheckNumber}
            />
        }
        else if (paymentMethod === 'cash') {
            return <CashContent
                key="cash-content"
                paymentAmount={paymentAmount}
                balanceDue={balanceDue}
                cashTendered={cashTendered}
                changeDue={changeDue}
            />
        }
        else if (paymentMethod === 'echeck') {
            return <ECheckContent
                accountType={accountType}
                setAccountType={setAccountType}
                bankName={bankName}
                setBankName={setBankName}
                accountNumber={accountNumber}
                setAccountNumber={setAccountNumber}
                confirmAccountNumber={confirmAccountNumber}
                setConfirmAccountNumber={setConfirmAccountNumber}
                routingNumber={routingNumber}
                setRoutingNumber={setRoutingNumber}
                confirmRoutingNumber={confirmRoutingNumber}
                setConfirmRoutingNumber={setConfirmRoutingNumber}
                nameOnAccount={nameOnAccount}
                setNameOnAccount={setNameOnAccount}
            />
        }
        return null;
    }, [
        accountType,
        cashTendered,
        checkNumber,
        changeDue,
        saveCard,
        bankName,
        accountNumber,
        confirmAccountNumber,
        routingNumber,
        confirmRoutingNumber,
        nameOnAccount,
        paymentAmount,
        balanceDue,
        paymentMethod,
        entryMethod,
        cardholderName,
        cardNumber,
        expiryDate,
        cvc,
        zipCode,
        readerStatus,
        handleExpiryChange,
        getCardType,
        formatCardNumber
    ]);


    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className={`modal-box relative bg-white p-4 rounded-lg shadow-lg ${paymentMethod === 'creditCard' && entryMethod === 'file'
                ? invoices.length > 1
                    ? 'w-full max-w-7xl'  // Multiple invoices + OnFile
                    : 'w-full max-w-4xl'  // Single invoice + OnFile
                : invoices.length > 1
                    ? 'w-full max-w-7xl'  // Multiple invoices
                    : 'w-full max-w-2xl'  // Single invoice
                }`}>


                {/* Header */}
                <div className="relative pb-2 mb-4 flex items-center justify-between">
                    <h3 className="font-bold text-xl text-center flex-grow">
                        {invoices.length > 1 ? 'Process Payment - Multiple Invoices' : 'Process Payment'}
                    </h3>
                    <button
                        onClick={onClose}
                        className="btn btn-sm btn-circle btn-ghost"
                    >
                        ✕
                    </button>
                </div>

                <div className={`${invoices.length > 1 ? 'flex gap-6' : ''}`}>
                    {/* Left side - Payment form */}
                    <div className={`${invoices.length > 1 ? 'w-1/2' : 'w-full'}`}>
                        {invoices.length > 1 ? (
                            <div className="mb-4 border-b pb-4">
                                <div className="text-lg">
                                    <div className="label-text font-semibold">Account Name:</div>
                                    <div className="label-text font-bold">{accountName}</div>
                                </div>
                            </div>
                        ) : (
                            <div className="flex justify-between items-center mb-2 border-b pb-4">
                                <div className="font-semibold text-lg">
                                    Order #: <span className="font-bold text-lg">{orderNumber}</span>
                                </div>
                                <div className="font-semibold text-lg">
                                    Balance Due: <span className="font-bold text-red">${balanceDue.toFixed(2)}</span>
                                </div>
                            </div>
                        )}

                        <form onSubmit={handleSubmit} className="space-y-4">
                            {/* Amount */}
                            {/* Only show for single or cash */}
                            {(invoices.length === 1 || paymentMethod === 'cash') && (
                                <div className="form-control">

                                    <label className="label">
                                        <span className="label-text font-semibold">{paymentAmountLabel}</span>
                                    </label>
                                    <div className="flex gap-4">
                                        <div className="relative w-1/3">
                                            <span className="absolute left-0 top-0 bottom-0 flex items-center justify-center bg-gray-200 text-gray-700 font-bold px-1.5 rounded-l-md border-r border-gray-300 w-8">
                                                $
                                            </span>
                                            <input
                                                type="number"
                                                step="0.01"
                                                value={paymentAmount}
                                                onChange={handlePaymentAmountChange}
                                                className="payment-input w-[130px] pl-4 font-bold text-right bg-sky-500"
                                                placeholder="0.00"
                                                required
                                            />
                                        </div>
                                        {paymentMethod === 'cash' && (
                                            <div className="relative w-1/3">
                                                <label className="absolute -top-6 label-text font-semibold">Cash Tendered:</label>
                                                <span className="absolute left-0 top-0 bottom-0 flex items-center justify-center bg-gray-200 text-gray-700 font-bold px-1.5 rounded-l-md border-r border-gray-300 w-8">
                                                    $
                                                </span>
                                                <input
                                                    type="number"
                                                    step="0.01"
                                                    value={cashTendered}
                                                    onChange={handleCashTenderedChange}
                                                    className={`payment-input w-[130px] pl-4 font-bold text-right ${parseFloat(cashTendered) < parseFloat(paymentAmount) ? 'border-red-500' : ''
                                                        }`}
                                                    placeholder="0.00"
                                                    required
                                                />
                                                {parseFloat(cashTendered) < parseFloat(paymentAmount) && (
                                                    <div className="absolute text-red-500 text-sm mt-1">
                                                        Cash tendered cannot be less than payment amount
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}

                            {/* Payment Type */}
                            <div className="form-control">
                                <label className="label">
                                    <span className="label-text font-semibold">Type:</span>
                                </label>
                                <div className="flex gap-2">
                                    {[
                                        { value: 'creditCard', label: 'Credit Card' },
                                        { value: 'check', label: 'Check' },
                                        { value: 'cash', label: 'Cash' },
                                        //{ value: 'echeck', label: 'E-Check' },
                                    ].map(({ value, label }) => (
                                        <label
                                            key={value}
                                            className={`btn btn-sm flex-1 px-4 py-2 rounded-md transition-colors cursor-pointer ${paymentMethod === value
                                                ? 'bg-blue-500 text-white border-solid border-1 border-blue-900 font-semibold'
                                                : 'bg-gray-200 hover:bg-gray-300 text-gray-500'
                                                }`}
                                        >
                                            <input
                                                type="radio"
                                                name="paymentMethod"
                                                value={value}
                                                checked={paymentMethod === value}
                                                onChange={(e) => setPaymentMethod(e.target.value)}
                                                className="hidden"
                                            />
                                            {label}
                                        </label>
                                    ))}
                                </div>
                            </div>

                            {/* Card Entry */}
                            {paymentMethod === 'creditCard' && (
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Entry Method:</span>
                                    </label>
                                    <div className="flex gap-2">
                                        {[
                                            { value: 'manual', label: 'Manual' },
                                            { value: 'swipe', label: 'Swipe/Tap' },
                                            { value: 'file', label: 'On File' }
                                        ].map(({ value, label }) => (
                                            <label
                                                key={value}
                                                className={`btn btn-sm flex-1 px-4 py-2 rounded-md transition-colors cursor-pointer ${entryMethod === value
                                                    ? 'bg-blue-500 text-white border-solid border-1 border-blue-900 font-semibold'
                                                    : 'bg-gray-200 hover:bg-gray-300 text-gray-500'
                                                    }`}
                                            >
                                                <input
                                                    type="radio"
                                                    name="entryMethod"
                                                    value={value}
                                                    checked={entryMethod === value}
                                                    onChange={(e) => setEntryMethod(e.target.value)}
                                                    className="hidden"
                                                />
                                                {label}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {renderPaymentContent()}

                            {/* Submit */}
                            <button
                                type="submit"
                                disabled={!isFormValid || isSubmitting}
                                className={`btn btn-sm w-full text-white font-bold normal-case text-lg ${!isFormValid || isSubmitting
                                    ? 'bg-gray-500 text-black cursor-not-allowed'
                                    : 'bg-green-500 hover:bg-green-600'
                                    }`}
                            >
                                {isSubmitting ? (
                                    <span className="flex items-center justify-center">
                                        <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        Processing...
                                    </span>
                                ) : paymentMethod === 'creditCard' && entryMethod === 'swipe' ? (
                                    <span className="flex items-center justify-center gap-4">
                                        <span className="swipe-icon-left" />
                                        {readerStatus || readerStatus}
                                        <span className="swipe-icon-right" />
                                    </span>
                                ) : (
                                    <>
                                        {paymentMethod === 'creditCard' && (
                                            <span className="lock-icon mr-2" aria-hidden="true" />
                                        )}
                                        {(paymentMethod === 'check' || paymentMethod === 'cash' || paymentMethod === 'echeck')
                                            ? `Submit $${formatCurrency(invoices.length > 1 ? totalAmount : paymentAmount)}`
                                            : `Process $${formatCurrency(invoices.length > 1 ? totalAmount : paymentAmount)}`}
                                    </>
                                )}
                            </button>
                        </form>
                    </div>

                    {/* Right side - Invoice grid */}
                    {invoices.length > 1 && (
                        <div className="w-1/2 flex flex-col">
                            <div className="flex-grow ag-theme-alpine" style={{ height: '300px' }}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={invoiceList}
                                    columnDefs={invoiceColumnDefs}
                                    suppressCellFocus={true}
                                    headerHeight={32}
                                    rowHeight={32}
                                    stopEditingWhenCellsLoseFocus={true}

                                    defaultColDef={{
                                        sortable: true,
                                        resizable: true
                                    }}
                                    onCellValueChanged={onCellValueChanged}
                                />
                            </div>
                            <div className="mt-4 flex justify-end items-center gap-2">
                                <span className="font-semibold">Total Payment Amount:</span>
                                <div className="relative">
                                    <span className="absolute left-0 top-0 bottom-0 flex items-center justify-center bg-gray-200 text-gray-700 font-bold px-3 rounded-l-md border-r border-gray-300">
                                        $
                                    </span>
                                    <input
                                        type="text"
                                        value={`$${formatCurrency(totalAmount)}`}
                                        className="input input-bordered w-[10rem] text-right font-bold h-8"
                                        readOnly
                                        placeholder="0.00"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <PaymentStatusModal
                isOpen={showStatusModal}
                onClose={() => {
                    setShowStatusModal(false);
                    if (paymentStatus.success) {
                        onClose({
                            success: true,
                            message: `${paymentMethod.charAt(0).toUpperCase() + paymentMethod.slice(1)} payment successful`
                        });
                    } else {
                        onClose({
                            success: false,
                            message: `${paymentMethod.charAt(0).toUpperCase() + paymentMethod.slice(1)} payment failed`
                        });
                    }
                }}
                success={paymentStatus.success}
                message={paymentStatus.message}
            />
        </div>
    );
}
