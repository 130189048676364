import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';

import { initFlowbite } from "flowbite";
import { Modal } from 'react-daisyui';

import { SetDocumentTitle } from "../_reactHelpers/ReactHelpers";
import { SetFocusById, NotEmpty, PreventDefault, IdExists } from '../../js/formHelpers.js';
import { SetPageTitle, GetValueById, FormatDate } from '../../js/helpers.js';
import { get } from '../../services/apiService.js';

//import './_order.css';

//import { ReceivingModal } from './ReceivingModal';
import { MaterialPickupModal } from '../Order/_modals/MaterialPickupModal';

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
//import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../css/ag-theme-lumina.css'; // local ag-theme-alpine.css

export const PickupSearch = (props) => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Material Pickup");

    const loadSearchTermsFromPage = "PickupSearch";
    const storedSearchTermsKey = "storedPickupSearchTerms";
    const tbSearchId = "tbPickupSearch";
    
    const serverSide = false;
    const minSearchChars = 2;
    const pageRows = 50;

    const orderModalRef = useRef();
    const [modalClass, setModalClass] = useState("");
    const [modalContent, setModalContent] = useState(null);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    const [rowData, setRowData] = useState([]);
    const [pendingTags, setPendingTags] = useState([]);
    const [pendingTagsCount, setPendingTagsCount] = useState(0);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const receiveSuccessMsg = "Line(s) received successfully.";

    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const [gridHeight, setGridHeight] = useState("750px");
    const olGridRef = useRef();
    const columnDefs = [
        {
            headerName: '', field: 'orderId', hide: true,
        },
        {
            headerName: "Order Number", field: 'orderNumber', width: 125,
            cellClass: ['text-secondary', 'font-bold'],
        },               
        {
            headerName: "Order Date", width: 140, field: 'orderDate', cellDataType: 'date',
            valueFormatter: params => FormatDate(params.data.orderDate)
        },
        {
            headerName: 'Status', field: 'statusStr', width: 125,
        },        
        {
            headerName: 'Account', field: 'accountName', //width: 300,
        },
        {
            headerName: 'Job Name', field: 'jobName', //width: 350,
            cellClass: ['text-black-lighter'],
        },
        {
            headerName: 'Store', field: 'storeDisplayName', width: 125, //cellClass: ['text-secondary'],
        }, 
        {
            headerName: '', field: 'orderId', //width: 350,
            cellClass: ['text-black-lighter'],
            cellRenderer: params => {
                return <button className="btn-submit-modal"
                    onClick={e => onOpenMaterialPickupModal(params.data.orderId)}
                >Material Pickup</button>;
            }
        },
    ];

    const gridOptions = {
        defaultColDef: {
            flex: 1,
            sortable: false,
            resizable: false,
            filter: false,
            suppressMovable: true,
            suppressMenu: true,
            cellClass: ["no-border", "cursor-pointer"],
        }
    };

    //const refreshCache = useCallback((e) => {
    //    //save search terms in session
    //    //var searchTerms = GetValueById(tbSearchId);
    //    //localStorage.setItem(storedSearchTermsKey, JSON.stringify(searchTerms));

    //    //if (searchTerms && searchTerms.length >= minSearchChars) {
    //    //    olGridRef.current.api.refreshServerSide({ purge: true });
    //    //}        
    //    olGridRef.current.api.refreshServerSide({ purge: true });
    //}, []);


    /****** Methods ******/

    function clearAllMessages() {
        setMessage("");
        setMessageClass("");
    }

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        clearAllMessages();

        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    function voidClick(e) {
        PreventDefault(e);
    }

    /****** Modal Methods ******/

    // Material Pickup Modal --------------------------------------

    function returnMaterialPickupModalComponent(olIds, ols) {
        return <MaterialPickupModal orderLineIds={olIds} orderLines={ols}
            onCloseModal={onCloseMaterialPickupModal}></MaterialPickupModal>;
    }

    async function onOpenMaterialPickupModal(orderId) {
        clearAllMessages();

        if (IdExists(orderId)) {
            var ols = await getPickupOrderLines(orderId);
            if (NotEmpty(ols)) {
                setModalContent(returnMaterialPickupModalComponent([], ols));
                onOpenModal();
            }
            else {
                updateMessage("No order lines available for pickup.", "text-primary");
            }
        }
        else {
            updateMessage("No order selected for pickup.", "text-primary");
        }
    }

    function onCloseMaterialPickupModal(e, linesDelivered) {
        PreventDefault(e);

        //reload to be sure any changes reflected
        var msg = "";
        var msgClass = "";
        if (linesDelivered === true) {
            msg = "Line(s) delivered successfully.";
            msgClass = "text-green";
        }

        updateMessage(msg, msgClass);
        onCloseModal();
    }

    const getPickupOrderLines = async (orderId) => {
        var ols = [];

        const endpoint = 'orders/GetOrder/' + orderId + '/0/0';
        try {
            const response = await get(endpoint);
            //console.log('Response: ' + JSON.stringify(response));

            if (NotEmpty(response.orderLines)) {
                response.orderLines.forEach((ol, index) => {
                    // Received and Stock
                    if (ol.statusId === 3 || ol.statusId === 15) {
                        ols.push(ol);
                    }
                });
            }
        } catch (error) {
            console.log('Error: ' + error);
            updateMessage(defaultErrMsg, "text-red", "Error getPickupOrderLines(): " + error);
        }

        return ols;
    }

    // base modal methods  --------------------------------------

    function onOpenModal() {
        handleOrderModalShow();
    }

    function onCloseModal() {
        //setModalClass("");
        handleOrderModalHide();
        setModalContent(null);

        loadPickupDataClient();
    }

    const handleOrderModalShow = useCallback(() => {
        orderModalRef.current?.showModal();
    }, [orderModalRef]);

    const handleOrderModalHide = useCallback(() => {
        orderModalRef.current?.close();
    }, [orderModalRef]);


    /****** db methods ******/

    const onGridReady = useCallback((params) => {        
        
        if (serverSide) {
            //params.api.setServerSideDatasource(serverSideDatasource());
        } else {
            loadPickupDataClient();
        }
    }, []);

    //const serverSideDatasource = () => {
    //    return {
    //        // called by the grid when more rows are required
    //        getRows: params => {

    //            var searchStr = GetValueById(tbSearchId);
    //            if (searchStr && searchStr.length >= minSearchChars) {

    //                var pageNum = params.request.endRow / pageRows;
    //                var page = pageNum - 1;
    //                var apiUrl = process.env.REACT_APP_API_URL + 'receiving/GetReceivingLinesSearch' +
    //                    '?numRows=' + pageRows + '&page=' + page + '&sText=' + searchStr ;

    //                console.log("server side apiUrl: " + apiUrl);
    //                fetch(apiUrl)
    //                    .then((response) => response.json())
    //                    .then((rowData) => {
    //                        if (NotEmpty(rowData.dataRows)) {
    //                            //debugger;
    //                            params.success({ rowData: rowData.dataRows });
    //                            hideOverlay();
    //                            console.log("GetReceivingLinesSearch rows: " + rowData.dataRows.length);
    //                        }
    //                        else {
    //                            params.success({ rowData: [] });
    //                            showNoRows();
    //                            console.log("GetReceivingLinesSearch rows: no data");                                
    //                        }
    //                        console.log(rowData.message);
    //                    })
    //                    .catch((error) => {
    //                        //debugger;
    //                        params.success({ rowData: [] });
    //                        showNoRows();
    //                        setError(error);
    //                        console.error('GetReceivingLinesSearch error:', error);
    //                    });
    //            }
    //        }
    //    };
    //}

    /****** Data Methods ******/
    
    async function loadPickupDataClient() {
        showLoading();

        try {
            const endpoint = 'warehouse/GetPickupSearchOrders';
            const response = await get(endpoint);
            //console.log('Response: ' + JSON.stringify(response));

            if (NotEmpty(response.dataRows)) {

                setRowData(response.dataRows);
                hideOverlay();
                //console.log(response.message);
            }
            else {
                setRowData([]);
                showNoRows();
            }
            console.log(response.message);
        } catch (error) {
            updateMessage(defaultErrMsg, "text-red", "loadPickupDataClient() error " + error);
        }
    };

    function handleSearch(e) {
        var api = olGridRef.current.api;
        const value = e.target.value.toLowerCase();
        api.setQuickFilter(value);
    };

    function showLoading() {
        olGridRef.current.api.showLoadingOverlay();
    }

    function showNoRows() {
        olGridRef.current.api.showNoRowsOverlay();
    }

    function hideOverlay() {
        olGridRef.current.api.hideOverlay();
    }

    //const isRowSelectable = useMemo(() => {
    //    return (params) => {
    //        return params.data && (params.data.statusId === 2 || params.data.statusId === 8);
    //    };
    //}, []);

    function onRowDoubleClicked(e) {
        if (e && e.node && e.node.data) {
            var orderId = e.node.data.orderId;
            e.node.setSelected(true, true);
            onOpenMaterialPickupModal(orderId);
        }
    }

    // ON LOAD
    useEffect(() => {
        SetPageTitle("Material Pickup");
        setLoading(false);
        
        //loadSavedSearchTerms();
    }, []);

    /*** Content ***/

    if (loading === true) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (error === true)
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;

    return (
        <div>
            <div className="p-2">
                <div className="clear">
                    <div className="pb-2">

                        <div className="table-cell">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input id={tbSearchId} type="search" placeholder="Filter..." 
                                    className="orders-filter-search @apply block p-2 pl-10 text-sm text-gray-900 
                                            border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500;"                                    
                                    autoFocus
                                    onChange={handleSearch}
                                    //onKeyDown={(e) => CheckEnterKeyPress(e, refreshCache)}
                                >
                                </input>
                                <div id="selectedRows" />
                            </div>                            
                        </div>
                        <div className="mb-2 pointer-events-hand pl-3 table-cell">
                            <button id="btnReceivingSearch" onClick={(e) => loadPickupDataClient()}
                                type="button" className="btn-search !m-0">
                                Reload
                            </button>
                        </div>

                        {/*<div className="table-cell pl-3">*/}
                        {/*    <button id="btnLoadReceivedModal" onClick={(e) => onOpenReceiveLinesModal(e)}                                */}
                        {/*        className="btn-load-orders">Receive Selected</button>*/}
                        {/*</div>*/}

                        {/*<div className="table-cell pl-3">*/}
                        {/*    <button onKeyDown={(e) => CheckEnterKeyPress(e, refreshCache)}*/}
                        {/*        className="btn-load-orders">Search</button>*/}
                        {/*</div>*/}
                        {/*</div>*/}

                        {/*<div className="pt-2 pb-2">*/}
                        {/*<div className="table-cell pl-12">*/}
                        {/*    */}{/*<label for="search"*/}
                        {/*    */}{/*    className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">*/}
                        {/*    */}{/*    General Search*/}
                        {/*    */}{/*</label>*/}
                        {/*    <div className="relative">*/}
                        {/*        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">*/}
                        {/*            <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">*/}
                        {/*                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />*/}
                        {/*            </svg>*/}
                        {/*        </div>*/}
                        {/*        */}{/*required onChange={handleSearch} */}
                        {/*        <input type="search" id={tbSearchGeneralId} placeholder="General Search"*/}
                        {/*            className="orders-filter-search @apply block p-2 pl-10 text-sm text-gray-900 */}
                        {/*            border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500;">*/}
                        {/*        </input>*/}
                        {/*        <div id="selectedRows" />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*<div className="table-cell pl-3">*/}
                        {/*    <button onKeyDown={(e) => CheckEnterKeyPress(e, refreshCache)}*/}
                        {/*        className="btn-load-orders">Search</button>*/}
                        {/*</div>*/}
                    </div>
                    {/*<div className="mt-1 mb-1">*/}
                    {/*    *'Enter' to search with 2 or more characters*/}
                    {/*</div>*/}
                </div>

                <div className="clear">
                    <div style={containerStyle} className="">
                        <div className="ag-theme-alpine" style={{ height: gridHeight, width: '100%', }}>
                            <div className={"text-left text-sm " + messageClass}>
                                {message}
                            </div>

                            <AgGridReact
                                headerHeight={32}
                                rowData={rowData}
                                ref={olGridRef}
                                gridOptions={gridOptions}
                                columnDefs={columnDefs}
                                onGridReady={onGridReady}
                                onRowDoubleClicked={(e) => onRowDoubleClicked(e)}                                
                                rowSelection='single'
                                //isRowSelectable={isRowSelectable}
                                //server side if needed
                                //cacheBlockSize={pageRows}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className={"order-detail-modal p-2 !pt-0" + modalClass}>
                <Modal ref={orderModalRef}
                    className="order-detail-modal-content min-w-[1500px] !pt-0 max-w-[100%]">
                    <Modal.Body>
                        {modalContent}
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}