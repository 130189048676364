import React from 'react';

export const StoreSelectModal = ({
    isOpen,
    onClose,
    stores,
    selectedStore,
    onStoreSelect,
    onConfirm
}) => {
    if (!isOpen) return null;

    return (
        <div className="modal-wrapper">
            <div className="modal-content !w-[400px] mt-[5%]">
                <div className="px-2 pt-2 pb-2">
                    <h3 className="text-xl font-bold text-gray-900 text-center underline">Change Current Store</h3>
                    <div className="pt-6">
                        <select
                            id="storeSelect"
                            className="w-full border-[#cccccc] rounded px-2 py-1 cursor-pointer"
                            value={selectedStore || ''}
                            onChange={(e) => onStoreSelect(e.target.value)}
                        >
                            <option value="">-- Select Store --</option>
                            {stores.map(store => (
                                <option
                                    key={store.storeId}
                                    value={store.storeId.toString()}
                                >
                                    {store.displayName}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="flex justify-end space-x-2 pt-8">
                        <button onClick={onClose} className="btn-cancel">
                            Cancel
                        </button>
                        <button onClick={onConfirm} className="btn-submit">
                            Change Store
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}