import React, { useEffect, useState } from 'react';

import { ClickById, GetValueById } from '../../../js/helpers.js';
import { IdExists, IsTrue, ParseIntSafe, PreventDefault } from '../../../js/formHelpers.js';

import { AccountsGrid } from '../../Account/AccountsGrid';

export const AddAccountModal = (props) => {
    const onCloseModal = props.onCloseModal;
    const propsOrderId = props.orderId;
    const propsIsQuote = props.isQuote;
    const propsIsClaim = props.isClaim;    

    const orderTypeStr = GetOrderTypeStr();
    const searchLink = GetSearchLink();

    const [modalClass, setModalClass] = useState("");
    const [modalTitle, setModalTitle] = useState("");

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");

    //Methods

    function GetOrderTypeStr() {
        if (IsTrue(propsIsQuote)) {
            return "Quote";
        }
        else if (IsTrue(propsIsClaim)) {
            return "Claim";
        }
        else {
            return "Order";
        }
    }

    function GetSearchLink() {
        if (IsTrue(propsIsQuote)) {
            return "/Quotes";
        }
        else if (IsTrue(propsIsClaim)) {
            return "/Claims";
        }
        else {
            return "/Orders";
        }
    }

    function selectAccount(e) {
        e.preventDefault();

        //selected account id
        const selectedAccountId = GetValueById("hfSelectedAccountId");
        const intAccountId = ParseIntSafe(selectedAccountId);
        if (IdExists(intAccountId)) {
            onClose(e, intAccountId);
        }
        else {
            setMessageClass("text-red");
            setMessage("Please select an Account.");            
        }
    }

    function onClose(e, intAccountId) {
        PreventDefault(e);
        onCloseModal(e, intAccountId);
    }

    // ON LOAD
    useEffect(() => {
        //document.activeElement.blur();
    }, []);

    return (        
        <div className={"accounts-grid-content " + modalClass}>
            <input id="hfSelectedAccountId" type="hidden" value=""></input>

            <div className="text-center">
                <div className="font-bold mb-1 text-xl">
                    {modalTitle}
                </div>
            </div>
            <div className="p-1 min-h-[550px]">
                <AccountsGrid pageType="order"></AccountsGrid>
            </div>
            <div className="align-bottom text-right pt-[30px]">
                <div className={"inline-block mr-3 " + messageClass}>
                    {message}
                </div>
                <div className="inline-block">
                    {propsOrderId === 0 && propsIsClaim !== true &&
                        <button className="btn-close-modal" onClick={() => ClickById("lnkOrdersSearch")}>
                            Cancel New {orderTypeStr}
                        </button>
                    }                    
                    {propsOrderId !== 0 &&
                        <button type="button" className="btn-close-modal ml-2"
                            onClick={(e) => onClose(e, 0)}>
                            Close
                        </button>
                    }
                    <button type="button" id="btnOrderSelectAccount" className="btn-submit-modal ml-2"
                        onClick={(e) => selectAccount(e)}>
                        Select Account
                    </button>
                </div>
            </div>
        </div>
    );
}
