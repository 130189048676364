import React, { useState, useEffect } from 'react';

export const CashContent = ({
    paymentAmount,
    cashTendered,
    balanceDue, changeDue
}) => {
    const [balance, setBalance] = useState('0.00');
    const [change, setChange] = useState('0.00');

    useEffect(() => {
        // Remaining balance
        const payment = parseFloat(paymentAmount) || 0;
        const remaining = balanceDue - payment;
        setBalance(Math.max(remaining, 0).toFixed(2));

        // Change due
        const tendered = parseFloat(cashTendered) || 0;
        const changeAmount = tendered - payment;
        setChange((changeAmount > 0 ? changeAmount : 0).toFixed(2));
    }, [paymentAmount, cashTendered, balanceDue]);

    return (
        <div>
            <div className="grid grid-cols-3 gap-3">
                <div className="relative">
                    <label className="label">
                        <span className="label-text font-semibold">Balance:</span>
                    </label>
                    <span className="absolute left-3 top-[35px] -translate-y-[-5px]">$</span>
                    <input
                        type="number"
                        step="0.01"
                        value={balance}
                        className="w-full payment-input !pl-6"
                        placeholder="0.00"
                        readOnly
                    />
                </div>
                <div className="relative">
                    <label className="label">
                        <span className="label-text font-semibold">Change Due:</span>
                    </label>
                    <span className="absolute left-3 top-[35px] -translate-y-[-5px]">$</span>
                    <input
                        type="number"
                        step="0.01"
                        value={change}
                        className="w-full payment-input !pl-6"
                        placeholder="0.00"
                        readOnly
                    />
                </div>
            </div>
        </div>
    );
};
