import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {
    AgCurrencyMxWd, AgDateMxWdSm, defaultErrMsg, Exists, GetDateNow,
    GetDisplayCurrencyStr, GetDisplayDateStr, IsFalse, IsTrue, LengthGreaterEqual, PreventDefault
} from '../../js/formHelpers';

import { get, post } from '../../services/apiService';
import { SetDocumentTitle } from '../_reactHelpers/ReactHelpers';
import { SetPageTitle } from '../../js/helpers';
import { IdExists, NotStringEmpty, StringEmpty } from '../../js/formHelpers';

import { DateFilter } from '../_reactHelpers/DateFilter';
import RadioButtonList from '../_reactHelpers/RadioButtonList';
import { AddEditSupplierBillModal } from './AddEditSupplierBillModal';
import { AddEditNonSupplierBillModal } from './AddEditNonSupplierBillModal';
import {Log} from "oidc-client";

export const SearchPayables = (props) => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina -");

    const isPayables = props.pageType === "Payables"
    const isCommissions = props.pageType === "Commissions"

    const [isModalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const [stores, setStores] = useState([]);

    const [bills, setBills] = useState([]);
    const [billIdToDelete, setBillIdToDelete] = useState(null);

    //const rblClassName = "rblDateType";
    //const dateTypes = setDateTypes();
    const [dateType, setDateType] = useState("BillDate");

    const [fromDate, setFromDate] = useState(new Date(new Date().setFullYear(new Date().getFullYear() - 1)).toISOString().split('T')[0]);
    const [toDate, setToDate] = useState(new Date().toISOString().split('T')[0]);

    const [message, setMessage] = useState('');
    const [messageClass, setMessageClass] = useState('');

    const[searchTextBills, setSearchTextBills] = useState('');
        
    const initialFormData = {
        storeId: 0,
        vendorId: 0,
        billType: 0,
        billDate: GetDateNow(),
        billStatus: 0,
        dueDate: '',
        poNumber: '',
        billNumber: '',
        billTotal: 0,
        amtDisc: 0,
        discRate: 0,
        discAmt: 0,
        nonDiscAmt: 0,
        additionalCharges: 0,
        adjustedTotal: 0,
        allocationGroupId: 0,
        billLineItems: []
    };

    function setDateTypes() {
        var types = ["Invoice Date", "Due Date", "Created Date"];
        if (IsFalse(isCommissions)) {
            types.push("Paid Date");
        }
        return types;
    }

    const [gridApi, setGridApi] = useState(null);
    const [searchText, setSearchText] = useState("");
    const gridRef = useRef();

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const handleEditClick = async (bill) => {
        try {
            const response = await get(`bills/GetBill/${bill.billId}`);
            const fullBill = response;
            console.log('Full bill:', fullBill);

            if (fullBill.billType === 2) {
                openModal(
                    <AddEditNonSupplierBillModal
                        initialBillData={fullBill}
                        handleSubmit={handleSubmit}
                        stores={stores}
                        onHide={closeModal}

                    />
                );
            }
            else
            {
                openModal(
                    <AddEditSupplierBillModal
                        initialBillData={fullBill}
                        handleSubmit={handleSubmit}
                        stores={stores}
                        onHide={closeModal}
                    />
                );
            }

        } catch (error) {
            console.error('Error fetching bill details:', error);
            updateMessage(defaultErrMsg(), 'text-red');
        }
    };

    const onFilterTextBoxChanged = (event) => {
        const newSearchText = event.target.value;
        setSearchText(newSearchText);
        gridApi.setQuickFilter(newSearchText);
    };

    const columnDefs = [
        { field: 'billId', headerName: 'Bill Id', maxWidth: 100, hide: true },
        { field: 'storeName', headerName: 'Store', hide: true },
        { field: 'payee', headerName: 'Vendor', },        
        { field: 'billStatusDesc', headerName: 'Status', maxWidth: 160, },
        { field: 'billTypeDesc', headerName: 'Type', maxWidth: 140, },
        { field: 'poNumber', headerName: 'PO #', maxWidth: 120, },
        { field: 'billNumber', headerName: 'Invoice #', width: 200, },
        {
            field: 'adjustedTotal', headerName: 'Total', maxWidth: AgCurrencyMxWd(),
            valueFormatter: (params) => GetDisplayCurrencyStr(params.value)
        },
        { field: 'billDate', headerName: 'Invoice Date', maxWidth: AgDateMxWdSm(), valueFormatter: params => GetDisplayDateStr(params.value) },
        { field: 'dueDate', headerName: 'Due Date', maxWidth: AgDateMxWdSm(), valueFormatter: params => GetDisplayDateStr(params.value) },
        { field: 'paidDate', headerName: 'Paid Date', maxWidth: AgDateMxWdSm(), valueFormatter: params => GetDisplayDateStr(params.value) },
        { field: 'checkNumber', headerName: 'Check #', maxWidth: 150, },
        { field: 'createdDateTime', headerName: 'Created Date', maxWidth: AgDateMxWdSm(), valueFormatter: params => GetDisplayDateStr(params.value) },
        {
            headerName: '', maxWidth: 60,
            cellRenderer: (params) => (
                <button className="btn-grid" onClick={() => handleEditClick(params.data)}>Edit</button>
            ),
        }
    ];

    const defaultColDef = {
        minWidth: 100,
        flex: 1,
        sortable: true,
        resizable: true,
        filter: true,
        suppressMovable: true,
        suppressMenu: false,
        cellClass: ["no-border"],
        menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
    };

    //const handleDelete = useCallback((billId) => {
    //    setBillIdToDelete(billId);
    //    openModal(
    //        <ConfirmModal
    //            confirmDelete={confirmDelete}
    //            onHide={closeModal}
    //            typeStr={"Bill"}
    //        />
    //    );
    //}, []);

    //const confirmDelete = useCallback(async () => {
    //    try {
    //        await axios.delete(`bills/delete/${billIdToDelete}`);
    //        fetchBills();
    //        updateMessage('Bill deleted successfully', 'text-green-600');
    //    } catch (error) {
    //        console.error('Error deleting the bill:', error);
    //        updateMessage('Error deleting the bill', 'text-red-600');
    //    } finally {
    //        closeModal();
    //        setBillIdToDelete(null);
    //    }
    //}, []);

    const handleSubmit = async (create) => {
        if (IsTrue(create)) {
            updateMessage('Bill created successfully', 'text-green');
        }
        else {
            updateMessage('Bill updated successfully', 'text-green');
        }
        closeModal();

        if (NotStringEmpty(dateType) && NotStringEmpty(fromDate) && NotStringEmpty(toDate)) {
            fetchBills();
        }
    }

    const openModal = (content) => {
        setModalContent(content);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalContent(null);
    };

    const updateMessage = (msg, cssClass) => {
        setMessage(msg);
        setMessageClass(cssClass);
    };

    const fetchStores = async () => {
        try {
            const response = await get('store/GetAllStores');
            setStores(response);
        } catch (error) {
            console.error('Error loading stores:', error);
            updateMessage('Error loading stores', 'text-red-600');
        }
    };


    async function fetchBills(pSearchAll) {        
        updateMessage();

        try {
            let response = null;            

            // Search All
            if (IsTrue(isPayables) && IsTrue(pSearchAll)) {

                if (!LengthGreaterEqual(searchTextBills, 2)) {
                    updateMessage('Search text must be at least 2 characters.', 'text-red');
                    return;
                }

                response = await get('bills/GetBillsBySearchText', {
                    params: { searchText: searchTextBills }
                });
            }
            else {
                //clear search all text
                setSearchTextBills('');

                // validate dates
                var pFromDate = fromDate;
                var pToDate = toDate;
                if (StringEmpty(dateType) || StringEmpty(pFromDate) || StringEmpty(pToDate)) {
                    updateMessage('Dates and Date Type are required.', 'text-red');
                    return;
                }

                // Search Payables
                if (IsTrue(isPayables)) {
                    response = await get('bills/GetBills', {
                        params: { pFromDate, pToDate, dateType }
                    });
                }
                // Commissions
                else if (IsTrue(isCommissions)) {
                    response = await get('bills/GetCommissionPendingBills', {
                        params: { pFromDate, pToDate, dateType }
                    });
                }
                else {
                    // should not occur
                    alert(defaultErrMsg());
                }
            }

            var billsData = [];
            if (Exists(response?.bills)) {
                billsData = response.bills || [];
            }

            const transformedData = billsData.map(bill => ({
                ...bill,
                dueDate: bill.dueDate ? new Date(bill.dueDate).toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric'
                }) : '',
                billDate: bill.billDate ? new Date(bill.billDate).toLocaleDateString('en-US', {
                    month: '2-digit',
                    day: '2-digit',
                    year: 'numeric'
                }) : '',
                billLineItems: Array.isArray(bill.billLineItems) ? bill.billLineItems.map(item => ({
                    ...item,
                    date: item.date ? new Date(item.date).toLocaleDateString('en-US', {
                        month: '2-digit',
                        day: '2-digit',
                        year: 'numeric'
                    }) : ''
                })) : []
            }));

            setBills(transformedData);
            //updateMessage('Bills fetched successfully', 'text-green-600');
        }
        catch (error) {
            console.error('Error fetching bills:', error);
            updateMessage('Error fetching bills: ' + (error.message || 'Unknown error'), 'text-red-600');
        } finally {
        }
    }

    function EnterSearchAll(e) {
        if (e.key === "Enter") {
            PreventDefault(e);
            fetchBills(true);
        }
    }

    useEffect(() => {
        if (isCommissions) {
            setDocTitle("Lumina - Commissions Pending");
            SetPageTitle("Commissions Pending");
        }
        else if (isPayables) {
            setDocTitle("Lumina - Search Payables");
            SetPageTitle("Search Payables")
        }

        fetchStores();
    }, []);

    return (
        <div className="page-wrapper">
            <div className="flex-wrapper">
                <div className="">
                    <DateFilter fromDate={fromDate} setFromDate={setFromDate} fromDateStr="Start Date:"
                        toDate={toDate} setToDate={setToDate} toDateStr="End Date:" enterFunction={fetchBills} />
                </div>
                <div>
                    <button className="btn-date-submit" onClick={e => fetchBills()}>
                        {IsTrue(isPayables) &&
                            <span>Search Payables</span>
                        }
                        {IsTrue(isCommissions) &&
                            <span>Search Commissions</span>
                        }
                    </button>
                </div>
                {/*<div>*/}
                {/*    <RadioButtonList rblClassName={rblClassName} valuesList={dateTypes} initialValue={dateType} setValue={setDateType} />*/}
                {/*</div>*/}
            </div>

            {IsTrue(isPayables) &&
                <div>
                    <div className="mt-2">
                        <input type="text" placeholder="Search..." className="grid-filter !w-[300px]"
                            value={searchTextBills}
                            onChange={(e) => setSearchTextBills(e.target.value)}
                            onKeyDown={(e) => EnterSearchAll(e)}                            
                        />
                        <button onClick={e => fetchBills(true)} className="btn-small ml-2">
                            Search All Payables
                        </button>
                    </div>
                    <div className="clear mt-0 mb-1 text-sm">
                        'Enter' to search with 2 or more characters.
                    </div>
                </div>
            }

            <div className="clear pt-2">
                <div className="flex-wrapper">
                    <div className="flex-1 space-x-3">
                        <input
                          type="text"
                          value={searchText}
                          onChange={onFilterTextBoxChanged}
                          placeholder="Filter..."
                          className="grid-filter !w-[300px]"
                        />
                        {message && <span className={`pl-2 text-sm ${messageClass}`}>{message}</span>}
                    </div>
                </div>

                <div className="ag-theme-alpine bills-table ag-grid-act pt-2" style={{height: 700, width: '100%'}}>
                    <AgGridReact
                      ref={gridRef}
                      rowData={bills}
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      pagination={true}
                      paginationPageSize={30}
                      onGridReady={onGridReady}
                      quickFilterText={searchText}
                    />
                </div>

                {isModalOpen && modalContent &&
                    <div className="modal-wrapper">
                        <div className="modal-content !w-[1600px] !max-w-[1600px]">
                            {modalContent}
                        </div>
                    </div>
                }
            </div>
        </div>
    );
}