import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {
    GetDisplayDateStr, IsTrue, NotEmpty, AgDateMxWdSm, NotStringEmpty,
    Exists, StringEmpty, IdExists, defaultErrMsg, GetDateNow,
} from '../../../js/formHelpers';

import { ClickById, SetHrefById, SetPageTitle } from '../../../js/helpers';
import { SetDocumentTitle } from '../../_reactHelpers/ReactHelpers';
import { get } from '../../../services/apiService';

import { AddEditSupplierBillModal } from '../../Payables/AddEditSupplierBillModal';
import { AddEditNonSupplierBillModal } from '../../Payables/AddEditNonSupplierBillModal';
import { Equals } from '../../../js/calculationHelpers';

export function ContentCentralPayables() {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Create Payables");
    const { type } = useParams();

    const [rowData, setRowData] = useState([]);
    const [showAll, setShowAll] = useState(false);

    const [isModalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);

    const [searchText, setSearchText] = useState("");
    const [gridApi, setGridApi] = useState(null);
    const gridRef = useRef();

    const [message, setMessage] = useState('');
    const [messageClass, setMessageClass] = useState('');
    const [isError, setIsError] = useState(false);

    const [stores, setStores] = useState([]);

    const initialFormData = {
        storeId: 0,
        vendorId: 0,
        billType: 0,
        billDate: GetDateNow(),
        billStatus: 0,
        dueDate: '',
        poNumber: '',
        billNumber: '',
        billTotal: 0,
        amtDisc: 0,
        discRate: 0,
        discAmt: 0,
        nonDiscAmt: 0,
        additionalCharges: 0,
        adjustedTotal: 0,
        allocationGroupId: 0,
        billLineItems: []
    };

    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const columnDefs = [
        {
            headerName: 'Supplier', field: 'supplier',
        },
        {
            headerName: 'Invoice #', field: 'ccInvoiceNumber',
        },
        {
            headerName: 'CC PO #', field: 'luminaOrderNumber',
            cellRenderer: params => (
                <div>
                    {NotStringEmpty(params.data.viewFilePath) &&
                        <a href={"/ViewFile/" + encodeURIComponent(params.data.viewFilePath)}
                            target="_blank" rel="noreferrer"
                            className="text-primary underline">
                            {params.value + " - View"}
                        </a>
                    }
                    {StringEmpty(params.data.viewFilePath) &&
                        <span>{params.value}</span>
                    }
                </div>
            )
        },
        //{
        //    headerName: 'Invoice Date', field: 'ccInvoiceDate', maxWidth: AgDateMxWdSm(),
        //    valueFormatter: params => GetDisplayDateStr(params.value)
        //},
        {
            headerName: 'Due Date', field: 'ccDueDate', maxWidth: AgDateMxWdSm(),
        },
        {
            headerName: 'Status', field: 'status', maxWidth: 130,
        },
        {
            headerName: 'Order #', field: 'orderNumber', maxWidth: 100,
            cellRenderer: params => (
                <div>
                    {IdExists(params.data.orderId) &&
                        <a href={"/Redirect?route=EditOrder&orderId=" + params.data.orderId}
                            target="_blank" rel="noreferrer" className="text-primary underline">
                            {params.value}
                        </a>
                    }
                    {!IdExists(params.data.orderId) &&
                        <span>{params.value}</span>
                    }
                </div>
            )
        },
        {
            headerName: 'Order Status', field: 'orderStatusStr', maxWidth: 120,
        },
        {
            headerName: '', maxWidth: 80, filter: false, sortable: false,
            cellRenderer: params => (
                <button type="button" className="btn-grid"
                    onClick={() => loadOrderContent(params.data)}
                >Review</button>
            )
        },
    ];

    const defaultColDef = {
        minWidth: 100,
        flex: 1,
        sortable: true,
        resizable: true,
        filter: true,
        suppressMovable: true,
        suppressMenu: false,
        cellClass: ["no-border"],
        menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
    };

    const onFilterTextBoxChanged = (event) => {
        const newSearchText = event.target.value;
        setSearchText(newSearchText);
        gridApi.setQuickFilter(newSearchText);
    };

    async function loadOrderContent(data) {
        var pDocumentId = data?.documentId ?? "";
        if (NotStringEmpty(pDocumentId))
        {
            var wait = await setCurrentDocumentIdQueue();

            var href = "/CreatePayable/" + pDocumentId + "/0/0";
            SetHrefById("lnkLoadCcCreatePayable", href);
            ClickById("lnkLoadCcCreatePayable");
        }
        else {
            alert(defaultErrMsg());
        }
    }

    async function setCurrentDocumentIdQueue(clear) {

        if (IsTrue(clear)) {
            localStorage.setItem('ccDocIdQueue', JSON.stringify(null));
        }
        else {

            var docIds = [];
            gridRef.current.api.forEachNodeAfterFilterAndSort((node) => {
                var docId = node?.data?.documentId;
                if (NotStringEmpty(docId)) {
                    docIds.push(docId)
                }
            });

            if (NotEmpty(docIds)) {
                localStorage.setItem('ccDocIdQueue', JSON.stringify(docIds));
            }
            else {
                localStorage.setItem('ccDocIdQueue', "");
            }
        }
    }

    const fetchContentCentral = async (pShowAll) => {
        setRowData([]);
        pShowAll = Exists(pShowAll) ? pShowAll : showAll;

        try {
            const data = await get('contentcentral/GetDocumentOrders', {
                params: { showAll: pShowAll }
            });

            if (Exists(data) && NotEmpty(data.scannedDocuments)) {
                setRowData(data.scannedDocuments);
            }
        } catch (error) {
            console.error("fetchContentCentral() error: ", error);
        }
    };

    const fetchStores = async () => {
        try {
            const response = await get('store/GetAllStores');
            setStores(response);
        } catch (error) {
            console.error('Error fetching stores:', error);
            updateMessage('Error fetching stores', 'text-red-600');
        }
    };

    const showAllInvoices = (e) => {
        var bShowAll = IsTrue(e.target.checked);
        setShowAll(bShowAll);
        fetchContentCentral(bShowAll);
    };

    const handleSubmit = async (create) => {
        if (IsTrue(create)) {
            updateMessage('Invoice created successfully', 'text-green');
        }
        else {
            updateMessage('Invoice updated successfully', 'text-green');
        }
        closeModal();

        fetchContentCentral();
        fetchStores();
    }

    const openModal = (content) => {
        setModalContent(content);
        setModalOpen(true);
    };

    const closeModal = () => {
        setModalOpen(false);
        setModalContent(null);
    };

    function showMessage() {
        if (Equals(type, "created")) {
            updateMessage('Invoice created successfully.', 'text-green');
        }
        else if (Equals(type, "updated")) {
            updateMessage('Invoice updated successfully.', 'text-green');
        }
        else if (Equals(type, "followup")) {
            updateMessage('Lumina Follow Up note created.', 'text-green');
        }
    }

    const updateMessage = (msg, cssClass) => {
        setMessage(msg);
        setMessageClass(cssClass);
    };

    useEffect(() => {
        SetPageTitle("Create Payables");
        showMessage();
        setCurrentDocumentIdQueue(true);
        fetchContentCentral();
        fetchStores();
    }, []);

    return (
        <div>
            <div className="page-wrapper">
                <div>
                    <div className="flex-wrapper">
                        <div className="">
                            <input type="text" className="grid-filter !w-[400px]" placeholder="Filter..."
                                value={searchText}
                                onChange={onFilterTextBoxChanged}
                            />
                        </div>
                        <div className="flex-grow pl-4">
                            <label htmlFor="showAll" className="text-secondary font-bold cursor-pointer">
                                Show All Invoices
                            </label>
                            <input type="checkbox" id="showAll" name="showAll"
                                className="input-cb ml-2 mb-[2px]"
                                checked={showAll}
                                onChange={e => showAllInvoices(e)}
                            />
                        </div>
                        <div className={"pr-4 " + messageClass}>
                            {message}
                        </div>
                        <div className="">
                            <button className="btn-small mr-2"
                                onClick={() => openModal(
                                    <AddEditSupplierBillModal
                                        initialBillData={initialFormData}
                                        handleSubmit={handleSubmit}
                                        stores={stores}
                                        onHide={closeModal}
                                    />
                                )}>Create Supplier Invoice
                            </button>

                            <button className="btn-small"
                                onClick={() => openModal(
                                    <AddEditNonSupplierBillModal
                                        initialBillData={initialFormData}
                                        handleSubmit={handleSubmit}
                                        stores={stores}
                                        onHide={closeModal}
                                    />
                                )}>Create Non-Supplier Invoice
                            </button>
                        </div>
                    </div>

                    <div className="ag-theme-alpine ag-grid-act w-full pt-2" style={{ height: '740px' }}>
                        <AgGridReact
                            ref={gridRef}
                            onGridReady={onGridReady}
                            rowData={rowData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            pagination={true}
                            paginationPageSize={15}
                            rowSelection={'single'}
                            onRowDoubleClicked={(e) => loadOrderContent(e.data)}
                        />
                    </div>
                    {message && (
                        <div className={`p-4 rounded-md ${isError ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
                            {message}
                        </div>
                    )}
                </div>
            </div>

            {/* select supplier for order */}
            {/*{isSupplierModalOpen &&*/}
            {/*    <div className="modal-wrapper">*/}
            {/*        <div className="modal-content mt-[10%] !max-w-[30rem]">*/}
            {/*            <h4 className="sub-header-act text-center">Multiple Suppliers for Order</h4>*/}
            {/*            <div className="pt-8 flex items-center">*/}
            {/*                <div className="flex-1 label-text text-base font-bold pr-4">Select Supplier</div>*/}
            {/*                <div className="flex-1">*/}
            {/*                    <select name="vendorId" className="input-bordered border rounded-lg cursor-pointer"*/}
            {/*                        //value={vendorId}*/}
            {/*                        onChange={e => selectInvoiceVendor(e.target.value)}>*/}
            {/*                        <option value="0" selected>-- Select --</option>*/}
            {/*                        {vendors.map(o =>*/}
            {/*                            <option value={o.vendorId}>{o.name}</option>)}*/}
            {/*                    </select>*/}
            {/*                </div>*/}
            {/*            </div>*/}

            {/*            <div className="pt-8 text-right pb-2">*/}
            {/*                <button type="button" className="btn-cancel mr-2"*/}
            {/*                    onClick={e => setIsSupplierModalOpen(false)}>*/}
            {/*                    Cancel*/}
            {/*                </button>*/}
            {/*                */}{/*<button type="button" className="btn-submit"*/}
            {/*                */}{/*    onClick={e => loadOrderContentForSupplier(null)}*/}
            {/*                */}{/*    disabled={!IdExists(vendorId)}>*/}
            {/*                */}{/*    Continue*/}
            {/*                */}{/*</button>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*}*/}

            {isModalOpen && modalContent &&
                <div className="modal-wrapper">
                    <div className="modal-content !w-[1600px] !max-w-[1600px]">
                        {modalContent}
                    </div>
                </div>
            }

            <a href="/" id="lnkLoadCcCreatePayable" className="hidden">Create CC Payable</a>
        </div>
    );
}