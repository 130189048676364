import React, { useState, useEffect } from 'react';
import {Button, Modal} from 'react-daisyui';
import { get, post } from '../../../services/apiService';
import { SetDocumentTitle } from '../../_reactHelpers/ReactHelpers';
import { Exists, IdExists, IsEmpty, IsTrue, NotEmpty, NotExists, NotStringEmpty } from '../../../js/formHelpers';
import { CheckPaymentList } from './CheckPaymentList.js';
import { SetPageTitle } from '../../../js/helpers';
import PrinterModal from "./PrinterModal";
import CheckRunDetails from "./CheckRunDetails";

export function PrintCheckQueue({ pBankId, loadCheckPayments }) {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Print Checks");
    const [bankId, setBankId] = useState(0);
    const [checkPayments, setCheckPayments] = useState([]);
    const [bankName, setBankName] = useState("");
    const [checkNumber, setCheckNumber] = useState(0);
    const [modalOpen, setModalOpen] = useState(false);
    const [disabled, setDisabled] = useState({});
    const [bankAccounts, setBankAccounts] = useState([]);
    const [bankAccount, setBankAccount] = useState({});
    const [showPrinterModal, setShowPrinterModal] = useState(false);
    const [showCheckRunDetails, setShowCheckRunDetails] = useState(false);
    const [currentPrintCheckId, setCurrentPrintCheckId] = useState("");
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [checkRunDetailsKey, setCheckRunDetailsKey] = useState(Date.now()); // Estado para la clave

    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    function updateMessage(msg, errorMsg, cssClass, logMsg) {
        if (NotStringEmpty(msg)) {
            setMessage(msg);
            setMessageClass("text-green");
        }
        if (NotStringEmpty(errorMsg)) {
            setMessage(msg);
            setMessageClass("text-red");
        }
        if (NotStringEmpty(logMsg)) {
            console.log(logMsg);
        }
        if (NotStringEmpty(cssClass)) {
            setMessageClass(cssClass);
        }
    }

    function getCheckPayments(pBankId) {
        var qBankId = bankId;
        if (IdExists(pBankId))
            qBankId = pBankId;

        if (IdExists(qBankId)) {
            closeModal();
            fetchCheckPayments(qBankId);
        }
        else {
            updateMessage(null, "Bank Account is required");
        }
    }

    const fetchCheckPayments = async (qBankId) => {
        setLoading(true);

        try {
            if (IdExists(qBankId)) {
                const response = await get('checkpayment/GetCheckPaymentsByBank/' + qBankId);
                const data = response.map(item => ({ ...item }));

                if (NotEmpty(data)) {
                    setBankName(data[0]?.bankAccountName ?? "");
                    setCheckNumber(data[data.length - 1].checkNumber)
                }

                setCheckPayments(data);
            }
            else {
                setCheckPayments([]);
            }
            setLoading(false);

        } catch (error) {
            console.error('Error fetching check payments:', error);
            updateMessage("", defaultErrMsg);
            setCheckPayments([]);
            setLoading(false);
        }
    };

    const handleClickPrintCheck = () => {
        if (IsEmpty(checkPayments)) {
            updateMessage("No checks selected to print.", "", "text-red");
            return;
        }

        setShowPrinterModal(true);
    }

    const handleChangePrint = async () => {
        try {
            const response = await post('checkpayment/UpdateCheckPaymentsStatus', checkPayments);
            if (IdExists(response.updatedCheckPaymentsCnt)) {
                updateMessage(response.updatedCheckPaymentsCnt + " check(s) printed successfully");
            }

            fetchCheckPayments();
            setShowPrinterModal(false);
            setShowCheckRunDetails(true);
            setCurrentPrintCheckId(response.printCheckId);
            setCheckRunDetailsKey(Date.now());
        }
        catch (error) {
            console.error('Error fetching check payments:', error);
            updateMessage("", defaultErrMsg);
            setShowPrinterModal(false);
        }
    }

    function getTotal() {
        var total = 0;

        if (NotEmpty(checkPayments)) {
            total =
                checkPayments.reduce((amount, check) => {
                    return amount + check.checkAmount;
                }, 0);
        }

        if (Exists(total)) {
            return "$" + total.toFixed(2);
        }
        else {
            return "$0.00";
        }
    }

    function loadCheckPaymentsOnClick() {
        loadCheckPayments(bankId, checkNumber + 1);
    }

    const fetchBankAccounts = async () => {
        try {
            const response = await get('bankaccount/GetAllBankAccounts');
            const data = response.map(item => ({
                ...item
            }));

            setBankAccounts(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching Bank Accounts:', error);
            setBankAccounts([]);
            setLoading(false);
        }
    };

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    function openBankAccountsModal() {
        openModal();
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'bankAccount') {
            var ba = bankAccounts.find(bank => bank.bankId === parseInt(value)) ?? null;
            setBankAccount(ba);

            if (Exists(ba)) {
                setBankId(ba.bankId);
                setBankName(`${ba.bankName} - ${ba.bankAccountName}`);
                setDisabled(false);
            }
            else {
                setBankId(0);
                setDisabled(false);
            }
        }
    };

    const handleXlPrint = async (e) => {
        try {
            const response = await get('checkpayment/ExportIronXl');
            if (IsTrue(response)) {
                alert("Success");
            }
        }
        catch (error) {
            console.error('Error fetching check payments:', error);
            updateMessage("", defaultErrMsg);
        }
    }

    useEffect(() => {
        SetPageTitle("Print Checks");

        if (IdExists(pBankId)) {
            setBankId(pBankId);
            getCheckPayments(pBankId);
        }
        else {
            fetchBankAccounts();
            openModal();
        }
    }, []);

  console.log(checkPayments)
  console.log(checkPayments.filter(item => item.isVendorElectronicPrintRemittance))

    return (
        <>
            <div>
                <div className="pt-2 pb-4">
                    <div className="flex-wrapper !items-start">
                        <div className="flex">
                            {IdExists(pBankId) &&
                                <a href="/PendingPayments" className='btn-small mr-2' onClick={() => loadCheckPaymentsOnClick()}>
                                    Go Back
                                </a>
                            }
                            {NotExists(pBankId) &&
                                <button type="button" className='btn-small' onClick={() => openBankAccountsModal()}>
                                    Select Bank
                                </button>
                            }
                            <button type="button" className='btn-grid ml-6 hidden' onClick={() => {
                                handleXlPrint()
                            }}>
                                Create Test Excel
                            </button>
                        </div>
                        <div className="flex-1 text-center">
                            <div className="text-2xl text-secondary">
                                {/*<span className="text-black">Bank:</span>*/}
                                <span className="ml-2 font-bold underline">{bankName}</span>
                            </div>
                            <div className="mt-4 text-md">
                                <span className="pr-2">Total amount of all checks:</span>
                                <span className="font-bold">{getTotal()}</span>
                            </div>
                            <div className="mt-1 text-md">
                                <span className="pr-2">Number of checks to print:</span>
                                <span className="font-bold">{NotEmpty(checkPayments) ? checkPayments.length : 0}</span>
                            </div>
                            <div className={"text-sm h-[30px] pt-2 " + messageClass}>
                                {message}
                            </div>
                        </div>
                        <div className="flex-end">
                            {/*<button type="button" className='btn-small' onClick={() => handleClickPrintCheck()}>*/}
                            {/*    Print Checks*/}
                            {/*</button>*/}
                        </div>
                    </div>
                </div>

                <div className="clear pt-2">
                    <CheckPaymentList checkPayments={checkPayments} loading={loading} />
                </div>

                {modalOpen &&
                    <div className="modal-wrapper">
                        <div className="modal-content">
                            <div className="pt-8 flex items-center">
                                <div className="flex-1 label-text text-base font-bold pr-4">Bank Account</div>
                                <div className="flex-1">
                                    <select name="bankAccount" className="input-bordered border rounded-lg !w-full"
                                        value={bankAccount.bankId}
                                        onChange={handleChange}>
                                        <option>-- Select --</option>
                                        {bankAccounts.map(bankAccount =>
                                            <option
                                                value={bankAccount.bankId}>{`${bankAccount.bankName} - ${bankAccount.bankAccountName}`}</option>)}
                                    </select>
                                </div>
                            </div>
                            <div className="pt-8 text-right">
                                <button type="button" onClick={closeModal} className="btn-cancel">Cancel</button>
                                <button type="button" className="btn-submit !mr-0"
                                    disabled={disabled} onClick={e => getCheckPayments()}
                                >Continue</button>
                            </div>
                        </div>
                    </div>
                }
            </div>

            <Modal open={showPrinterModal} >
                <Modal.Body className="w-auto">
                    <PrinterModal setShowPrinterModal={setShowPrinterModal} processPrintCheck={handleChangePrint} />
                </Modal.Body>
            </Modal>

            <Modal open={showCheckRunDetails} className="max-w-full w-auto">
                <Modal.Header className="mb-4">
                    <div className="flex justify-between">
                      <h1 className="text-xl font-bold">Check Run Details</h1>
                      <Button size="sm" color="ghost" shape="circle" 
                              onClick={e => setShowCheckRunDetails(false)}
                              className="absolute right-4 top-2 min-h-[1.5rem] h-[1.5rem] w-[1.5rem]"
                      >x</Button>
                    </div>
                </Modal.Header>
              
                <Modal.Body>
                    <CheckRunDetails key={checkRunDetailsKey} printCheckId={currentPrintCheckId} />
                </Modal.Body>
            </Modal>
        </>
    );
}