import { StandardDataAndResponsePOST } from '../js/dataHelpers';
import { Exists, IdExists, IsTrue, NotStringEmpty, ParseIntSafe } from '../js/formHelpers';
import { StringContains } from '../js/helpers';

import { get, post } from '../services/apiService.js';
//import axios from 'axios';

function setUserData(userData) {
    localStorage.setItem('userData', JSON.stringify(userData));
};

const login = async (username, password) => {
    var response = null;
    try {
        setUserData(null);
        var model = {
            username: username,
            password: password
        };
        var postModel = JSON.stringify(model);
        response = await StandardDataAndResponsePOST(postModel, "auth/Login", "auth/Login");

        if (Exists(response) && IdExists(response.userId) && IdExists(response.defaultStoreId)) {
            console.log('Data!: ' + JSON.stringify(response));
            setUserData(response);
            return response;
        }
        else {
            throw new Error(`login() authentication failed: No user returned. Error: ${response}`);
        }
    } catch (error) {
        console.error('Login failed:', error);
        throw error;
    }
};

const logout = async () => {
    try {
        localStorage.removeItem('token');
        localStorage.removeItem('userData');
    } catch (error) {
        console.error('Logout failed:', error);
    }
};

const getADUser = async () => {
    try {
        const endpoint = "activedirectory/GetActiveDirectoryUser?run=true";
        const resp = await get(endpoint);

        if (Exists(resp)) {
            var luminaUserId = ParseIntSafe(resp.userId);
            if (IdExists(luminaUserId)) {
                console.log('Valid AD user found:', resp);
                setUserData(resp);
                return resp;
            }
            console.log('Invalid or missing userId in AD response');
        }
        return null;
    } catch (error) {
        console.error('Error in getADUser:', error);
        return null;
    }
};

async function getCurrentUser() {
    try {
        var userData = JSON.parse(localStorage.getItem('userData'));
        if (Exists(userData)) {
            return userData;
        }

        //userData = await getADUser();
        //if (Exists(userData)) {
        //    setUserData(userData);
        //    return userData;
        //}

        return null;
    } catch (error) {
        console.error('Error in getCurrentUser:', error);
        return null;
    }
}

async function getCurrentUserId() {
    try {
        var userData = await getCurrentUser();
        if (Exists(userData) && IdExists(userData.userId)) {
            const userId = userData.userId;
            return userId;
        }
        else {
            // TODO: fallback get default store from user?
            // TODO: show message to login?
            console.error('getCurrentUserId() data is null or userId=0');
        }
    } catch (error) {
        console.error('Error in getCurrentUserId:', error);
    }
    return 0;
}

async function getCurrentStoreId() {
    try {
        var userData = await getCurrentUser();        
        if (IdExists(userData?.user?.defaultStoreId)) {
            const storeId = userData.user.defaultStoreId;
            return storeId;
        }
        else {
            // TODO: fallback get default store from user?
            // TODO: show message to select store / login?
            console.error('getCurrentStoreId() data is null or defaultStoreId=0');
        }
    } catch (error) {
        console.error('Error in getCurrentStoreId:', error);
    }
    return 0;
}

async function getCurrentStoreName() {
    try {
        var userData = await getCurrentUser();
        if (IdExists(userData?.user?.defaultStoreId) && NotStringEmpty(userData?.user?.defaultStore)) {
            const storeName = userData.user.defaultStore;
            return storeName;
        }
        else {
            // TODO: fallback get default store from user?
            // TODO: show message to select store / login?
            console.error('getCurrentStoreName() data is null or defaultStoreId=0');
        }
    } catch (error) {
        console.error('Error in getCurrentStoreName:', error);
    }
    return 0;
}

function isInRole(role) {
    const userData = getCurrentUser();

    if (!role || !userData.role) {
        return false;
    }
    return userData.role.toLowerCase() === role.toLowerCase();
}

function hasPermission(reqPerm) {
    //const userData = getCurrentUser();
    //if (!reqPerm || !userData || !userData.permissions) return false;
    //return userData.permissions.some(p => p.permission?.toLowerCase() === reqPerm.toLowerCase());
    return true;
}

function isAuthenticated () {
    const userData = getCurrentUser();
    return userData !== null && userData.isRegistered;
}

function GetDefaultUserLink(userData) {
    var hlUrl = "/Login";

    if (Exists(userData) && Exists(userData.user)) {
        hlUrl = "/Orders";

        if (StringContains(userData.user.roleNames, 'productadmin')) {
            hlUrl = "/ProductPricingAdmin";
        }
        else if (StringContains(userData.user.roleNames, 'accounting')) {
            hlUrl = "/Payables";
        }
        else if (StringContains(userData.user.roleNames, 'sales')) {
            hlUrl = "/Orders";
        }
        else if (StringContains(userData.user.roleNames, 'warehouse')) {
            hlUrl = "/Receiving";
        }
    }
    return hlUrl;
}

const exports = {
    login,
    logout,
    getCurrentUser,
    getADUser,
    isInRole,
    hasPermission,
    isAuthenticated,
    GetDefaultUserLink,
    getCurrentUserId, 
    getCurrentStoreId,
    getCurrentStoreName,
    //isAdmin
};

export default exports;