import React, { useEffect, useState, useRef, useCallback } from 'react';

import { Modal } from 'react-daisyui';

import { OrderLinesTable } from '../../_reactHelpers/ReactHtmlHelpers.js';

import {
    GetInputValuesCheckedByClass
} from '../../../js/helpers.js';
import {
    handleFieldChange, formatCurrencyDecimal, ParseIntSafe, IdExists, PreventDefault
} from '../../../js/formHelpers.js';

import { createPickupDataModel } from '../_js/_orderHelpers.js';
import { post } from '../../../services/apiService.js';

export const MaterialPickupModal = (props) => {
    const signatureModal = useRef();
    const onCloseModal = props.onCloseModal;
    const propsOrderLines = props.orderLines ?? [];
    //not currently used
    const propsOrderLineIds = props.orderLineIds ?? [];

    //TODO: remove when use token
    const [user, setUser] = useState(null);
    const [userId, setUserId] = useState(null);

    const cbOlClass = "cb-ol-olt";
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("false");    
    const [modalClass, setModalClass] = useState("");

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");

    const [orderLines, setOrderLines] = useState([]);

    const [pickupData, setPickupData] = useState(createPickupDataModel());
    const [pickupMessage, setPickupMessage] = useState("");
    const [pickupMessageClass, setPickupMessageClass] = useState("");

    const [pickCbsDisabled, setPickCbsDisabled] = useState(false);
    const [deliverLinesDisabled, setDeliverLinesDisabled] = useState(false);
    const [printTicketDisabled, setPrintTicketDisabled] = useState(true);

    const noLinesDeliveredMsg = "No lines have been picked to deliver.";

    // Signature Modal
    function onOpenSignModal() {
        setMessage("");
        setMessageClass("");

        var olIds = GetInputValuesCheckedByClass(cbOlClass);
        if (olIds && olIds.length > 0) {
            handleSignModalShow();
        }
        else {
            setMessageClass("text-red")
            setMessage(noLinesDeliveredMsg);
        }
    }

    function onCloseSignModal(e, save) {
        PreventDefault(e);
        setPickupMessage("");

        var closeModal = false;
        if (save === true) {

            if ((!pickupData.pickupName || pickupData.pickupName === "") ||
                (!pickupData.pickupSignature || pickupData.pickupSignature === "")) {
                setPickupMessageClass("text-red");
                setPickupMessage("Name and Signature are required.");
            }
            else {
                setPickCbsDisabled(true);
                setDeliverLinesDisabled(true);
                setPrintTicketDisabled(false);
                closeModal = true;
            }
        }
        else {
            closeModal = true;
        }

        if (closeModal === true) {
            handleSignModalHide();
        }
    }

    const handleSignModalShow = useCallback(() => {
        signatureModal.current?.showModal();
    }, [signatureModal]);

    const handleSignModalHide = useCallback(() => {
        signatureModal.current?.close();
    }, [signatureModal]);


    // Methods    

    function onCloseMaterialPickupModal(e, linesDelivered) {
        PreventDefault(e);
        onCloseModal(e, linesDelivered);
    }

    function setupOrderLines() {
        setModalTitle("Deliver Received Lines");
        if (propsOrderLines && propsOrderLines.length > 0) {
            setOrderLines(propsOrderLines);
        }
    }

    function deliverPickedLines() {
        setMessage("");
        setMessageClass("");

        var olIds = GetInputValuesCheckedByClass(cbOlClass);
        if (olIds && olIds.length > 0) {
            setDeliverLinesDisabled(true);
            savePickedLines(olIds);
        }
        else {
            setMessageClass("text-red")
            setMessage(noLinesDeliveredMsg);
        }
    }

    const handleChange = (e) => {
        setMessage("");

        var data = handleFieldChange(e);
        if (data) {
            //updateMessage("", "");
            setPickupData((pickupData) => ({
                ...pickupData,
                [data.name]: data.value
            }));
        }
        else {
            //updateMessage(defaultErrMsg, "text-red", "null handleChange data");
        }
    };


    async function savePickedLines(olIds) {
        if (olIds && olIds.length > 0) {
            if ((!pickupData.pickupName || pickupData.pickupName === "") ||
                (!pickupData.pickupSignature || pickupData.pickupSignature === "")) {
                setMessageClass("text-red");
                setMessage("Name and Signature are required.");
            }
            else {
                var postModel = createPickupDataModel(pickupData.pickupName, pickupData.pickupSignature, olIds);
                postModel.userId = userId ?? 0;
                var model = JSON.stringify(postModel);

                const endpoint = 'orders/SavePickupData';
                try {
                    const response = await post(endpoint, model);
                    //console.log('Response: ' + JSON.stringify(response));

                    setMessageClass("text-green");
                    const pickTicketId = ParseIntSafe(response);
                    if (IdExists(pickTicketId)) {
                        onCloseMaterialPickupModal(null, true);
                    }
                    return response;
                } catch (error) {
                    console.log('Error: ' + error);
                }
            }
        }
        else {
            setMessage(noLinesDeliveredMsg);
            setMessageClass("text-red");
        }
    }

    function EnterSave(e) {
        if (e.key === "Enter") {
            onCloseSignModal(e, true);
        }
    }

    function setUserFields() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        var cUserId = 0;
        if (userData) {
            cUserId = userData.userId;
            setUserId(userData.userId);
            setUser(userData);
        }
        return cUserId;
    }

    // ON LOAD
    useEffect(() => {
        setUserFields();
        setupOrderLines();
        setIsModalOpen(true);
    }, []);

    return (
        <div>
            {isModalOpen &&
                <div className={"modal-wrapper"}>
                    <div className={"modal-content min-w-[1450px] " + modalClass}>
                        <div className="min-w-[1400px]">
                            <div className="text-center py-2">
                                <div className="font-bold mb-1 text-xl">
                                    {modalTitle}
                                </div>
                            </div>
                            <div className="min-h-[400px] p-2">
                                {/*** Received Order Lines ***/}
                                <OrderLinesTable orderLines={orderLines} type="material-pickup" useCbs={true}></OrderLinesTable>
                            </div>
                            <div className="align-bottom text-right pt-[10px] pb-2">
                                <div className={"inline-block mr-2 " + messageClass}>
                                    {message}
                                </div>
                                <div>
                                    <button type="button" className="btn-cancel-modal mr-2"
                                        onClick={(e) => onCloseMaterialPickupModal(e)}>
                                        Cancel
                                    </button>
                                    <button type="button" className="btn-submit-modal mr-2"
                                        onClick={(e) => onOpenSignModal(e)} disabled={deliverLinesDisabled}>
                                        Get Signature
                                    </button>
                                    <button type="button" className="btn-submit-modal mr-2"
                                        onClick={(e) => deliverPickedLines(e)} disabled={printTicketDisabled}>
                                        Print Pick Ticket
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/**** Get Signature ****/}
                        <div className="">
                            <Modal ref={signatureModal} className="py-2">
                                <Modal.Body>
                                    <div>
                                        <div className="text-center py-4">
                                            <div className="font-bold mb-1 text-xl">
                                                Save Pickup Name and Signature
                                            </div>
                                        </div>
                                        <div>
                                            <div className="po-col-1">
                                                Pickup Name
                                            </div>
                                            <div className="po-col-2">
                                                <input type="text" className="inp-quantity-modal mark w-[250px]"
                                                    id="pickupName" name="pickupName"
                                                    onKeyUp={(e) => EnterSave(e)}
                                                    onChange={handleChange}
                                                    value={pickupData.pickupName}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className="po-col-1">
                                                Signature
                                            </div>
                                            <div className="po-col-2">
                                                <input type="text" className="inp-quantity-modal mark w-[250px]"
                                                    id="pickupSignature" name="pickupSignature"
                                                    onKeyUp={(e) => EnterSave(e)}
                                                    onChange={handleChange}
                                                    value={pickupData.pickupSignature}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="align-bottom text-right pt-[10px] pb-4">
                                        <div className={"inline-block mr-2 " + pickupMessageClass}>
                                            {pickupMessage}
                                        </div>
                                        <button type="button" className="btn-submit-modal mr-2"
                                            onClick={(e) => onCloseSignModal(e, true)}>
                                            Save
                                        </button>
                                        <button type="button" className="btn-close-modal"
                                            onClick={(e) => onCloseSignModal(e, false)}>
                                            Cancel
                                        </button>
                                    </div>
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}