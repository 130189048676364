import { Exists, IsFalse, NotEmpty, NotExists, PreventDefault, SetAndClickById, StringEmpty } from "./formHelpers";

export const SetPageTitle = (title1, title2, title3) => {

    if (NotExists(title1))
        title1 = "";
    if (NotExists(title2))
        title2 = "";
    if (NotExists(title3))
        title3 = "";

    SetTextContentById("hdrPageTitle1", title1);
    SetTextContentById("hdrPageTitle2", title2);
    SetTextContentById("hdrPageTitle3", title3);
}

export const ClickById = (id) => {
    var el = CheckAndLogElementById(id);
    //debugger;
    if (el) {
        el.click();
    }
}

export const GetValueById = (id) => {
    var el = CheckAndLogElementById(id);
    if (el) {
        return el.value;
    }
}

export const GetCheckedById = (id) => {
    var el = CheckAndLogElementById(id);
    if (el) {
        return el.checked;
    }
}

export const GetSelectedRadioValueByName = (name) => {
    //var el = CheckAndLogElementById(id);
    var elValue = document.querySelector('input[name=' + name + ']:checked').value;
    if (elValue) {
        return elValue;
    }
    else {
        console.log("Element not found: name = " + name);
        return "";
    }
}

export const GetSelectItemTextById = (id) => {
    var el = CheckAndLogElementById(id);
    if (el) {
        var options = el.options;
        if (CheckAndLogElById(options, id + "-options")) {
            if (options.length > 0) {
                var selItem = options[el.selectedIndex];
                if (selItem) {
                    return selItem.text;
                }
            }
        }
        return "";
    }
}


export const GetSelectItemTextByValue = (id, val) => {
    var el = CheckAndLogElementById(id);
    if (el) {
        var options = el.options;
        if (CheckAndLogElById(options, id + "-options")) {

            for (var i = 0; i < options.length; i++) {
                var option = options[i];
                if (option.value === val) {
                    return option.text;
                }
            }
        }
        return "";
    }
}

export const GetSelectItemValueById = (id) => {
    var el = CheckAndLogElementById(id);
    if (el) {
        var options = el.options;
        if (CheckAndLogElById(options, id + "-options")) {
            if (options.length > 0) {
                var selItem = options[el.selectedIndex];
                if (selItem) {
                    return selItem.value;
                }
            }
        }
        return "";
    }
}

export const GetSelectItemValueByText = (id, text) => {
    var el = CheckAndLogElementById(id);
    if (el) {
        var options = el.options;
        if (CheckAndLogElById(options, id + "-options")) {

            for (var i = 0; i < options.length; i++) {
                var option = options[i];
                if (option.text === text) {
                    return option.value;
                }
            }
        }
        return "";
    }
}

export const GetElementsByClass = (className) => {
    var els = [];

    var clEls = document.querySelectorAll("." + className);
    for (var i = 0; i < clEls.length; i++) {
        var el = clEls[i];
        if (el) {
            els.push(el);
        }
    }
    return els;
}

export const GetInputValuesCheckedByClass = (className) => {
    var vals = [];

    var cbs = document.querySelectorAll("." + className);
    for (var i = 0; i < cbs.length; i++) {
        var current = cbs[i];
        if (current.checked) {
            vals.push(current.value);
        }
    }
    return vals;
}

export const GetRblValueCheckedByClass = (className) => {
    var val = null;

    //Radio Button list should only have one selected value at a time
    var cbs = document.querySelectorAll("." + className);
    for (var i = 0; i < cbs.length; i++) {
        var current = cbs[i];
        if (current.checked) {
            val = current.value;
        }
    }
    return val;
}

export const SetInputsValueByClass = (className, value) => {
    
    var cbs = document.querySelectorAll("." + className);
    for (var i = 0; i < cbs.length; i++) {
        var current = cbs[i];
        if (current) {
            current.value = value;
        }
    }
}

export const SetInputsCheckedByClass = (className, checked) => {

    var cbs = document.querySelectorAll("." + className);
    for (var i = 0; i < cbs.length; i++) {
        var current = cbs[i];
        if (current) {
            current.checked = checked;
        }
    }
}

export const SetInputCheckedById = (id, checked) => {
    var el = CheckAndLogElementById(id);
    if (el) {
        el.checked = checked;
    }
}

export const SetValueById = (id, val) => {
    var el = CheckAndLogElementById(id);
    if (el) {
        el.value = val;
    }
}

export const SetTextContentById = (id, text) => {
    var el = CheckAndLogElementById(id);
    if (el) {
        el.textContent = text;
    }
}

export const SetHrefById = (id, val) => {
    var el = CheckAndLogElementById(id);
    if (el) {
        el.href = val;
    }
}

export const SetAttributeById = (id, attrName, attrValue) => {
    var el = CheckAndLogElementById(id);
    if (el) {
        el.setAttribute(attrName, attrValue);
    }
}

export const GetAttributeValueById = (id, attrName) => {
    var el = CheckAndLogElementById(id);
    var val = null;
    if (el) {
        val = el.getAttribute(attrName);
    }
    return val;
}

export const ShowHideElementById = (elId) => {
    var el = CheckAndLogElementById(elId);
    if (el) {
        var className = "hidden";
        var hasClass = el.classList.contains(className);
        if (hasClass === false) {
            className = "display-none";
            hasClass = el.classList.contains(className);
        }        
        //if (hasClass) {
        //not currently working like this, try class
        if (el.style.display === "none") {
            el.style.display = "block";
        } else {
            el.style.display = "none";
        }
    }
}

export const ShowHideById = (elId, show) => {
    var el = CheckAndLogElementById(elId);
    if (el) {
        var className = "hidden";
        var hasClass = el.classList.contains(className);
        if (hasClass === false) {
            className = "display-none";
            hasClass = el.classList.contains(className);
        }
        //if (hasClass) {
        //not currently working like this, try class
        if (show === true) {
            el.style.display = "block";
        } else {
            el.style.display = "none";
        }
    }
}

export const HasClassById = (elId, className) => {
    var el = CheckAndLogElementById(elId);
    if (el) {
        var hasClass = el.classList.contains(className);
        return hasClass;
    }
}

export const AddClassById = (elId, className) => {
    var el = CheckAndLogElementById(elId);
    if (el) {
        el.classList.add(className);
    }
}

export const RemoveClassById = (elId, className) => {
    var el = CheckAndLogElementById(elId);
    if (el) {
        el.classList.remove(className);
    }
}

export function RemoveClassByName(className) {
    var els = GetElementsByClass(className);
    els.forEach((el, index) => {
        el.classList.remove(className);
    });
}

export const RemoveClassByClass = (className, rClassName) => {
    var els = [];

    var clEls = document.querySelectorAll("." + className);
    for (var i = 0; i < clEls.length; i++) {
        var el = clEls[i];
        if (el) {
            el.classList.remove(rClassName);
        }
    }
    return els;
}

export const AddClassByClass = (className, aClassName) => {
    var els = [];

    var clEls = document.querySelectorAll("." + className);
    for (var i = 0; i < clEls.length; i++) {
        var el = clEls[i];
        if (el) {
            el.classList.add(aClassName);
        }
    }
    return els;
}

export const RemoveAttributeByClass = (className, attr) => {
    var els = [];

    var clEls = document.querySelectorAll("." + className);
    for (var i = 0; i < clEls.length; i++) {
        var el = clEls[i];
        if (el) {
            el.removeAttribute(attr);
        }
    }
    return els;
}

export const AddAttributeByClass = (className, attr) => {
    var els = [];

    var clEls = document.querySelectorAll("." + className);
    for (var i = 0; i < clEls.length; i++) {
        var el = clEls[i];
        if (el) {
            el.setAttribute(attr, "");
        }
    }
    return els;
}

export const CheckAndLogElementById = (elId) => {
    if (NotEmpty(elId)) {
        var el = document.querySelector("#" + elId);
        if (el) {
            return el;
        }
    }    
    console.error("Element not found: #" + elId);
    return null;    
}

export const CheckAndLogElById = (el, elId) => {
    if (el) {
        return el;
    }
    else {
        console.log("Element not found: #" + elId);
        return null;
    }
}

export const FormatDate = (date) => {
    if (!date) return '';  // Handle null, undefined, and empty strings

    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) return '';  // Check if the date is invalid

    const month = String(dateObj.getMonth() + 1);//.padStart(2, '0');
    const day = String(dateObj.getDate());//.padStart(2, '0');
    const year = dateObj.getFullYear();

    return `${month}/${day}/${year}`;
}

export const FormatDateLong = (date) => {
    if (!date) return '';  // Handle null, undefined, and empty strings

    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) return '';  // Check if the date is invalid

    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();

    return `${month}/${day}/${year}`;
}

export const FormatDateLongInput = (date) => {
    if (!date) return '';  // Handle null, undefined, and empty strings

    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) return '';  // Check if the date is invalid

    const month = String(dateObj.getMonth() + 1).padStart(2, '0');
    const day = String(dateObj.getDate()).padStart(2, '0');
    const year = dateObj.getFullYear();

    return `${year}-${month}-${day}`;
}

export const FormatDateTime = (dateTime) => {
    var dateTimeStr =
        ("00" + (dateTime.getMonth() + 1)).slice(-2) + "/" +
        ("00" + dateTime.getDate()).slice(-2) + "/" +
        dateTime.getFullYear() + " " +
        ("00" + dateTime.getHours()).slice(-2) + ":" +
        ("00" + dateTime.getMinutes()).slice(-2) + ":" +
        ("00" + dateTime.getSeconds()).slice(-2);
    return dateTimeStr;
}

export function FormatDateStrMMDDYYYY(inputDate) {
    // expects yyyy-MM-dd
    var splitDate = inputDate.split('-');
    if (splitDate.count === 0) {
        return "";
    }

    var year = splitDate[0];
    var month = splitDate[1];
    var day = splitDate[2];

    return month + '/' + day + '/' + year;
}

export const StringContains = (stringVal, term) => {
    if (stringVal && stringVal.indexOf(term) > -1){
        return true;
    }
    else {
        return false;
    }
}

export const NotStringContains = (stringVal, term) => {
    if (StringContains(stringVal, term)) {
        return false;
    }
    else {
        return true;
    }
}

export const GetPreviousUrl = () => {    
    const previousUrl = document?.referrer || "";
    //console.log(previousUrl);
    return previousUrl;
}

export const CloseTab = (e) => {
    PreventDefault(e);
    var success = false;
    try {
        if (window) {
            success = true;
            // will close window even if only tab
            // want to redirect to search if only tab open?            
            window.opener = null;
            window.open("", "_self");
            window.close();
        }
    }
    catch (err) {
        success = false;
    }
    return success;
}

export const DisableEscKey = (e) => {
    // disable ESC key so that modals are only closed with defined buttons
    // prevents issues with functions and destroying modal components onClose
    if (e.keyCode === 27 || e.key === "Escape" || e.key === "Esc") {
        //console.log("DisableEscKey()");
        PreventDefault(e);
    }
}

export function GetPricingGridDateCellContent(params, priceType) {
    if (params && params.data) {
        //var editable = false;
        //if (priceType === "PNW") {
        //    editable = params.data.pnwProductPriceId !== 0;
        //}
        //else if (priceType === "AZ") {
        //    editable = params.data.azProductPriceId !== 0;
        //}
        //else if (priceType === "WEB") {
        //    editable = params.data.webProductPriceId !== 0;
        //}

        //if (IsFalse(editable)) {
        //    return "";
        //}
        //else

        if (StringEmpty(params.value)) {
            return "";
        }
        else {
            var dateStr = FormatDateStrMMDDYYYY(params.value);
            return dateStr;
        }
    }
    return "";
}