import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { Modal, Button } from 'react-daisyui'
//import { initFlowbite } from "flowbite";

import { SetDocTitle } from "../_reactHelpers/ReactHelpers";
import {
    CheckEnterKeyPress, formatCurrencyDecStr, NotEmpty, PreventDefault, handleFieldChange,
    Exists, IdExists, IsTrue, IsFalse, LengthGreaterEqual, NotStringEmpty
} from '../../js/formHelpers.js';
import {
    FormatDateTime, FormatDate, GetValueById, SetPageTitle,
    ClickById, GetPreviousUrl
} from '../../js/helpers.js';

import { ClearSearchFilters, GetOrderEndpoint } from '../../js/searchHelpers'; 
import { GreaterThan } from '../../js/calculationHelpers';
import { get, post } from '../../services/apiService';

//import { OpenCloseAllOrderLines, ToggleAllOrderLines } from './_orderSearchHelpers.js';
//import { OrderAccountRenderer } from './cellRenderers/_orderAccountCellRenderer.js';
//import { OrderJobCellRenderer } from './cellRenderers/_orderJobCellRenderer.js';

import { SearchFilters } from '../SearchFilters/SearchFilters';
import { OrderLinesHeaderRenderer } from './cellRenderers/_orderLinesHeaderRenderer.js';
import { OrderIconsCellRenderer } from './cellRenderers/_orderIconsCellRenderer.js';
import { OrderNumberCellRenderer } from './cellRenderers/_orderNumberCellRenderer.js';
import { OrderDateCellRenderer } from './cellRenderers/_orderDateCellRenderer.js';
import OrderLinesCellRenderer from './cellRenderers/_orderLinesCellRenderer.js';

import { BillingGroupModal } from '../BillingGroup/BillingGroupModal.js';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import '../../css/ag-theme-lumina.css'; // local ag-theme-alpine.css
import '../../css/ag-orders.css'; // custom orders grid css

import { ModuleRegistry } from '@ag-grid-community/core';
import { MasterDetailModule } from 'ag-grid-enterprise';

import { PaymentModal } from '../Payment/_modals/PaymentModal';

ModuleRegistry.registerModules([MasterDetailModule]);

export const OrdersGrid = (props) => {
    const location = useLocation();
    //const locOrderType = getOrderType();
    //const typeName = Exists(locOrderType) ? locOrderType : "Order";    

    const propsPageType = props.pageType ?? "";
    const propsAccountPageType = props.accountPageType ?? "";
    const propsAccountId = props.accountId ?? 0;
    //const propsSearchStr = props.searchStr ?? "";

    const propsAddOrderToBillGroup = props.addOrderToBillGroup ?? null;
    const propsSelectOrderForQuoteLines = props.selectOrderForQuoteLines ?? null;

    const IsOrdersPageType = propsPageType === "" || propsPageType === "Order";
    const IsQuotesPageType = propsPageType === "Quote";
    const IsQuoteOrdersPageType = propsPageType === "QuoteOrders";
    const IsBillingGroupPageType = propsPageType === "BillingGroupOrders";
    const IsAccountPageType =
        propsAccountPageType === "AccountOrders" ||
        propsAccountPageType === "AccountQuotes";

    // use Order type filters in modal, else IsQuotesPageType use Quote filters
    const IsOrdersFilterType = IsOrdersPageType || IsQuoteOrdersPageType ||
        IsBillingGroupPageType || IsAccountPageType;

    // set for Orders or Quotes main search pages
    const IsMainSearchPage = IsOrdersPageType || IsQuotesPageType;

    const typeName = getTypeName();
    const orderNumHeader = typeName + " #";
    const orderDateHeader = typeName + " Date";
    const accountColHeader = "Account Name";

    const [message, setMessage] = useState(getOrderMessage());

    const loadSearchTermsFromPage = "EditOrder";
    const storedSearchTermsKey = getSearchTermsKey();
    const tbSearchId = IsBillingGroupPageType === true ? "tbBgOrdersSearch" : "tbOrdersSearch";
    const minSearchChars = 2;

    const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);

    const billGroupModalRef = useRef();
    const [billGroupModalContent, setBillGroupModalContent] = useState(null);

    const pageRows = 50; //100
    const serverSide = true;
    var rowModelType = 'serverSide';
    if (!serverSide) {
        rowModelType = 'clientSide';
    }

    const [searchStr, setSearchStr] = useState(props.searchStr ?? "");
    const [clearFilterMsg, setClearFilterMsg] = useState("");

    const [selectedRows, setSelectedRows] = useState([]);
    const [showPaymentModal, setShowPaymentModal] = useState(false);

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    const gridRef = useRef();
    const [gridClass, setGridClass] = useState(getGridClass());
    //const [gridHeight, setGridHeight] = useState(getGridHeight());
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    //const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

    const [rowData, setRowData] = useState();
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '',
            field: 'checkboxSelection',
            headerCheckboxSelection: true,
            checkboxSelection: (params) => {
                return params.data?.balance > 0;
            },
            maxWidth: 50,
            pinned: 'left',
            hide: IsFalse(IsAccountPageType) || (IsTrue(IsAccountPageType) && IsTrue(IsQuotesPageType)),
            cellRenderer: params => {
                params.node.selectable = params.data?.balance > 0;
            }
        },
        {
            headerName: '', field: 'orderId', cellClass: 'text-primary px-2',
            hide: !IsBillingGroupPageType && !IsQuoteOrdersPageType,
            width: IsBillingGroupPageType ? 40 : 60,
            cellRenderer: params => {
                return <div>
                    {IsBillingGroupPageType &&
                        <div>
                            {params.data.hasBillingGroup === false && propsAddOrderToBillGroup &&
                                <span className="underline" onClick={e => propsAddOrderToBillGroup(e, params.data.orderId)}>
                                    Add
                                </span>
                            }
                        </div>
                    }
                    {IsQuoteOrdersPageType && propsSelectOrderForQuoteLines &&
                        <div>
                            <span className="underline" onClick={e => propsSelectOrderForQuoteLines(e, params.data.orderId)}>
                                Select
                            </span>
                        </div>
                    }
                </div>;
            }
        },
        {
            headerName: '', field: 'orderId', maxWidth: 85, cellClass: 'cell-order-icons',
            cellRenderer: OrderIconsCellRenderer,
        },
        {
            headerName: 'Store', field: 'storeDisplayName', maxWidth: 125, cellClass: ['text-secondary'],
            hide: !IsQuotesPageType && !IsQuoteOrdersPageType,
        },
        {
            headerName: orderNumHeader, field: 'orderNumber', maxWidth: 125, autoHeight: true, resizable: true,
            cellRenderer: OrderNumberCellRenderer,
        },
        {
            headerName: orderDateHeader, maxWidth: 140, field: 'orderDate', cellDataType: 'date', autoHeight: true,
            cellRenderer: OrderDateCellRenderer,
            valueFormatter: params => FormatDate(params.data.orderDate)
        },
        {
            headerName: 'Status',
            field: 'statusStr',
            maxWidth: 175,
            hide: IsQuotesPageType || IsQuoteOrdersPageType,
            cellClass: 'ag-cell-middle align-middle',
            cellRenderer: params => {
                if (params.data.statusStr?.toLowerCase() === 'closed' && IsTrue(IsAccountPageType)) {
                    return (
                        <div className="flex flex-col gap-0">
                            <span>Closed</span>
                            <span className="text-sm -mt-1 -pt-5 -mt-4">
                                <span className="!text-[10px]">Due Date: {FormatDate(params.data.invoiceDueDate)}</span>
                            </span>
                        </div>
                    );
                }
                return params.data.statusStr;
            }
        },
        {
            headerName: 'Exported Orders', field: 'exportedQuoteOrderDateStr', autoHeight: true,
            hide: !IsQuotesPageType,
            cellRenderer: params => {
                return <div className="order-cell-div">
                    <div>{params.data.exportedQuoteOrderDateStr}</div>
                    {getExportedOrdersLinks(params.data.exportedQuoteOrders ?? [])}
                </div>;
            }
        },
        {
            headerName: 'Account Name', field: 'accountName', width: 350, cellClass: ['ag-cell-wrap', 'order-cell-title'],
            hide: !showAccountColumn(),            
            cellRenderer: params => {
                var accountName = params.data.accountName ?? "";
                var accountId = params.data.accountId ?? 0;
                var orderId = params.data.orderId ?? 0;
                var url = "/Redirect?route=EditAccount&accountId=" + accountId;
                var linkId = "lnkAccountId-" + orderId;
                return <div>
                    <span className="underline text-black-lighter" onDoubleClick={e => document.getElementById(linkId).click()}>
                        {accountName}
                    </span>
                    <a id={linkId} href={url} className="hidden" target="_blank" rel="noreferrer">&nbsp;</a>
                </div>;
            }
        },
        {
            headerName: 'Job Name', field: 'jobName', width: 350, cellClass: ['text-wrap'],
            autoHeight: true,
            cellRenderer: params => {
                var jobName = params.data.jobName ?? "";
                return <div className="order-cell-title text-black-lighter">{jobName}</div>;
            }
        },       
        {
            headerName: 'Job Detail', field: 'jobDetail', width: 350, cellClass: ['text-wrap'],
            maxWidth: 175,
            cellRenderer: params => {
                return <p>{params.data.jobModel.jobNotes}</p>
            }
        },
        {
            headerName: 'Details', field: 'orderLineSearchStr', autoHeight: true, maxWidth: 200, cellClass: ['text-primary'],            
            hide: IsBillingGroupPageType,
            headerComponent: OrderLinesHeaderRenderer,
            cellRenderer: 'agGroupCellRenderer'
        },
        {
            headerName: 'Total', field: 'orderTotal', maxWidth: 120,
            hide: IsBillingGroupPageType || IsQuoteOrdersPageType,
            cellRenderer: params => {
                return <div>{formatCurrencyDecStr(params.data.orderTotal)}</div>;
            }
        },
        {
            headerName: 'Balance Due', field: 'balance', maxWidth: 120,
            hide: IsBillingGroupPageType || IsQuotesPageType || IsQuoteOrdersPageType,
            cellRenderer: params => {
                var balance = params.data.balance;
                return <div className={GreaterThan(balance, 0) ? "text-red" : ""}>
                    {formatCurrencyDecStr(balance)}
                </div>;
            }
        },
    ]);

    const detailCellRenderer = useMemo(() => { return OrderLinesCellRenderer }, []);

    const gridOptions = {
        defaultColDef: {
            sortable: false,
            resizable: false,
            filter: false,
            suppressMenu: true,
            suppressCellFocus: true,
            suppressMovable: true,
            suppressRowClickSelection: true,
            cellClass: ["no-border"],
        },
        detailRowAutoHeight: true,
    };


    const expandCollapseAll = useCallback((expand) => {
        expand = Exists(expand) ? expand : false;
        gridRef.current.api.forEachNode(function (node) {
            gridRef.current.api.getDisplayedRowAtIndex(node.rowIndex).setExpanded(expand);
        });
    }, []);

    function getExportedOrdersLinks(orders) {
        return <div>
            {orders &&
                <div className="pr-2">
                    {getOrderLinks(orders)}
                </div>
            }
        </div>;
    }

    function getOrderLinks(orders) {
        var links = [];

        orders.forEach((o) => {
            links.push(
                <span className="mr-1">
                    <a href={"/Redirect?route=EditQuote&orderId=" + o.orderId} //target="_blank" rel="noreferrer" 
                        className="underline text-primary">{o.orderNumber}</a>
                </span>
            );
        });
        return links;
    }

    function getGridClass() {
        if (IsBillingGroupPageType) {
            return "ag-billgroup-search-lines";
        }
        else if (IsAccountPageType) {
            return "ag-account-search-lines";
        }
        else {
            return "ag-order-search-lines";
        }
    }

    function getGridHeight() {
        if (IsBillingGroupPageType) {
            return "600px";
        }
        else if (IsAccountPageType) {
            return "600px";
        }
        else {
            return "750px";
        }
    }

    function getTypeName() {
        var type = "Order";
        if (IsQuotesPageType) {
            type = "Quote";
        }
        return type;
    }

    function getSearchBtnText() {
        var text = "Search Orders";
        if (IsOrdersPageType && IsAccountPageType) {
            text = "Search Account Orders";
        }
        else if (IsQuotesPageType && IsAccountPageType) {
            text = "Search Account Quotes";
        }
        else if (IsQuotesPageType) {
            text = "Search Quotes";
        }
        return text;
    }

    //function getOrderType() {
    //    if (location.state && Exists(location.state.orderType)) {
    //        return location.state.orderType;
    //    }
    //    return "";
    //}

    function showAccountColumn() {
        if (IsAccountPageType) {
            return false;
        }
        else {
            return true;
        }
    }

    // Billing Group Modal
    const billGroupModalShow = useCallback(() => {
        billGroupModalRef.current?.showModal();
    }, [billGroupModalRef]);

    const billGroupModalHide = useCallback(() => {
        billGroupModalRef.current?.close();
    }, [billGroupModalRef]);

    function getBillGroupModalContent(billingGroupId, orderId) {
        return <BillingGroupModal billingGroupId={billingGroupId} orderId={orderId}
            onCloseModal={onCloseBillGroupModal}></BillingGroupModal>;
    }

    function onOpenBillGroupModal(billingGroupId, orderId) {
        //updateMessage("", "");        
        setBillGroupModalContent(getBillGroupModalContent(billingGroupId, orderId));
        billGroupModalShow();
    }

    function onCloseBillGroupModal(e) {
        PreventDefault(e);
        setBillGroupModalContent(null);
        billGroupModalHide();
        refreshCache();
    }

    //const [clickTimeout, setClickTimeout] = useState();      // timeout reference to debounce the click event
    ////clickTimeout    
    //const [performedClicks, setPerformedClicks] = useState(0); // click counter used to track click events for clickDebouncer
    ////performedClicks = 0;
    ///**
    //* debounce the click event by some time to give the user a chance to perform a double click
    //* If the grid is double clicked it will usually fire two click events for the cell and then one double click event
    //* this behavior is not desirable since the user only wants to execute the double click when double clicking and
    //* not two extra click events. To fix this, the debouncer waits some time and only when in this time no second click
    //* is performed, a click event is fired. If a second click happens, the (single) click event will not fire but the double click event will
    //* @param handler the method to call if the click event (really) fires
    //* @param event the event fired from the grid
    //* @param delay number of milliseconds to wait (default 250)
    //*/
    ////onRowClicked(event): void {
    ////    self.debounceCellClick(self.rowClicked, event);
    ////},
    //function debounceCellClick(handler, event) {
    //    setPerformedClicks(performedClicks + 1);

    //    var tmo = setTimeout(() => {
    //        if (performedClicks === 1) {
    //            setPerformedClicks(0);
    //            handler(event);
    //        } else {
    //            setPerformedClicks(0);
    //        }
    //    }, 250);
    //    setClickTimeout(tmo);

    //    if (performedClicks > 1) {
    //        clearTimeout(clickTimeout);
    //    }
    //}

    //---------------- Load Orders  -----------------//

    function getSearchTermsKey() {
        if (IsMainSearchPage) {            
            if (IsOrdersPageType)
                return "storedOrderSearchTerms";
            else if (IsQuotesPageType)
                return "storedQuoteSearchTerms";
        }
        return "";
    }

    function saveSearchTerms() {
        //do not overwrite saved search terms for embedded Orders search
        if (IsMainSearchPage && NotStringEmpty(storedSearchTermsKey)) {
            var searchTerms = GetValueById(tbSearchId);
            localStorage.setItem(storedSearchTermsKey, JSON.stringify(searchTerms));
        }
    }

    function loadSavedSearchTerms() {

        if (IsMainSearchPage) {
            //load stored search terms if coming back from Order Details
            var allowed = true;
            try {
                //var fromURl = GetPreviousUrl();
                //var allowed = StringContains(fromURl, loadSearchTermsFromPage);            
                //if (allowed !== true) {
                //    allowed = location.state && location.state.useSavedFiltersOrder;
                //}

                if (allowed) {
                    // search terms
                    const savedSearchTerm = localStorage.getItem(storedSearchTermsKey);
                    if (NotStringEmpty(savedSearchTerm)) {

                        const strSearchTerm = JSON.parse(savedSearchTerm);
                        if (NotStringEmpty(strSearchTerm)) {
                            setSearchStr(strSearchTerm);
                        }
                    }
                }
                //else {
                //    localStorage.setItem(storedSearchTermsKey, "");
                //}
            }
            catch (error) {
                console.error("localStorage error: " + storedSearchTermsKey);
                //localStorage.setItem(storedSearchTermsKey, "");
            }
            //localStorage.setItem(storedSearchTermsKey, "");
        }
    }

    const onGridReady = useCallback((params) => {
        if (serverSide) {
            params.api.setServerSideDatasource(serverSideDatasource());            
        }
    }, []);

    const serverSideDatasource = () => {
        return {
            // called by the grid when more rows are required
            getRows: async params => {

                var searchTerms = GetValueById(tbSearchId);
                var validSearch = LengthGreaterEqual(searchTerms, minSearchChars);
                if (validSearch || IsAccountPageType) {
                    saveSearchTerms();

                    var pageNum = params.request.endRow / pageRows;
                    var page = pageNum - 1;
                                        
                    console.log("loadOrdersServer startTime: " + FormatDateTime(new Date()));

                    var sType = "order";
                    if (IsQuotesPageType) {
                        sType = "quote";
                    }

                    //orders
                    var endpoint = GetOrderEndpoint(sType, searchTerms, propsPageType, pageRows, page, propsAccountId);
                    console.log("server side apiUrl: " + endpoint);

                    try {
                        const response = await get(endpoint);
                        //console.log('Response: ' + JSON.stringify(response));
                        if (Exists(response) && NotEmpty(response.dataRows)) {
                            
                            params.success({ rowData: response.dataRows });
                            hideOverlay();
                            //console.log(response.message);
                        }
                        else {                            
                            params.success({ rowData: [] });
                            console.log("loadOrdersServer: no data");
                        }
                    } catch (error) {
                        params.success({
                            rowData: []
                        });
                        showNoRows();
                        setError(error);
                        console.error('loadOrdersServer error:', error);
                    }
                }
                else {
                    params.success({ rowData: [] });
                    showNoRows();
                    console.log("loadOrdersServer: load none");
                }
            }
        };
    }

    const isSelectionEnabled = useMemo(() => {
        return IsTrue(IsAccountPageType) && IsFalse(IsQuotesPageType);
    }, [IsAccountPageType, IsQuotesPageType]);

    const refreshCache = useCallback((e) => {
        setMessage("");
        setClearFilterMsg("");
        gridRef.current.api.refreshServerSide({ purge: true });
    }, []);

    //function handleSearch(e) {
    //    var api = gridRef.current.api;
    //    const value = e.target.value.toLowerCase();
    //    api.setQuickFilter(value);
    //};

    function showLoading() {
        gridRef.current.api.showLoadingOverlay();
    }
    function showNoRows() {
        gridRef.current.api.showNoRowsOverlay();
    }
    function hideOverlay() {
        gridRef.current.api.hideOverlay();
    }
    const onFirstDataRendered = useCallback((params) => {
        console.log("onFirstDataRendered endTime: " + FormatDateTime(new Date()));
    }, []);

    const cellClicked = useCallback((params) => {
        if (!isSelectionEnabled) {
            return;
        }
        params.node.setSelected(true);
    }, [isSelectionEnabled]);


    const onSelectionChanged = useCallback(() => {
        if (!isSelectionEnabled) {
            console.log('not selectable');
            gridRef.current.api.deselectAll();
            setSelectedRows([]);
            return;
        } else {
            const selectedRows = gridRef.current.api.getSelectedRows();
            setSelectedRows(selectedRows);
        }
    }, [isSelectionEnabled]);

    const getSelectedRows = () => {
        const selectedNodes = gridRef.current.api.getSelectedRows();

        return selectedNodes;
    };

    //onRowDoubleClicked
    function onCellDoubleClicked(node) {       

        if (!IsQuoteOrdersPageType) {
            if (node && node.column) {
                //dont double click for Account node
                if (node.colDef.headerName !== accountColHeader) {
                    //debugger;

                    var orderData = node.data;
                    if (orderData) {
                        if (orderData.billingGroupId !== 0) {
                            onOpenBillGroupModal(orderData.billingGroupId, orderData.orderId);
                        }
                        else if (orderData.orderId !== 0) {
                            // link in _orderNumberCellRenderer.js
                            ClickById("lnkOrderId-" + orderData.orderId);
                        }
                    }
                    else {
                        alert("There was an issue loading this order. Please refresh the page and try again.")
                    }
                }
            }
        }
    };

    function UpdateSearchStr(e) {
        var str = "";
        if (e) {
            var data = handleFieldChange(e);
            if (data) {
                str = data.value;
            }
        }
        setSearchStr(str);
    }

    const handleApplyPayments = () => {
        if (selectedRows.length === 0) {
            return;
        }
        setShowPaymentModal(true);
    };

    const handlePaymentModalClose = (result) => {
        setShowPaymentModal(false);
        if (result?.success) {
            gridRef.current.api.deselectAll();
            setSelectedRows([]);
            refreshCache();
        }
    };

    const handleConfirmPayment = async (paymentModel) => {
        try {
            await post('payments/CreatePayment', paymentModel);
            refreshCache();
            setSelectedRows([]);
            return {
                success: true,
                message: 'Payment processed successfully'
            };
        } catch (error) {
            console.error('Payment failed:', error);
            return {
                success: false,
                message: error.message || 'Payment failed'
            };
        }
    };

    //function SelectRowOnEnterFunction(e) {
    //    if (e.key === "Enter" && e.target.id !== "tbOrdersSearch") {
    //        PreventDefault(e);

    //        var rows = gridRef.current.api.getSelectedRows();
    //        rows.forEach(function (row) {
    //            //params.node.setSelected(true);
    //            //gridRef.current.api.getDisplayedRowAtIndex(row.rowIndex).setExpanded(true);
    //        });
    //        /*https://plnkr.co/edit/?open=index.tsx&preview*/
    //    }
    //}

    function getOrderMessage() {
        var message = "";
        if (location.state) {
            var msg = location.state.orderMessage;
            if (NotEmpty(msg)) {
                message = msg;
            }
            else {
                var status = location.state.orderStatus;
                if (status === "create") {
                    message = typeName + " created successfully.";
                }
                else if (status === "update") {
                    message = typeName + " updated successfully.";
                }
            }
        }
        return message;
    }

    //---------------- Filters  -----------------//

    function clearFilters(e) {
        PreventDefault(e);
        ClearSearchFilters(storedSearchTermsKey);
        setClearFilterMsg("Filters cleared");

        // dont clear search str on filter clear
        //setSearchStr("");
    }

    const handleOpenFiltersModal = () => setIsFiltersModalOpen(true);
    function handleCloseFiltersModal(e, loadData) {
        PreventDefault(e);
        setIsFiltersModalOpen(false);

        if (IsTrue(loadData)) {
            refreshCache();
        }
    }

    // ON LOAD
    useEffect(() => {
        setLoading(false);

        // TODO: set up keyboard navigation through orders w/ 'Tab'
        // 'Space' selects and expands
        //document.addEventListener("keydown", SelectRowOnEnterFunction, false);

        // TODO: remove
        //initFlowbite();

        //do not set titles if grid embedded in other page
        if (IsMainSearchPage) {
            var titleStr = "Lumina - " + typeName + "s";
            SetDocTitle(titleStr);
            SetPageTitle(typeName + "s");

            setTimeout(() => {
                //dont overwrite saved search terms for embedded search
                loadSavedSearchTerms(typeName);
            }, 300);
        }
    }, []);

    if (loading === true) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (error === true)
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;

    return (
        <div>
            <div className="display-none">
                {/* hidden buttons to fire open/close details from header */}
                <button id="btnHdrOpenDetails" type="button" onClick={e => expandCollapseAll(true)}>Open Rows</button>
                <button id="btnHdrCloseDetails" type="button" onClick={e => expandCollapseAll(false)}>Close Rows</button>
            </div>

            <div style={containerStyle} className="p-2 pt-2">
                <div className="ag-theme-alpine" style={{ width: '100%', }}>                    
                    <div className="flex justify-between items-center w-full">

                        {/* Left side - buttons and search */}
                        <div className="flex items-center">
                            <div className="flex items-center mr-4">                            
                                {IsTrue(IsOrdersPageType) && IsFalse(IsAccountPageType) &&
                                    <a href="/Redirect?route=CreateOrder&orderType=1&status=create"
                                        className="btn-load-orders">
                                        Create Order
                                    </a>
                                }
                                {IsTrue(IsQuotesPageType) && IsFalse(IsAccountPageType) &&
                                    <a href={"/Redirect?route=CreateQuote&orderType=2&status=create&accountId=" + propsAccountId}
                                        className="btn-load-orders">
                                        Create Quote
                                    </a>
                                }
                            </div>
                            <div className="flex items-center">
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                        </svg>
                                    </div>
                                    <div>
                                        <input autoFocus type="search" id={tbSearchId} placeholder="Search..."
                                            className="orders-filter-search block p-2 pl-10 text-sm text-gray-900 
                                            border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500;"
                                            onKeyDown={(e) => CheckEnterKeyPress(e, refreshCache)}
                                            onChange={UpdateSearchStr}
                                            value={searchStr}
                                        />
                                    </div>
                                    <div id="selectedRows" />
                                </div>

                                <button className="btn-load-orders ml-4" onClick={(e) => refreshCache(e)}>
                                    {getSearchBtnText()}
                                </button>
                                <button className="btn-close-modal ml-3" onClick={(e) => clearFilters(e)}>
                                    Clear Filters
                                </button>

                                {/* Binoculars icon */}
                                <div onClick={handleOpenFiltersModal} className="div-binocs">
                                    <svg fill="#000000" height="25px" width="25px" version="1.1" id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 488.1 488.1" space="preserve">
                                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier"><g><g>
                                            <path d="M299.9,86.8c14.2-6.3,30.3-9.8,46.2-9.8c9.2,0,18,1.1,26.3,3.2c3.3,0.8,6.5-2,6-5.4l-1.2-9.6l0,0 c-1-16-19.3-28.9-41.2-28.9c-22.5,0-44,13.5-44,30.2l1,16C293.3,86,296.8,88.2,299.9,86.8z"></path>
                                            <path d="M487.3,361.9l-0.5-2.9c0-0.2-0.1-0.4-0.1-0.6l-18.5-94c-2.4-21.7-18.2-40.6-41.2-52.3l-8.8-68.3c0-29-32.8-52.7-72.1-52.7 s-76.7,23.5-76.7,52.6c0,1.4,0.1,2.9,0.3,4.3l0.5,7.4c-7.8-2.8-16.7-4.4-26.1-4.4c-9.5,0-18.4,1.6-26.3,4.5l0.5-7.5 c0.2-1.4,0.3-2.8,0.3-4.3c0-29-37.5-52.6-76.7-52.6c-39.3,0-72.1,23.7-72.1,52.7L61,212.1c-23,11.6-38.8,30.6-41.2,52.3l-18.5,94 c0,0.2-0.1,0.4-0.1,0.6l-0.5,2.9l0,0c-0.4,3-0.7,6-0.7,9.1c0,44.6,46.7,80.8,104.2,80.8s104.2-36.2,104.2-80.8l0,0l0,0v-37 c10.3,4.8,22.6,7.5,35.7,7.5s25.3-2.7,35.6-7.4V371l0,0l0,0c0,44.6,46.7,80.8,104.2,80.8c57.4,0,104.2-36.2,104.2-80.8 C488,367.9,487.7,364.9,487.3,361.9z M104.2,422.7c-40.7,0-75.1-23.7-75.1-51.7c0-28.1,34.4-51.7,75.1-51.7s75.1,23.7,75.1,51.7 C179.3,399,144.9,422.7,104.2,422.7z M244.1,315.9c-17.1,0-31-9.7-31-21.6s13.9-21.6,31-21.6s31,9.7,31,21.6 C275.1,306.2,261.2,315.9,244.1,315.9z M383.8,422.7c-40.7,0-75.1-23.7-75.1-51.7c0-28.1,34.4-51.7,75.1-51.7s75.1,23.7,75.1,51.7 S424.5,422.7,383.8,422.7z"></path>
                                            <path d="M115.5,80.2c8.3-2.1,17.2-3.2,26.3-3.2c15.9,0,32.1,3.5,46.2,9.8c3.1,1.4,6.6-0.8,6.8-4.2l1-16c0-16.7-21.5-30.2-44-30.2 c-21.9,0-40.1,12.9-41.2,28.9l0,0l-1.2,9.6C109,78.2,112.1,81.1,115.5,80.2z"></path>
                                        </g></g></g>
                                    </svg>
                                </div>

                                <div className="color-primary text-[12px] font-bold ml-3">{clearFilterMsg}</div>
                                <div className="text-green text-[1rem] ml-4">{message}</div>
                            </div>
                        </div>

                        {/* Right side buttons */}
                        <div className="flex items-center gap-2">                            
                            {IsTrue(IsAccountPageType) && IsFalse(IsQuotesPageType) &&
                                <button
                                    disabled={selectedRows.length === 0}
                                    onClick={() => handleApplyPayments()}
                                    className={`btn-small-green ml-2 ${selectedRows.length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                                >
                                    Apply Payments {selectedRows.length > 0 ? `(${selectedRows.length})` : ''}
                                </button>
                            }
                        </div>
                    </div>

                    <div className="text-sm text-gray-600 mt-1 mb-1">
                        'Enter' to search with 2 or more characters.
                    </div>

                    <div className="clear"></div>

                    <div className={gridClass}>
                        <AgGridReact
                            masterDetail={true}
                            ref={gridRef} 
                            gridOptions={gridOptions}
                            columnDefs={columnDefs}
                            rowModelType={rowModelType}
                            onGridReady={onGridReady}
                            rowData={rowData}
                            detailCellRenderer={detailCellRenderer}
                            onCellDoubleClicked={(e) => onCellDoubleClicked(e)}
                            cacheBlockSize={pageRows}
                            headerHeight={35}
                            domLayout='autoHeight'
                            rowSelection={isSelectionEnabled ? 'multiple' : 'false'}
                            suppressRowClickSelection={true}
                            rowMultiSelectWithClick={false}
                            onSelectionChanged={onSelectionChanged}
                            isRowSelectable={(params) => {
                                return isSelectionEnabled && params.data?.balance > 0;
                            }}
                            onFirstDataRendered={onFirstDataRendered}
                            getRowHeight={params => {
                                return params.data?.statusStr?.toLowerCase() === 'closed' && IsTrue(IsAccountPageType) ? 50 : 40;
                            }}
                        />
                    </div>
                </div>
            </div>

            <div>
                <SearchFilters
                    isOpen={isFiltersModalOpen}
                    isOrderType={IsOrdersFilterType}
                    isQuoteType={IsQuotesPageType}
                    clearFilters={clearFilters}
                    onClose={handleCloseFiltersModal}
                />
            </div>

            <div className="">
                <Modal ref={billGroupModalRef} className="order-bg-modal min-h-[700px] min-w-[1350px]">
                    <Modal.Body>
                        {billGroupModalContent}
                    </Modal.Body>
                </Modal>
            </div>

            <div>
                {showPaymentModal && (
                    <PaymentModal
                        invoices={selectedRows.map(row => ({
                            invoiceId: row.invoiceId || 0,
                            orderId: row.orderId,
                            orderNumber: row.orderNumber,
                            balanceDue: row.balance,
                            accountName: row.accountName || ''
                        }))}
                        onClose={handlePaymentModalClose}
                        confirmPayment={handleConfirmPayment}
                    />
                )}
            </div>
        </div>
    );
}