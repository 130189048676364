import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Checkbox } from 'flowbite-react';

import { SetDocumentTitle } from "../_reactHelpers/ReactHelpers";
import {
    CheckEnterKeyPress, NotEmpty, PreventDefault, handleFieldChange,
    IdExists, ParseIntSafe, IsTrue, NotExists, Exists, NotStringEmpty
} from '../../js/formHelpers.js';
import {
    FormatDateTime, GetValueById, SetPageTitle, ClickById, GetCheckedById
} from '../../js/helpers.js';

import { StandardDataAndResponsePOST } from '../../js/dataHelpers';
import { get } from '../../services/apiService.js';


import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import '../../css/ag-theme-lumina.css'; // local ag-theme-alpine.css
import '../../css/ag-orders.css'; // custom orders grid css

import { ModuleRegistry } from '@ag-grid-community/core';
import { MasterDetailModule } from 'ag-grid-enterprise';

ModuleRegistry.registerModules([MasterDetailModule]);

export const SampleCheckoutGrid = (props) => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Sample Checkout");
    const navigate = useNavigate();
    const location = useLocation();
    
    const [checkedOut, setCheckedOut] = useState(true);

    const tbSearchId = "tbSampleCheckoutSearch";
    const minSearchChars = 2;        

    const pageRows = 50;
    var rowModelType = 'serverSide';

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    const [searchStr, setSearchStr] = useState(props.searchStr ?? "");
    const storedSearchTermsKey = "storedSampleCheckoutSearchTerms";

    const gridRef = useRef();
    const [gridHeight, setGridHeight] = useState(getGridHeight());
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    //const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

    const [rowData, setRowData] = useState();
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '', field: 'sampleCheckoutId', width: 50, hide: true,
        },
        {
            headerName: 'Returned', field: 'checkedIn', cellDataType: 'boolean', maxWidth: 100,
            cellRenderer: params => {
                var checkedIn = params.data.checkedIn;
                return <Checkbox className="pr-[15px] cursor-pointer text-primary border-gray-300 rounded ring-0 focus:ring-0"
                    checked={checkedIn} disabled={checkedIn}
                    onClick={e => checkInSamplePOST(params.data.sampleCheckoutId)}></Checkbox>;
            }
        },        
        {
            headerName: 'Date Out', field: 'enteredDateStr', maxWidth: 120,
        },        
        {
            headerName: 'Date In', field: 'checkedInDateStr', maxWidth: 120,
        },        
        {
            headerName: 'Store', field: 'storeName', maxWidth: 110,
        },        
        {
            headerName: 'Customer Name', field: 'customerName', minWidth: 200, //cellClass: ['text-secondary'],
        },
        {
            headerName: 'Phone', field: 'phone', minWidth: 40,
        },
        {
            headerName: 'Email', field: 'email', minWidth: 40, //cellClass: ['text-secondary', 'font-bold'],
        },
        {
            headerName: 'Sample Types', field: 'categories', minWidth: 300,
            autoHeight: true, cellClass: ['text-wrap','leading-5','pt-1','pb-1'],            
        },
        {
            headerName: 'Salesperson', field: 'salespersonName', minWidth: 150,
        },        
        {
            headerName: 'Notes', field: 'notes', minWidth: 200,
            autoHeight: true, cellClass: ['text-wrap','leading-5','pt-1','pb-1'],    
        },
    ]);

    const gridOptions = {
        defaultColDef: {
            flex: 1,
            sortable: false,
            resizable: true,
            filter: false,
            suppressMenu: true,
            suppressMovable: true,
            cellClass: ["no-border"]
        }
    };

    async function checkInSamplePOST(scId) {
        var response = null;
        var success = false;

        if (IdExists(scId)) {
            var postModel = JSON.stringify(scId);
            var endpoint = "samplecheckout/SampleCheckoutCheckIn";
            var typeStr = "checkInSamplePOST";
            response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);

            var rScId = ParseIntSafe(response);
            if (IdExists(rScId)) {
                success = true;
            }
        }

        if (IsTrue(success)) {
            refreshCache();
            updateMessage("Sample checked in.", "text-green");
        }
        else {
            updateMessage(defaultErrMsg, "text-red");
        }
    }

    function getGridHeight() {
        return "750px";
    }

    function createViewSampleClick(sampleCheckoutData) {      
        if (Exists(sampleCheckoutData)) {
            navigate("/ViewSampleCheckout", { state: { sampleCheckoutData } });
        }
        else {
            navigate("/CreateSampleCheckout");
        }
    }

    function updateMessage(msg, cssClass, errorMsg, logMsg) {

        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    function getStatusMessage() {
        var message = "";
        var messageClass = "";
        if (location.state && location.state.statusMsg) {
            message = location.state.statusMsg;
            messageClass = "text-green";
        }
        updateMessage(message, messageClass);
    } 

    //---------------- Load Data  -----------------//

    const onGridReady = useCallback((params) => {
        params.api.setServerSideDatasource(serverSideDatasource());
    }, []);

    const serverSideDatasource = () => {
        return {
            // called by the grid when more rows are required
            getRows: async params => {

                var searchTerms = GetValueById(tbSearchId);
                var validSearch = NotEmpty(searchTerms) && searchTerms.length >= minSearchChars;
                if (validSearch) {
                    saveSearchValues();

                    var pageNum = params.request.endRow / pageRows;
                    var page = pageNum - 1;                   

                    var checkedOutOnly = GetCheckedById("checkedOut");
                    var endpoint = 'samplecheckout/GetSampleCheckoutSearch?'
                    endpoint += "checkedOutOnly=" + checkedOutOnly + "&numRows=" + pageRows +
                        "&page=" + page + "&sText=" + searchTerms;

                    console.log("server side apiUrl: " + endpoint);
                    try {
                        const response = await get(endpoint);
                        //console.log('Response: ' + JSON.stringify(response));

                        if (response && response.dataRows && response.dataRows.length > 0) {
                            //debugger;

                            var dataRows = response.dataRows;
                            params.success({ rowData: dataRows });

                            hideOverlay();
                            console.log(response.message);
                        }
                        else {
                            params.success({ rowData: [] });
                            showNoRows();
                            console.log("load samplecheckout: no data");
                        }
                    } catch (error) {
                        params.success({
                            rowData: []
                        });
                        showNoRows();
                        //setError(error);
                        console.error('load samplecheckout error:', error);
                    }
                }
                else {
                    params.success({ rowData: [] });
                    showNoRows();
                    console.log("load samplecheckout: load none");
                }
            }
        };
    }

    const refreshCache = useCallback((e) => {
        //PreventDefault(e);

        var searchTerms = GetValueById(tbSearchId);
        var validSearch = NotEmpty(searchTerms) && searchTerms.length >= minSearchChars;
        if (validSearch) {
            gridRef.current.api.refreshServerSide({ purge: true });
        }
    }, []);

    //function showLoading() {
    //    gridRef.current.api.showLoadingOverlay();
    //}

    function showNoRows() {
        gridRef.current.api.showNoRowsOverlay();
    }
    function hideOverlay() {
        gridRef.current.api.hideOverlay();
    }

    const onFirstDataRendered = useCallback((params) => {
        console.log("onFirstDataRendered endTime: " + FormatDateTime(new Date()));
    }, []);

    //const cellClicked = useCallback((params) => {
    //    params.node.setSelected(true);
    //}, []);

    function onCellDoubleClicked(node) {
        if (node && node.column) {
            var data = node.data;
            if (data) {
                if (IdExists(data.sampleCheckoutId)) {
                    createViewSampleClick(data);
                }
            }
            else {
                alert(defaultErrMsg);
            }
        }
    }

    function saveSearchValues() {
        //save search terms in session
        var searchTerms = GetValueById(tbSearchId);
        localStorage.setItem(storedSearchTermsKey, JSON.stringify(searchTerms));
    }

    function loadSavedSearchTerms() {        
        var allowed = true;
        try {
            if (allowed) {
                const savedSearchTerm = localStorage.getItem(storedSearchTermsKey);
                if (NotStringEmpty(savedSearchTerm)) {

                    const strSearchTerm = JSON.parse(savedSearchTerm);
                    if (NotStringEmpty(strSearchTerm)) {
                        setSearchStr(strSearchTerm);
                    }
                }
            }
        }
        catch (error) {
            console.error("localStorage error: " + storedSearchTermsKey);
            //localStorage.setItem(storedSearchTermsKey, "");
            //localStorage.setItem(storedSearchTypesKey, "");
        }

        //localStorage.setItem(storedSearchTermsKey, "");
        //localStorage.setItem(storedSearchTypesKey, "");
        //clearLocationState();
    }

    function UpdateSearchStr(e) {
        var str = "";
        if (e) {
            var data = handleFieldChange(e);
            if (data) {
                str = data.value;
            }
        }
        setSearchStr(str);
    }

    function UpdateCheckedOut(e) {
        var checked = false;
        if (e) {
            var data = handleFieldChange(e);
            if (data) {
                checked = data.value;
            }
        }
        setCheckedOut(checked);
        refreshCache(e);
    }

    // ON LOAD
    useEffect(() => {
        setLoading(false);
        getStatusMessage();

        SetPageTitle("Sample Checkout");
        setTimeout(() => {            
            loadSavedSearchTerms();
        }, 300);
    }, []);

    if (loading === true) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (error === true)
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;

    return (
        <div>
            <div style={containerStyle} className="p-2 pt-2">
                <div className="ag-theme-alpine" style={{ height: gridHeight, width: '100%', }}>
                    <div>
                        <div className="float-left">
                            <div className="table-cell pr-4">
                                <button onClick={e => createViewSampleClick()} className="btn-load-orders">
                                    Create Sample
                                </button>
                            </div>
                            {/* Search */}
                            <div className="table-cell">
                                <label for="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                        </svg>
                                    </div>
                                    <div>
                                        <input autoFocus type="search" id={tbSearchId} placeholder="Search..." 
                                            onKeyDown={(e) => CheckEnterKeyPress(e, refreshCache)}
                                            className="orders-filter-search block p-2 pl-10 text-sm text-gray-900 
                                                border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500;"
                                            onChange={UpdateSearchStr}
                                            value={searchStr}
                                        >
                                        </input>                                        
                                    </div>
                                    <div id="selectedRows" />
                                </div>
                            </div>                            
                            <div className="pl-4 table-cell">
                                {/* Search Buttons */}
                                <div className="table-cell">
                                    <button onClick={(e) => refreshCache(e)} className="btn-load-orders">Search Samples</button>
                                </div>
                                {/* Filters */}
                                <div className="pl-3 pr-4 table-cell">
                                    <label className="lbl-cb">
                                        <span className="pr-2">Checked Out</span>
                                        <Checkbox className="cursor-pointer text-primary border-gray-300 rounded ring-0 focus:ring-0" 
                                            id="checkedOut" name="checkedOut"                                            
                                            checked={checkedOut} onChange={UpdateCheckedOut}></Checkbox>
                                    </label>
                                </div>
                                <span className={"table-cell pl-4 " + messageClass}>{message}</span>
                            </div>
                            <div className="clear mt-1 mb-1">
                                'Enter' to search with 2 or more characters
                            </div>
                        </div>                        
                    </div>
                    <div className="clear"></div>

                    {/*Grid Def*/}
                    <AgGridReact
                        enableColumnMenu={false}
                        ref={gridRef}
                        gridOptions={gridOptions}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        onFirstDataRendered={onFirstDataRendered}
                        //onCellClicked={cellClicked}
                        //onRowDoubleClicked={(e) => onRowDoubleClicked(e)}
                        //onRowClicked={(e) => debounceCellClick(e, self.rowClicked)}
                        onCellDoubleClicked={(e) => onCellDoubleClicked(e)}
                        rowData={rowData}
                        rowModelType={rowModelType}
                        cacheBlockSize={pageRows}
                        headerHeight={35}
                    >
                    </AgGridReact>
                </div>
            </div>
        </div>
    );
}
