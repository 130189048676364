import React from 'react';

export const CheckContent = ({
    checkNumber,
    setCheckNumber
}) => (
    <div className="space-y-3">
        <input
            type="text"
            placeholder="Check Number"
            value={checkNumber}
            onChange={(e) => setCheckNumber(e.target.value)}
            className="payment-input"
            required
        />
    </div>
);
