import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Modal } from 'react-daisyui';


import { post } from '../../../services/apiService.js';
import {
    GetStoreSelectList, GetProductCategorySelectList, GetVendorSelectList, GetUnitsSelectList
} from '../../../js/dataHelpers.js';
import {
    FormatDateLongInput, FormatDate, NotStringContains
} from '../../../js/helpers.js';
import {
    handleFieldChange, SetFocusById, formatCurrencyDecimal, FormatStrLength,
    PreventDefault, isSelectedDropdown, NotEmpty, IsEmpty, Exists, ParseIntSafe, IdExists, IsTrue, IsFalse, EnforceNumDecimalPlaces,
    KeyHandlerDatePicker, GetDateNow, NotStringEmpty, StringEmpty, isValidDate, NotExists, NotIsValidDate, GetInputDecimalStr, defaultErrMsg
} from '../../../js/formHelpers.js';
import {
    GetQuantityFromWidthLength, GetLengthFromWidthQuantity, GetRemainder,
    GetNewQuantityForMin, GetMultDec, GreaterThan, Equals
} from '../../../js/calculationHelpers.js';

import { DoMinQtyCheck, GetBoxQtyStr, createEditOlModel } from '../_js/_orderHelpers.js';
import { MinQtyModal } from './MinQtyModal';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const EditOrderLineModal = (props) => {
    const qtyModal = useRef();
    const onCloseModal = props.onCloseModal;

    const propsType = props.type;
    const propsPriceLevelStr = props.priceLevelStr ?? "";
    const propsBcMultiplier = props.bcMultiplier ?? 0;
    const propsOrderLines = props.orderLines ?? [];
    const propsIsSamplesOrder = props.isSamplesOrder ?? false;

    const typeEdit = "edit-lines";
    const typeDelete = "delete-lines";

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("Edit Line");    
    const [modalClass, setModalClass] = useState("");

    const [storeList, setStoreList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const [vendorList, setVendorList] = useState([]);
    const [unitsList, setUnitsList] = useState([]);

    const [statusList, setStatusList] = useState([]);
    const [originalStatusId, setOriginalStatusId] = useState("");
    const [originalQty, setOriginalQty] = useState("");

    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [closeDisabled, setCloseDisabled] = useState(false);
    const [customFieldsDisabled, setCustomFieldsDisabled] = useState(true);

    const [qtyDisabled, setQtyDisabled] = useState(true);

    const [linesEdited, setLinesEdited] = useState(false);
    const [orderLineIds, setOrderLineIds] = useState([]);
    const [olData, setOlData] = useState(null);

    const [isRoll, setIsRoll] = useState(false);
    //const [boxQtyStr, setBoxQtyStr] = useState(false);
    const [stdLength, setStdLength] = useState(0);
    const [promiseDate, setPromiseDate] = useState("");
    const [units, setUnits] = useState("");
    const [width, setWidth] = useState(0);
    const [length, setLength] = useState(0);
    const [quantity, setQuantity] = useState(0);
    const [lineTotal, setLineTotal] = useState(0);
    const [linePrice, setLinePrice] = useState(0);

    //used for min qty adjustment
    const [qtyModalContent, setQtyModalContent] = useState(null);
    const [allowQtyOverride, setAllowQtyOverride] = useState(false);

    const [requiredClass, setRequiredClass] = useState(" hidden ");
    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [changeStatusMsg, setChangeStatusMsg] = useState("");
    const [poQtyUpdateMsg, setPoQtyUpdateMsg] = useState("");

    const [isEditable, setIsEditable] = useState(false);

    const updateMsg = "Line updated successfully.";
    const updateErrMsg = "There was an issue updating the Line. Please try again or contact support.";
    
    const purOrderQtyUpdateMsg = "Editing quantity will update the PO qty for this line.";
    const deleteConfirmMsg = "Are you sure you want to delete the selected line(s)?";

    //const defaultErrMsg = "An error occurred. Please try again or contact support.";
    //const [showPoData, setShowPoData] = useState(false);
    //const [showAddLineForm, setShowAddLineForm] = useState(false);
    //const [saveButtonDisabled, setSaveButtonDisabled] = useState(false);
    //const [skipButtonDisabled, setSkipButtonDisabled] = useState(true);
    //const [inputsDisabled, setInputsDisabled] = useState(false);
    //const [locationDisabled, setLocationDisabled] = useState(true);
    //const [priceDisabled, setPriceDisabled] = useState(false);
    //const [unitsDisabled, setUnitsDisabled] = useState(false);
    //const [lastKeypressTime, setLastKeypressTime] = useState(null);

    // Helpers
    function updateMessage(msg, cssClass) {
        setMessage(msg);
        setMessageClass(cssClass);
    }

    function onCloseDeleteLineModal(e, deleteLines) {
        PreventDefault(e);
        setIsModalOpen(false);
        onCloseModal(e, deleteLines);
    }

    // Methods
    async function createProductCategoryList() {
        var list = [];
        list.push(<option key="0" value="0">None</option>);

        var dataList = await GetProductCategorySelectList("order");
        dataList.forEach((item, index) => {
            list.push(<option key={index + 1} value={item.value}>{item.text}</option>);
        });

        setCategoryList(list);
    }

    async function createVendorSupplierList() {
        var list = [];
        list.push(<option key="0" value="0">None</option>);

        var dataList = await GetVendorSelectList(1);
        dataList.forEach((item, index) => {
            list.push(<option key={index + 1} value={item.value}>{item.text}</option>);
        });

        setVendorList(list);
        //setShowAddLineForm(true);
    }

    async function createStoreList() {
        var list = [];
        //list.push(
        //    <option key="0" value="0">None</option>
        //);

        var dataList = await GetStoreSelectList(2);
        dataList.forEach((item, index) => {
            list.push(
                <option key={index + 1} value={item.value}>{item.text}</option>
            );
        });
        setStoreList(list);
    }

    async function createUnitsList(units) {
        var list = [];
        list.push(<option key="0" value="0">None</option>);

        var createForValue = true;
        var dataList = await GetUnitsSelectList();

        var cnt = 0;
        dataList.forEach((item, index) => {
            list.push(<option key={cnt + 1} value={item.value}>{item.text}</option>);
            cnt++;
            if (IsTrue(createForValue)) {
                createForValue = NotStringContains(item.text, units);
            }
        });

        // ensure selected value is always in list
        if (IsTrue(createForValue)) {
            list.push(<option key={cnt + 1} value={units}>{units}</option>);
        }

        setUnitsList(list);
    }

    async function createStatusList(currentStatusId) {
        var isQtyDisabled = true;
        //var isLocationDisabled = true;
        //var isShowPoData = false;

        var index = 0;
        var list = [];
        //None, To Be Ordered
        if (currentStatusId === 0 || currentStatusId === 1) {
            index++;
            list.push(<option key={index} value={0}>None</option>);
            index++;
            list.push(<option key={index} value={1}>To Be Ordered</option>);

            isQtyDisabled = false;
            //isLocationDisabled = false;
        }
        //Stock Item
        if (currentStatusId === 15) {
            //allow None and ToBeOrdered??
            index++;
            list.push(<option key={index} value={0}>None</option>);
            index++;
            list.push(<option key={index} value={1}>To Be Ordered</option>);
            index++;
            list.push(<option key={index} value={15}>Stock Item</option>);

            isQtyDisabled = false;
        }
        //Emailed
        if (currentStatusId === 10) {
            index++;
            list.push(<option key={index} value={0}>None</option>);
            index++;
            list.push(<option key={index} value={1}>To Be Ordered</option>);
            index++;
            list.push(<option key={index} value={10}>Emailed</option>);

            isQtyDisabled = false;
            //isLocationDisabled = false;
        }
        //On Order
        if (currentStatusId === 2) {
            setChangeStatusMsg("This line is On Order. Changing the status will remove the PO.");

            index++;
            list.push(<option key={index} value={0}>None</option>);
            index++;
            list.push(<option key={index} value={1}>To Be Ordered</option>);
            index++;
            list.push(<option key={index} value={2}>On Order</option>);

            isQtyDisabled = false;
            //isShowPoData = true;
        }
        //Received
        if (currentStatusId === 3) {
            setChangeStatusMsg("Changing the status will un-receive this line and update the PO.");

            index++;
            list.push(<option key={index} value={2}>On Order</option>);
            index++;
            list.push(<option key={index} value={3}>Received</option>);
        }
        //Delivered
        if (currentStatusId === 4) {
            setChangeStatusMsg("Changing the status will un-deliver this line and set it received.");

            //cant go from On Order to Delivered, dont allow reverse action
            //index++;
            //list.push(<option key={index} value={2}>On Order</option>);
            index++;
            list.push(<option key={index} value={3}>Received</option>);
            index++;
            list.push(<option key={index} value={4}>Delivered</option>);
        }
        //Closed
        if (currentStatusId === 5) {
            //once line Closed no edits
            //lines are Closed once order is Invoiced(JobCosted) and Closed
            index++;
            list.push(<option key={index} value={5}>Closed</option>);
        }
        //Pre-Delivered
        if (currentStatusId === 6) {
            index++;
            list.push(<option key={index} value={2}>On Order</option>);
            index++;
            list.push(<option key={index} value={3}>Received</option>);
            index++;
            list.push(<option key={index} value={6}>Pre-Delivered</option>);
        }
        //'Will Call' status currently not used
        if (currentStatusId === 7) {
            index++;
            list.push(<option key={index} value={7}>Will Call</option>);
        }

        //TODO: Data driven OrderStatus list
        //w current status and set available statuses for edit

        //var dataList = await GetStoreSelectList(2);
        //dataList.forEach((item, index) => {
        //    list.push(
        //        <option key={index + 1} value={item.value}>{item.text}</option>
        //    );
        //});

        // TODO: remove
        //setLocationDisabled(isLocationDisabled);
        //setShowPoData(isShowPoData);

        setQtyDisabled(isQtyDisabled);
        setStatusList(list);
    }

    function onCloseEditLineModal(e, edited) {
        PreventDefault(e);

        //if only one line linesReceived not set due to async
        var hasEditLines = linesEdited || edited;
        onCloseModal(e, hasEditLines);
    }

    async function setupOrderLines() {
        var hasData = false;
        if (propsOrderLines && propsOrderLines.length > 0) {

            var olIds = [];
            propsOrderLines.forEach((ol, index) => {
                if (ol) {
                    olIds.push(ol.orderLineId);
                }
            });
            setOrderLineIds(olIds);

            var olId = await moveNextOrderLineId(olIds);
            if (olId && olId !== 0) {
                //set line data from ol
                setupOrderLineData(olId);
                hasData = true;
            }
        }

        if (hasData !== true) {
            //close if no orderLine on load
            onCloseEditLineModal(null);
        }
    }

    function setupOrderLineData(olId) {
        if (propsOrderLines && propsOrderLines.length > 0) {
            var olArr = propsOrderLines.filter((element) => {
                return element.orderLineId === olId;
            });

            if (olArr && olArr.length === 1) {
                var ol = olArr[0];
                createStatusList(ol.statusId);

                //set page fields
                var canEditCustom = (ol.statusId === 0 || ol.statusId === 1 || ol.statusId === 15);
                setCustomFieldsDisabled(!canEditCustom);

                //var unitsDisabled = (ol.productCategoryId === 1 || ol.productCategoryId === 2);
                //setUnitsDisabled(unitsDisabled);

                setOriginalStatusId(ol.statusId);
                setLinePrice(GetInputDecimalStr(ol.price))
                setLineTotal(GetInputDecimalStr(ol.total));
                setOriginalQty(ol.quantity);

                //setPromiseDate(ol.promiseDateInputStr);
                if (NotStringEmpty(ol.promiseDateInputStrDtPckr)) {
                    var date = new Date(ol.promiseDateInputStrDtPckr);
                    setPromiseDate(date);
                }
                else {
                    setPromiseDate(new Date());
                }

                setAllowQtyOverride(true);
                setIsRoll(ol.isRoll);

                setUnits(ol.unitsStr);
                createUnitsList(ol.unitsStr)

                setWidth(GetInputDecimalStr(ol.width));
                setLength(GetInputDecimalStr(ol.length));
                setStdLength(GetInputDecimalStr(ol.length));
                setQuantity(ol.quantity);

                //var bxQtyStr = GetBoxQtyStr(ol.sellQuantity, ol.sellUnit, ol.unitsStr);
                //setBoxQtyStr(bxQtyStr);

                //set page model
                setOlData(ol);

                if (IsTrue(ol.isCustom)) {
                    //setModalTitle("Edit Custom Line");
                    createProductCategoryList();
                    createVendorSupplierList();
                    setIsEditable(true);
                }
                //else {
                //    setModalTitle("Edit Order Line");
                //    //setShowAddLineForm(true);
                //}

                if (IsTrue(propsIsSamplesOrder)) {
                    //setModalTitle("Edit Samples Line");
                    //setPriceDisabled(true);
                    setQtyDisabled(true);
                    setSubmitDisabled(false);
                }
            }
        }
    }

    async function moveNextOrderLineId(olIds) {
        // TODO: only allowing single line edit
        var olId = 0;
        //setSkipButtonDisabled(true);
        //setShowAddLineForm(false);

        if (!olIds) {
            //get from state if not passed in
            olIds = orderLineIds;
        }

        if (olIds && olIds.length > 0) {
            //get next ID from array
            olId = ParseIntSafe(olIds.slice(0, 1) ?? "0");

            //remove first ID from array
            olIds = olIds.slice(1);

            //show skip button if lines left
            //if (olIds && olIds.length > 0) {
            //    setSkipButtonDisabled(false);
            //}
        }

        setOrderLineIds(olIds);
        return olId;
    }

    async function saveOrNextOrderLine(e, save) {
        PreventDefault(e);
        updateMessage();
        setRequiredClass(" hidden ");

        var valid = true;
        if (save === true) {
            valid = validateSave();
        }

        //if not valid stop all actions
        //error messages displayed
        if (valid === true) {

            var response = null;
            if (IsTrue(save)) {
                response = await saveOrderLine();

                // sucess = true
                if (IsTrue(response)) {
                    onCloseEditLineModal(e, true);
                }
                else {
                    alert(defaultErrMsg());
                }
                //save = false;
            }

            //if (IsFalse(save)) {
            //    //move to next order line or close modal if last
            //    var olId = await moveNextOrderLineId();
            //    if (olId && olId !== 0) {
            //        setupOrderLineData(olId);
            //    }
            //    else {
            //        //close modal on last received
            //        onCloseEditLineModal(e, response);
            //    }
            //}
        }
    }

    function validateSave() {
        updateMessage();
        var valid = true;
        var msg = "";

        if (!olData.quantity || olData.quantity <= 0) {
            valid = false;
        }

        if (olData.statusId === 2 && NotIsValidDate(promiseDate)) {
            valid = false;
        }

        if (olData.isCustom) {
            if (isSelectedDropdown(olData.productCategoryId) !== true) {
                valid = false;
            }
            if (isSelectedDropdown(olData.vendorId) !== true) {
                valid = false;
            }
            if (IsEmpty(olData.style)) {
                valid = false;
            }
            if (IsEmpty(olData.color)) {
                valid = false;
            }
        }

        //show message if changing status once Received/Delivered/Closed
        //if (olData.statusId !== originalStatusId) {
        //    updateMessage(changeStatusMsg, "text-red");
        //    return false;
        //}

        if (IsFalse(valid)) {
            setRequiredClass("");
            updateMessage("*Required fields", "text-red");
        }
        return valid;
    }

    function showQtyMessage(currStatusId, currQty, origQty) {
        setPoQtyUpdateMsg("");

        if (currStatusId === 2) {
            if (!origQty) {
                origQty = originalQty;
            }
            if (currQty !== origQty) {
                setMessageClass("text-red");
                setPoQtyUpdateMsg(purOrderQtyUpdateMsg);
            }
        }
    }

    const handleChange = (e) => {
        var data = handleFieldChange(e);
        if (data) {

            // individual fields
            if (data.name === "units") {
                setUnits(data.value);
            }
            else if (data.name === "width") {
                data.value = EnforceNumDecimalPlaces(data.value, 2);

                let currStatusId = ParseIntSafe(olData.statusId);
                let origQty = parseFloat(olData.quantity);

                let currQty = UpdateForWidthLengthQuantity(data.value, null, null);
                showQtyMessage(currStatusId, currQty, origQty);
            }
            else if (data.name === "length") {
                data.value = EnforceNumDecimalPlaces(data.value, 2);

                let currStatusId = ParseIntSafe(olData.statusId);
                let origQty = parseFloat(olData.quantity);

                let currQty = UpdateForWidthLengthQuantity(null, data.value, null);
                showQtyMessage(currStatusId, currQty, origQty);
            }
            else if (data.name === "quantity") {
                data.value = EnforceNumDecimalPlaces(data.value, 2);

                let currStatusId = ParseIntSafe(olData.statusId);
                let origQty = parseFloat(olData.quantity);

                setSubmitDisabled(true);
                DisableSubmit(e, data.value, false);

                // reset allow override for submit on qty change
                setAllowQtyOverride(false);
                let currQty = UpdateForWidthLengthQuantity(null, null, data.value);
                showQtyMessage(currStatusId, currQty, origQty);
            }
            else if (data.name === "linePrice") {
                data.value = EnforceNumDecimalPlaces(data.value, 2);

                setLinePrice(data.value);
                UpdatePricesAndTotals(quantity, data.value, null, null);
            }
            else {
                // olData fields

                // Bin always capitalized
                if (data.name === "bin") {
                    data.value = data.value.toUpperCase();
                }

                // show message if changing status once OnOrder/Received/Delivered/Closed
                if (data.name === "statusId") {
                    updateMessage();

                    let currStatusId = ParseIntSafe(data.value);
                    if (currStatusId !== originalStatusId) {
                        updateMessage(changeStatusMsg, "text-red");
                    }

                    // TODO: remove
                    //if (currStatusId === 2) {
                    //    setShowPoData(true);
                    //}
                    //else {
                    //    setShowPoData(false);
                    //}
                }

                // cost
                if (data.name === "cost") {
                    data.value = EnforceNumDecimalPlaces(data.value, 2);

                    UpdatePricesAndTotals(null, null, data.value, null);
                }
                // multiplier
                if (data.name === "multiplier") {
                    data.value = EnforceNumDecimalPlaces(data.value, 3);

                    UpdatePricesAndTotals(null, null, null, data.value);
                }
                // comments
                if (data.name === "comments") {
                    data.value = FormatStrLength(data.value, 50);
                }
                // productCategoryId
                if (data.name === "productCategoryId") {
                    if (data.value === "1" || data.value === "2") {
                        setUnits("SY");
                        setIsRoll(true);
                        //setUnitsDisabled(true);
                    }
                    else {
                        setIsRoll(false);
                        //setUnitsDisabled(false);
                        if (units === "SY") {
                            setUnits("");
                        }
                        else {
                            //dont clear units if not switching from Rolls
                        }
                    }
                }

                //updateMessage("", "");
                setOlData((olData) => ({
                    ...olData,
                    [data.name]: data.value
                }));
            }
        }
        else {
            //updateMessage(defaultErrMsg, "text-red", "null handleChange data");
        }
    };

    function UpdateForWidthLengthQuantity(cWidth, cLength, cQuantity) {

        //update roll fields based on changed
        if (isRoll === true) {
            //quantity always set here for rolls

            if (cWidth !== null) {
                //set changed value
                setWidth(cWidth);
                //set calc values for non-changed fields
                cLength = length ?? 0;

                cQuantity = GetQuantityFromWidthLength(cWidth, cLength, "SY")
                cQuantity = formatCurrencyDecimal(cQuantity);
            }
            else if (cLength !== null) {
                //set changed value
                setLength(cLength);
                //set calc values for non-changed fields
                cWidth = width ?? 0;

                cQuantity = GetQuantityFromWidthLength(cWidth, cLength, "SY")
                cQuantity = formatCurrencyDecimal(cQuantity);
            }
            else if (cQuantity !== null) {
                //set calc values for non-changed fields
                cWidth = width ?? 0;

                cLength = GetLengthFromWidthQuantity(cWidth, cQuantity, "SY")
                setLength(GetInputDecimalStr(cLength));
            }
        }

        //quantity always updated
        setQuantity(cQuantity);
        //update pricing and totals
        UpdatePricesAndTotals(cQuantity, null, null, null);
        return cQuantity;
    }

    function UpdatePricesAndTotals(cQuantity, cLinePrice, cCost, cMultiplier) {
        var reCalcLinePrice = false;

        if (cQuantity === null) {
            cQuantity = quantity;
        }
        if (cLinePrice === null) {
            cLinePrice = linePrice;
        }
        //re-calc line price when cost changed
        if (cCost === null) {
            cCost = olData.cost;
        }
        else {
            reCalcLinePrice = true;
        }
        //re-calc line price when multiplier changed
        if (cMultiplier === null) {
            cMultiplier = olData.multiplier;
        }
        else {
            reCalcLinePrice = true;
        }

        // TODO: make 2 decimal places
        //cMultiplier = formatCurrencyDecimal(cMultiplier);
        //cCost = formatCurrencyDecimal(cCost);

        olData.cost = cCost;
        olData.multiplier = cMultiplier;
        setOlData(olData);

        //update for changed fields
        if (reCalcLinePrice === true) {
            const bcPrice = GetMultDec(cCost, cMultiplier);
            cLinePrice = GetMultDec(bcPrice, propsBcMultiplier);
            setLinePrice(GetInputDecimalStr(cLinePrice));
        }

        //always update LineTotal
        const lt = GetMultDec(cQuantity, cLinePrice);
        setLineTotal(GetInputDecimalStr(lt));

        /** CFM 4-16-24: dont show Referral **
        //only calculate bc and referral for custom items
        if (IsCustomProduct) {
            const bcPrice = GetMultDec(cCost, cMultiplier);
            const priceReferral = cLinePrice - bcPrice;
            const referral = GetMultDec(priceReferral, cQuantity);
            setReferral(referral);
        }*/
    }

    // Min Qty Modal
    const handleQtyModalShow = useCallback(() => {
        qtyModal.current?.showModal();
    }, [qtyModal]);

    const handleQtyModalHide = useCallback(() => {
        qtyModal.current?.close();
        SetFocusById("linePrice");
    }, [qtyModal]);

    function returnMinQtyModalComponent(minQuantity, newQuantity, onCloseQtyModal) {
        return <MinQtyModal minQuantity={minQuantity} newQuantity={newQuantity}
            onCloseModal={onCloseQtyModal}></MinQtyModal>;
    }

    function onOpenQtyModal(minQuantity, newQuantity) {
        //updateMessage("", "");

        setQtyModalContent(returnMinQtyModalComponent(minQuantity, newQuantity, onCloseQtyModal));
        handleQtyModalShow();
    }

    function onCloseQtyModal(e, allowAdj, newQuantity) {
        PreventDefault(e);

        if (allowAdj) {
            //set adjusted qty for min sell qty
            setQuantity(newQuantity);
            UpdatePricesAndTotals(newQuantity, null, null, null);
            updateMessage("Quantity and Totals have been adjusted.", "text-red");
        }
        else {
            //set allow qty override for submit
            setAllowQtyOverride(true);
        }
        handleQtyModalHide();
    }

    function DisableSubmit(e, cQuantity, cQuantityOverride) {
        PreventDefault(e);

        var minQtyRem = DoMinQtyCheck(cQuantity, cQuantityOverride, allowQtyOverride, quantity, olData.sellQuantity, olData.sellUnit, isRoll);
        if (minQtyRem !== 0) {
            setSubmitDisabled(true);
            setCloseDisabled(true);
        }
        else {
            setSubmitDisabled(false);
            setCloseDisabled(false);
        }
    }

    function CheckMinQuantity(e) {
        PreventDefault(e);
        setSubmitDisabled(false);
        setCloseDisabled(false);

        var minQtyRem = DoMinQtyCheck(null, null, allowQtyOverride, quantity, olData.sellQuantity, olData.sellUnit, isRoll);
        if (minQtyRem !== 0) {
            var newQuantity = GetNewQuantityForMin(olData.sellQuantity, quantity);
            // open min qty notification modal
            onOpenQtyModal(olData.sellQuantity, newQuantity);
            return true;
        }
        return false;
    }

    async function saveOrderLine() {
        var resp = false;

        var model = createEditOlModel(olData);
        setIndividualFields(model);

        var postModel = JSON.stringify(model);
        const endpoint = 'orders/EditOrderLine';
        try {
            const response = await post(endpoint, postModel);
            //console.log('Response: ' + JSON.stringify(response));

            if (response.value) {
                var respData = response.value;

                if (IsTrue(respData.success)) {
                    const orderLineId = ParseIntSafe(respData.data);
                    if (IdExists(orderLineId)) {
                        resp = true;
                    }
                }
                else {
                    console.error("saveOrderLine api error: " + respData.error);
                    console.error("apiUrl: " + endpoint);
                    resp = respData;
                }
            }
            else {
                console.error("saveOrderLine api error, no response.value: " + response);
                console.error("apiUrl: " + endpoint);
                resp = response;
            }
        } catch (error) {
            console.error("error fetching saveOrderLine: ", error);
            console.error("apiUrl: " + endpoint);
            resp = error;
        }

        return resp;
    }

    function setIndividualFields(model) {
        model.price = linePrice;
        model.units = units;
        model.width = width;
        model.length = length;
        model.quantity = quantity;

        if (!model.cost || model.cost === "") {
            model.cost = 0;
        }

        if (NotExists(promiseDate)) {
            model.promiseDate = null;
        } else {
            model.promiseDate = FormatDateLongInput(promiseDate);
        }
        return model;
    }

    function EnterSave(e) {
        if (e.key === "Enter") {
            PreventDefault(e);
            saveOrNextOrderLine(e, true);
        }
    }

    function setTodaysDate() {
        var dateInp = GetDateNow();
        setPromiseDate(dateInp);
    }

    function getCartons() {
        if (GreaterThan(parseFloat(quantity), 0) && GreaterThan(parseFloat(olData?.sellQuantity), 0)) {
            const cartons = parseFloat(quantity) / parseFloat(olData?.sellQuantity);
            return Number.isInteger(cartons) ? cartons : cartons.toFixed(2);
        }
        return 0;
    }

    function handleCustomizeLine() {
        setIsEditable(prev => {
            const newIsEditable = !prev;
            olData.isCustom = newIsEditable;
            setOlData(olData);
            return newIsEditable;
        });
    }

    function openModal() {
        if (propsType === typeEdit) {
            setModalClass("edit-line-modal");
        }
        else if (propsType === typeDelete) {
            setModalClass("delete-line-modal");
        }
        setIsModalOpen(true);
    }


    // ON LOAD
    useEffect(() => {
        createProductCategoryList();
        createVendorSupplierList();

        if (propsType === typeEdit) {
            createStoreList();
            setupOrderLines();
        }
        openModal();        
        //initFlowbite();
    }, []);

    return (
        <div>
            {isModalOpen &&
                <div className={"modal-wrapper"}>
                    <div className={"modal-content " + modalClass}>
                        {propsType === typeEdit &&
                            <>
                                <div className="p-2">
                                    <div className="flex justify-between pb-2">
                                        <h1 className="text-secondary font-bold text-xl underline">+ Product Line</h1>

                                        {(olData?.statusId === 0 || olData?.statusId === 1) &&
                                            <button className="bg-primary text-white pb-[2px] px-3 font-medium rounded-md" type="button"
                                                onClick={handleCustomizeLine}>Customize
                                            </button>
                                        }
                                    </div>

                                    <div className="grid grid-cols-1 gap-8 mt-2 edit-line-form">
                                        <div className="flex gap-1">
                                            <div className="grid grid-cols-1 gap-1 w-[150px]">
                                                <span className="font-semibold">Supplier:</span>
                                                <span className="font-semibold">Category:</span>
                                                <span className="font-semibold">Product:</span>
                                                <span className="font-semibold">Color:</span>
                                            </div>
                                            {
                                                !isEditable ?
                                                    <div className="grid grid-cols-1 gap-2 w-[full]">
                                                        <span className="font-bold px-0 py-0 bg-transparent border-0 text-black">{olData?.vendorName}</span>
                                                        <span className="font-bold px-0 py-0 bg-transparent border-0 text-black">{olData?.productCategoryName}</span>
                                                        <span className="font-bold px-0 py-0 bg-transparent border-0 text-black">{olData?.style}</span>
                                                        <span className="font-bold px-0 py-0 bg-transparent border-0 text-black">{olData?.color}</span>
                                                    </div>
                                                    :
                                                    <div className="grid grid-cols-1 gap-2 w-[50%]">
                                                        <select id="vendorId" name="vendorId"
                                                            className="font-bold rounded-md py-0 pl-1 text-black"
                                                            value={olData.vendorId}
                                                            onChange={handleChange}
                                                            disabled={customFieldsDisabled}
                                                        >
                                                            {vendorList}
                                                        </select>

                                                        <select id="productCategoryId" name="productCategoryId"
                                                            className="font-bold rounded-md py-0 pl-1 text-black"
                                                            onKeyDown={(e) => EnterSave(e)}
                                                            onChange={handleChange}
                                                            value={olData.productCategoryId}
                                                            disabled={customFieldsDisabled}
                                                        >
                                                            {categoryList}
                                                        </select>

                                                        <input type="text" id="style" name="style"
                                                            className="font-bold rounded-md py-0 pl-1 text-black"
                                                            onKeyUp={(e) => EnterSave(e)}
                                                            onChange={handleChange}
                                                            value={olData.style}
                                                            disabled={customFieldsDisabled}
                                                        />

                                                        <input type="text" id="color" name="color"
                                                            className="font-bold rounded-md py-0 pl-1 text-black"
                                                            onKeyUp={(e) => EnterSave(e)}
                                                            onChange={handleChange}
                                                            value={olData.color}
                                                            disabled={customFieldsDisabled}
                                                        />
                                                    </div>
                                            }
                                        </div>

                                        <div className="flex gap-4">
                                            <div className="flex gap-2">
                                                <div className="grid grid-cols-1 gap-2 w-[130px]">
                                                    <span className="font-semibold">Quantity:</span>
                                                    {isEditable &&
                                                        <span className="font-semibold">Cost:</span>
                                                    }
                                                    {isEditable &&
                                                        <span className="font-semibold">Multiplier:</span>
                                                    }
                                                    <span className="font-semibold">Price:</span>
                                                    <span className="font-semibold">Total:</span>
                                                </div>

                                                <div className="grid grid-cols-1 gap-2 pr-4">
                                                    <div className="flex gap-2 rounded-md w-full">
                                                        <input
                                                            id="quantity" name="quantity" type="number"
                                                            className="font-semibold rounded-md w-[100px] py-0 px-1 ml-[18px] text-black"
                                                            autoFocus
                                                            onKeyDown={(e) => EnterSave(e)}
                                                            onBlur={(e) => CheckMinQuantity(e)}
                                                            onChange={handleChange}
                                                            value={quantity}
                                                            disabled={qtyDisabled} />
                                                    </div>

                                                    {isEditable &&
                                                        <div className="flex gap-2 rounded-md w-full">
                                                            <span className="font-bold py-0">$</span>
                                                            <input
                                                                id="cost" name="cost" type="number"
                                                                className="font-semibold rounded-md w-[100px] py-0 px-1 text-black"
                                                                onKeyDown={(e) => EnterSave(e)}
                                                                onBlur={(e) => GetInputDecimalStr(e.target.value)}
                                                                onChange={handleChange}
                                                                value={olData.cost}
                                                                disabled={customFieldsDisabled} />
                                                        </div>
                                                    }

                                                    {isEditable &&
                                                        <div className="flex gap-2 rounded-md w-full">
                                                            <input
                                                                id="multiplier" name="multiplier" type="number"
                                                                className="font-semibold rounded-md w-[100px] py-0 px-1 ml-[18px] text-black"
                                                                onKeyUp={(e) => EnterSave(e)}
                                                                onBlur={(e) => GetInputDecimalStr(e.target.value, 3)}
                                                                onChange={handleChange}
                                                                value={olData.multiplier}
                                                                disabled={customFieldsDisabled} />
                                                        </div>
                                                    }

                                                    <div className="flex gap-2 rounded-md w-full">
                                                        <span className="font-bold py-0">$</span>
                                                        <input
                                                            id="linePrice" name="linePrice" type="number"
                                                            className="font-semibold rounded-md w-[100px] py-0 px-1 text-black"
                                                            onKeyUp={(e) => EnterSave(e)}
                                                            onBlur={(e) => GetInputDecimalStr(e.target.value)}
                                                            onChange={handleChange}
                                                            value={linePrice}
                                                            disabled={!isEditable} />
                                                    </div>

                                                    <div className="flex gap-2 rounded-md w-full">
                                                        <span className="font-bold py-0">$</span>
                                                        <input
                                                            id="total" name="total"
                                                            className="font-semibold rounded-md w-[100px] py-0 px-1 text-black" type="number"
                                                            value={GetInputDecimalStr(lineTotal)}
                                                            disabled />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex gap-2">
                                                <div className="grid grid-cols-1 gap-2">
                                                    <span className="font-semibold">UOM:</span>
                                                    <span className="font-semibold">Price Level:</span>
                                                    <span className="font-semibold">Weight:</span>
                                                    {isEditable && <span>&nbsp;</span>}
                                                    {isEditable && <span>&nbsp;</span>}
                                                </div>

                                                <div className="grid grid-cols-1 gap-2">
                                                    {
                                                        !isEditable ?
                                                            <span className="font-bold text-black">{units}</span>
                                                            :
                                                            <select id="units" name="units"
                                                                className="font-bold rounded-md py-0 pl-1 text-black w-[100px] h-[26px]"
                                                                onKeyDown={(e) => EnterSave(e)}
                                                                value={units}
                                                                onChange={handleChange}
                                                            >
                                                                {unitsList}
                                                            </select>
                                                    }
                                                    <span className="font-bold text-black">{propsPriceLevelStr}</span>
                                                    <span className="font-bold w-[100px]">{GetInputDecimalStr(olData?.pcWeight * quantity)}</span>
                                                    {isEditable && <span>&nbsp;</span>}
                                                    {isEditable && <span>&nbsp;</span>}
                                                </div>
                                            </div>

                                            <div className="flex gap-1">
                                                <div className="grid grid-cols-1 gap-2">
                                                    {!isRoll &&
                                                        <span className="font-semibold">Carton Qty:</span>}
                                                    {isRoll &&
                                                        <span className="font-semibold">Length:</span>}
                                                    {isRoll && isEditable &&
                                                        <span className="font-semibold">Width:</span>}

                                                    {IsTrue(olData?.isStock) && //Equals(olData.statusId, 15) &&
                                                        <span className="font-semibold">Stock Qty:</span>
                                                    }
                                                    {IsTrue(olData?.isStock) && <span>&nbsp;</span>}

                                                    {isRoll && isEditable && <span>&nbsp;</span>}
                                                    {isRoll && isEditable && <span>&nbsp;</span>}
                                                    {isRoll && isEditable && <span>&nbsp;</span>}

                                                </div>

                                                <div className="grid grid-cols-1 gap-2">
                                                    {!isRoll &&
                                                        <span className="font-bold w-[100px]">{GetInputDecimalStr(olData?.sellQuantity)}</span>}
                                                    {isRoll &&
                                                        <input
                                                            id="length" name="length" type="number"
                                                            className="font-semibold rounded-md py-0 px-1 text-black w-[80px] h-[26px]"
                                                            value={length}
                                                            onKeyDown={(e) => EnterSave(e)}
                                                            onChange={handleChange}
                                                            disabled={qtyDisabled} />
                                                    }
                                                    {isRoll && isEditable &&
                                                        <input id="width" name="width" type="number"
                                                            className="font-semibold rounded-md py-0 px-1 text-black w-[80px] h-[26px]"
                                                            value={width}
                                                            onKeyDown={(e) => EnterSave(e)}
                                                            onChange={handleChange}
                                                            disabled={!olData.isCustom || customFieldsDisabled}
                                                        />
                                                    }

                                                    {IsTrue(olData?.isStock) && //Equals(olData.statusId, 15) &&
                                                        <span className="font-bold text-black">{olData?.productStockModel?.quantityAvailable ?? 0}</span>
                                                    }
                                                    {IsTrue(olData?.isStock) && <span>&nbsp;</span>}

                                                    {isRoll && isEditable && <span>&nbsp;</span>}
                                                    {isRoll && isEditable && <span>&nbsp;</span>}
                                                    {isRoll && isEditable && <span>&nbsp;</span>}
                                                </div>
                                            </div>

                                            <div className="flex gap-2">
                                                <div className="grid grid-cols-1 gap-2">
                                                    {!isRoll &&
                                                        <span className="font-semibold">Cartons:</span>
                                                    }
                                                    {isRoll && //NotStringEmpty(stdLength) && IsFalse(isEditable))  &&
                                                        <span className="font-semibold">Std Length:</span>}
                                                </div>
                                                <div className="grid grid-cols-1 gap-2">
                                                    {!isRoll &&
                                                        <span className="font-bold">{GetInputDecimalStr(getCartons())}</span>
                                                    }
                                                    {isRoll && //IsFalse(isEditable) && NotStringEmpty(stdLength)) &&
                                                        <span className="font-bold">{GetInputDecimalStr(stdLength) + " LF"}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <div className="flex gap-1">
                                                <div className="grid grid-cols-1 gap-2 w-[150px]">
                                                    <span className="font-semibold">Comments:</span>
                                                    <span className="font-semibold">Ship To WH:</span>
                                                    <span className="font-semibold">Status:</span>
                                                    <span className="font-semibold">Created by:</span>

                                                    {olData?.showPurchaseOrderInfo === true &&
                                                        //(olData.statusId !== 10 && olData.statusId !== 0 && olData.statusId !== 1) &&
                                                        <div className="grid grid-cols-1 gap-2">
                                                            <span className="font-semibold">Ordered By:</span>
                                                            <span className="font-semibold">Ordered On:</span>
                                                            <span className="font-semibold">Promise Date (ETA):</span>
                                                            <span className="font-semibold">Reference Number:</span>
                                                        </div>
                                                    }
                                                    {olData?.showReceivedStore === true &&
                                                        <div className="grid grid-cols-1 gap-2">
                                                            <span className="font-semibold">Received On:</span>
                                                            <span className="font-semibold">Bin:</span>
                                                        </div>
                                                    }
                                                    {olData?.showDeliveredInfo === true &&
                                                        //olData.showReceivedStoreStatuses === true && olData.statusId !== 3 &&
                                                        <div className="grid grid-cols-1 gap-2">
                                                            <span className="font-semibold">Delivered On:</span>
                                                            <span className="font-semibold">Picked Up By:</span>
                                                        </div>
                                                    }
                                                    {olData?.showClosedInfo === true &&
                                                        <div className="grid grid-cols-1 gap-2">
                                                            <span className="font-semibold">Closed On:</span>
                                                        </div>
                                                    }
                                                </div>

                                                <div className="grid grid-cols-1 gap-2">
                                                    <input
                                                        type="text"
                                                        id="comments" name="comments" maxLength="50"
                                                        onKeyDown={(e) => EnterSave(e)}
                                                        onChange={handleChange}
                                                        value={olData?.comments}
                                                        className="font-semibold rounded-md py-0 pl-1 w-[345px] text-black"
                                                    />

                                                    <select
                                                        id="warehouseId" name="warehouseId"
                                                        className="font-bold rounded-md py-0 pl-1 w-[200px] text-black"
                                                        onKeyDown={(e) => EnterSave(e)}
                                                        value={olData?.warehouseId}
                                                        onChange={handleChange}
                                                        //disabled={inputsDisabled}
                                                        disabled={qtyDisabled}
                                                    >
                                                        {storeList}
                                                    </select>

                                                    <select id="statusId" name="statusId"
                                                        className="font-bold rounded-md py-0 pl-1 w-[200px] text-black"
                                                        onChange={handleChange}
                                                        value={olData?.statusId}
                                                    >
                                                        {statusList}
                                                    </select>

                                                    <span className="font-semibold px-0 py-0 bg-transparent border-0 text-black">{olData?.takenBy}</span>

                                                    {olData?.showPurchaseOrderInfo === true &&
                                                        <div className="grid grid-cols-1 gap-2">
                                                            <span className="font-semibold px-0 py-0 bg-transparent border-0 text-black">{olData?.orderedBy}</span>
                                                            <span className="font-semibold px-0 py-0 bg-transparent border-0 text-black">{FormatDate(olData?.purchaseOrderDateStr)}</span>

                                                            <DatePicker id="promiseDate" name="promiseDate" className="order-date-modal rounded-md"
                                                                showIcon toggleCalendarOnIconClick
                                                                dateFormat="MM/dd/yyyy"
                                                                selected={promiseDate}
                                                                onKeyDown={(e) => KeyHandlerDatePicker(e, null, setTodaysDate, null)}
                                                                onChange={(date) => setPromiseDate(date)}
                                                                //disabled={inputsDisabled}
                                                                disabled={qtyDisabled}
                                                            />

                                                            <input type="text" id="poReferenceNumber" name="poReferenceNumber"
                                                                className="font-semibold rounded-md py-0 pl-1 text-black w-[120px]"
                                                                onKeyDown={(e) => EnterSave(e)}
                                                                onChange={handleChange}
                                                                value={olData?.poReferenceNumber}
                                                                //disabled={inputsDisabled}
                                                                disabled={qtyDisabled}
                                                            />
                                                        </div>
                                                    }
                                                    {olData?.showReceivedStore === true &&
                                                        <div className="grid grid-cols-1 gap-2">
                                                            <span className="font-semibold px-0 py-0 bg-transparent border-0 text-black">{FormatDate(olData?.receivedDate)}</span>
                                                            <input id="bin" name="bin" type="text"
                                                                className="font-semibold rounded-md w-full py-0 px-1 text-black !w-[60px]"
                                                                maxLength="4"
                                                                onKeyDown={(e) => EnterSave(e)}
                                                                onChange={handleChange}
                                                                value={olData?.bin} />
                                                        </div>
                                                    }
                                                    {olData?.showDeliveredInfo === true &&
                                                        <div className="grid grid-cols-1 gap-2">
                                                            <span className="font-semibold px-0 py-0 bg-transparent border-0 text-black">{FormatDate(olData?.deliveredDate)}</span>
                                                            <span className="font-semibold px-0 py-0 bg-transparent border-0 text-black">{olData?.pickupPrintedName}</span>
                                                        </div>
                                                    }
                                                    {olData?.showClosedInfo === true &&
                                                        <div className="grid grid-cols-1 gap-2">
                                                            <span className="font-semibold px-0 py-0 bg-transparent border-0 text-black">{FormatDate(olData.closedDate)}</span>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex justify-between mt-5">
                                        <div className={"text-center pt-1 mx-1 min-h-[45px] " + messageClass}>
                                            <div>{message}</div>
                                            <div>{poQtyUpdateMsg}</div>
                                        </div>

                                        <div className="justify-end text-right pt-[10px]">
                                            <button type="button" className="btn-cancel-modal"
                                                disabled={closeDisabled}
                                                onClick={(e) => onCloseModal(e, false)}>
                                                Cancel
                                            </button>

                                            {/* TODO: remove - per CFM 11-19-24 */}
                                            {/*<button type="button" className="btn-submit-modal mr-2"*/}
                                            {/*    disabled={skipButtonDisabled}*/}
                                            {/*    onClick={(e) => saveOrNextOrderLine(e, false)}*/}
                                            {/*>*/}
                                            {/*    Skip*/}
                                            {/*</button>*/}

                                            <button type="button" className="btn-submit-modal ml-2"
                                                disabled={submitDisabled}
                                                onClick={(e) => saveOrNextOrderLine(e, true)}>
                                                Save & Close
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }

                        {/**** Delete Lines ****/}
                        {propsType === typeDelete &&
                            <div className="text-center pb-4">
                                <div className="text-red text-[16px] py-4">
                                    {deleteConfirmMsg}
                                </div>
                                <div className="pt-2">
                                    <button type="button" className="btn-close-modal !bg-red"
                                        onClick={(e) => onCloseDeleteLineModal(e, false)}>
                                        Cancel
                                    </button>
                                    <button type="button" className="btn-submit-modal ml-4"
                                        autoFocus
                                        onClick={(e) => onCloseDeleteLineModal(e, true)}>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        }

                        {/**** Min Quantity Modal ****/}
                        <div className="">
                            <Modal ref={qtyModal} className="px-3">
                                <Modal.Body>
                                    {qtyModalContent}
                                </Modal.Body>
                            </Modal>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}