import React, {useState} from "react";
import {Button} from "react-daisyui";
import { GetDisplayCurrencyStr } from "../../../js/formHelpers";

export default function AdjustModal({ orderId, amount, setOpenAdjustPaymentModal }) {
    const [selectedOption, setSelectedOption] = useState("adjust");
    const [newAmount, setNewAmount] = useState("");

    return (
        <div className="p-1 pt-0 w-full">
            <div className="flex justify-content-between">
                <div className="pb-2 text-center w-full">
                    <span className="text-xl font-bold underline">Adjust Payment</span>
                </div>

                <Button size="sm" color="ghost" shape="circle"
                    onClick={() => setOpenAdjustPaymentModal(false)}
                    className="absolute right-4 top-2 min-h-[1.5rem] h-[1.5rem] w-[1.5rem]"
                >x</Button>
            </div>
            <div className="flex justify-between gap-10 mt-4 text-sm text-gray-600">
                <p>
                    <span className="font-medium">Order #:</span>
                    <span className="pl-1 font-bold">{orderId}</span>                    
                </p>
                <p>
                    <span className="font-medium">Original Amount:</span>
                    <span className="pl-1 font-bold">{GetDisplayCurrencyStr(amount)}</span>
                </p>
            </div>
            <form className="mt-4">
                <div>
                    <label className="flex items-center mb-2">
                        <input
                            type="radio"
                            name="paymentOption"
                            value="adjust"
                            checked={selectedOption === "adjust"}
                            onChange={() => setSelectedOption("adjust")}
                            className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500 cursor-pointer"
                        />
                        <span className="ml-2 text-sm text-gray-700 cursor-pointer">Adjust Payment</span>
                    </label>
                    {selectedOption === "adjust" && (
                        <div className="ml-6">
                            <label htmlFor="newAmount" className="block text-sm text-gray-600">
                                New Amount:
                            </label>
                            <input
                                type="number"
                                id="newAmount"
                                value={newAmount}
                                onChange={(e) => setNewAmount(e.target.value)}
                                className="cursor-pointer mt-1 block w-full px-3 py-2 text-sm border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
                            />
                        </div>
                    )}
                </div>
                <div className="mt-4">
                    <label className="flex items-center">
                        <input
                            type="radio"
                            name="paymentOption"
                            value="void"
                            checked={selectedOption === "void"}
                            onChange={() => setSelectedOption("void")}
                            className="w-4 h-4 text-blue-600 border-gray-300 focus:ring-blue-500"
                        />
                        <span className="ml-2 text-sm text-gray-700 cursor-pointer">Void Payment</span>
                    </label>
                    {selectedOption === "void" && (
                        <div className="ml-6 mt-2">
                            <span className="text-sm text-gray-600">Cancel Amount:</span>
                            <span className="pl-1 font-semibold">{GetDisplayCurrencyStr(amount)}</span>
                        </div>
                    )}
                </div>
                <button //type="submit"
                    type="button"
                    className="mt-6 w-full bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 font-semi6bold uppercase"
                >
                    Change Payment
                </button>
            </form>
        </div>
    )
}