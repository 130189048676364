import React from "react";
import { IsFalse, IsTrue } from "../../../js/formHelpers";

export default function DetailBox({ title, style, children, showButton, styleGrid, onClickAccountClaimVendor, onClickJobOrClaimAccount, openClaimAccount }) {
    return (
        <div className="bg-white border border-gray-300 shadow-md rounded pb-2" style={{ ...style }}>
            <div className="bg-gray-100 px-4 py-2 font-bold text-secondary flex justify-between text-xl">
                <span className="text-secondary">{title}</span>
                {showButton &&
                    <button id="btnOpenAccountClaimVendorModal" type="button"
                        onClick={(e) => onClickAccountClaimVendor(e)}>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                            stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round"
                                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                        </svg>
                    </button>

                    //<div>
                    //    {IsTrue(openClaimAccount) &&
                    //        <button id="btnOpenAccountClaimVendorModal" type="button"
                    //            onClick={(e) => onClickAccountClaimVendor(e)}>
                    //            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5}
                    //                stroke="currentColor" className="w-6 h-6">
                    //                <path strokeLinecap="round" strokeLinejoin="round"
                    //                    d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                    //            </svg>
                    //        </button>
                    //    }
                    //    {IsFalse(openClaimAccount) &&
                    //    }
                    //</div>
                }
            </div>

            <div className="grid p-1 pl-4 pr-4 gap-2"
                style={{ gridTemplateColumns: "repeat(auto-fit, minmax(100px, 1fr))", ...styleGrid }}>
                {children}
            </div>
        </div>
    )
}