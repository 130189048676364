import React, { useState, useRef, useCallback } from "react";

import { EditProductCodeForm } from "./EditProductCodeForm.js";
import { ProductCodeForm } from "./ProductCodeForm.js";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

export function ProductCodeList({
  productCodes,
  loading,
  onProductCodeUpdated,
  handleAddProductCode,
  propsMessage,
  propsMessageClass,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddModalOpen, setAddIsModalOpen] = useState(false);

  const [currentProductCode, setCurrentProductCode] = useState(null);
  const [quickFilterText, setQuickFilterText] = useState("");

  const [message, setMessage] = useState(propsMessage ?? "");
  const [messageClass, setMessageClass] = useState(propsMessageClass ?? "");
  const defaultErrMsg =
    "An error occurred. Please try again or contact support.";

  const gridRef = useRef();

  const columns = [
    { headerName: "PC", field: "productCode", maxWidth: 150 },
    { headerName: "Name", field: "name" },
    { headerName: "PC Weight", field: "pcWeight" },
    {
      headerName: "Roll",
      field: "isRoll",
      cellClass: ["text-center"],
      cellDataType: "boolean",
    },
    {
      headerName: "Item",
      field: "isItem",
      cellClass: ["text-center"],
      cellDataType: "boolean",
    },
    {
      headerName: "Sample",
      field: "isSamples",
      cellClass: ["text-center"],
      cellDataType: "boolean",
    },
  ];

  const onQuickFilterChanged = useCallback((event) => {
    setQuickFilterText(event.target.value);
  }, []);

  const handleEdit = useCallback((productCode) => {
    setCurrentProductCode(productCode);
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setCurrentProductCode(null);
  }, []);

  const handleRowDoubleClicked = useCallback(
    (event) => {
      handleEdit(event.data);
    },
    [handleEdit]
  );

  const gridOptions = {
    defaultColDef: {
      flex: 1,
      sortable: true,
      resizable: true,
      filter: true,
      suppressMenu: true,
      suppressMovable: true,
      cellClass: ["no-border"],
    },
  };

  const handleAdd = useCallback((productCode) => {
    setCurrentProductCode(productCode);
    setAddIsModalOpen(true);
  }, []);

  const closeAddModal = useCallback(() => {
    setAddIsModalOpen(false);
    setCurrentProductCode(null);
  }, []);

  const updateMessage = (msg, cssClass) => {
    setMessage(msg);
    setMessageClass(cssClass);
  };

  if (loading) {
    return (
      <div className="text-center text-xl mt-[10%]">
        <span className="pr-1">Loading...</span>
        <span className="loading loading-spinner text-blue-600"></span>
      </div>
    );
  }

  return (
    <div>
      <div className=" pb-2">
        <div className="table-cell">
          <button className="btn-submit !pl-0 !ml-0" onClick={handleAdd}>
            Add Product Category
          </button>
        </div>
        <div className="table-cell">
          <label
            for="search"
            className="mb-2 text-sm font-medium text-gray-900 sr-only"
          >
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-4 h-4 text-gray-500"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
            <input
              autoFocus
              type="text"
              id="tbSearch"
              placeholder="Filter..."
              className="orders-filter-search block p-2 pl-10 text-sm text-gray-900 
                            border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500;"
              onChange={onQuickFilterChanged}
            ></input>
            <div id="selectedRows" />
          </div>
        </div>
        <div className="table-cell pl-4">
          {message && (
            <div className={`text-center ${messageClass}`}>{message}</div>
          )}
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ height: 600, width: "100%" }}>
        <AgGridReact
          columnDefs={columns}
          rowData={productCodes}
          ref={gridRef}
          gridOptions={gridOptions}
          onRowDoubleClicked={handleRowDoubleClicked}
          quickFilterText={quickFilterText}
          //pagination={true}
          //paginationPageSize={20}
        />
      </div>
      {isModalOpen && currentProductCode && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg w-[600px]">
            <h2 className="text-xl font-bold mb-4">Edit Product Category</h2>
            <EditProductCodeForm
              productCodeData={currentProductCode}
              onProductCodeUpdated={() => {
                closeModal();
                onProductCodeUpdated();
                updateMessage(
                  "Product code updated successfully",
                  "text-green-600"
                );
              }}
              onCancel={closeModal}
            />
          </div>
        </div>
      )}
      {isAddModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-4 rounded-lg w-[600px]">
            <h2 className="text-xl font-bold mb-4">Add Product Category</h2>
            <ProductCodeForm
              onProductCodeAdded={() => {
                closeAddModal();
                handleAddProductCode();
                updateMessage(
                  "Product code added successfully",
                  "text-green-600"
                );
              }}
              onCancel={closeAddModal}
            />
          </div>
        </div>
      )}
    </div>
  );
}
