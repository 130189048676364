import React, {useEffect, useState} from 'react';

import {OrderLinesTable} from '../_reactHelpers/ReactHtmlHelpers.js';

import {
  formatCurrencyDecimal,
  handleFieldChange,
  IdExists,
  ParseIntSafe,
  PreventDefault
} from '../../js/formHelpers.js';

import {createReceiveDataModel} from './_receiveHelpers.js';
import {get, post} from '../../services/apiService.js';
import {Alert, Popover} from "flowbite-react";
import {HiInformationCircle} from "react-icons/hi";
import authService from "../../services/authService";
import { ClickById } from '../../js/helpers.js';

export const ReceivingModal = (props) => {
    const onCloseModal = props.onCloseModal;
    const propsOrderLines = props.orderLines ?? [];

    //TODO: remove when use token
    const [user, setUser] = useState(null);
    const [userId, setUserId] = useState(null);

    const [modalTitle, setModalTitle] = useState("Receive Line");
    const [showSkipButton, setShowSkipButton] = useState(true);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [qtyReceivedMsg, setQtyReceivedMsg] = useState("");
    const [binMsg, setBinMsg] = useState("");

    const [orderId, setOrderId] = useState(0);
    const [orderLineIds, setOrderLineIds] = useState([]);
    const [orderLines, setOrderLines] = useState([]);

    const [receiveData, setReceiveData] = useState(null);
    const [linesReceived, setLinesReceived] = useState(false);
    const [receievedMessages, setReceievedMessages] = useState([]);

    const [receievedOrderLines, setReceievedOrderLines] = useState([]);

    const [currentStoreId, setCurrentStoreId] = useState(0);
    const [currentStoreName, setCurrentStoreName] = useState("");

    // Methods

    function clearMessages() {
        setMessage("");
        setQtyReceivedMsg("");
        setBinMsg("");
        setMessageClass("");
    }

    function onCloseReceivingModal(e, received) {
        //check null e - allow being called from non-button click
        PreventDefault(e);

        //if only one line linesReceived not set due to async
        var hasReceivedLines = linesReceived || received;
        onCloseModal(e, hasReceivedLines);
    }

    async function setupOrderLines() {
        var hasData = false;
        if (propsOrderLines && propsOrderLines.length > 0) {

            var olIds = [];
            propsOrderLines.forEach((ol, index) => {
                if (ol) {
                    olIds.push(ol.orderLineId);
                }
            });
            setOrderLineIds(olIds);

            var olId = await moveNextOrderLineId(olIds);
            if (olId && olId !== 0) {
                var resp = await loadReceivedLines(olId);
                createReceiveData(olId);
                hasData = true;
            }
        }

        if (hasData !== true) {
            //close if no orderLine on load
            onCloseReceivingModal(null);
        }
    }

    async function getCurrentStoreIdInitial() {
        var cStoreId = await authService.getCurrentStoreId();
        cStoreId = ParseIntSafe(cStoreId);
        setCurrentStoreId(cStoreId);

        var cStoreName = await authService.getCurrentStoreName();
        setCurrentStoreName(cStoreName);
    }

    async function moveNextOrderLineId(olIds) {
        var olId = 0;
        setShowSkipButton(false);

        if (!olIds) {
            //get from state if not passed in
            olIds = orderLineIds;
        }

        if (olIds && olIds.length > 0) {
            //get next ID from array
            olId = ParseIntSafe(olIds.slice(0, 1) ?? "0");

            //remove first ID from array
            olIds = olIds.slice(1);

            //show skip button if lines left
            if (olIds && olIds.length > 0) {
                setShowSkipButton(true);
            }
        }

        setOrderLineIds(olIds);
        return olId;
    }

    function createReceiveData(olId) {
        if (propsOrderLines && propsOrderLines.length > 0) {
            var olArr = propsOrderLines.filter((element) => {
                return element.orderLineId === olId;
            });

            if (olArr && olArr.length === 1) {
                var ol = olArr[0];
                var model = createReceiveDataModel(ol);

                // TODO: set Received At Store to current store

                setReceievedMessages(model.messages);
                setReceiveData(model);
            }
        }
    }

    function EnterReceive(e) {
        if (e.key === "Enter") {
            ClickById("btnReceiveAndNext");
        }
    }

    function validateReceive() {
        clearMessages();
        var valid = true;
        var msg = "";

        if (receiveData.quantityReceived > receiveData.quantityRemaining) {
            valid = false;
            setQtyReceivedMsg("Qty Received cannot be more than ordered.");
        }
        if (!receiveData.quantityReceived || receiveData.quantityReceived <= 0) {
            valid = false;
            setQtyReceivedMsg("Qty Receiving is required.");
        }
        if (!receiveData.bin || receiveData.bin === "") {
            valid = false;
            setBinMsg("Bin is required.");
        }

        //if (valid !== true) {
        //    setMessageClass("text-red");
        //    setMessage(msg);
        //}
        return valid;
    }

    async function receiveLine(e, receive) {
        e.preventDefault();
        clearMessages();

        var valid = true;
        //only validate data if receiving
        if (receive === true) {
            valid = validateReceive();
        }

        //if not valid stop all actions
        //error messages displayed
        if (valid === true) {

            var response = null;
            if (receive === true) {
                response = await saveReceivedLine();
            }

            //move to next order line or close modal if last
            var olId = await moveNextOrderLineId();
            if (olId && olId !== 0) {
                loadReceivedLines(olId);
                createReceiveData(olId);
            }
            else {
                //close modal on last received
                onCloseReceivingModal(e, response);
            }
        }
    }

    //TODO: move to inventory page
    async function reprintTag(e) {
        e.preventDefault();
        //createReprintTag();
    }

    const handleChange = (e) => {
        setMessage("");

        var data = handleFieldChange(e);
        if (data) {

            //Bin always capitalized
            if (data.name === "bin") {
                data.value = data.value.toUpperCase();
            }

            //updateMessage("", "");
            setReceiveData((receiveData) => ({
                ...receiveData,
                [data.name]: data.value
            }));
        }
        else {
            //updateMessage(defaultErrMsg, "text-red", "null handleChange data");
        }
    };

    async function saveReceivedLine() {
        var resp = null;
        receiveData.userId = userId;

        var model = JSON.stringify(receiveData);
        const endpoint = 'receiving/ReceiveOrderLine';
        try {
            const response = await post(endpoint, model);
            //console.log('Response: ' + JSON.stringify(response));

            if (response.ok) {
                //post successful
                setLinesReceived(true);
                resp = true;
            }
            else {
                //error
                resp = false;
            }
            const resVal = response.json();
            const id = ParseIntSafe(resVal);
            if (IdExists(id)) {
                setMessage("Line received successfully.");
                setMessageClass("text-green");
                resp = true;
            }
            else {
                resp = false;
            }
        } catch (error) {
            console.log('Error: ' + error);
        }

        return resp;
    }

    async function loadReceivedLines(olId) {

        var resp = false;
        var currOrderId = 0;
        var olArr = [];
        var ol = {};

        if (propsOrderLines && propsOrderLines.length > 0) {

            if (!olId || olId === 0) {
                ol = propsOrderLines[0];
            }
            else {
                olArr = propsOrderLines.filter((element) => {
                    return element.orderLineId === olId;
                });
                if (olArr && olArr.length > 0) {
                    ol = olArr[0];
                }
            }

            if (ol) {
                olId = ol.orderLineId;
                currOrderId = ol.orderId;
            }
            setOrderId(currOrderId);

            const endpoint = 'receiving/GetReceivingLinesSearch?orderId=' + currOrderId;
            try {
                const response = await get(endpoint);
                //console.log('Response: ' + JSON.stringify(response));

                var rows = [];
                if (response && response.dataRows && response.dataRows.length !== 0) {
                    rows = response.dataRows;
                    resp = true;
                }
                setReceievedOrderLines(rows);

            } catch (error) {
                console.error('loadReceivedLines Error:', error);
                resp = false;
            }
        }
        return resp;
    };

    function getMessages() {
        if (receiveData && receiveData.receivedMessages) {
            var msgs = [];

            for (var i = 0; i < receiveData.receivedMessages.length; i++) {
                var msg = receiveData.receivedMessages[i];
                msgs.push(<div>{msg}</div>);
            }

            return msgs;
        }
    }
    function setUserFields() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        var cUserId = 0;
        if (userData) {
            cUserId = userData.userId;
            setUserId(userData.userId);
            setUser(userData);
        }
        return cUserId;
    }

    // ON LOAD
    useEffect(() => {
        setUserFields();
        setupOrderLines();
        getCurrentStoreIdInitial();

        //setTimeout(() => {
        //    SetFocusById("quantityReceived");
        //}, 500);
    }, []);

    const confirmReceiveContentPopover = (
        <div className="grid gap-4">
            <div className="space-y-2">
                <p className="text-sm text-muted-foreground">
                    Do you want to continue?
                </p>
            </div>
            <div className="flex justify-end gap-1">
                <button className="btn-close-modal">No</button>
                <button type="button" className="btn-submit-modal"
                    autoFocus
                    onClick={(e) => receiveLine(e, true)}
                >Yes</button>
            </div>
        </div>
    )

    const confirmReceiveContentAlert = (
        <Alert additionalContent={confirmReceiveContentPopover} color="failure" icon={HiInformationCircle}>
            <h4 className="font-medium leading-none">
                Warning, this item is being received into a different <br />
                warehouse then order line is set for
            </h4>
        </Alert>
    )

    return (
        <div className="min-w-[800px]">
            <div className="">
                {receiveData &&
                    <div>
                        {/*Supplier & Line Info*/}
                        <div className="text-center">
                            <div className="font-bold mb-1 text-xl">
                                {modalTitle}
                                <span> - </span>
                                <span className="text-secondary">{receiveData.poNumberStr}
                                </span>
                                <input type="hidden" value={receiveData.orderLineId}></input>
                            </div>
                            <div>
                                {
                                    receiveData.vendorName !== "" &&
                                    <div>{receiveData.vendorName}</div>
                                }
                                <div>
                                    {receiveData.style}
                                </div>
                                {
                                    receiveData.color !== "" &&
                                    <div>{receiveData.color}</div>
                                }
                            </div>
                        </div>

                        {/*PO fields*/}
                        <div className="pt-4 pl-[20%]">
                            <div className="inline-block align-top text-[14px] text-red text-right pr-[8px] w-[310px]">
                                <div className="mt-[51px] h-[21px]">
                                    {qtyReceivedMsg}
                                </div>
                                <div className="mt-[10px] h-[21px]">
                                    {binMsg}
                                </div>
                            </div>

                            <div className="inline-block">
                                <div className="table w-[240px]">
                                    <div>
                                        <div className="po-col-1">
                                            Ship To Location
                                        </div>
                                        <div className="po-col-2">
                                            {receiveData.warehouseName}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="po-col-1">
                                            Qty Ordered
                                        </div>
                                        <div className="po-col-2 text-left">
                                            {receiveData.quantityOrdered}
                                        </div>
                                    </div>
                                    <div>
                                        <div className="po-col-1">
                                            Qty Receiving
                                        </div>
                                        <div className="po-col-2">
                                            <input type="number" className="inp-quantity-modal mark mr-2"
                                                id="quantityReceived" name="quantityReceived"
                                                onKeyUp={(e) => EnterReceive(e)}
                                                onChange={handleChange}
                                                value={receiveData.quantityReceived}
                                            />
                                            {/*{poData.units && poData.units !== "" &&*/}
                                            {/*    <span className="pr-4 text-primary font-bold">{poData.units}</span>*/}
                                            {/*}*/}
                                            {/*<span className="pl-2">{poData.serialNo}</span>*/}
                                        </div>
                                    </div>
                                    {/*<div>*/}
                                    {/*    <div className="po-col-1">*/}
                                    {/*        Date Received*/}
                                    {/*    </div>*/}
                                    {/*    <div className="po-col-2">*/}
                                    {/*        {receiveData.dateReceived}*/}
                                    {/*        */}{/*<input type="date" className="order-date-modal mark"*/}
                                    {/*        */}{/*    id="dateReceived" name="dateReceived"*/}
                                    {/*        */}{/*    onChange={handleChange}*/}
                                    {/*        */}{/*    value={receiveData.dateReceived}*/}
                                    {/*        */}{/*/>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}

                                    {true && //IsFalse(receiveData.isStock) &&
                                        <div>
                                            <div className="po-col-1">
                                                Bin
                                            </div>
                                            <div className="po-col-2">
                                                <input type="text" className="inp-quantity-modal mark"
                                                    id="bin" name="bin"
                                                    autoFocus
                                                    onKeyUp={(e) => EnterReceive(e)}
                                                    onChange={handleChange}
                                                    value={receiveData.bin}
                                                />
                                            </div>
                                        </div>
                                    }

                                    {/*<div>*/}
                                    {/*    <div className="po-col-1">*/}
                                    {/*        Create Tag*/}
                                    {/*    </div>*/}
                                    {/*    <div className="po-col-2">*/}
                                    {/*        <input className="inp-cb mark" type="checkbox"*/}
                                    {/*            id="createTag" name="createTag"*/}
                                    {/*            checked={receiveData.createTag}*/}
                                    {/*            onChange={handleChange}*/}
                                    {/*        />*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    <div>
                                        <div className="po-col-1">
                                            Received At
                                        </div>
                                        <div className="po-col-2">
                                            {currentStoreName ?? ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="align-bottom text-center pt-4 pb-2">       
                                
                                    <button type="button" className="btn-close-modal mr-2"
                                        onClick={(e) => onCloseReceivingModal(e)}>
                                        Cancel
                                    </button>
                                    {showSkipButton === true &&
                                        <button type="button" className="btn-submit-modal mr-2"
                                            onClick={(e) => receiveLine(e, false)}>
                                            Skip
                                        </button>
                                    }
                                    {
                                        currentStoreId !== receiveData.warehouseId ?
                                            <Popover content={confirmReceiveContentAlert}>
                                                <button id="btnReceiveAndNext" type="button"
                                                    className="btn-submit-modal">
                                                    Receive
                                                </button>
                                            </Popover>
                                            :
                                            <button id="btnReceiveAndNext" type="button"
                                                className="btn-submit-modal"
                                                onClick={(e) => receiveLine(e, true)}>
                                                Receive
                                            </button>
                                    }
                                    {/*<button type="button" className="btn-close-modal mr-2"*/}
                                    {/*    onClick={(e) => reprintTag(e)}*/}
                                    {/*>*/}
                                    {/*    Re-print Tag*/}
                                    {/*</button>*/}
                                    
                                </div>
                            </div>

                            <div className="inline-block align-top">
                                <div className="text-secondary text-[14px] mt-[24px] h-[21px]">
                                    {
                                        <div className="text-red">
                                            <span>Qty {formatCurrencyDecimal(receiveData.quantityRemaining)} </span>
                                            <span>left to receive.</span>
                                        </div>
                                    }
                                </div>
                                <div className="text-secondary text-[14px] mt-[6px] h-[21px]">
                                    {getMessages()}
                                </div>
                                <div className={"inline-block pt-[83px] pl-2 " + messageClass}>
                                    {message}
                                </div>
                            </div>

                        </div>
                    </div>
                }

                {/*** Received Order Lines ***/}
                <div className="min-h-[100px] p-2 pt-3">
                    <OrderLinesTable orderLines={receievedOrderLines} type="receiving-modal"></OrderLinesTable>
                </div>
            </div>
        </div>
    );
}