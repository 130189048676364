import { StringEmpty } from "../../js/formHelpers";

export function accountModel() {
  var accountModel = {};
  accountModel.accountId = 0;
  accountModel.name = "";
  accountModel.accountNumber = "";
  accountModel.description = "";
  accountModel.notes = "";
  accountModel.address1 = "";
  accountModel.address2 = "";
  accountModel.city = "";
  accountModel.state = "";
  accountModel.zip = "";
  accountModel.phone1 = "";
  accountModel.phone2 = "";
  accountModel.email1 = "";
  accountModel.email2 = "";
  accountModel.businessLicense = "";
  accountModel.taxIdNumber = "";
  accountModel.ownerName = "";
  accountModel.ownerName2 = "";
  accountModel.creditRatingId = 0;
  accountModel.accountTypeId = 0;
  accountModel.termsId = 0;
  accountModel.termsDaysId = 0;
  accountModel.taxTypeId = 0;
  accountModel.statementTypeId = 0;
  accountModel.resellerPermitExpirationStr = null;
  accountModel.storeId = 0;
  accountModel.statementEmail = "";
  accountModel.materialsEmail = "";
  accountModel.materialsPhone = "";
  accountModel.creditHold = false;
  accountModel.samplesAccount = false;
  accountModel.inactive = false;
  //commissions
  accountModel.payToName = "";
  accountModel.payToAddress1 = "";
  accountModel.payToAddress2 = "";
  accountModel.payToCity = "";
  accountModel.payToState = "";
  accountModel.payToZip = "";
  accountModel.doNotPayCommissions = false;
  accountModel.isW9 = false;
  accountModel.is1099 = false;
  accountModel.isElectronic1099 = false;
  accountModel.isElectronicRemittance = false;
  //non-editable
  accountModel.address = null;
  accountModel.enteredByName = "";
  accountModel.lastOrderDateStr = "";
  accountModel.saveTabType = "";
  return accountModel;
}

export function createAccountModel(accountData) {
  var model = new accountModel();

  // set data for POST
  if (accountData) {
    model.accountId = accountData.accountId;
    model.name = accountData.name;
    model.accountNumber = accountData.accountNumber;
    model.description = accountData.description;
    model.notes = accountData.notes;
    model.storeId = accountData.storeId;
    model.address1 = accountData.address1;
    model.address2 = accountData.address2;
    model.city = accountData.city;
    model.state = accountData.state;
    model.zip = accountData.zip;
    model.phone1 = accountData.phone1;
    model.phone2 = accountData.phone2;
    model.email1 = accountData.email1;
    model.email2 = accountData.email2;
    model.businessLicense = accountData.businessLicense;
    model.taxIdNumber = accountData.taxIdNumber;
    model.ownerName = accountData.ownerName;
    model.ownerName2 = accountData.ownerName2;
    model.creditRatingId = accountData.creditRatingId;
    model.accountTypeId = accountData.accountTypeId;
    model.accountTypeOther = accountData.accountTypeOther;
    model.termsId = accountData.termsId;
    model.termsDaysId = accountData.termsDaysId;
    model.taxTypeId = accountData.taxTypeId;
    model.statementTypeId = accountData.statementTypeId;
    model.defaultPriceLevelId = accountData.defaultPriceLevelId;

    if (StringEmpty(accountData.resellerPermitExpiration)) {
      // non-required field set to null if empty for POST
      model.resellerPermitExpirationStr = null;
      model.resellerPermitExpirationStrDtPckr = null;
      model.resellerPermitExpiration = null;
    } else {
      // use string field to populate form data
      model.resellerPermitExpirationStr =
        accountData.resellerPermitExpirationStr;
      model.resellerPermitExpirationStrDtPckr =
        accountData.resellerPermitExpirationStrDtPckr;
      // use DateTime field for POST to auto convert and bind
      model.resellerPermitExpiration = accountData.resellerPermitExpiration;
    }

    model.statementEmail = accountData.statementEmail;
    model.materialsEmail = accountData.materialsEmail;
    model.materialsPhone = accountData.materialsPhone;
    model.creditHold = accountData.creditHold;
    model.samplesAccount = accountData.samplesAccount;
    model.inactive = accountData.inactive;
    // commissions
    model.payToName = accountData.payToName ?? "";
    model.payToAddress1 = accountData.payToAddress1 ?? "";
    model.payToAddress2 = accountData.payToAddress2 ?? "";    
    model.payToCity = accountData.payToCity ?? "";
    model.payToState = accountData.payToState ?? "";
    model.payToZip = accountData.payToZip ?? "";
    model.doNotPayCommissions = accountData.doNotPayCommissions ?? false;
    model.isW9 = accountData.isW9 ?? false;
    model.is1099 = accountData.is1099 ?? false;
    model.isElectronic1099 = accountData.isElectronic1099 ?? false;
    model.isElectronicRemittance = accountData.isElectronicRemittance ?? false;
    // non-editable
    model.enteredByName = accountData.enteredByName;
    model.lastOrderDateStr = accountData.lastOrderDateStr;
    model.saveTabType = accountData.saveTabType;
    model.createdDateTimeStr = accountData.createdDateTimeStr;
  }

  return model;
}
