import { GetRemainder, GreaterThan } from '../../../js/calculationHelpers.js';
import { Exists, formatCurrencyDecimal, IdExists, NotEmpty, NotExists } from '../../../js/formHelpers.js';
import { } from '../../../js/helpers.js';

export function accountModel() {
    var model = {};
    model.accountId = 0;
    model.accountName = "";
    model.accountAddressId = 0;    
    model.termsId = 0;
    model.address1 = "";
    model.address2 = "";
    model.city = "";
    model.state = "";
    model.zip = "";    
    //non-post fields
    model.materialNotificationPhone = "";
    model.materialNotificationEmail = "";
    model.samplesAccount = false;
    model.accountEmail = "";
    model.accountPhone = "";
    return model;
}

export function createAccountModel(orderData, accountData) {
    var model = new accountModel();
    
    if (orderData) {
        model.accountId = orderData.accountId;
        model.accountName = orderData.account.name;
        model.materialNotificationPhone = orderData.account.materialsPhone;
        model.materialNotificationEmail = orderData.account.materialsEmail;

        model.accountAddressId = orderData.accountAddress.addressId;
        model.address1 = orderData.accountAddress.address1;
        model.address2 = orderData.accountAddress.address2;
        model.city = orderData.accountAddress.city;
        model.state = orderData.accountAddress.state;
        model.zip = orderData.accountAddress.zip;
        model.termsId = orderData.termsId;

        model.accountEmail = orderData.accountEmail;
        model.accountPhone = orderData.accountPhone;

        //set for new Order
        if (Exists(orderData.accountData)) {
            model.priceLevelId = orderData.accountData.defaultPriceLevelId;
            model.samplesAccount = orderData.accountData.samplesAccount;
        }        
    }
    else if (accountData) {
        model.accountId = accountData.accountId;
        model.accountName = accountData.name;
        model.materialNotificationPhone = accountData.materialsPhone;
        model.materialNotificationEmail = accountData.materialsEmail;

        model.accountAddressId = accountData.addressId;
        model.address1 = accountData.address1;
        model.address2 = accountData.address2;
        model.city = accountData.city;
        model.state = accountData.state;
        model.zip = accountData.zip;
        model.samplesAccount = accountData.samplesAccount;
        model.termsId = accountData.termsId;
        model.defaultPriceLevelId = accountData.defaultPriceLevelId;

        model.accountEmail = accountData.email1;
        model.accountPhone = accountData.phone1;
    }
    return model;
}

export function jobModel() {
    var model = {};
    model.orderId = 0;
    model.jobName = "";
    model.jobAddress1 = "";
    model.jobAddress2 = "";
    model.jobCity = "";
    model.jobState = "";
    model.jobZip = "";
    model.jobNotes = "";

    model.jobPlacedByName = "";
    model.jobPlacedByEmail = "";
    model.jobPlacedByPhone = "";    
    model.jobMaterialNotificationPhone = "";
    model.jobMaterialNotificationEmail = "";
    // set for post on order create
    model.jobMaterialNotificationEmails = [];
    model.jobMaterialNotificationEmailItems = [];
    model.jobMaterialNotificationPhones = [];
    // set from account on order create
    model.accountJobMaterialNotificationEmail = "";
    model.jobStoreId = 0;

    // non-post fields
    model.samplesAccount = false;
    return model;
}

export function createJobModel(orderData, jobData) {
    var model = new jobModel();

    if (orderData) {
        //set from Order data for modal        
        if (IdExists(orderData.orderId) && Exists(orderData.jobModel))
        {
            model = orderData.jobModel;
            model.jobMaterialNotificationEmailItems = [];

            if (NotEmpty(model.jobMaterialNotificationEmails)) {
                model.jobMaterialNotificationEmails.forEach((email, index) => {
                    // create list items for MultiSelect
                    model.jobMaterialNotificationEmailItems.push({
                        label: email,
                        value: email
                    });
                });
            }
        }
        else {
            // only used on new Order
            model.jobStoreId = orderData.storeId;

            //TODO: Make work for multiple emails
            //if(NotEmpty(orderData.materialNotificationEmails)) {
            //    var emailArr = [];
            //    orderData.materialNotificationEmails.forEach((email) => {
            //        emailArr.push({ label: email, value: email });
            //    });
            //}

            if (NotEmpty(orderData.materialNotificationEmail)) {
                model.accountJobMaterialNotificationEmail = orderData.materialNotificationEmail;

                model.jobMaterialNotificationEmailItems = [{
                    label: orderData.materialNotificationEmail,
                    value: orderData.materialNotificationEmail
                }];
            }

            if (NotEmpty(orderData.materialNotificationPhone)) {
                model.jobMaterialNotificationPhone = orderData.materialNotificationPhone;

            }
        }
    }
    return model;
}

export function poModel() {
    var model = {};
    model.orderLineId = 0;
    model.poQuantity = "";
    model.quantity = "";
    model.promDate = "";
    model.referenceNumber = "";
    model.warehouseId = 0;

    //non-editable fields
    model.lineNumberStr = "";    
    model.poQuantityReceived = "";    
    model.quantityReceived = "";
    model.cost = 0;
    model.totalCost = 0;     
    model.units = "";
    model.deliveredDate = "";
    model.orderedBy = "";
    model.pickupPrintedName = "";
    model.statusId = 0;
    model.showLocationData = false;
    model.showReceivedStore = false;
    model.warehouseName = "";
    model.receivedStoreName = "";
    model.type = "";

    model.width = 0;
    model.length = 0;
    model.isRoll = false;
    model.isItem = false;
    model.isStock = false;
    model.stockQtyAvailable = 0;

    //sellQuantity used for product min qty
    model.sellUnit = "";
    model.sellQuantity = 0;

    return model;
}

export function createPoModel(orderData, lineData, poData, post, type) {
    var model = new poModel();
    model.type = type;

    if (post === true && poData) {
        //model.orderLineId = poData.orderLineId;
        //model.orderedBy = poData.orderedBy;
        //model.takenBy = poData.takenBy;
        //model.quantity = poData.quantity;
        //model.shipDate = poData.shipDate;
        //model.promDate = poData.promDate;
        //model.deliveredDate = poData.deliveredDate;
    }
    else {
        //TODO: default values
        //var dateStr = FormatDateLong(new Date());
        //model.shipDate = data.shipDate;
        //model.promDate = data.promDate;
        //model.deliveredDate = data.deliveredDate;

        if (lineData) {
            //set from line data if po created
            model.orderLineId = lineData.orderLineId;
            model.lineNumberStr = lineData.lineNumberStr;
            model.statusId = lineData.statusId;
            model.statusStr = lineData.statusStr;
            model.takenBy = lineData.takenBy;
            model.warehouseId = lineData.warehouseId;

            if (type === "create") {
                model.poQuantity = lineData.quantity;
            }
            //else if (type === "edit" || type === "view")
            else {
                model.poQuantity = lineData.poQuantity;
            }

            model.poQuantityReceived = lineData.poQuantityReceived;
            model.quantity = lineData.quantity;
            model.quantityReceived = lineData.quantityReceived;

            //model.promDate = lineData.promiseDateInputStr;
            model.promDate = lineData.promiseDateInputStrDtPckr;

            model.receivedDate = lineData.receivedDateStr;
            model.deliveredDate = lineData.deliveredDateInputStr;

            model.referenceNumber = lineData.poReferenceNumber;
            model.orderedBy = lineData.orderedBy;

            model.cost = lineData.cost;
            var total = model.cost * model.quantity;
            total = formatCurrencyDecimal(total);
            model.totalCost = total;

            model.pickupPrintedName = lineData.pickupPrintedName;
            model.units = lineData.unitsStr;
            
            model.showLocationData = lineData.showLocationData;
            model.showReceivedStore = lineData.showReceivedStore;
            model.warehouseName = lineData.warehouseName;
            model.receivedStoreName = lineData.receivedStoreName;

            model.sellQuantity = lineData.sellQuantity;
            model.sellUnit = lineData.sellUnit;

            model.width = lineData.width;
            model.length = lineData.length;
            model.isRoll = lineData.isRoll;
            model.isItem = lineData.isItem;
            model.isStock = lineData.isStock;
            model.stockQtyAvailable = lineData.productStockModel.quantityAvailable;
        }

        //set defaults from order data if po not created
        if (orderData) {
            if (!lineData) {
                model.warehouseId = lineData.warehouseId;
            }
            model.orderedBy = orderData.orderedBy;
            model.takenBy = orderData.takenBy;
        }
    }
    return model;
}

export function olModel() {
    var model = {};        
    model.categoryName = "";
    model.vendorName = "";

    model.productCategoryId = 0;
    model.vendorId = 0;    
    model.statusId = 0;
    model.warehouseId = 0;
    //model.priceLevelId = 0;
           
    model.style = "";
    model.color = "";    
    model.units = "";
    model.boxQty = "";
    model.quantity = "";
    model.price = "";
    model.cost = "";
    model.comments = "";       
    
    //sellQuantity used for product min qty
    model.sellUnit = "";
    model.sellQuantity = 0;
    //model.stockQuantity = 0;
    //model.buyUnit = "";
    //model.buyQuantity = 0;

    model.pcWeight = 0;
    model.width = 0;
    model.length = 0;

    model.freightIncluded = false;        
    model.isRoll = false;
    model.isItem = false;
    model.isStock = false;
    model.quantityAvailable = 0;

    //post models
    model.orderId = 0;
    model.orderLineId = 0;
    model.promiseDate = null;
    model.poReferenceNumber = "";
    model.comments = "";

    //products
    model.productId = 0;
    model.productPriceId = 0;

    //custom
    model.referral = 0;
    model.multiplier = 1;
    model.isCustom = false;

    //all    
    model.type = "";

    return model;
}

export function createAddOlModel(type, storeId, orderId, selectedProduct, selectedProductPrice) {
    var model = new olModel();

    //defaults for all types
    model.type = type ?? "";
    model.orderId = orderId ?? 0;
    model.warehouseId = storeId ?? 0;    

    if (Exists(selectedProduct)) {
        model.productId = selectedProduct.productId;
        model.productCategoryId = selectedProduct.categoryId;
        model.categoryName = selectedProduct.categoryName;
        model.pcWeight = selectedProduct.pcWeight;
        model.vendorId = selectedProduct.vendorId;
        model.vendorName = selectedProduct.vendorName
        model.style = selectedProduct.style;
        model.units = selectedProduct.units;

        model.sellQuantity = selectedProduct.sellQuantity;
        model.sellUnit = selectedProduct.sellUnit;
        
        model.isRoll = selectedProduct.isRoll;
        model.isItem = selectedProduct.isItem;
        model.isStock = selectedProduct.isStock;

        if (Exists(selectedProductPrice)) {
            model.productPriceId = selectedProductPrice.productPriceId;
            model.color = selectedProductPrice.color;
            model.price = selectedProductPrice.priceBc;
            model.cost = selectedProductPrice.cost;
            model.boxQty = selectedProductPrice.serialNo;

            model.width = selectedProductPrice.rollWidth;
            model.length = selectedProductPrice.rollLength;

            model.multiplier = selectedProductPrice.priceBcMultiplier;

            if (NotEmpty(selectedProductPrice.productStockModels)) {
                model.quantityAvailable = selectedProductPrice.productStockModels[0].quantityAvailable ?? 0;
            }
        }        
    }

    if (type === "Products") {
        model.statusId = 1;
    }
    //else if (type === "Custom") {
    //    model.statusId = 0;

    //    if (selectedProductPrice) {
    //        model.multiplier = selectedProductPrice.priceBcMultiplier;
    //    }
    //}
    //else if (type === "Stock") {
    //    //model.statusId = 2;
    //}
    return model;
}

export function createEditOlModel(olData) {
    var model = new olModel();

    //edit line
    if (olData && olData !== null) {
        //editable order line fields
        model.orderId = olData.orderId;
        model.orderLineId = olData.orderLineId;        
        model.productCategoryId = olData.productCategoryId;

        model.vendorId = olData.vendorId;
        model.style = olData.style;        
        model.color = olData.color;
        model.width = olData.width;
        model.length = olData.length;
        model.quantity = olData.quantity;
        model.units = olData.units;        
        model.cost = olData.cost;
        model.multiplier = olData.multiplier;
        model.price = olData.price;

        model.bin = olData.bin;
        model.comments = olData.comments;
        model.warehouseId = olData.warehouseId;
        model.statusId = olData.statusId;

        model.promiseDate = olData.promiseDate;
        model.poReferenceNumber = olData.poReferenceNumber;
        model.isCustom = olData.isCustom;
    }
    return model;
}

export function pickupDataModel() {
    var model = {};
    model.pickupName = "";
    model.pickupSignature = "";
    model.pickupOrderLineIds = [];
}

export function createPickupDataModel(pickupName, pickupSignature, pickupOrderLineIds) {
    var model = new pickupDataModel();
    if (pickupName) {
        model.pickupName = pickupName;
    }
    if (pickupSignature) {
        model.pickupSignature = pickupSignature;
    }
    if (pickupOrderLineIds) {
        model.pickupOrderLineIds = pickupOrderLineIds;
    }    
    return model;
}


export function DoMinQtyCheck(cQuantity, cAllowQtyOverride, allowQtyOverride, quantity, sellQuantity, sellUnit, isRoll) {

    if (NotExists(cAllowQtyOverride)) {
        cAllowQtyOverride = allowQtyOverride;
    }

    // only check min qty for items
    // dont check if qty override allowed
    if (isRoll === false && cAllowQtyOverride === false) {

        if (!Exists(cQuantity)) {
            cQuantity = quantity;
        }
        cQuantity = formatCurrencyDecimal(cQuantity);
        var dSellQuantity = formatCurrencyDecimal(sellQuantity);

        // only check non-zero qty, has sellQuantity/sellUnit
        if (dSellQuantity > 0 && sellUnit !== "") {

            //check non-multiple of sell quantity
            var rem = GetRemainder(cQuantity, dSellQuantity);
            if (rem !== 0) {
                return rem;
            }
        }
    }
    // if 0 returned here or from 'rem' qty check not needed
    return 0;
}

export function GetBoxQtyStr(sellQuantity, sellUnit, units) {
    var bxQtyStr = ""
    if (GreaterThan(sellQuantity, 1) && NotEmpty(sellUnit)) {
        bxQtyStr = formatCurrencyDecimal(sellQuantity) + " " + units + " / " + sellUnit;
    }
    return bxQtyStr;
}