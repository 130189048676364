import React, { useEffect, useState } from 'react';

import { GetValueById } from '../../../js/helpers.js';
import { IdExists, ParseIntSafe, PreventDefault } from '../../../js/formHelpers.js';

import { VendorsGrid } from '../../Vendors/VendorsGrid';

export const AddVendorModal = (props) => {
    const onCloseModal = props.onCloseModal;
    const propsOrderId = props.orderId;
    const propsIsClaim = props.isClaim;

    const [modalClass, setModalClass] = useState("");
    const [modalTitle, setModalTitle] = useState("");

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");

    //Methods
    function selectVendor(e) {
        PreventDefault(e);

        //selected vendor id
        var selectedVendorId = GetValueById("hfSelectedVendorId")
        var intVendorId = ParseIntSafe(selectedVendorId);
        if (IdExists(intVendorId)) {
            onClose(e, intVendorId);
        }
        else {
            setMessageClass("text-red");
            setMessage("Please select a Vendor.");            
        }
    }

    function onClose(e, vendorId) {
        PreventDefault(e);
        onCloseModal(e, vendorId);
    }

    // ON LOAD
    useEffect(() => {        
    }, []);

    return (        
        <div className={"vendors-grid-content " + modalClass}>
            <input id="hfSelectedVendorId" type="hidden" value=""></input>

            <div className="text-center">
                <div className="font-bold mb-1 text-xl">
                    {modalTitle}
                </div>
            </div>
            <div className="p-1 min-h-[800px]">
                <VendorsGrid pageType="claim"></VendorsGrid>
            </div>
            <div className="align-bottom text-right pt-[10px]">
                <div className={"inline-block mr-3 " + messageClass}>
                    {message}
                </div>
                <div className="inline-block">
                    {propsOrderId === 0 && propsIsClaim === true &&
                        <span>
                            <a href="/Claims" className="btn-cancel">
                                Cancel New Claim
                            </a>
                        </span>
                    }
                    {propsOrderId !== 0 &&
                        <button type="button" className="btn-cancel"
                            onClick={(e) => onClose(e)}>
                            Cancel
                        </button>
                    }
                    <button type="button" id="btnOrderSelectVendor" className="btn-submit-modal ml-2"
                        onClick={(e) => selectVendor(e)}>
                        Select Vendor
                    </button>                                        
                </div>
            </div>
        </div>
    );
}
