import React, { useState } from 'react';
import { handleFieldChange } from '../../../js/formHelpers';

export function BatchPreferencesForm({ bankAccounts, handleBatchPreferences }) {

    const [bankAccount, setBankAccount] = useState({});
    const [checkNumber, setCheckNumber] = useState(1);

    const handleChange = (e) => {
        const data = handleFieldChange(e);
        if (data) {
            if (data.name === 'bankAccount') {
                setBankAccount(bankAccounts.find(bank => bank.bankId === parseInt(data.value)) ?? {});
            }
            else if (data.name === 'checkNumber') {
                setCheckNumber(data.value);
            }
        }
    };

    function handleContinue() {
        if (bankAccount.bankId == null) {
            alert('You must select a Bank account');
        }
        else {
            handleBatchPreferences(bankAccount, checkNumber);
        }
    }

    return (
        <div>
            <div className="w-full pt-4">
                <div>
                    <label className="label table-cell label-text text-base font-bold w-[200px]">Starting Check Number:</label>
                    <div className="table-cell">
                        <input type="text" name="checkNumber"
                            className="input input-bordered border-black h-8 py-4 px-2 w-[100px] opacity-75"
                            value={checkNumber}
                            onChange={handleChange}
                            required
                        />
                    </div>
                </div>
                <div className="pt-4">
                    <label className="label table-cell label-text text-base font-bold w-[200px]">Bank Account:</label>
                    <div className="table-cell">
                        <select name="bankAccount" className="input-bordered border rounded-lg"
                            value={bankAccount.bankId}
                            onChange={handleChange}>
                            <option>Select...</option>
                            {bankAccounts.map(bankAccount =>                                
                                <option value={bankAccount.bankId}>{`${bankAccount.bankName} - ${bankAccount.bankAccountName}`}</option>)}
                        </select>
                    </div>
                </div>
            </div>
            <div className="pt-8 text-right">
                <button className='btn-submit !mr-0' type="button" onClick={e => handleContinue()}>Continue</button>
            </div>
        </div>
    );
}