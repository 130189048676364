import {useState} from "react";

export default function PrinterModal({ setShowPrinterModal, processPrintCheck }) {
  const [selectedPrinter, setSelectedPrinter] = useState(null);

  const printers = [
    { id: 1, name: 'Printer 1' },
    { id: 2, name: 'Printer 2' },
    { id: 3, name: 'Printer 3' },
    { id: 4, name: 'Printer 4' },
    { id: 5, name: 'Printer 5' },
  ];

  const handleSelectChange = (event) => {
    setSelectedPrinter(Number(event.target.value));
  };

  return (
    <div className="w-full">
      <label htmlFor="printerSelect" className="block text-sm font-medium text-gray-700 mb-2">
        Select a printer:
      </label>
      <select
        id="printerSelect"
        value={selectedPrinter}
        onChange={handleSelectChange}
        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
      >
        <option value="" disabled>Select an option</option>
        {printers.map((printer) => (
          <option key={printer.id} value={printer.id}>
            {printer.name}
          </option>
        ))}
      </select>

      <div className="flex gap-1 justify-end">
        <button className="btn-small-red mt-2" onClick={() => setShowPrinterModal(false)}>Cancel</button>
        <button className="btn-small mt-2" onClick={() => { processPrintCheck() }}>Print</button>
      </div>
    </div>
  );
}