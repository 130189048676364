import React, { useState, useCallback, useEffect, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { EntityAutocomplete } from '../_reactHelpers/EntityAutocomplete';
import {
    AgCurrencyMxWdSm, AgIntMxWdSm, defaultErrMsg, Exists, ParseIntSafe,
    GetDateDiffDays, GetDateNow, GetDisplayCurrencyStr, GetDisplayDecimalStr,
    GetDisplayIntStr, GetInputDate, handleFieldChange, IdExists, IsFalse,
    isSelectedDropdown, IsTrue, NotEmpty, NotStringEmpty, GetInputDecimalStr, FormatNumberDecimal, IsEmpty, EnforceNumDecimalPlaces,
} from '../../js/formHelpers';

import { get, post } from '../../services/apiService';
import { Equals, GreaterThan, NotEquals } from '../../js/calculationHelpers';

export const AddEditSupplierBillModal = ({ initialBillData, handleSubmit, stores, onHide, }) => {
    //isContentCentralBill, ccOrderId, ccVendorId, ccOrderLines 

    const vendorAcRef = useRef();

    //// process Content Central payables 
    //isContentCentralBill = IsTrue(isContentCentralBill);
    //ccOrderId = ccOrderId ?? 0;
    //ccVendorId = ccVendorId ?? 0;
    //ccOrderLines = ccOrderLines ?? [];

    const isEditMode = IdExists(initialBillData.billId);
    const [localFormData, setLocalFormData] = useState(initialBillData);

    const [vendors, setVendors] = useState([]);
    //const [lineTotalAmount, setLineTotalAmount] = useState(0);

    const gridRef = useRef();
    const defaultColDef = {
        flex: 1,
        minWidth: 100,
        editable: true,
        singleClickEdit: true,
        stopEditingWhenCellsLoseFocus: true,
        sortable: false,
        resizable: true,
        filter: false,
        suppressMenu: true,
        suppressMovable: true,
        cellClass: ["no-border"]
    };

    const handleDeleteRow = (params) => {
        const updatedLineItems = localFormData.billLineItems
            .filter((item, index) => index !== params.node.rowIndex);
        setLocalFormData(prevData => ({
            ...prevData,
            billLineItems: updatedLineItems
        }));
    };

    const columnDefs = [
        {
            field: 'lineNumber', headerName: 'Line #', maxWidth: AgIntMxWdSm(),
            editable: false,
            valueFormatter: params => GetDisplayIntStr(params.value)
        },
        {
            field: 'description', headerName: 'Style',
        },
        {
            field: 'details', headerName: 'Color',
        },
        {
            headerName: 'Is Freight', field: 'isFreightLine', maxWidth: 90,
            //editable: true,
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
        },
        {
            field: 'quantity', headerName: 'Quantity', maxWidth: AgCurrencyMxWdSm(),
            //editable: true,
            valueFormatter: params => GetDisplayDecimalStr(params.value)
        },
        {
            field: 'cost', headerName: 'Cost', maxWidth: AgCurrencyMxWdSm(),
            //editable: true,
            valueFormatter: params => GetDisplayCurrencyStr(params.value)
        },
        {
            field: 'total', headerName: 'Total', maxWidth: AgCurrencyMxWdSm(),
            //editable: true,
            valueFormatter: params => GetDisplayCurrencyStr(params.value)
        },
        {
            headerName: '', field: '', maxWidth: 80,
            editable: false,
            suppressNavigable: true,
            cellRenderer: (params) => {
                return (
                    <button onClick={() => handleDeleteRow(params)}
                        className="btn-grid-red" tabIndex="-1"
                    >Delete</button>
                );
            },
        }
    ];

    const onCellValueChanged = useCallback((event) => {
        const updatedLineItems = [...localFormData.billLineItems];
        const updatedRow = { ...updatedLineItems[event.rowIndex] };
        updatedRow[event.colDef.field] = event.newValue;

        // update line values
        if (event.colDef.field === "quantity" || event.colDef.field === "cost") {
            let qty = parseFloat(updatedRow["quantity"] ?? 0);
            let cost = parseFloat(updatedRow["cost"] ?? 0);
            let total = parseFloat(qty * cost);
            total = FormatNumberDecimal(total);
            updatedRow["total"] = total;
        }
        else if (event.colDef.field === "total") {
            let qty = parseFloat(updatedRow["quantity"] ?? 0);
            let total = parseFloat(updatedRow["total"] ?? 0);

            let cost = 0;
            if (GreaterThan(qty, 0)) {
                cost = parseFloat(total / qty);
            }
            cost = FormatNumberDecimal(cost);
            updatedRow["cost"] = cost;
        }
        updatedLineItems[event.rowIndex] = updatedRow;

        // update bill values
        var billTotal = parseFloat(0);
        var nonDiscAmt = parseFloat(0);
        var discountRate = parseFloat(localFormData.discRate) ?? 0;
        var additionalCharges = parseFloat(localFormData.additionalCharges) ?? 0;

        updatedLineItems.forEach((ol, index) => {
            billTotal += parseFloat(ol.total);

            if (IsTrue(ol.isFreightLine)) {
                nonDiscAmt += parseFloat(ol.total);
            }
        });

        // calculate
        var amtDisc = parseFloat((billTotal - nonDiscAmt));
        var discAmt = amtDisc * parseFloat(discountRate / 100);
        //format        
        var adjustedTotal = GetInputDecimalStr((billTotal - discAmt + additionalCharges));
        billTotal = GetInputDecimalStr(billTotal);
        nonDiscAmt = GetInputDecimalStr(nonDiscAmt);
        amtDisc = GetInputDecimalStr(amtDisc);
        discAmt = GetInputDecimalStr(discAmt);
        additionalCharges = GetInputDecimalStr(additionalCharges);

        // update form data
        setLocalFormData(prevData => ({
            ...prevData,
            billTotal: billTotal,
            nonDiscAmt: nonDiscAmt,
            amtDisc: amtDisc,
            discAmt: discAmt,
            adjustedTotal: adjustedTotal,
            additionalCharges: additionalCharges,
            billLineItems: updatedLineItems
        }));
    }, [localFormData.billLineItems, setLocalFormData, localFormData.discRate, localFormData.additionalCharges]);

    const addNewRow = useCallback(() => {
        setLocalFormData(prevData => {

            const newLineNumber = (prevData.billLineItems?.length || 0) + 1;
            const newRow = {
                lineNumber: newLineNumber,
                description: '',
                details: '',
                quantity: 0,
                cost: 0,
                isFreightLine: false,
                freight: 0,
                total: 0
            };
            return {
                ...prevData,
                billLineItems: [
                    ...(prevData.billLineItems || []),
                    newRow
                ]
            };
        });
    }, []);

    const handleChange = (e) => {
        var data = handleFieldChange(e);
        if (data) {
            var name = data.name;
            var value = data.value;

            var dueDate = localFormData.dueDate;
            var adjustedTotal = localFormData.adjustedTotal;
            var discAmt = localFormData.discAmt;
            var amtDisc = localFormData.amtDisc;
            var discRate = localFormData.discRate;
            var billTotal = localFormData.billTotal;
            var additionalCharges = localFormData.additionalCharges;

            if (name === 'discRate') {
                discRate = EnforceNumDecimalPlaces(value, 2);
            }
            if (name === 'additionalCharges') {
                additionalCharges = EnforceNumDecimalPlaces(value, 2);
            }

            if (name === 'billTotal' || name === 'nonDiscAmt' || name === 'discRate' || name === 'additionalCharges') {
                discRate = parseFloat(name === 'discRate' ? discRate : localFormData.discRate) || 0;
                additionalCharges = parseFloat(name === 'additionalCharges' ? additionalCharges : localFormData.additionalCharges) || 0;
                billTotal = parseFloat(name === 'billTotal' ? value : localFormData.billTotal) || 0;
                const nonDiscAmt = parseFloat(name === 'nonDiscAmt' ? value : localFormData.nonDiscAmt) || 0;
                // calculate
                amtDisc = parseFloat(billTotal - nonDiscAmt);
                discAmt = amtDisc * parseFloat(discRate / 100);
                // format
                adjustedTotal = GetInputDecimalStr(billTotal - discAmt + additionalCharges);
                billTotal = GetInputDecimalStr(billTotal);
                amtDisc = GetInputDecimalStr(amtDisc);
                discAmt = GetInputDecimalStr(discAmt);
            }

            if (data.name === "dueDate") {
                dueDate = value;
            }
            if (data.name === "billDate") {
                const diffDays = GetDateDiffDays(localFormData.billDate, localFormData.dueDate);
                dueDate = GetInputDate(value, 0, 0, diffDays ?? 0);
            }

            setLocalFormData((prevData) => ({
                ...prevData,
                [data.name]: data.value,
                dueDate: dueDate,
                amtDisc: amtDisc,
                discAmt: discAmt,
                discRate: discRate,
                billTotal: billTotal,
                additionalCharges: additionalCharges,
                adjustedTotal: adjustedTotal,                        
            }));

            if (data.name === "vendorId") {
                if (IdExists(localFormData.orderId) && IdExists(value)) {
                    createNewSupplierBillFromOrder(localFormData.orderId, value);
                }
            }
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            var billNumberUsed = await billNumberExists(localFormData.billNumber, false);
            if (IsTrue(billNumberUsed)) {
                alert("Invoice Number has already been used for this Supplier.");
                return;
            }
            //if (!isSelectedDropdown(localFormData.billStatus)) {
            //    alert("Bill Status is required");
            //    return;
            //}

            var storeId = ParseIntSafe(localFormData.storeId);
            var billStatusId = ParseIntSafe(localFormData.billStatus);
            if (!IdExists(storeId) || !IdExists(billStatusId) || IsEmpty(localFormData.billLineItems)) {
                alert('Please fill in all fields and add at least one line item.');
                return;
            }

            //var total = 0;
            //if (IsFalse(isContentCentralBill)) {
            //    total = FormatNumberDecimal(parseFloat(localFormData.billTotal));
            //}
            //else {
            //    total = FormatNumberDecimal(parseFloat(localFormData.adjustedTotal));
            //}
            //const lta = getLineTotalAmount();
            //if (NotEquals(total, lta)) {
            //    alert('Total and Line Items Total must be equal.');
            //    return;
            //}

            const formDataToSubmit = { ...localFormData };
            formDataToSubmit.vendorId = parseInt(formDataToSubmit.vendorId, 10);
            formDataToSubmit.storeId = parseInt(formDataToSubmit.storeId, 10);
            formDataToSubmit.billStatus = parseInt(formDataToSubmit.billStatus, 10);

            ['billTotal', 'discRate', 'discAmt', 'nonDiscAmt'].forEach(field => {
                formDataToSubmit[field] = parseFloat(formDataToSubmit[field]);
            });

            formDataToSubmit.billLineItems = formDataToSubmit.billLineItems.map(item => ({
                ...item,
                lineNumber: parseInt(item.lineNumber, 10),
                quantity: parseFloat(item.quantity),
                freight: 0,
                total: parseFloat(item.total),
                vendorId: formDataToSubmit.vendorId
            }));
            //console.log('Submitting form data:', formDataToSubmit);

            var response = null;
            if (isEditMode) {
                response = await post('bills/UpdateBill', formDataToSubmit);
            }
            else {
                response = await post('bills/CreateBill', formDataToSubmit);
            }

            if (IdExists(response)) {
                handleSubmit(true);
            }
        } catch (error) {
            console.error('Error creating bill:', error);

            if (error.response) {
                console.error('Error response:', error.response.data);
                alert(defaultErrMsg());
            } else {
                console.error('Error response:', error.message);
                alert(defaultErrMsg());
            }
        }
    };

    const handleSelectOrder = (selectedOption) => {
        setLocalFormData(prevData => ({
            ...prevData,
            orderId: selectedOption.orderId,
            poNumber: selectedOption.orderNumber
        }));
        createNewSupplierBillFromOrder(selectedOption.orderId, 0);
    };

    const handleSelectVendor = (selectedOption) => {

        if (IdExists(localFormData.billId)) {
            setLocalFormData(prevData => ({
                ...prevData,
                vendorId: selectedOption.vendorId,
            }));
        }
        else {

            var dueDate = localFormData.dueDate;
            if (IsFalse(isEditMode) && GreaterThan(selectedOption.termsDays, 0)) {
                dueDate = GetInputDate(localFormData.billDate, 0, 0, parseFloat(selectedOption.termsDays) + 1);
            }

            setLocalFormData(prevData => ({
                ...prevData,
                vendorId: selectedOption.vendorId,
                discRate: GetInputDecimalStr(selectedOption.discountPercent),
                dueDate: dueDate,
            }));
        }    
    };

    async function billNumberExists(billNumber, alert) {
        if (!IdExists(localFormData.billId) && NotStringEmpty(billNumber) && IdExists(localFormData.vendorId)) {
            try {
                const billNumberExists = await get("/bills/BillNumberExists/" + billNumber + "/" + localFormData.vendorId);
                if (IsTrue(billNumberExists)) {
                    if (IsTrue(alert)) {
                        alert("Invoice Number has already been used for this Supplier.");
                    }
                    else {
                        return true;
                    }
                }
                else {
                    return false;
                }
            } catch (error) {
                console.error('billNumberExists() error - ', error);
            } finally {
            }
        }
    }

    const updateSupplierBillData = useCallback((updatedLines, discountRate) => {
        var billTotal = parseFloat(0);
        var nonDiscAmt = parseFloat(0);
        discountRate = parseFloat(discountRate) ?? 0;

        updatedLines = updatedLines ?? [];
        updatedLines.forEach((ol, index) =>
        {
            billTotal += parseFloat(ol.total);
            if (IsTrue(ol.isFreightLine)) {
                nonDiscAmt += parseFloat(ol.total);
            }
        });

        // calculate
        var amtDisc = parseFloat((billTotal - nonDiscAmt));
        var discAmt = amtDisc * parseFloat(discountRate / 100);
        //format        
        var adjustedTotal = GetInputDecimalStr((billTotal - discAmt));
        billTotal = GetInputDecimalStr(billTotal);
        nonDiscAmt = GetInputDecimalStr(nonDiscAmt);
        amtDisc = GetInputDecimalStr(amtDisc);
        discAmt = GetInputDecimalStr(discAmt);

        setLocalFormData((prevData) => ({
            ...prevData,
            billTotal: billTotal,
            nonDiscAmt: nonDiscAmt,
            amtDisc: amtDisc,
            discAmt: discAmt,
            adjustedTotal: adjustedTotal,
            additionalCharges: GetInputDecimalStr(0)
        }));
    }, [setLocalFormData]);

    async function createNewSupplierBillFromOrder(orderId, vendorId) {
        try {
            if (IdExists(orderId)) {

                const billData = await get('/bills/CreateNewSupplierBillFromOrder?orderId=' + orderId + '&vendorId=' + vendorId);
                if (Exists(billData)) {
                    if (vendorId === 0) {
                        setVendors(billData.poVendors ?? []);
                    }
                    else {
                        var billDate = GetDateNow();
                        if (Exists(billData.billDate)) {
                            billDate = GetInputDate(billData.billDate);
                        }
                        billData.billDate = billDate;

                        if (NotEmpty(vendors)) {
                            var vendor = vendors.find(item => ParseIntSafe(item.vendorId) === ParseIntSafe(vendorId));
                            if (Exists(vendor)) {
                                // set DueDate if TermsDays are set
                                if (IsFalse(isEditMode) && GreaterThan(vendor.termsDays, 0)) {
                                    billData.dueDate = GetInputDate(billDate, 0, 0, parseFloat(vendor.termsDays) + 1);
                                }
                            }
                        }

                        // TODO: update - temp fix for discount rates saved as decimal
                        billData.discRate = parseFloat(billData.discRate ?? 0);
                        if (billData.discRate < 1) {
                            billData.discRate = parseFloat(billData.discRate * 100);
                        }

                        billData.vendorId = vendorId;
                        if (IdExists(localFormData.billId)) {
                            vendorAcRef.current?.fetchEntityById(vendorId);
                        }
                        
                        setLocalFormData(billData);
                        updateSupplierBillData(billData.billLineItems, billData.discRate);
                    }
                }
                else {
                    alert("Unable to create Bill for selected PO Number. Please try again or contact support.");
                }
            }
        } catch (error) {
            console.error('createNewSupplierBillFromOrder() error - ', error);
        } finally {
        }
    }

    useEffect(() => {
        if (initialBillData) {
            initialBillData.billType = 1;
            initialBillData.billStatus = IdExists(initialBillData.billId) ? initialBillData.billStatus : 1;
            initialBillData.billDate = GetInputDate(initialBillData.billDate);
            initialBillData.dueDate = IdExists(initialBillData.billId) ? GetInputDate(initialBillData.dueDate) : "";
            initialBillData.billTotal = GetInputDecimalStr(initialBillData.billTotal);
            initialBillData.nonDiscAmt = GetInputDecimalStr(initialBillData.nonDiscAmt);
            initialBillData.amtDisc = GetInputDecimalStr((initialBillData.billTotal ?? 0) - (initialBillData.nonDiscAmt ?? 0));
            initialBillData.discRate = GetInputDecimalStr(initialBillData.discRate);
            initialBillData.discAmt = GetInputDecimalStr(initialBillData.discAmt);
            initialBillData.adjustedTotal = GetInputDecimalStr(initialBillData.adjustedTotal);
            initialBillData.additionalCharges = GetInputDecimalStr(initialBillData.additionalCharges);            
            //console.log('Setting localFormData:', initialBillData);
            setLocalFormData(initialBillData);
        }
    }, []);

    return (
        <div className="">
            <div className="max-h-[90vh]">
                <h2 className="text-2xl font-bold text-center underline">{isEditMode ? 'Update Supplier Invoice' : 'Create Supplier Invoice'}</h2>
                <div className="">

                    <form onSubmit={handleFormSubmit} className="">
                        <h4 className="text-xl font-bold underline">Invoice Details</h4>
                        <div className="p-2">
                            <div className="grid grid-cols-4 gap-4 pr-2">

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">PO Number</span>
                                    </label>
                                    {!IdExists(localFormData.billId) &&
                                        <EntityAutocomplete name="poNumber" id="poNumber"
                                            onSelect={handleSelectOrder} onChange={null}
                                            initialEntityId={localFormData.orderId}
                                            entityType="Order"
                                            required
                                        />
                                    }
                                    {IdExists(localFormData.billId) &&
                                        <input type="text" name="poNumber" placeholder=""
                                            value={localFormData.poNumber}
                                            onChange={handleChange}
                                            className="input input-bordered"
                                            required
                                        />
                                    }
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">PO Vendor</span>
                                    </label>
                                    {!IdExists(localFormData.billId) &&
                                        <select
                                            name="vendorId"
                                            value={localFormData.vendorId}
                                            onChange={handleChange}
                                            className={"select select-bordered " +
                                                (IdExists(localFormData.orderId) && !IdExists(localFormData.vendorId) ? " border-red-500" : "")}
                                            required
                                        >
                                            <option value="">-- Select --</option>
                                            {NotEmpty(vendors) && vendors.map((vendor) => (
                                                <option key={vendor.vendorId} value={vendor.vendorId}>
                                                    {vendor.name}
                                                </option>
                                            ))}
                                        </select>
                                    }
                                    {IdExists(localFormData.billId) &&
                                        <EntityAutocomplete name="vendorId" id="vendorId"
                                            ref={vendorAcRef}
                                            onSelect={handleSelectVendor}
                                            onChange={null}
                                            initialEntityId={localFormData.vendorId}
                                            entityType="Vendor"
                                            required
                                        />
                                    }
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Invoice Number</span>
                                    </label>
                                    <input type="text" name="billNumber"
                                        className="input input-bordered"
                                        value={localFormData.billNumber}
                                        onChange={handleChange}                                        
                                        onBlur={(e) => { billNumberExists(e.target.value, true) }}
                                        required
                                    />
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Store</span>
                                    </label>
                                    <select
                                        name="storeId"
                                        value={localFormData.storeId}
                                        onChange={handleChange}
                                        className="select select-bordered"
                                        required
                                    >
                                        <option value="">-- Select --</option>
                                        {NotEmpty(stores) && stores.map((store) => (
                                            <option key={store.storeId} value={store.storeId}>
                                                {store.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="grid grid-cols-3 gap-3 pt-2">

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Invoice Date</span>
                                    </label>
                                    <input
                                        type="date"
                                        name="billDate"
                                        value={localFormData.billDate}
                                        onChange={handleChange}
                                        className="input input-bordered"
                                        required
                                    />
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Due Date</span>
                                    </label>
                                    <input
                                        type="date"
                                        name="dueDate"
                                        value={localFormData.dueDate}
                                        onChange={handleChange}
                                        className="input input-bordered"
                                        required
                                    />
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Status</span>
                                    </label>
                                    <select name="billStatus"
                                        value={localFormData.billStatus}
                                        onChange={handleChange}
                                        className="select select-bordered"
                                    >
                                        <option value="">-- Select --</option>
                                        <option value={1}>Ready for Payment</option>
                                        <option value={2}>Payment Pending</option>
                                        <option value={3}>Paid</option>
                                        <option value={4}>Hold</option>
                                    </select>
                                </div>
                            </div>

                            <div className="grid grid-cols-7 gap-6 pt-2">
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Total</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="billTotal" type="number" step="0.01"
                                            className="input input-bordered !w-[95%] read-only"
                                            value={localFormData.billTotal}
                                            //onChange={handleChange}
                                            onBlur={(e) => {
                                                const formattedValue = Number(e.target.value).toFixed(2);
                                                setLocalFormData(prev => ({
                                                    ...prev,
                                                    billTotal: formattedValue
                                                }));
                                            }}
                                            readOnly
                                            required
                                        />
                                    </label>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Non-Discount Amount</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="nonDiscAmt" type="number" step="0.01"
                                            className="input input-bordered !w-[95%] read-only"
                                            value={localFormData.nonDiscAmt}
                                            //onChange={handleChange}                                            
                                            onBlur={(e) => {
                                                const formattedValue = Number(e.target.value).toFixed(2);
                                                setLocalFormData(prev => ({
                                                    ...prev,
                                                    nonDiscAmt: formattedValue
                                                }));
                                            }}
                                            readOnly
                                            required
                                        />
                                    </label>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Discount Amount</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="amtDisc" type="number" step="0.01"
                                            className="input input-bordered !w-[95%] read-only"
                                            value={localFormData.amtDisc}
                                            readOnly
                                        />
                                    </label>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Discount Rate</span>
                                    </label>
                                    <label className="input-group">
                                        <input name="discRate" type="number" step="0.01"
                                            className="input input-bordered !w-[95%]"
                                            value={localFormData.discRate}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                const formattedValue = Number(e.target.value).toFixed(2);
                                                setLocalFormData(prev => ({
                                                    ...prev,
                                                    discRate: formattedValue
                                                }));
                                            }}
                                            required
                                        />
                                        <span>%</span>
                                    </label>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Discount Total</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="discAmt" type="number" step="0.01"
                                            value={localFormData.discAmt}
                                            className="input input-bordered !w-[95%] read-only"
                                            readOnly
                                        />
                                    </label>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Additional Charges</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="additionalCharges" type="number" step="0.01" placeholder=""
                                            className="input input-bordered !w-[95%]"
                                            value={localFormData.additionalCharges}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                const formattedValue = GetInputDecimalStr(e.target.value);
                                                setLocalFormData(prev => ({
                                                    ...prev,
                                                    additionalCharges: formattedValue
                                                }));
                                            }}
                                            required
                                        />
                                    </label>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Adjusted Total</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="adjustedTotal" type="number" step="0.01"
                                            value={localFormData.adjustedTotal}
                                            className="input input-bordered !w-[95%] read-only"
                                            readOnly
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="mt-8">
                                <h4 className="text-xl font-bold underline float-left">Line Item Details</h4>                                
                                <div className="float-right">
                                    <button type="button" onClick={addNewRow} className="btn-submit">
                                        Add New Line Item
                                    </button>
                                </div>
                                
                            </div>

                            <div className="ag-theme-alpine w-full ag-grid-act clear pt-2 clear" style={{ height: '250px' }}>
                                <AgGridReact
                                    gridRef={gridRef}
                                    rowData={localFormData.billLineItems || []}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columnDefs}
                                    onCellValueChanged={onCellValueChanged}
                                    editType="fullRow"
                                    enterNavigatesVerticallyAfterEdit={true}
                                    stopEditingWhenCellsLoseFocus={false}
                                />
                            </div>
                        </div>
                        {/*<div className="flex justify-end mt-1 pb-2 pr-2">*/}
                        {/*    <span className="pr-1">Line Total Amount:</span>*/}
                        {/*    <span className={"font-bold " + getLineTotalCss()}>*/}
                        {/*        {GetDisplayCurrencyStr(getLineTotalAmount())}</span>*/}
                        {/*</div>*/}

                        <div className="modal-action my-2">
                            <button type="button" onClick={onHide} className="btn-cancel">Cancel</button>
                            <button type="submit" className="btn-submit">
                                {isEditMode ? 'Update Invoice' : 'Create Invoice'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}