import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import { Modal, Button } from 'react-daisyui'

import { SetDocTitle } from "../_reactHelpers/ReactHelpers";
import {
    CheckEnterKeyPress, formatCurrencyDecStr, NotEmpty,
    PreventDefault, handleFieldChange, IsTrue, NotStringEmpty, Exists
} from '../../js/formHelpers.js';
import {
    FormatDateTime, FormatDate, GetValueById, SetPageTitle, ClickById, SetHrefById
} from '../../js/helpers.js';
import { get } from '../../services/apiService.js';

import { BillingGroupModal } from '../BillingGroup/BillingGroupModal.js';
import { SearchFilters } from '../SearchFilters/SearchFilters';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import '../../css/ag-theme-lumina.css'; // local ag-theme-alpine.css
import '../../css/ag-orders.css'; // custom orders grid css

import { ModuleRegistry } from '@ag-grid-community/core';
import { MasterDetailModule } from 'ag-grid-enterprise';
import { ClearSearchFilters, GetOrderEndpoint } from '../../js/searchHelpers';

ModuleRegistry.registerModules([MasterDetailModule]);

export const ClaimsGrid = (props) => {
    
    const billGroupModalRef = useRef();
    const [billGroupModalContent, setBillGroupModalContent] = useState(null);

    const [searchStr, setSearchStr] = useState("");
    const [clearFilterMsg, setClearFilterMsg] = useState("");
    const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);  

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    const claimLinkId = "lnkClaimOrderId";    
    const tbSearchId = "tbClaimsSearch";
    const minSearchChars = 2;
    const storedSearchTermsKey = "storedAccountSearchTerms";

    const pageRows = 50;
    var rowModelType = 'serverSide';    

    const gridRef = useRef();
    const [gridHeight, setGridHeight] = useState(getGridHeight());
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    //const gridStyle = useMemo(() => ({ height: '100%', width: '100%' }), []);

    const [rowData, setRowData] = useState();
    const [columnDefs, setColumnDefs] = useState([
        {
            headerName: '', field: 'orderId', width: 85, //cellClass: 'cell-order-icons',
            hide: true,
        },
        {
            headerName: 'Store', field: 'storeDisplayName', width: 125, cellClass: ['text-secondary'],
        },
        {
            headerName: 'Claim #', field: 'orderNumber', width: 125, cellClass: ['text-secondary', 'font-bold'],
        },
        {
            headerName: 'Claim Against', field: 'claimAgainstVendorName', width: 350,
        },
        {
            headerName: 'Claim For', field: 'accountName', width: 300,
        },
        {
            headerName: 'Claim Date', width: 140, field: 'orderDate', cellDataType: 'date',
            cellRenderer: params => {
                return <div>{FormatDate(params.data.orderDate)}</div>;
            }
        },
        {
            headerName: 'Total', field: 'orderTotal', minWidth: 120, maxWidth: 120,
            cellRenderer: params => {
                return <div>{formatCurrencyDecStr(params.data.orderTotal)}</div>;
            }
        },
        {
            headerName: 'Balance Due', field: 'balance', minWidth: 120, maxWidth: 120, cellClass: ['text-red'],
            cellRenderer: params => {
                return <div>{formatCurrencyDecStr(params.data.balance)}</div>;
            }
        },
        {
            headerName: 'Claim Invoice', field: 'claimForOrderNumber', minWidth: 120, maxWidth: 120,
            cellClass: ['text-primary'],
        },
        {
            headerName: 'Reason', field: 'orderId', minWidth: 120, maxWidth: 120,
            cellRenderer: params => {
                return <div></div>;
            }
        },
        {
            headerName: 'Status', field: 'statusStr', minWidth: 120, maxWidth: 120,
        }
    ]);

    const gridOptions = {
        defaultColDef: {
            sortable: false,
            resizable: true,
            filter: false,
            suppressMenu: true,
            suppressMovable: true,
            cellClass: ["no-border"]
        }
    };

    function getGridHeight() {
        return "750px";
        //if (IsBillingGroupPageType) {
        //    return "600px";
        //}
        //else if (IsAccountPageType) {
        //    return "600px";
        //}
        //else {
        //    return "750px";
        //}
    }

    //function showAccountColumn() {
    //    if (IsAccountPageType) {
    //        return false;
    //    }
    //    else {
    //        return true;
    //    }
    //}

    //---------------- Filters  -----------------//

    //const handleFiltersModalShow = useCallback(() => {
    //    orderFiltersModalRef.current?.showModal();
    //}, [orderFiltersModalRef]);

    //function getSelectedStores() {
    //    var strList = "";

    //    var vals = GetInputValuesCheckedByClass("cb-order-stores");
    //    if (vals) {
    //        strList = vals.join(", ");
    //    }
    //    return strList;
    //}

    //function clearFilters(e, advFilters) {
    //    if (e !== null) {
    //        e.preventDefault();
    //    }

    //    if (advFilters === true) {
    //        SetInputsValueByClass("advFilterDate", "");
    //    }
    //    else {
    //        SetInputsCheckedByClass("cb-order-stores", false);
    //        SetInputsValueByClass("filterDate", "");
    //        setClearFilterMsg("Filters Cleared");
    //    }
    //}

    //---------------- Grid Functions  -----------------//

    function loadSavedSearchTerms() {
        //load stored search terms if coming back from Order Details
        var allowed = true;        
        try {
            //var fromURl = GetPreviousUrl();
            //var allowed = StringContains(fromURl, loadSearc5rmsFromPage);            
            //if (allowed !== true) {
            //    allowed = location.state && location.state.useSavedFiltersOrder;
            //}

            if (allowed) {
                const savedSearchTerm = localStorage.getItem(storedSearchTermsKey);
                if (NotStringEmpty(savedSearchTerm)) {

                    const strSearchTerm = JSON.parse(savedSearchTerm);
                    if (NotStringEmpty(strSearchTerm)) {
                        setSearchStr(strSearchTerm);
                    }
                }
            }
        }
        catch (error) {
            console.error("localStorage error: " + storedSearchTermsKey);            
        }
        //localStorage.setItem(storedSearchTermsKey, "");
    }


    //---------------- Load Orders  -----------------//

    const onGridReady = useCallback((params) => {
        setLoading(false);
        params.api.setServerSideDatasource(serverSideDatasource());
    }, []);

    const serverSideDatasource = () => {
        return {
            // called by the grid when more rows are required
            getRows: async params => {

                var searchTerms = GetValueById(tbSearchId);
                var validSearch = NotEmpty(searchTerms) && searchTerms.length >= minSearchChars;
                if (validSearch) {
                    saveSearchTerms();

                    var pageNum = params.request.endRow / pageRows;
                    var page = pageNum - 1;

                    // todo: remove
                    var endpointOLD = 'orders/SearchOrders?sType=claim&numRows=' + pageRows + '&page=' + page + '&sText=' + searchTerms;

                    var endpoint = GetOrderEndpoint("claim", searchTerms, "", pageRows, page, 0);
                    console.log("server side apiUrl: " + endpoint);

                    try {
                        const rowData = await get(endpoint);
                        if (Exists(rowData) && NotEmpty(rowData.dataRows)) {

                            params.success({ rowData: rowData.dataRows });
                            hideOverlay();
                            //console.log(rowData.message);
                        }
                        else {
                            params.success({ rowData: [] });
                            showNoRows();
                            console.log("loadClaimsServer: no data");
                        }
                    } catch (error) {
                        params.success({ rowData: [] });
                        showNoRows();
                        console.error('loadClaimsServer error:', error);
                    }
                }
                else {
                    params.success({ rowData: [] });
                    showNoRows();
                    console.log("loadClaimsServer: load none");
                }
            }
        };
    }

    const refreshCache = useCallback((e) => {
        PreventDefault(e);
        setClearFilterMsg("");
        gridRef.current.api.refreshServerSide({ purge: true });        
    }, []);

    function saveSearchTerms() {
        //do not overwrite saved search terms for embedded Orders search        
        var searchTerms = GetValueById(tbSearchId);
        localStorage.setItem(storedSearchTermsKey, JSON.stringify(searchTerms));        
    }

    function showLoading() {
        gridRef.current.api.showLoadingOverlay();
    }
    function showNoRows() {
        gridRef.current.api.showNoRowsOverlay();
    }
    function hideOverlay() {
        gridRef.current.api.hideOverlay();
    }

    const onFirstDataRendered = useCallback((params) => {
        //console.log("onFirstDataRendered endTime: " + FormatDateTime(new Date()));
    }, []);

    const cellClicked = useCallback((params) => {
        params.node.setSelected(true);
    }, []);


    //onRowDoubleClicked
    function onCellDoubleClicked(node) {
        if (node && node.column) {
            //dont double click for Account node
            //if (node.colDef.headerName !== accountColHeader) {
            var orderData = node.data;
            if (orderData) {
                if (orderData.billingGroupId !== 0) {
                    onOpenBillGroupModal(orderData.billingGroupId, orderData.orderId);
                }
                else if (orderData.orderId !== 0) {
                    var route = "/Redirect?route=EditClaim&orderId=" + orderData.orderId;
                    SetHrefById(claimLinkId, route);
                    ClickById(claimLinkId);
                }
            }
            else {
                alert("There was an issue loading this order. Please refresh the page and try again.")
            }
            //}
        }
    }

    // Billing Group Modal
    const billGroupModalShow = useCallback(() => {
        billGroupModalRef.current?.showModal();
    }, [billGroupModalRef]);

    const billGroupModalHide = useCallback(() => {
        billGroupModalRef.current?.close();
    }, [billGroupModalRef]);

    function getBillGroupModalContent(billingGroupId, orderId) {
        return <BillingGroupModal billingGroupId={billingGroupId} orderId={orderId}
            onCloseModal={onCloseBillGroupModal}></BillingGroupModal>;
    }

    function onOpenBillGroupModal(billingGroupId, orderId) {
        //updateMessage("", "");        
        setBillGroupModalContent(getBillGroupModalContent(billingGroupId, orderId));
        billGroupModalShow();
    }

    function onCloseBillGroupModal(e) {
        PreventDefault(e);
        setBillGroupModalContent(null);
        billGroupModalHide();
        refreshCache();
    }

    //---------------- Filters  -----------------//

    function UpdateSearchStr(e) {
        var str = "";
        if (e) {
            var data = handleFieldChange(e);
            if (data) {
                str = data.value;
            }
        }
        setSearchStr(str);
    }

    function clearFilters(e) {
        PreventDefault(e);
        ClearSearchFilters(storedSearchTermsKey);
        setClearFilterMsg("Filters cleared");
        // dont clear search str on filter clear
        //setSearchStr("");
    }

    const handleOpenFiltersModal = () => setIsFiltersModalOpen(true);
    function handleCloseFiltersModal(e, loadData) {
        PreventDefault(e);
        setIsFiltersModalOpen(false);

        if (IsTrue(loadData)) {
            refreshCache();
        }
    }

    // ON LOAD
    useEffect(() => {        
        setLoading(false);

        SetDocTitle("Lumina - Claims");
        SetPageTitle("Claims");

        setTimeout(() => {
            loadSavedSearchTerms();
        }, 300);
    }, []);

    if (loading === true) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (error === true)
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;

    return (
        <div>
            <div style={containerStyle} className="p-2 pt-2">
                <div className="ag-theme-alpine" style={{ height: gridHeight, width: '100%', }}>
                    <div>
                        <div className="float-left">
                            <div className="table-cell pr-4">
                                <a href="/Redirect?route=CreateClaim&orderType=3&status=create" //target="_blank" rel="noreferrer"
                                    className="btn-load-orders">
                                    Create Claim
                                </a>
                            </div>
                            {/* Search */}
                            <div className="table-cell">
                                <label for="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
                                <div className="relative">
                                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                        <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                        </svg>
                                    </div>
                                    <div>
                                        <input autoFocus type="search" id={tbSearchId} placeholder="Search..."                                            
                                            className="orders-filter-search block p-2 pl-10 text-sm text-gray-900 
                                                border border-gray-300 rounded-lg focus:ring-blue-500 focus:border-blue-500;"
                                            onKeyDown={(e) => CheckEnterKeyPress(e, refreshCache)}
                                            onChange={UpdateSearchStr}
                                            value={searchStr}></input>
                                    </div>
                                    <div id="selectedRows" />
                                </div>
                            </div>
                            {/* Search Buttons */}
                            <div className="pl-4 table-cell align-middle">
                                <div className="table-cell">
                                    <button onClick={(e) => refreshCache(e)}
                                        className="btn-load-orders">Search Claims</button>
                                </div>
                                <div className="table-cell pl-3">
                                    <button className="btn-close-modal"
                                        onClick={(e) => clearFilters(e)}>
                                        Clear Filters
                                    </button>
                                </div>
                                {/*<!-- Advanced Filters -->*/}
                                <div onClick={handleOpenFiltersModal} className="div-binocs">
                                    <svg fill="#000000" height="25px" width="25px" version="1.1" id="Capa_1"
                                        xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 488.1 488.1" space="preserve">
                                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                        <g id="SVGRepo_iconCarrier"><g><g>
                                            <path d="M299.9,86.8c14.2-6.3,30.3-9.8,46.2-9.8c9.2,0,18,1.1,26.3,3.2c3.3,0.8,6.5-2,6-5.4l-1.2-9.6l0,0 c-1-16-19.3-28.9-41.2-28.9c-22.5,0-44,13.5-44,30.2l1,16C293.3,86,296.8,88.2,299.9,86.8z"></path> <path d="M487.3,361.9l-0.5-2.9c0-0.2-0.1-0.4-0.1-0.6l-18.5-94c-2.4-21.7-18.2-40.6-41.2-52.3l-8.8-68.3c0-29-32.8-52.7-72.1-52.7 s-76.7,23.5-76.7,52.6c0,1.4,0.1,2.9,0.3,4.3l0.5,7.4c-7.8-2.8-16.7-4.4-26.1-4.4c-9.5,0-18.4,1.6-26.3,4.5l0.5-7.5 c0.2-1.4,0.3-2.8,0.3-4.3c0-29-37.5-52.6-76.7-52.6c-39.3,0-72.1,23.7-72.1,52.7L61,212.1c-23,11.6-38.8,30.6-41.2,52.3l-18.5,94 c0,0.2-0.1,0.4-0.1,0.6l-0.5,2.9l0,0c-0.4,3-0.7,6-0.7,9.1c0,44.6,46.7,80.8,104.2,80.8s104.2-36.2,104.2-80.8l0,0l0,0v-37 c10.3,4.8,22.6,7.5,35.7,7.5s25.3-2.7,35.6-7.4V371l0,0l0,0c0,44.6,46.7,80.8,104.2,80.8c57.4,0,104.2-36.2,104.2-80.8 C488,367.9,487.7,364.9,487.3,361.9z M104.2,422.7c-40.7,0-75.1-23.7-75.1-51.7c0-28.1,34.4-51.7,75.1-51.7s75.1,23.7,75.1,51.7 C179.3,399,144.9,422.7,104.2,422.7z M244.1,315.9c-17.1,0-31-9.7-31-21.6s13.9-21.6,31-21.6s31,9.7,31,21.6 C275.1,306.2,261.2,315.9,244.1,315.9z M383.8,422.7c-40.7,0-75.1-23.7-75.1-51.7c0-28.1,34.4-51.7,75.1-51.7s75.1,23.7,75.1,51.7 S424.5,422.7,383.8,422.7z"></path> <path d="M115.5,80.2c8.3-2.1,17.2-3.2,26.3-3.2c15.9,0,32.1,3.5,46.2,9.8c3.1,1.4,6.6-0.8,6.8-4.2l1-16c0-16.7-21.5-30.2-44-30.2 c-21.9,0-40.1,12.9-41.2,28.9l0,0l-1.2,9.6C109,78.2,112.1,81.1,115.5,80.2z">
                                            </path></g></g>
                                        </g>
                                    </svg>
                                </div>
                                <div className="table-cell pl-3 color-primary text-[12px] font-bold">{clearFilterMsg}</div>
                            </div>
                            <div className="clear mt-1 mb-1">
                                'Enter' to search with 2 or more characters
                            </div>
                        </div>                        
                    </div>
                    <div className="clear"></div>

                    {/*Grid Def*/}
                    <AgGridReact
                        //masterDetail={true}
                        //cacheQuickFilter={true}
                        //enableColumnMenu={true}
                        enableColumnMenu={false}
                        ref={gridRef}
                        gridOptions={gridOptions}
                        columnDefs={columnDefs}
                        onGridReady={onGridReady}
                        onFirstDataRendered={onFirstDataRendered}
                        onCellClicked={cellClicked}
                        //onRowDoubleClicked={(e) => onRowDoubleClicked(e)}
                        //onRowClicked={(e) => debounceCellClick(e, self.rowClicked)}
                        onCellDoubleClicked={(e) => onCellDoubleClicked(e)}
                        rowData={rowData}
                        rowModelType={rowModelType}
                        cacheBlockSize={pageRows}
                        headerHeight={35}
                    ></AgGridReact>
                    {/*includeHiddenColumnsInQuickFilter={true}*/}
                    {/*onViewportChanged={onViewportChanged}*/}
                    {/*onFirstDataRendered={onFirstDataRendered}*/}
                    {/*getRowStyle={getRowStyle}*/}
                    {/*getRowClass={getRowClass}*/}
                    {/*defaultColDef={defaultColDef}*/}
                </div>
            </div>

            <div className="">
                <Modal ref={billGroupModalRef} className="order-bg-modal min-h-[700px] min-w-[1350px]">
                    <Modal.Body>
                        {billGroupModalContent}
                    </Modal.Body>
                </Modal>
            </div>

            <div>
                <SearchFilters isOpen={isFiltersModalOpen} isClaimType={true}
                    clearFilters={clearFilters} onClose={handleCloseFiltersModal} />
            </div>

            <a href="/" id={claimLinkId} //target="_blank" rel="noreferrer"
                className="hidden">Open Link</a>
        </div>
    );
}