import React, { useState } from 'react';
import axios from 'axios';

import { Exists, handleFieldChange, IdExists, NotExists } from '../../js/formHelpers';
import { SetValueById } from '../../js/helpers';

export const FileUploader = (props) => {
    const handleCloseFunc = props.handleClose ?? null;
    const accountId = props.accountId ?? 0;
    
    const [description, setDescription] = useState(null);
    const [file, setFile] = useState(null);

    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    const handleChange = (e) => {
        var data = handleFieldChange(e);
        if (data) {

            if (data.name === "description") {
                setDescription(data.value);
            }
            if (data.name === "uploadedFile") {
                if (e.target.files) {
                    setFile(e.target.files[0]);
                    //setFile(e.target.files);
                }
            }
        }
        else {
            alert(defaultErrMsg);
        }
    };

    const handleUpload = async () => {
        if (Exists(file)) {

            const formData = new FormData();
            var endpoint = "";            

            if (IdExists(accountId)) {
                endpoint = "data/UploadAccountAttachmentToDb";                
                formData.append("accountId", accountId);
            }

            formData.append("description", description);
            formData.append("file", file, file.name);
            
            try {
                var config = {};
                config.headers = {
                    "Content-Type": "multipart/form-data",
                    Accept: "application/json",
                };

                var userData = JSON.parse(localStorage.getItem('userData'));
                if (userData) {
                    const userId = userData.userId ?? 0;
                    const storeId = userData.user?.defaultStoreId ?? 0;
                    config.headers['X-UserId'] = userId;
                    config.headers['X-StoreId'] = storeId;
                    //console.log('Adding X-UserId = ' + userId + ' to header');
                } else {
                    console.log('No userData');
                }

                const resp = await axios.post(process.env.REACT_APP_API_URL + endpoint, formData, config);

                setDescription("");
                setFile(null);
                SetValueById("uploadedFile", null);

                handleCloseFunc();
            } catch (error) {
                console.error(error);
                alert(defaultErrMsg);
            }
        }
    };

    return (
        <div className="p-2">
            <div className="details-form-field !w-full">
                <input id="description" name="description" type="text" placeholder="Description"
                    className="inp-text !text-lg"
                    value={description}
                    onChange={handleChange}
                />                
            </div>
            <div className="details-form-field !w-full !pt-4">
                <input id="uploadedFile" name="uploadedFile" className="w-full"
                    type="file"
                    onChange={handleChange} />
            </div>
            <div className="details-form-field !w-full text-right !pt-8">
                <button className="btn-submit" onClick={handleUpload}
                    disabled={NotExists(file)}>
                    Upload File
                </button>
                <button id="fileUploadClose" type="button" className="btn-cancel !mr-0" onClick={(e) => handleCloseFunc()}>
                    Cancel
                </button>
            </div>
        </div>
    );
}