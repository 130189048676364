import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { get } from '../../services/apiService.js';
import { NotEmpty, NotStringEmpty, StringEmpty } from '../../js/formHelpers.js';

export function ViewFile({ path, zoom }) {
    const { qPath } = useParams();
    const filePath = NotStringEmpty(path) ? path : qPath;
    
    const [src, setSrc] = useState("");
    const [type, setType] = useState("");
    const [srcZoom, setSrcZoom] = useState(NotStringEmpty(zoom) ? zoom : "35");

    //const [blobUrl, setBlobUrl] = useState(null);

    function setFileAttrs(response) {
        if (NotStringEmpty(response)) {
            //var srcUrl = "/contentcentral/ViewFile?path=" + encodeURIComponent(fp);

            var srcUrl = base64ToArrayBuffer(response, "application/pdf");
            setSrc(srcUrl + "#zoom=" + srcZoom);
            setType("application/pdf");

            //var ct = fp.split('.').pop() ?? "";
            //if (NotStringEmpty(ct)) {
            //    ct = "application/pdf";
            //    setType(ct);
            //}
        }
        else {
            setSrc("");
            console.error('fetchFile() getSrc() error: ', "empty 'filepath'");
        }
        return "";
    }

    const fetchFile = async () => {
        try {
            if (NotStringEmpty(filePath)) {
                //const fp = await get(`/contentcentral/ViewFile?path=` + encodeURIComponent(path));
                //setFileAttrs(fp);

                const response = await get(`/contentcentral/ViewFile?path=` + encodeURIComponent(filePath));
                if (NotEmpty(response)) {
                    setFileAttrs(response);

                    //var url = base64ToArrayBuffer(response, "application/pdf");
                    //window.open(url);

                    //var blob = new Blob([response], { type: 'application/pdf' });
                    //var url = URL.createObjectURL(response);
                    //window.open(url);
                    //setBlobUrl(url);
                }
            }
            else {
                setSrc("");
                console.error('fetchFile() error: ', "empty 'path'");
            }
        } catch (error) {
            console.error('fetchFile() error:', error);
        }
    };

    function base64ToArrayBuffer(fBytes, contentType) {
        var binary_string = window.atob(fBytes)
        var len = fBytes.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        let blob = new Blob([bytes.buffer], { type: contentType })
        var url = URL.createObjectURL(blob);
        return url;
    }

    useEffect(() => {
        fetchFile();
        //setFileAttrs(path);
    }, []);

    return (
        <div>
            {NotStringEmpty(src) &&
                <div className="w-[100%] m-auto mt-2">
                    <embed width="100%" frameBorder="0" scrolling="auto"
                        height="550px" 
                        src={src} type={type}></embed>
                </div>
            }
            {StringEmpty(src) &&
                <div className="pt-4 text-lg text-black font-bold text-center">
                    Invoice PDF not found.
                </div>
            }

            {/*<iframe src={src} title="View File" width="100%" height="100%"></iframe>*/}

            {/*<embed height="100%" width="100%" frameBorder="0" scrolling="auto"*/}
            {/*    src={src} type={type}></embed>*/}

            {/*{(NotStringEmpty(src) || NotStringEmpty(blobUrl)) &&*/}
            {/*    <embed height="100%" width="100%" frameBorder="0" scrolling="auto"*/}
            {/*        src={src} type={type}></embed>*/}

            {/*    <iframe src={blobUrl} title="View File"*/}
            {/*        width="100%" height="100%"></iframe>*/}
            {/*}*/}
        </div>
    );
}