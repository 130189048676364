import React, {useState, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Modal} from 'react-daisyui'

import { StandardDataAndResponsePOST, StandardIdAndResponseGET} from '../../js/dataHelpers';
import {
  Exists, handleFieldChange, IdExists, IsTrue, NotExists, NotStringEmpty,
  ParseIntSafe, PreventDefault, SetFocusById, Timeout
} from '../../js/formHelpers';
import {ListData} from "../_reactHelpers/ListData";

export default function AccountCommissionsNotes() {
  const location = useLocation();
  const navigate = useNavigate();

  const [accountId, setAccountId] = useState(getAccountId());
  const [commissionsNotesList, setCommissionsNotesList] = useState([]);

  const [newNoteData, setNewNoteData] = useState(newNoteModel());
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [message, setMessage] = useState("");
  const [messageClass, setMessageClass] = useState("");
  
  const defaultErrMsg = "An error occurred. Please try again or contact support.";

  function getAccountId() {
    let accountId = 0;
    try {
      accountId = location.state.accountId;
      if (!accountId) {
        accountId = 0;
      }
    } catch (error) {
      accountId = 0;
    }
    
    return accountId;
  }
  
  function newNoteModel() {
    const model = {
      accountId: accountId,
      note: "",
      pinned: false,
      important: false
    };
    return model;
  }

  function updateMessage(msg, cssClass, errorMsg, logMsg) {
    if (NotExists(msg)) {
      msg = "";
    }
    if (NotExists(cssClass)) {
      cssClass = "";
    }

    if (errorMsg) {
      console.error(errorMsg);
    }
    if (logMsg) {
      console.log(logMsg);
    }
    setMessage(msg);
    setMessageClass(cssClass);
  }

  const handleNewNoteChange = (e) => {
    updateMessage();

    const data = handleFieldChange(e);
    if (data) {

      setNewNoteData((newNoteData) => ({
        ...newNoteData,
        [data.name]: data.value
      }));
    } else {
      updateMessage(defaultErrMsg, "text-red", "null handleNewNoteChange data");
    }
  };
  
  const handleCommissionsNotesLoad = async () => {

    // CommissionNotes tab should only load for existing Account
    if (IdExists(accountId)) {
      const data = await StandardIdAndResponseGET(accountId, "accounts/GetAccountCommissionsNotes/", "handleCreditNotesLoad()");
      if (Exists(data)) {
        setCommissionsNotesList(data);
      } else {
        updateMessage(defaultErrMsg, "text-red");
      }
    } else {
      updateMessage(defaultErrMsg, "text-red");
    }
  };

  async function SaveAccountCommissionsNotePost() {
    let response = null;

    if (Exists(newNoteData)) {

      if (NotStringEmpty(newNoteData.note)) {
        const postModel = JSON.stringify(newNoteData);
        const endpoint = "accounts/SaveAccountCommissionsNotes";
        const typeStr = "SaveAccountCommissionsNotesPost";
        response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
      } else {
        updateMessage("Note is required", "text-red");
      }
    } else {
      response = "No POST data for SaveAccountCommissionsNotesPost().";
    }
    return response;
  }

  async function DeleteCommissionsAccountNotePOST(noteId) {
    let response = null;

    if (IdExists(noteId)) {
      const model = newNoteModel();
      model.accountNoteId = noteId;

      const postModel = JSON.stringify(model);
      const endpoint = "accounts/DeleteAccountNote";
      const typeStr = "DeleteCommissionAccountNotePOST";
      response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
    } else {
      response = "No POST data for DeleteCommissionAccountNotePOST()";
    }
    return response;
  }

  const handleOpenModal = () => setIsModalOpen(true);

  function openModal() {
    Timeout(200, SetFocusById("commissionNotesTextArea"));
    handleOpenModal();
  }

  async function handleCloseModal(saveNote) {
    let resp = null;

    if (IsTrue(saveNote)) {
      resp = await SaveAccountCommissionsNotePost();
      //var success = IsSuccessStandardResponseAndDataPOST(resp);
      const id = ParseIntSafe(resp);

      if (IdExists(id)) {
        setNewNoteData(newNoteModel());
        setIsModalOpen(false);
        handleCommissionsNotesLoad();
      }
    } else {
      setNewNoteData(newNoteModel());
      setIsModalOpen(false);
    }
  }

  async function deleteNote(e, noteId) {
    PreventDefault(e);

    if (window.confirm("Are you sure you want to delete this Note?")) {
      const resp = await DeleteCommissionsAccountNotePOST(noteId);
      //var success = IsSuccessStandardResponseAndDataPOST(resp);
      await handleCommissionsNotesLoad();
    }
  }
  
  // ON LOAD
  useEffect(() => {
    handleCommissionsNotesLoad();
  }, []);

  return (
    <div className="p-4 pt-0">
      {IdExists(accountId) &&
        <div>
          <div className="act-form">
            <div className="flex-wrap forms-page clear">
              <div>
                <div className="form-break !pb-0">
                  <hr className="form-break-line"/>
                  <span className="form-break-title">
                                        Notes
                                    </span>
                </div>

                <div className="float-right">
                  <button type="button" className="btn-search !pt-0 !pb-0"
                          onClick={(e) => openModal()}>
                    + Note
                  </button>
                </div>
              </div>

              <div className="clear pt-2">
                <div
                  className="min-h-[50px] rounded border-gray-200 border w-full h-[78vh] overflow-x-hidden overflow-y-auto">
                  {commissionsNotesList &&
                    <ListData dataLines={commissionsNotesList} type="account-notes" deleteFunc={deleteNote}></ListData>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      }

      <div>
        <Modal open={isModalOpen} className="min-w-[800px] py-2">
          <Modal.Header
            className="font-bold mb-0 text-center pb-2 !text-secondary !text-underline">
            + Note
          </Modal.Header>
          <Modal.Body>
            <div className="min-h-[200px]">
              <div className="w-full mb-4">
                <div className="border-textarea">
                                    <textarea className="inp-textarea acct-notes-textarea !overflow-clip !px-2 !py-1"
                                              id="commissionNotesTextArea" name="note" rows="6"
                                              value={newNoteData.note}
                                              onChange={handleNewNoteChange}
                                              autoFocus>
                                    </textarea>
                </div>
              </div>

              <div className="details-form-field">
                <label for="pinned" className="lbl-cb pr-4">
                  Pin Note to Top
                  <input id="pinned" name="pinned" type="checkbox"
                         className="input-cb ml-2"
                         checked={newNoteData.pinned}
                         onChange={handleNewNoteChange}
                  />
                </label>
              </div>

              <div className="details-form-field">
                <label for="important" className="lbl-cb pr-4">
                  Mark As Important
                  <input id="important" name="important" type="checkbox"
                         className="input-cb ml-2"
                         checked={newNoteData.important}
                         onChange={handleNewNoteChange}
                  />
                </label>
              </div>

              <div className={"h-[30px] text-center " + messageClass}>
                {message}
              </div>
              <div className="text-right pt-2 pb-2">
                <button type="button" className="btn-cancel" onClick={(e) => handleCloseModal(false)}>
                  Cancel
                </button>
                <button type="button" id="btnSubmit" className="btn-submit" onClick={(e) => handleCloseModal(true)}>
                  Save & Close
                </button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}