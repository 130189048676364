import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-daisyui'

import { get, post } from '../../../services/apiService';

import { SetDocumentTitle } from '../../_reactHelpers/ReactHelpers';
import { SetPageTitle } from '../../../js/helpers';
import { Exists, GetDateNow, IdExists, IsFalse, IsTrue, NotEmpty, NotStringEmpty, ParseIntSafe, StringEmpty } from '../../../js/formHelpers';

import { BillsList } from './BillsList.js';
import { BatchPreferencesForm } from './BatchPreferencesForm.js';
import { PrintCheckQueue } from '../PrintCheckQueue/PrintCheckQueue.js';
import { DateFilter } from '../../_reactHelpers/DateFilter';
import { GreaterThan } from '../../../js/calculationHelpers';

export function CheckPayments(props) {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina -");

    const loadAchVendors = props.loadAchVendors ?? false;
    const typeStr = IsTrue(loadAchVendors) ? "ACH " : "";

    //const navigate = useNavigate();
    //const [searchParams] = useSearchParams();
    //const bankId = searchParams.get('bank');
    //const checkNumber = searchParams.get('number');

    const [bankId, setBankId] = useState(null);
    const [checkNumber, setCheckNumber] = useState(null);

    const [bills, setBills] = useState([]);
    const [selectedBills, setSelectedBills] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);

    const [loading, setLoading] = useState(true);
    const [modalOpen, setModalOpen] = useState(false);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [showPrintCheckQueue, setShowPrintCheckQueue] = useState(false);

    const [message, setMessage] = useState('');
    const [messageClass, setMessageClass] = useState('');

    // Methods

    const updateMessage = (msg, cssClass) => {
        setMessage(msg);
        setMessageClass(cssClass);
    };

    const fetchBills = async () => {
        updateMessage();

        if (StringEmpty(startDate) || StringEmpty(endDate)) {
            updateMessage('Dates are required.', 'text-red');
            return;
        }

        try {
            const response = await get(`bills/GetBillsByVendorOrder/${startDate}/${endDate}/${loadAchVendors}`);
            const data = response.map(item => ({
                ...item
            }));

            setBills(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching bills:', error);
            setBills([]);
            setLoading(false);
        }
    };

    const fetchBankAccounts = async () => {
        try {
            const response = await get('bankaccount/GetAllBankAccounts');
            const data = response.map(item => ({
                ...item
            }));

            setBankAccounts(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching Bank Accounts:', error);
            setBankAccounts([]);
            setLoading(false);
        }
    };

    const postCheckPayments = async (bankId, checkNumber) => {
        var currentIndex = ParseIntSafe(checkNumber);
        if (GreaterThan(currentIndex, 0)) {

            const checkPayments = [];
            if (NotEmpty(selectedBills)) {

                // Vendor Bills        
                var vendorBills = selectedBills.filter(item => IdExists(item.vendorId)) ?? [];
                if (NotEmpty(vendorBills)) {
                    vendorBills.sort((a, b) => a.vendorId - b.vendorId);

                    var currentVendor = vendorBills[0].vendorId;

                    var auxCheckPayment = {
                        checkPaymentId: 0,
                        bankId: bankId,
                        vendorId: currentVendor,
                        payee: "",
                        checkAmount: 0,
                        checkDate: new Date().toISOString(),
                        notes: '',
                        status: 'pending',
                        checkNumber: currentIndex
                    };

                    for (let bill of vendorBills) {
                        if (IdExists(bill.vendorId)) {
                            if (currentVendor === bill.vendorId) {

                                let billIds = [];
                                if (NotEmpty(auxCheckPayment.billIds)) {
                                    billIds = auxCheckPayment.billIds;
                                }
                                billIds.push(bill.billId);

                                auxCheckPayment = {
                                    ...auxCheckPayment,
                                    checkAmount: auxCheckPayment.checkAmount + bill.adjustedTotal,
                                    notes: auxCheckPayment.notes + `${bill.billNumber}, `,
                                    billIds: billIds
                                }
                            }
                            else {
                                auxCheckPayment.notes = auxCheckPayment.notes.substring(0, auxCheckPayment.notes.length - 2);
                                checkPayments.push(auxCheckPayment);

                                // reset aux
                                currentVendor = bill.vendorId;
                                currentIndex++;

                                let billIds = [];
                                billIds.push(bill.billId);
                                auxCheckPayment = {
                                    checkPaymentId: 0,
                                    bankId: bankId,
                                    vendorId: currentVendor,
                                    payee: bill.payee,
                                    checkAmount: bill.adjustedTotal,
                                    checkDate: new Date().toISOString(),
                                    notes: `${bill.billNumber}, `,
                                    status: 'pending',
                                    checkNumber: currentIndex,
                                    billIds: billIds
                                };
                            }
                        }
                    }
                    // push last item
                    auxCheckPayment.notes = auxCheckPayment.notes.substring(0, auxCheckPayment.notes.length - 2);
                    checkPayments.push(auxCheckPayment);
                }

                // non-Vendor Bills
                var nonVendorBills = selectedBills.filter(item => !IdExists(item.vendorId));
                if (NotEmpty(nonVendorBills)) {
                    nonVendorBills.sort((a, b) => a.payee - b.payee);

                    var currentPayee = nonVendorBills[0].payee;
                    currentIndex++;

                    auxCheckPayment = {
                        checkPaymentId: 0,
                        bankId: bankId,
                        vendorId: null,
                        payee: currentPayee,
                        checkAmount: 0,
                        checkDate: new Date().toISOString(),
                        notes: '',
                        status: 'pending',
                        checkNumber: currentIndex
                    };

                    for (let bill of nonVendorBills) {
                        if (!IdExists(bill.vendorId)) {
                            if (currentPayee === bill.payee) {

                                let billIds = [];
                                if (NotEmpty(auxCheckPayment.billIds)) {
                                    billIds = auxCheckPayment.billIds;
                                }
                                billIds.push(bill.billId);

                                auxCheckPayment = {
                                    ...auxCheckPayment,
                                    checkAmount: auxCheckPayment.checkAmount + bill.adjustedTotal,
                                    notes: auxCheckPayment.notes + `${bill.billNumber}, `,
                                    billIds: billIds
                                }
                            }
                            else {
                                auxCheckPayment.notes = auxCheckPayment.notes.substring(0, auxCheckPayment.notes.length - 2);
                                checkPayments.push(auxCheckPayment);

                                // reset aux
                                currentPayee = bill.payee;
                                currentIndex++;

                                let billIds = [];
                                billIds.push(bill.billId);
                                auxCheckPayment = {
                                    checkPaymentId: 0,
                                    bankId: bankId,
                                    vendorId: null,
                                    payee: bill.payee,
                                    checkAmount: bill.adjustedTotal,
                                    checkDate: new Date().toISOString(),
                                    notes: `${bill.billNumber}, `,
                                    status: 'pending',
                                    checkNumber: currentIndex,
                                    billIds: billIds
                                };
                            }
                        }
                    }
                    // push last item
                    auxCheckPayment.notes = auxCheckPayment.notes.substring(0, auxCheckPayment.notes.length - 2);
                    checkPayments.push(auxCheckPayment);
                }
            }

            if (NotEmpty(checkPayments)) {
                try {
                    const response = await post('checkpayment/CreateCheckPayments', checkPayments);
                    loadPrintCheckQueue();

                } catch (error) {
                    console.error('Error creating Check Payments:', error);
                    setBankAccounts([]);
                    setLoading(false);
                }
            }
        }
        else {
            alert("Check Number is required.");
        }
    };

    const updateSelectedBills = async (billIds) => {
        try {
            const response = await post('bills/SetBillsPaid', billIds);
            loadCheckPayments();
        } catch (error) {
            console.error('Error updating Bills', error);
        }
    };

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    function processPayments() {
        if (IsTrue(loadAchVendors)) {
            if (NotEmpty(selectedBills)) {
                updateSelectedBills(selectedBills.map(bill => bill.billId));
            }
        }
        else {
            openBatchPreferencesModal();
        }
    }
    
    function openBatchPreferencesModal() {
        if (NotEmpty(selectedBills)) {
            openModal();
        }
    }

    const handleBatchPreferences = (bankAccount, number) => {
        closeModal();

        setBankId(bankAccount.bankId);
        setCheckNumber(number);
        postCheckPayments(bankAccount.bankId, number);
    };

    const handleSelectedRowsChange = (selectedRows) => {
        setSelectedBills(selectedRows);
    };

    function loadAchPayments() {
        // clear selected Bills for new run
        setSelectedBills([]);
        // BankId and CheckNumber not used here
        setBankId(0);
        setCheckNumber(0);

        // auto load after process payments
        if (NotStringEmpty(startDate) && NotStringEmpty(endDate)) {        
            fetchBills();
        }
        fetchBankAccounts();
    }

    function loadPayments(bId, number) {
        // clear selected Bills for new run
        setSelectedBills([]);
        // BankId reset for new check run
        setBankId(0);
        // CheckNumber manually entered when first load page
        setCheckNumber(number ?? 0);

        //// do not auto load
        //if (IdExists(number)) {
        //    fetchBills();
        //}

        //fetchBills();
        fetchBankAccounts();
    }

    function loadCheckPayments(bId, number) {
        setDocTitle("Lumina - " + typeStr + "Payments Pending");
        SetPageTitle(typeStr + "Payments Pending");
        setShowPrintCheckQueue(false);

        if (IsTrue(loadAchVendors)) {
            loadAchPayments();
        }
        else {
            loadPayments(bId, number);
        }
    }

    function loadPrintCheckQueue() {
        setShowPrintCheckQueue(true);
    }

    useEffect(() => {
        loadCheckPayments();
    }, []);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen pt-[10%]">
                <span className="loading loading-spinner loading-lg"></span>
            </div>
        );
    }

    return (
        <div className="page-wrapper">
            {IsFalse(showPrintCheckQueue) &&
                <div>
                    <div className="flex-wrapper">
                        <div className="">
                            <DateFilter fromDate={startDate} setFromDate={setStartDate} fromDateStr="Start Due Date:"
                                toDate={endDate} setToDate={setEndDate} toDateStr="End Due Date:" enterFunction={fetchBills} />
                        </div>
                        <div>
                            <button className="btn-date-submit" onClick={e => fetchBills()}>Search Invoices</button>
                        </div>
                        <div className={"flex-1 pl-2 " + messageClass}>{message}</div>
                        <div className="">
                            <Button className='btn-small' onClick={() => processPayments() }>Process Payments</Button>
                        </div>
                    </div>

                    <div className="clear pt-4">
                        <BillsList bills={bills} handleSelectedRowsChange={handleSelectedRowsChange} />


                        {modalOpen && (
                            <div className="modal-wrapper">
                                <div className="modal-content !mt-[10%]">
                                    <Button onClick={closeModal} size="sm" color="ghost" shape="circle"
                                        className="absolute right-2 min-h-[1.5rem] h-[1.5rem] w-[1.5rem]">x</Button>

                                    <BatchPreferencesForm bankAccounts={bankAccounts} handleBatchPreferences={handleBatchPreferences} />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            }
            {IsTrue(showPrintCheckQueue) &&                
                <PrintCheckQueue pBankId={bankId} loadCheckPayments={loadCheckPayments}></PrintCheckQueue>
            }
        </div>
    );
}