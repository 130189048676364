import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { EntityAutocomplete } from '../_reactHelpers/EntityAutocomplete';
import { post, get } from '../../services/apiService';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { SetDocumentTitle } from '../_reactHelpers/ReactHelpers';
import {
    AgCurrencyMxWd, AgCurrencyMxWdSm, Exists, formatNumberDecPlaces,
    FormatNumberDecimal, GetDisplayCurrencyStr, GetDisplayDateStr, GetDisplayDecimalStr,
    GetInputDate, GetInputDecimalStr, handleFieldChange, IdExists, IsFalse, IsTrue, NotEmpty,
    NotExists, NotStringEmpty, ParseIntSafe, PreventDefault
} from '../../js/formHelpers';

import { GetValueById, SetPageTitle } from '../../js/helpers';
import { NotEquals } from '../../js/calculationHelpers';

export function PostDeposits({ bankId, onBankRegisterAdded, onClose }) {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Post Deposits");
    const isBankRegisterPage = IdExists(bankId);

    const [depositRegisters, setDepositRegisters] = useState([]);
    const [banks, setBanks] = useState([]);

    const [stores, setStores] = useState([]);
    const [storeNames, setStoreNames] = useState([]);
    const [chartAccounts, setChartAccounts] = useState([]);

    // payments
    const [payments, setPayments] = useState([]);
    const [isAnyRowSelected, setIsAnyRowSelected] = useState(false);

    // manual type
    const [allocations, setAllocations] = useState([]);

    // allocation groups type
    const [allocationGroups, setAllocationGroups] = useState([]);
    const [groupAllocations, setGroupAllocations] = useState([]);

    const [isSetLineChartAccountOpen, setIsSetLineChartAccountOpen] = useState(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);

    const [gridApi, setGridApi] = useState(null);
    const [statusMessage, setStatusMessage] = useState('');
    const [isError, setIsError] = useState(false);

    const [step, setStep] = useState(1);
    const [chartAccount, setChartAccount] = useState(null);
    const [lineTotalAmount, setLineTotalAmount] = useState(0);

    const [formData, setFormData] = useState({
        // details
        bankId: ParseIntSafe(bankId),
        date: GetInputDate(),
        description: '',
        status: 'Open',
        transactionType: 'deposit',
        isSalesIncome: true,
        amount: GetInputDecimalStr(0),        
        comment: '',
        // payments
        depositRegisterId: 0,
        selectedPayments: [],
        // allocations
        allocationType: 'Manual',
        storeId: 0,
        allocationGroupId: 0,        
        allocations: [],
        
    });

    const handleContinue = (e) => {
        e.preventDefault();
        if (step === 1) {
            if (formData.isSalesIncome) {
                setStep(2);
            } else {
                setStep(3);
            }
        } else if (step === 2) {
            handleSubmit(e);
        }
    };

    const handleBack = () => {
        if (step === 2 || step === 3) {
            setStep(1);
        }
    };

    const defaultColDef = {
        minWidth: 100,
        flex: 1,
        editable: true,
        sortable: true,
        resizable: true,
        filter: true,
        suppressMovable: true,
        suppressMenu: false,
        cellClass: ["no-border"],
        menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
    };

    const columnDefs = [
        //{ field: 'depositRegisterId', headerName: 'Deposit Register ID', maxWidth: 180, hide: true },
        {
            headerName: '', maxWidth: 50,
            checkboxSelection: true,
            headerCheckboxSelection: true,
        },
        {
            field: 'paymentId', headerName: 'Payment ID', maxWidth: 130
        },
        {
            field: 'amount', headerName: 'Amount', width: 200,
            valueFormatter: params => GetDisplayCurrencyStr(params.value)
        },
        {
            field: 'paymentDate', headerName: 'Payment Date',
            width: 200,
            valueFormatter: params => GetDisplayDateStr(params.value)
        }
    ];

    const onSelectionChanged = useCallback((event) => {
        const selectedRows = event.api.getSelectedRows();
        const selectedPaymentIds = selectedRows.map(row => row.paymentId);
        setFormData(prevState => ({ ...prevState, selectedPayments: selectedPaymentIds }));
        setIsAnyRowSelected(selectedRows.length > 0);
    }, []);

    function getStoreNamesList(storeData) {
        var list = [];
        storeData.forEach((store, index) => {
            var name = getStoreNameById(store.storeId, storeData);
            list.push(name);
        });
        return list;
    }

    function getStoreNameById(storeId, storeData) {
        storeData = storeData ?? stores;
        storeId = ParseIntSafe(storeId);
        const store = storeData.find(store => store.storeId === storeId);
        if (Exists(store)) {
            return "#" + store.storeNumber + " - " + store.name;
        }
        return "";
    }

    const getStoreIdByName = useCallback((storeName) => {
        const store = stores.find(store => ("#" + store.storeNumber + " - " + store.name) === storeName);
        if (Exists(store)) {
            return store.storeId;
        }
        return 0;
    }, [stores]);

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
    }, []);

    const getChartAccountNameById = useCallback((id, data) => {
        if (NotExists(data)) {
            data = chartAccounts ?? [];
        }
        id = ParseIntSafe(id);

        const chart = data.find(c => c.chartAccountId === id);
        if (Exists(chart)) {
            return chart.accountNumber + " - " + chart.accountName;
        }
        return "";
    }, [chartAccounts]);

    const getChartIdByName = useCallback((chartName) => {
        var data = chartAccounts ?? [];
        const chart = data.find(chart => (chart.accountNumber + " - " + chart.accountName) === chartName);
        if (Exists(chart)) {
            return chart.chartAccountId;
        }
        return 0;
    }, [chartAccounts]);

    const handleAddAllocation = () => {
        const storeName = getStoreNameById(formData.storeId);
        var chartAccountId = chartAccount?.chartAccountId ?? 0;
        var glAccountCode = getChartAccountNameById(chartAccountId);

        if (formData.allocationType === 'Manual') {

            const newAllocation = {
                storeId: formData.storeId,
                storeName: storeName,
                chartAccountId: chartAccountId,
                glAccountCode: glAccountCode,
                amount: 0,
            };
            setAllocations(prevAllocations => {
                const updatedAllocations = [...prevAllocations, newAllocation];
                return updatedAllocations;
            });
        }
        else {
            const newAllocation = {
                storeId: formData.storeId,
                storeName: storeName,
                chartAccountId: chartAccountId,
                glAccountCode: glAccountCode,
                allocationPercent: 0,
                amount: 0,
            };
            setGroupAllocations(prevAllocations => {
                const updatedAllocations = [...prevAllocations, newAllocation];
                return updatedAllocations;
            });
        }

        //setAllocations(prevAllocations => {
        //    const updatedAllocations = [...prevAllocations, newAllocation];
        //    setTimeout(() => {
        //        if (gridApi) {
        //            const rowIndex = updatedAllocations.length - 1;
        //            //const rowNode = gridApi.getDisplayedRowAtIndex(rowIndex);
        //            gridApi.setFocusedCell(rowIndex, 'storeName');
        //            gridApi.startEditingCell({
        //                rowIndex: rowIndex,
        //                colKey: 'storeName'
        //            });
        //        }
        //    }, 0);
        //    return updatedAllocations;
        //});
    };

    const handleRemoveAllocation = (params) => {
        if (formData.allocationType === 'Manual') {
            const updatedLineItems = allocations.filter((item, index) => index !== params.node.rowIndex);
            setAllocations(updatedLineItems);
        }
        else {
            const updatedLineItems = groupAllocations.filter((item, index) => index !== params.node.rowIndex);
            setGroupAllocations(updatedLineItems);
        }
    };

    const onManualCellValueChanged = useCallback((event) => {
        const updatedLineItems = [...allocations];
        const updatedRow = { ...updatedLineItems[event.rowIndex] };

        if (event.colDef.field === "storeName") {
            const storeId = getStoreIdByName(event.newValue);
            updatedRow["storeId"] = storeId;
            updatedRow[event.colDef.field] = event.newValue;
        }
        else if (event.colDef.field === "glAccountCode") {
            const chartAccountId = getChartIdByName(event.newValue);
            updatedRow["chartAccountId"] = chartAccountId;
            updatedRow[event.colDef.field] = event.newValue;
        }
        else {
            updatedRow[event.colDef.field] = event.newValue;
        }
        updatedLineItems[event.rowIndex] = updatedRow;
        setAllocations(updatedLineItems);

    }, [allocations, setAllocations, getStoreIdByName, getChartIdByName]);

    const manualAllocationColumnDefs = [
        {
            field: 'storeName', headerName: 'Store',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: storeNames,
            },
        },
        {
            field: '', headerName: 'GL Account',
            editable: false,
            cellRenderer: (params) => {
                return (
                    <button type="button" className="link-primary underline"
                        onClick={() => openSetLineChartAccount(params)}>
                        {NotStringEmpty(params.data.glAccountCode) ? params.data.glAccountCode : "Select"}
                    </button>
                );
            },
        },
        {
            field: 'amount', headerName: 'Amount', maxWidth: AgCurrencyMxWdSm(),
            valueFormatter: params => GetDisplayCurrencyStr(params.value)
        },
        {
            headerName: '', field: '', maxWidth: 80,
            editable: false,
            suppressNavigable: true,
            cellRenderer: (params) => {
                return (
                    <button type="button" onClick={() => handleRemoveAllocation(params)}
                        className="btn-grid-red" tabIndex="-1"
                    >Delete</button>
                );
            },
        }
    ];

    const groupAllocationColumnDefs = [
        {
            field: 'storeName', headerName: 'Store',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: storeNames
            },
        },
        {
            field: '', headerName: 'GL Account',
            editable: false,
            cellRenderer: (params) => {
                return (
                    <button type="button" className="link-primary underline"
                        onClick={() => openSetLineChartAccount(params)}>
                        {NotStringEmpty(params.data.glAccountCode) ? params.data.glAccountCode : "Select"}
                    </button>
                );
            },
        },
        {
            field: 'allocationPercent', headerName: 'Percent', maxWidth: AgCurrencyMxWd(),
            valueFormatter: params => GetDisplayDecimalStr(params.value) + "%"
        },
        {
            field: 'amount', headerName: 'Amount', maxWidth: AgCurrencyMxWd(),
            editable: false,
            valueFormatter: params => GetDisplayCurrencyStr(params.value)
        },
        {
            headerName: '', field: '', maxWidth: 80,
            editable: false,
            suppressNavigable: true,
            cellRenderer: (params) => {
                return (
                    <button type="button" onClick={() => handleRemoveAllocation(params)}
                        className="btn-grid-red" tabIndex="-1"
                    >Delete</button>
                );
            },
        }
    ];

    const onAllocCellValueChanged = useCallback((event) => {
        const updatedLineItems = [...groupAllocations];
        const updatedRow = { ...updatedLineItems[event.rowIndex] };

        if (event.colDef.field === "storeName") {
            const storeId = getStoreIdByName(event.newValue);
            updatedRow["storeId"] = storeId;
            updatedRow[event.colDef.field] = event.newValue;
        }
        else if (event.colDef.field === "glAccountCode") {
            const chartAccountId = getChartIdByName(event.newValue);
            updatedRow["chartAccountId"] = chartAccountId;
            updatedRow[event.colDef.field] = event.newValue;
        }
        else {
            updatedRow[event.colDef.field] = event.newValue;
        }
        updatedLineItems[event.rowIndex] = updatedRow;
        setGroupAllocations(updatedLineItems);

    }, [groupAllocations, setGroupAllocations, getStoreIdByName, getChartIdByName]);

    const fetchGroupAllocations = (groupId) => {
        var groupAllocs = [];

        const selectedGroup = allocationGroups.find(group => group.groupId.toString() === groupId.toString());
        if (selectedGroup && selectedGroup.allocationItems) {

            var chartAccountId = chartAccount?.chartAccountId ?? 0;
            var glAccountCode = getChartAccountNameById(chartAccountId);

            groupAllocs = selectedGroup.allocationItems.map(item => ({
                storeId: item.storeId,
                storeName: getStoreNameById(item.storeId),
                allocationPercent: parseFloat(item.allocationPercent),
                amount: parseFloat((parseFloat(item.allocationPercent) / 100) * parseFloat(formData.amount)),
                chartAccountId: chartAccountId,
                glAccountCode: glAccountCode
            }));
            setGroupAllocations(groupAllocs);
        }
        else {
            console.error('Selected group not found or has no allocation items');
            setStatusMessage('Error: Selected group not found or has no allocation items');
            setGroupAllocations([]);
        }
    };

    const fetchBanks = async () => {
        try {
            const response = await get('bankaccount/GetAllBankAccounts');
            setBanks(response || []);
            setIsError(false);
        } catch (error) {
            setStatusMessage('Error fetching banks');
            setIsError(true);
        }
    }

    const fetchStores = async () => {
        try {
            const response = await get("/store/GetAllStores");
            setStores(response || []);
            var snList = getStoreNamesList(response || []);
            setStoreNames(snList);

            setIsError(false);
        } catch (error) {
            setStatusMessage("Error fetching stores");
            setIsError(true);
        }
    };

    const fetchAllocationGroups = async () => {
        try {
            const response = await get('storeallocation/GetStoreAllocationGroups');
            setAllocationGroups(response);
        } catch (error) {
            console.error('Error fetching allocation groups:', error);
            setStatusMessage('Error fetching allocation groups');
        }
    };

    const fetchDepositRegisters = async () => {
        try {
            const response = await get(`depositregisters/GetDepositRegisters`);
            setDepositRegisters(response);
        } catch (error) {
            console.error('Error fetching deposit registers:', error);
            setStatusMessage('Error fetching deposit registers');
        }
    };

    const fetchPayments = useCallback(async (depositRegisterId) => {
        try {
            const response = await get(`/payments/GetUnassignedPayments/${depositRegisterId}`);            
            setPayments(response);

            setFormData(prevState => ({
                ...prevState,
                selectedPayments: []
            }));
        } catch (error) {
            setStatusMessage('Error fetching payments');
            console.error('Error fetching payments:', error);
        }
    }, []);


    const fetchChartAccounts = async () => {
        try {
            const response = await get("chartaccount/GetChartAccounts");
            setChartAccounts(response ?? []);
        }
        catch (error) {
            console.error("Error fetching chart account:", error);
            setChartAccounts([]);
        }
    }

    const handleChange = (e) => {
        var data = handleFieldChange(e);
        if (data) {
            setFormData(prevState => ({ ...prevState, [data.name]: data.value }));

            if (data.name === 'depositRegisterId') {
                fetchPayments(data.value);
            }
            if (data.name === 'allocationGroupId') {
                fetchGroupAllocations(data.value);
            }
        }
    };

    const openSetLineChartAccount = (params) => {
        if (NotStringEmpty(params.rowIndex)) {
            setSelectedRowIndex(params.rowIndex);
            setIsSetLineChartAccountOpen(true);
        }
    };

    const handleSelectLineGlAccount = (selectedOption) => {
        if (Exists(selectedRowIndex) && IdExists(selectedOption.chartAccountId)) {

            if (formData.allocationType === 'Allocations') {
                const updatedLineItems = [...groupAllocations];
                const updatedRow = { ...updatedLineItems[selectedRowIndex] };

                const glAccountCode = getChartAccountNameById(selectedOption.chartAccountId);
                updatedRow["glAccountCode"] = glAccountCode;
                updatedRow["chartAccountId"] = selectedOption.chartAccountId;

                updatedLineItems[selectedRowIndex] = updatedRow;
                setGroupAllocations(updatedLineItems);
            }
            else {
                const updatedLineItems = [...allocations];
                const updatedRow = { ...updatedLineItems[selectedRowIndex] };

                const glAccountCode = getChartAccountNameById(selectedOption.chartAccountId);
                updatedRow["glAccountCode"] = glAccountCode;
                updatedRow["chartAccountId"] = selectedOption.chartAccountId;

                updatedLineItems[selectedRowIndex] = updatedRow;
                setAllocations(updatedLineItems);
            }
        }
        setSelectedRowIndex(null);
        setIsSetLineChartAccountOpen(false);
    };

    const handleSelectGlAccount = (selectedOption) => {
        setChartAccount(selectedOption ?? null);

        if (Exists(selectedOption)) {
            var chartAccountName = getChartAccountNameById(selectedOption.chartAccountId);
            if (NotStringEmpty(chartAccountName)) {

                if (formData.allocationType === 'Allocations') {
                    let lineItems = groupAllocations.map(item => ({
                        ...item,
                        chartAccountId: selectedOption.chartAccountId,
                        glAccountCode: chartAccountName
                    }));
                    setGroupAllocations(lineItems);
                }
                else {
                    let lineItems = allocations.map(item => ({
                        ...item,
                        chartAccountId: selectedOption.chartAccountId,
                        glAccountCode: chartAccountName
                    }));
                    setAllocations(lineItems);
                }
            }
        }
    };

    const getLineTotalCss = useCallback(() => {
        return (NotEquals(parseFloat(formData.amount), parseFloat(lineTotalAmount)) ?
            "text-red" : "text-green");
    }, [formData.amount, lineTotalAmount]);

    const handleSubmit = async (e) => {
        PreventDefault(e);
        try {
            var selectedAllocations = [];            

            // sales income
            if (IsTrue(formData.isSalesIncome)) {
                let lineSum = 0;
                formData.selectedPayments.forEach(pId => {
                    const payment = payments.find(item => item.paymentId === pId);
                    if (Exists(payment)) {
                        lineSum += parseFloat(payment.amount ?? 0);
                    }
                });

                let totalsEqual = FormatNumberDecimal(formData.amount) === FormatNumberDecimal(lineSum);
                if (IsFalse(totalsEqual)) {
                    alert('Deposit Amount and selected Payments Total must be equal.');
                    return;
                }
            }
            // allocations
            else {
                selectedAllocations = formData.allocationType === 'Manual' ? (allocations ?? []) : (groupAllocations ?? []);
                                
                let lineSum = 0;
                selectedAllocations.forEach(line => {
                    lineSum += parseFloat(line.amount) ?? parseFloat(0);
                });

                let totalsEqual = FormatNumberDecimal(formData.amount) === FormatNumberDecimal(lineSum);
                if (IsFalse(totalsEqual)) {
                    alert('Deposit Amount and Line Items Total must be equal.');
                    return;
                }
            }

            const selectedGroup = allocationGroups.find(group => group.groupId.toString() === formData.allocationGroupId.toString());
            const allocationName = selectedGroup ? selectedGroup.groupName : '';

            const submitData = {
                bankId: Number(formData.bankId),
                date: formData.date,
                description: formData.description,
                status: formData.status,
                transactionType: formData.transactionType,
                isSalesIncome: formData.isSalesIncome,
                amount: FormatNumberDecimal(formData.amount),
                selectedPayments: formData.selectedPayments,
                allocationType: formData.allocationType,
                allocationGroupId: formData.allocationGroupId,
                allocationName: allocationName,
                comment: formData.comment,
                allocations: selectedAllocations.map(alloc => ({
                    storeId: parseInt(alloc.storeId),
                    chartAccountId: ParseIntSafe(alloc.chartAccountId),
                    amount: FormatNumberDecimal(alloc.amount)
                })),
            };
            //console.log('Submitting data:', submitData);

            const response = await post('/bankregister/CreateBankRegisterAndDeposits', submitData);
            //console.log('Server response:', response);
            setStatusMessage('Bank Register Added Successfully');
            setIsError(false);

            if (IsTrue(isBankRegisterPage)) {
                onBankRegisterAdded();
            }
            else {
                window.location.reload();
            }
        } catch (error) {
            console.error('Error adding Bank Register:', error);
            setStatusMessage('Error adding Bank Register: ' + (error.response?.data || error.message));
            setIsError(true);
        }
    };

    useEffect(() => {
        SetPageTitle("Post Deposits");
        fetchBanks();
        fetchDepositRegisters();
        fetchStores();
        fetchAllocationGroups();
        fetchChartAccounts();
    }, []);

    useEffect(() => {
        var lineTotal = 0;

        // payments
        if (IsTrue(formData.isSalesIncome) && NotEmpty(formData.selectedPayments)) {

            formData.selectedPayments.forEach(pId => {
                const payment = payments.find(item => item.paymentId === pId);
                if (Exists(payment)) {
                    lineTotal += parseFloat(payment.amount ?? 0);
                }
            });
        }
        // manual allocations
        else if (formData.allocationType === 'Manual' && NotEmpty(allocations)) {

            allocations.forEach(item => {
                lineTotal += parseFloat(item.amount ?? 0);
            });
        }
        // allocation group
        else if (formData.allocationType === 'Allocations' && NotEmpty(groupAllocations)) {

            var updatedAllocations = groupAllocations.map(item => {
                const allocationPercent = formatNumberDecPlaces(item.allocationPercent, 2) || 0;
                return {
                    ...item,
                    allocationPercent: GetInputDecimalStr(allocationPercent),
                    amount: GetInputDecimalStr(((allocationPercent / 100) * parseFloat(formData.amount)))
                };
            });
            if (JSON.stringify(updatedAllocations) !== JSON.stringify(groupAllocations)) {
                setGroupAllocations(updatedAllocations);
            }

            updatedAllocations.forEach(item => {
                lineTotal += parseFloat(item.amount ?? 0);
            });
        }
        setLineTotalAmount(FormatNumberDecimal(lineTotal));

    }, [formData.amount, formData.isSalesIncome, formData.selectedPayments, payments,
        formData.allocationType, allocations, groupAllocations]);        

    const renderStep1 = () => (
        <div className="">
            <h2 className="text-2xl font-bold mb-4 text-center underline">Deposit Details</h2>
            <form onSubmit={handleContinue} className="space-y-4">
                <div className="flex space-x-4">
                    <div className="flex-1">
                        <label htmlFor="date" className="block text-sm font-medium text-gray-700">Date</label>
                        <input
                            type="date"
                            id="date"
                            name="date"
                            value={formData.date}
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            required
                        />
                    </div>
                    {IsFalse(isBankRegisterPage) &&
                        <div className="flex-1">
                            <label htmlFor="bankId" className="block text-sm font-medium text-gray-700">Bank Account</label>
                            <select
                                id="bankId"
                                name="bankId"
                                value={formData.bankId || 0}
                                onChange={handleChange}
                                required
                                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            >
                                <option value="">-- Select --</option>
                                {banks && banks.map(bank => (
                                    <option key={bank.bankId} value={bank.bankId}>                                        
                                        {`${bank.bankName} - ${bank.bankAccountName}`}
                                    </option>
                                ))}
                            </select>
                        </div>
                    }
                </div>
                <div>
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">Description</label>
                    <input
                        type="text"
                        id="description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        required
                    />
                </div>
                <div className="flex space-x-4">
                    <div className="flex-1">
                        <label htmlFor="status" className="block text-sm font-medium text-gray-700">Status</label>
                        <select
                            id="status"
                            name="status"
                            value={formData.status}
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        >
                            <option value="Open">Open</option>
                            <option value="Cleared">Cleared</option>
                            <option value="Voided">Voided</option>
                        </select>
                    </div>
                    <div className="flex-1">
                        <label htmlFor="transactionType" className="block text-sm font-medium text-gray-700">Transaction Type</label>
                        <select
                            id="transactionType"
                            name="transactionType"
                            value={formData.transactionType}
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            disabled
                        >
                            <option value="deposit">Deposit</option>
                        </select>
                    </div>
                </div>
                <div>
                    <label htmlFor="isSalesIncome" className="flex justify-end">
                        <input type="checkbox" id="isSalesIncome" name="isSalesIncome" className="input-cb"
                            checked={formData.isSalesIncome} onChange={handleChange} />
                        <span className="text-sm font-medium text-gray-700 pl-1">Is Sales Income</span>
                    </label>
                </div>
                <div className="flex justify-end space-x-2 pt-2">
                    {IsTrue(isBankRegisterPage) &&
                        <button type="button" onClick={() => onClose()} className="btn-cancel">Cancel</button>
                    }
                    <button type="submit" className="btn-submit">Continue</button>
                </div>
            </form>
        </div>
    );

    const renderStep2 = () => (
        <div className="">
            <h2 className="text-2xl font-bold mb-4 text-center underline">Sales Income Details</h2>
            <form onSubmit={handleContinue} className="space-y-4">
                <div className="flex space-x-4">
                    <div className="flex-1">
                        <label htmlFor="amount" className="block text-sm font-medium text-gray-700">Amount</label>
                        <div className="mt-1 relative rounded-md shadow-sm">
                            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                <span className="text-gray-500 sm:text-sm">$</span>
                            </div>
                            <input type="number" name="amount" step="0.01"
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                                value={formData.amount}
                                onChange={handleChange}
                                onBlur={(e) => { setFormData(prev => ({ ...prev, [e.target.name]: GetInputDecimalStr(e.target.value) })); }}
                            />
                        </div>
                    </div>
                    <div className="flex-1">
                        <label htmlFor="depositRegisterId" className="block text-sm font-medium text-gray-700">Deposit Register</label>
                        <select
                            id="depositRegisterId"
                            name="depositRegisterId"
                            value={formData.depositRegisterId || 0}
                            onChange={handleChange}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        >
                            <option value="">-- Select --</option>
                            {depositRegisters && depositRegisters.map(register => (
                                <option key={register.depositRegisterId} value={register.depositRegisterId}>
                                    {`${register.registerName}`}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                <div>
                    <label htmlFor="comment" className="block text-sm font-medium text-gray-700">Comment</label>
                    <textarea id="comment" name="comment"
                        value={formData.comment}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        rows="2"
                    ></textarea>
                </div>
                <div className="justify-end">
                    <span className="pr-1">Payments Total Amount:</span>
                    <span className={"font-bold " + getLineTotalCss()}>{GetDisplayCurrencyStr(lineTotalAmount)}</span>
                </div>

                <div className="ag-theme-alpine" style={{ height: 400, width: '100%' }}>
                    <AgGridReact
                        rowData={payments}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}                        
                        onGridReady={onGridReady}
                        onSelectionChanged={onSelectionChanged}
                        rowSelection="multiple"
                        rowMultiSelectWithClick={true}
                    />
                </div>
                <div className="flex justify-end space-x-2">
                    <button type="button" onClick={handleBack} className="btn-cancel">Back</button>
                    <button type="submit"
                        className={`btn-submit ${!isAnyRowSelected ? 'opacity-50 cursor-not-allowed' : ''}`}
                        disabled={!isAnyRowSelected}>
                        Add Deposit
                    </button>
                </div>
            </form>
        </div>
    );

    const renderStep3 = () => (
        <div className="">
            <h2 className="text-2xl font-bold mb-4 text-center underline">Non-Sales Details</h2>
            <form onSubmit={handleSubmit} className="space-y-4">
                <div className="flex-wrapper">
                    <div className="">
                        <label className="label-form pr-4">Allocation Type</label>
                    </div>
                    <div className="pb-[2px]">
                        <label className="mr-4">
                            <input type="radio" name="allocationType" value="Manual"
                                checked={formData.allocationType === 'Manual'}
                                onChange={handleChange}
                                className="form-radio cursor-pointer mb-[2px]"
                            />
                            <span className="ml-1">Manual</span>
                        </label>
                    </div>
                    <div className="pb-[2px]">
                        <label className="">
                            <input type="radio" name="allocationType" value="Allocations"
                                checked={formData.allocationType === 'Allocations'}
                                onChange={handleChange}
                                className="form-radio cursor-pointer mb-[2px]"
                            />
                            <span className="ml-1">Allocations</span>
                        </label>
                    </div>
                </div>

                <div className="flex space-x-1">
                    <div className="flex-1">
                        <label htmlFor="allocationGlCode" className="block text-sm font-medium text-gray-700">Store</label>
                        <select name="storeId" className="select select-bordered"
                            value={formData.storeId || 0}
                            onChange={handleChange}
                            required
                        >
                            <option value={0}>-- Select --</option>
                            {NotEmpty(stores) && stores.map((store) => (
                                <option key={store.storeId} value={store.storeId}>
                                    {"#" + store.storeNumber + " - " + store.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex-1">
                        <label htmlFor="allocationGlCode" className="block text-sm font-medium text-gray-700">GL Account</label>
                        <EntityAutocomplete name="allocationGlCode" id="allocationGlCode"
                            onSelect={handleSelectGlAccount} onChange={null} initialEntityId={null}
                            entityType="GL Account"
                            required
                        />
                    </div>
                </div>

                <div className="">
                    <label htmlFor="amount" className="block text-sm font-medium text-gray-700">Amount</label>
                    <div className="mt-1 relative rounded-md shadow-sm">
                        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                            <span className="text-gray-500 sm:text-sm">$</span>
                        </div>
                        <input type="number" name="amount" step="0.01"
                            className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                            value={formData.amount}
                            onChange={handleChange}
                            onBlur={(e) => { setFormData(prev => ({ ...prev, [e.target.name]: GetInputDecimalStr(e.target.value) })); }}
                        />
                    </div>
                </div>

                <div className="flex-1">
                    <label htmlFor="comment" className="block text-sm font-medium text-gray-700">Comment</label>
                    <textarea id="comment" name="comment"
                        className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        value={formData.comment} onChange={handleChange}
                        rows="2"
                    ></textarea>
                </div>

                {formData.allocationType === 'Manual' &&
                    <div>
                        <div className="flex-wrapper">
                            <div className="flex-grow">
                                <button type="button" onClick={handleAddAllocation} className="btn-grid">
                                    Add Allocation
                                </button>
                            </div>
                            <div className="">
                                <span className="pr-1">Line Total Amount:</span>
                                <span className={"font-bold " + getLineTotalCss()}>{GetDisplayCurrencyStr(lineTotalAmount)}</span>
                            </div>
                        </div>
                        <div className="ag-theme-alpine ag-grid-act" style={{ height: 350, width: '100%' }}>
                            {/* MANUAL ALLOCATION */}
                            <AgGridReact
                                rowData={allocations}
                                columnDefs={manualAllocationColumnDefs}
                                defaultColDef={defaultColDef}
                                onCellValueChanged={onManualCellValueChanged}
                                editType="fullRow"
                                singleClickEdit={true}
                                enterNavigatesVerticallyAfterEdit={true}
                                stopEditingWhenCellsLoseFocus={true}
                            />
                        </div>
                    </div>
                }
                {formData.allocationType === 'Allocations' &&
                    <div>
                        <div className="">
                            <label htmlFor="allocationGroupId" className="block text-sm font-medium text-gray-700">Allocation Group</label>
                            <select id="allocationGroupId" name="allocationGroupId" className="select select-bordered"
                                value={formData.allocationGroupId}
                                onChange={handleChange}
                            >
                                <option value="">-- Select --</option>
                                {allocationGroups.map(group => (
                                    <option key={group.groupId} value={group.groupId}>{group.groupName}</option>
                                ))}
                            </select>
                        </div>

                        <div className="flex-wrapper pt-3">
                            <div className="flex-grow">
                                <button type="button" onClick={handleAddAllocation} className="btn-grid">
                                    Add Allocation
                                </button>
                            </div>
                            <div className="">
                                <span className="pr-1">Line Total Amount:</span>
                                <span className={"font-bold " + getLineTotalCss()}>{GetDisplayCurrencyStr(lineTotalAmount)}</span>
                            </div>
                        </div>
                        <div className="ag-theme-alpine ag-grid-act" style={{ height: 350, width: '100%' }}>
                            {/* GROUP ALLOCATION */}
                            <AgGridReact
                                rowData={groupAllocations}
                                columnDefs={groupAllocationColumnDefs}
                                defaultColDef={defaultColDef}
                                onCellValueChanged={onAllocCellValueChanged}
                                editType="fullRow"
                                singleClickEdit={true}
                                enterNavigatesVerticallyAfterEdit={true}
                                stopEditingWhenCellsLoseFocus={true}
                            />
                        </div>
                    </div>
                }

                <div className="flex justify-end space-x-2">
                    <button type="button" onClick={handleBack} className="btn-cancel">Back</button>
                    <button type="submit" className="btn-submit">Add Deposit</button>
                </div>
            </form>
        </div>
    );

    return (
        <div className="container mx-auto p-2">
            <div className="max-w-[800px] w-full mx-auto bg-white rounded-lg">
                <div className="p-4 pt-2">
                    {step === 1 && renderStep1()}
                    {step === 2 && renderStep2()}
                    {step === 3 && renderStep3()}
                    {statusMessage && (
                        <div className={`p-4 rounded-md ${isError ? 'bg-red-100 text-red-700' : 'bg-green-100 text-green-700'}`}>
                            {statusMessage}
                        </div>
                    )}
                </div>
            </div>

            {/*** Add Line GL Account ***/}
            {IsTrue(isSetLineChartAccountOpen) &&
                <div className="modal-wrapper">
                    <div className="modal-content top-30">
                        <h2 className="header-act pb-4">Select Line GL Account</h2>
                        <div>
                            <EntityAutocomplete name="chartAccountId" id="chartAccountId"
                                onSelect={handleSelectLineGlAccount} onChange={null} initialEntityId={null}
                                entityType="GL Account"
                                required
                            />
                        </div>
                        <div className="modal-action mt-6">
                            <button type="button" onClick={e => setIsSetLineChartAccountOpen(false)} className="btn-cancel">Cancel</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}