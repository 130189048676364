import React, { } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

// flowbite
//import 'flowbite/dist/flowbite.min.css';
import 'flowbite/dist/flowbite.min.js';
import 'flowbite/dist/datepicker.js';

// include both tailwinds files for DEV and PUBLISHED environments
import './css/tailwind-compiled.css';
import './css/tailwind-imports.css';

// custom css
import './css/index.css';
import './css/custom.css';

// ag grid key
import { LicenseManager } from 'ag-grid-enterprise';
LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-048026 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Remote Tech Resources )_is_granted_a_( Single Application )_Developer_License_for_the_application_( CFM Lumina )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( CFM Lumina )_need_to_be_licensed___( CFM Lumina )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 19 September 2024 )____[v2]_MTcyNjcwMDQwMDAwMA==600e43bb67f41dea9cffadc1eb1b15b4");


const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
    <BrowserRouter basename={baseUrl}>
        <App />
    </BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
