import React, { Component } from 'react';
import { Container } from 'reactstrap';

import { SetPageTitle } from '../js/helpers.js';

import { initFlowbite } from "flowbite";
import { Header } from './Header/Header';
import { SetDocTitle } from './_reactHelpers/ReactHelpers.js';

export class Layout extends Component {
  static displayName = Layout.name;
    
    componentDidMount() {        
        SetDocTitle("Lumina");

        initFlowbite();
    }

    render() {
      return (
          <div>
              <div className="">
                  <Header tag="header" />
              </div>
              <div id="responsiveOuterContainer" className="responsive-outer">
                  <Container id="layoutContainer" tag="main" className="h-screen">
                      {this.props.children}
                  </Container>
              </div>
          </div>
      );
  }
}
