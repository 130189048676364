import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { EntityAutocomplete } from '../_reactHelpers/EntityAutocomplete';
import {
    AgCurrencyMxWdSm, AgIntMxWdSm, defaultErrMsg, EnforceNumDecimalPlaces, enforceZipFormat, enforceZipLength,
    Exists, formatCurrencyDecimal, FormatNumberDecimal, formatNumberDecPlaces, formatToPhone, GetDateDiffDays,
    GetDateNow, GetDisplayCurrencyStr, GetDisplayDecimalStr, GetDisplayIntStr, GetInputDate, GetInputDecimalStr, handleFieldChange,
    IdExists, IsFalse, isSelectedDropdown, IsTrue, NotEmpty, NotExists, NotStringEmpty, ParseIntSafe, PreventDefault
} from '../../js/formHelpers';

import { get, post } from '../../services/apiService';
import { GreaterThan, LessThanEq, NotEquals } from '../../js/calculationHelpers';

import { createVendorModel } from '../Vendors/_vendorHelpers.js';

export const AddEditNonSupplierBillModal = ({ initialBillData, handleSubmit, stores, onHide }) => {
    const vendorAcRef = useRef();
    const chartAccountAcRef = useRef();
    
    const isEditMode = IdExists(initialBillData.billId);
    const [localFormData, setLocalFormData] = useState(initialBillData);

    const [billNumberUsed, setBillNumberUsed] = useState(false);    
    const [chartAccount, setChartAccount] = useState(null);

    const [isManualAllocation, setIsManualAllocation] = useState(true);
    const [allocationGroups, setAllocationGroups] = useState([]);

    const [isAddVendorOpen, setIsAddVendorOpen] = useState(false);
    const [vendorFormData, setVendorFormData] = useState(null);
    const [vendorFormChartAccount, setVendorFormChartAccount] = useState(null);

    const [isSetLineChartAccountOpen, setIsSetLineChartAccountOpen] = useState(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    
    const storeNames = getStoreNamesList();
    const [chartAccounts, setChartAccounts] = useState([]);
    
    const [gridApi, setGridApi] = useState(null);
    const defaultColDef = useMemo(() => ({
        flex: 1,
        minWidth: 100,
        editable: true,
        singleClickEdit: true,
        stopEditingWhenCellsLoseFocus: true,
        sortable: false,
        resizable: true,
        filter: false,
        suppressMenu: true,
        suppressMovable: true,
        cellClass: ["no-border"]
    }), []);

    function getChartAccountNameById(id, data) {
        if (NotExists(data)) {
            data = chartAccounts ?? [];
        }
        id = ParseIntSafe(id);

        const chart = data.find(c => c.chartAccountId === id);
        if (Exists(chart)) {
            return chart.accountNumber + " - " + chart.accountName;
        }
        return "";
    }


    const getChartIdByName = useCallback((chartName) => {
        var data = chartAccounts ?? [];
        const chart = data.find(chart => (chart.accountNumber + " - " + chart.accountName) === chartName);
        if (Exists(chart)) {
            return chart.chartAccountId;
        }
        return 0;
    }, [chartAccounts]);

    function getStoreNamesList() {
        var list = [];
        stores.forEach((store, index) => {
            var name = getStoreNameById(store.storeId);
            list.push(name);
        });
        return list;
    }

    function getStoreNameById(storeId) {
        storeId = ParseIntSafe(storeId);
        const store = stores.find(store => store.storeId === storeId);
        if (Exists(store)) {
            return "#" + store.storeNumber + " - " + store.name;
        }
        return "";
    }

    const getStoreIdByName = useCallback((storeName) => {
        const store = stores.find(store => ("#" + store.storeNumber + " - " + store.name) === storeName);
        if (Exists(store)) {
            return store.storeId;
        }
        return 0;
    }, [stores]);

    const handleDeleteRow = useCallback((params) => {
        const updatedLineItems = localFormData.billLineItems
            .filter((item, index) => index !== params.node.rowIndex);

        setLocalFormData(prevData => ({
            ...prevData,
            billLineItems: updatedLineItems
        }));
    }, [localFormData.billLineItems]);

    const columnDefs = [
        {
            field: 'lineNumber', headerName: 'Line #', maxWidth: AgIntMxWdSm(), editable: false, hide: true,
            valueFormatter: params => GetDisplayIntStr(params.value)
        },
        {
            field: 'storeName', headerName: 'Store',
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: storeNames
            },    
        },
        {
            field: '', headerName: 'GL Account',
            editable: false,
            cellRenderer: (params) => {
                return (
                    <button type="button" className="link-primary underline"
                        onClick={() => openSetLineChartAccount(params)}>
                        {NotStringEmpty(params.data.glAccountCode) ? params.data.glAccountCode : "Select"}
                    </button>
                );
            },
        },
        {
            field: 'allocationPercent', headerName: 'Percent', maxWidth: AgCurrencyMxWdSm(),
            hide: IsTrue(isManualAllocation),
            valueFormatter: params => GetDisplayDecimalStr(params.value) + "%"
        },
        {
            field: 'total', headerName: 'Amount', maxWidth: AgCurrencyMxWdSm(),
            editable: IsTrue(isManualAllocation),
            valueFormatter: params => GetDisplayCurrencyStr(params.value)
        },
        {
            headerName: '', field: '', maxWidth: 80,
            editable: false,
            suppressNavigable: true,
            cellRenderer: (params) => {
                return (
                    <button type="button" onClick={() => handleDeleteRow(params)}
                        className="btn-grid-red" tabIndex="-1" // Prevent tab focus?
                    >Delete</button>
                );
            },
        }
    ];

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    }, []);

    const onCellValueChanged = useCallback((event) => {
        const updatedLineItems = [...localFormData.billLineItems];
        const updatedRow = { ...updatedLineItems[event.rowIndex] };

        if (event.colDef.field === "storeName") {
            const storeId = getStoreIdByName(event.newValue);
            updatedRow["storeId"] = storeId;
            updatedRow[event.colDef.field] = event.newValue;
        }
        else if (event.colDef.field === "glAccountCode") {
            const chartAccountId = getChartIdByName(event.newValue);
            updatedRow["chartAccountId"] = chartAccountId;
            updatedRow[event.colDef.field] = event.newValue;
        }
        else {
            updatedRow[event.colDef.field] = event.newValue;
        }

        updatedLineItems[event.rowIndex] = updatedRow;
        setLocalFormData(prevData => ({
            ...prevData,
            billLineItems: updatedLineItems
        }));
    }, [localFormData.billLineItems, setLocalFormData, getStoreIdByName, getChartIdByName]);

    const addNewRow = () => {
        const storeName = getStoreNameById(localFormData.storeId);
        var chartAccountId = chartAccount?.chartAccountId ?? 0;
        var glAccountCode = getChartAccountNameById(chartAccountId);

        setLocalFormData(prevData => {
            const newLineNumber = (prevData.billLineItems?.length || 0) + 1;
            const newRow = {
                lineNumber: newLineNumber,
                storeId: localFormData.storeId,
                storeName: storeName,                                
                chartAccountId: chartAccountId,
                glAccountCode: glAccountCode,
                allocationPercent: 0,
                total: 0,
            };

            return {
                ...prevData,
                billLineItems: [
                    ...(prevData.billLineItems || []),
                    newRow
                ]
            };
        });
    };

    const handleChange = (e) => {
        var data = handleFieldChange(e);
        if (data) {
            var name = data.name;
            var value = data.value;

            var dueDate = localFormData.dueDate;
            var discAmt = localFormData.discAmt;
            var amtDisc = localFormData.amtDisc;
            var adjustedTotal = localFormData.adjustedTotal;        
            var discRate = localFormData.discRate;
            var billTotal = localFormData.billTotal;
            var additionalCharges = localFormData.additionalCharges;
            var allocationGroupId = localFormData.allocationGroupId;
            var billLineItems = localFormData.billLineItems ?? [];
            
            if (name === 'discRate') {
                discRate = EnforceNumDecimalPlaces(value, 2);
            }
            if (name === 'additionalCharges') {
                additionalCharges = EnforceNumDecimalPlaces(value, 2);
            }

            if (name === 'billTotal' || name === 'nonDiscAmt' || name === 'discRate' || name === 'additionalCharges') {
                discRate = parseFloat(name === 'discRate' ? discRate : localFormData.discRate) || 0;
                additionalCharges = parseFloat(name === 'additionalCharges' ? additionalCharges : localFormData.additionalCharges) || 0;
                billTotal = parseFloat(name === 'billTotal' ? value : localFormData.billTotal) || 0;
                const nonDiscAmt = parseFloat(name === 'nonDiscAmt' ? value : localFormData.nonDiscAmt) || 0;
                // calculate
                amtDisc = parseFloat(billTotal - nonDiscAmt);
                discAmt = amtDisc * parseFloat(discRate / 100);
                // format
                adjustedTotal = GetInputDecimalStr(billTotal - discAmt + additionalCharges);
                billTotal = GetInputDecimalStr(billTotal);
                amtDisc = GetInputDecimalStr(amtDisc);
                discAmt = GetInputDecimalStr(discAmt);
            }
            
            if (data.name === "dueDate") {
                dueDate = value;
            }
            if (data.name === "billDate") {
                const diffDays = GetDateDiffDays(localFormData.billDate, localFormData.dueDate);
                dueDate = GetInputDate(value, 0, 0, diffDays ?? 0);
            }
            if (data.name === "storeId") {
                setIsManualAllocation(true);
                allocationGroupId = 0;
                
                value = parseInt(value, 10);
                data.value = value;

                const storeName = getStoreNameById(value);
                var chartAccountId = chartAccount?.chartAccountId ?? 0;
                var glAccountCode = getChartAccountNameById(chartAccountId);

                var newLine = {
                    lineNumber: 1,
                    storeId: value,
                    storeName: storeName,
                    chartAccountId: chartAccountId,
                    glAccountCode: glAccountCode,
                    allocationPercent: 0,
                    total: 0
                };                
                //billLineItems = billLineItems.concat([newLine]);  // add to existing
                billLineItems = [newLine];
            }

            setLocalFormData((prevData) => ({
                ...prevData,
                [data.name]: data.value,
                dueDate: dueDate,
                amtDisc: amtDisc,
                discAmt: discAmt,
                discRate: discRate,
                adjustedTotal: adjustedTotal,
                billTotal: billTotal,
                additionalCharges: additionalCharges,
                allocationGroupId: allocationGroupId,
                billLineItems: billLineItems
            }));

            if (data.name === "allocationGroupId") {
                createAllocationRows(value);                
            }
        }
    };

    function createAllocationRows(groupId) {
        var allocations = [];
        setIsManualAllocation(false);        
        groupId = ParseIntSafe(groupId);

        if (NotEmpty(allocationGroups) && IdExists(groupId)) {

            const selectedGroup = allocationGroups.find(group => group.groupId === groupId);
            if (selectedGroup && selectedGroup.allocationItems) {
                                
                var chartAccountId = chartAccount?.chartAccountId ?? 0;
                var glAccountCode = getChartAccountNameById(chartAccountId);                

                allocations = selectedGroup.allocationItems.map(item => ({
                    storeId: item.storeId,
                    storeName: getStoreNameById(item.storeId),
                    allocationPercent: parseFloat(item.allocationPercent),
                    total: (item.allocationPercent / 100) * localFormData.billTotal,
                    chartAccountId: chartAccountId,
                    glAccountCode: glAccountCode
                }));
                console.log('Group Allocations: ' + JSON.stringify(allocations));

            } else {
                console.error('Selected group not found or has no allocation items');                
            }
        }

        setLocalFormData(prevData => ({
            ...prevData,
            billLineItems: allocations
        }));
    }

    const handleSelectGlAccount = (selectedOption) => {
        setChartAccount(selectedOption ?? null);

        if (Exists(selectedOption)) {
            var chartAccountName = getChartAccountNameById(selectedOption.chartAccountId);

            if (NotStringEmpty(chartAccountName)) {
                var billLineItems = localFormData.billLineItems.map(item => ({
                    ...item,
                    chartAccountId: selectedOption.chartAccountId,
                    glAccountCode: chartAccountName
                }));
                setLocalFormData(prevData => ({
                    ...prevData,
                    billLineItems: billLineItems
                }));
            }
        }
    };

    const handleSelectVendor = (selectedOption) => {

        if (IdExists(localFormData.billId)) {
            setLocalFormData(prevData => ({
                ...prevData,
                vendorId: selectedOption.vendorId
            }));
        }
        else {
            var dueDate = localFormData.dueDate;
            if (IsFalse(isEditMode) && GreaterThan(selectedOption.termsDays, 0)) {
                dueDate = GetInputDate(localFormData.billDate, 0, 0, parseFloat(selectedOption.termsDays) + 1);
            }

            setChartAccount(selectedOption.chartAccount ?? null);
            if (Exists(selectedOption.chartAccount)) {
                chartAccountAcRef.current?.fetchEntityById(selectedOption.chartAccount.chartAccountId);
            }

            // TODO: update allocation items when vendor changed?
            //var glAccountCode = getChartAccountNameById(selectedOption.chartAccount?.chartAccountId ?? 0);        
            //var billLineItems = localFormData.billLineItems.map(item => ({
            //    ...item,
            //    chartAccountId: selectedOption.chartAccountId,
            //    glAccountCode: glAccountCode
            //}));

            setLocalFormData(prevData => ({
                ...prevData,
                vendorId: selectedOption.vendorId,
                discRate: GetInputDecimalStr(selectedOption.discountPercent),
                dueDate: dueDate,
                //billLineItems: billLineItems
            }));
        }

        billNumberExists(localFormData.billNumber, selectedOption.vendorId);
    };

    async function billNumberExists(billNumber) {
        setBillNumberUsed(false);
        if (!IdExists(localFormData.billId) && NotStringEmpty(billNumber) && NotStringEmpty(localFormData.vendorId))
        {
            try {
                const billNumberExists = await get("/bills/BillNumberExists/" + billNumber + "/" + localFormData.vendorId);
                if (IsTrue(billNumberExists)) {
                    setBillNumberUsed(true);
                    alert("Invoice Number has already been used for Vendor.")
                }
            } catch (error) {
                console.error('billNumberExists() error - ', error);
            } finally {
            }
        }
    }

    const handleFormSubmit = async (e) => {
        PreventDefault(e);
        try {

            // validation
            if (IsTrue(billNumberUsed)) {
                alert("Invoice Number has already been used for Vendor.")
                return;
            }
            if (!isSelectedDropdown(localFormData.billStatus)) {
                alert("Bill Status is required.");
                return;
            }
            if (LessThanEq(localFormData.billTotal, 0)) {
                alert("Bill Total is required.");
                return;
            }
            if (localFormData.billLineItems.length === 0) {
                alert('Please add at least one line item.');
                return;
            }

            var lineSum = 0;
            localFormData.billLineItems.forEach(line => {
                lineSum += parseFloat(line.total) ?? parseFloat(0);
            });
            //var totalsEqual = parseFloat(localFormData.adjustedTotal) === parseFloat(lineSum);
            const total = FormatNumberDecimal(parseFloat(localFormData.billTotal));
            const lta = FormatNumberDecimal(parseFloat(lineSum));
            if (NotEquals(total, lta)) {
                alert('Total and Line Items Total must be equal.');
                return;
            }

            // form data
            const formDataToSubmit = { ...localFormData };
            formDataToSubmit.vendorId = parseInt(formDataToSubmit.vendorId, 10);            
            formDataToSubmit.billStatus = parseInt(formDataToSubmit.billStatus, 10);
            
            ['billTotal', 'discRate', 'discAmt', 'nonDiscAmt', 'adjustedTotal', 'additionalCharges'].forEach(field => {
                formDataToSubmit[field] = parseFloat(formDataToSubmit[field]);
            });

            formDataToSubmit.billLineItems = formDataToSubmit.billLineItems.map(item => ({
                ...item,
                storeId: getStoreIdByName(item.storeName),
                chartAccountId: getChartIdByName(item.glAccountCode)
            }));

            var storeId = parseInt(formDataToSubmit.storeId, 10);
            if (IdExists(storeId)) {
                formDataToSubmit.storeId = storeId;
            }
            else {
                formDataToSubmit.storeId = formDataToSubmit.billLineItems[0].storeId;
            }
            //console.log('Submitting form data:', formDataToSubmit);

            // post
            var response = null;
            if (isEditMode) {
                response = await post('bills/UpdateBill', formDataToSubmit);
            }
            else {
                response = await post('bills/CreateBill', formDataToSubmit);
            }

            if (IdExists(response)) {
                handleSubmit(true);
            }
        } catch (error) {
            console.error('Error creating bill:', error);

            if (error.response) {
                console.error('Error response:', error.response.data);
                alert(defaultErrMsg());
            } else {
                console.error('Error response:', error.message);
                alert(defaultErrMsg());
            }
        }
    };

    const openSetLineChartAccount = (params) => {
        if (NotStringEmpty(params.rowIndex)) {
            setSelectedRowIndex(params.rowIndex);
            setIsSetLineChartAccountOpen(true);
        }
    };

    const handleSelectLineGlAccount = (selectedOption) => {
        if (Exists(selectedRowIndex) && IdExists(selectedOption.chartAccountId)) {
        
            const updatedLineItems = [...localFormData.billLineItems];
            const updatedRow = { ...updatedLineItems[selectedRowIndex] };

            const glAccountCode = getChartAccountNameById(selectedOption.chartAccountId);
            updatedRow["glAccountCode"] = glAccountCode;
            updatedRow["chartAccountId"] = selectedOption.chartAccountId;            

            updatedLineItems[selectedRowIndex] = updatedRow;
            setLocalFormData(prevData => ({
                ...prevData,
                billLineItems: updatedLineItems
            }));
        }
        setSelectedRowIndex(null);
        setIsSetLineChartAccountOpen(false);
    };

    const openAddVendorModal = (e) => {
        PreventDefault(e);
        if (NotExists(vendorFormData)) {
            setVendorFormData(createVendorModel());
        }
        setIsAddVendorOpen(true); 
    }; 

    const handleSelectVendorGlAccount = (selectedOption) => {
        setVendorFormChartAccount(selectedOption);
        setVendorFormData((prev) => ({
            ...prev,
            chartAccountId: selectedOption.chartAccountId,
        }));
    };

    const handleVendorFormChange = (e) => {
        var data = handleFieldChange(e);
        if (data) {

            if (data.name === "zip") {
                data.value = enforceZipLength(data.value, 50);
            }
            if (data.name === "payToZip") {
                data.value = enforceZipLength(data.value, 50);
            }
            if (data.name === "phone1") {
                data.value = formatToPhone(e);
            }

            setVendorFormData((vendorData) => ({
                ...vendorData,
                [data.name]: data.value
            }));
        }
        else {
            //updateMessage(defaultErrMsg, "text-red", "null handleChange data");
        }
    };

    const handleAddVendorFormSubmit = async (e) => {
        PreventDefault(e);

        try {
            // add as NonMaterialSupplier
            vendorFormData.vendorTypeId = 3;
            vendorFormData.payToAddress1 = vendorFormData.address1;
            vendorFormData.payToAddress2 = vendorFormData.address2;
            vendorFormData.payToCity = vendorFormData.city;
            vendorFormData.payToState = vendorFormData.state;
            vendorFormData.payToZip = vendorFormData.zip;            

            var model = createVendorModel(vendorFormData);
            var response = await post("vendors/VendorAdd", JSON.stringify(model))
            
            var newVendorId = ParseIntSafe(response);
            if (IdExists(newVendorId)) {

                // update Bill form Chart Account for adding lines                
                if (Exists(vendorFormChartAccount)) {
                    setChartAccount(vendorFormChartAccount);
                    chartAccountAcRef.current?.fetchEntityById(vendorFormChartAccount.chartAccountId);
                }
                // set due date from Vendor terms
                var dueDate = GetInputDate(null, 0, 0, vendorFormData.termsDays);

                setLocalFormData(prevData => ({
                    ...prevData,
                    vendorId: newVendorId,
                    discRate: GetInputDecimalStr(vendorFormData.discountPercent),
                    dueDate: dueDate,
                }));

                vendorAcRef.current?.fetchEntityById(newVendorId);
                setVendorFormData(null);
                setVendorFormChartAccount(null);
                setIsAddVendorOpen(false); 
            }

        } catch (error) {
            console.error('Error creating bill:', error);

            if (error.response) {
                console.error('Error response:', error.response.data);
                alert(defaultErrMsg());
            } else {
                console.error('Error response:', error.message);
                alert(defaultErrMsg());
            }
        }
    };

    const fetchAllocationGroups = async () => {
        try {
            const response = await get('storeallocation/GetStoreAllocationGroups');
            console.log('Fetched allocation groups:', response);
            setAllocationGroups(response);
        } catch (error) {
            console.error('Error fetching allocation groups:', error);
        }
    };

    const fetchChartAccounts = async () => {
        try {

            const response = await get("chartaccount/GetChartAccounts");
            setChartAccounts(response ?? []);
        }
        catch (error) {
            console.error("Error fetching chart account:", error);
            setChartAccounts([]);
        }
    }

    const getLineTotalAmount = useCallback(() => {
        var lineSum = 0;
        localFormData.billLineItems.forEach(line => {
            lineSum += parseFloat(line.total) ?? parseFloat(0);
        });
        lineSum = FormatNumberDecimal(lineSum);
        return lineSum;
    }, [localFormData.billLineItems]);

    const getLineTotalCss = useCallback(() => {
        //const adjTotal = FormatNumberDecimal(parseFloat(localFormData.adjustedTotal));
        const total = FormatNumberDecimal(parseFloat(localFormData.billTotal));
        const lta = getLineTotalAmount();
        return NotEquals(total, lta) ? "text-red" : "text-green";
    }, [localFormData.billTotal, getLineTotalAmount]); //localFormData.adjustedTotal
    
    useEffect(() => {
        fetchAllocationGroups();
        fetchChartAccounts();
    }, []);

    useEffect(() => {
        var updatedLineItems = [];

        if (IsFalse(isManualAllocation)) {
            if (NotEmpty(localFormData.billLineItems)) {

                updatedLineItems = localFormData.billLineItems.map(item => {
                    const allocationPercent = parseFloat(item.allocationPercent) || 0;
                    return {
                        ...item,
                        allocationPercent: GetInputDecimalStr(allocationPercent),
                        total: GetInputDecimalStr(((allocationPercent / 100) * localFormData.billTotal))
                    };
                });
            }

            if (JSON.stringify(updatedLineItems) !== JSON.stringify(localFormData.billLineItems)) {
                setLocalFormData(prevData => ({
                    ...prevData,
                    billLineItems: updatedLineItems
                }));
            }
        }

    }, [localFormData.billLineItems, localFormData.billTotal, isManualAllocation]);

    useEffect(() => {
        if (initialBillData) {

            initialBillData.billType = 2;
            initialBillData.billStatus = IdExists(initialBillData.billId) ? initialBillData.billStatus : 1;
            initialBillData.billDate = GetInputDate(initialBillData.billDate);
            initialBillData.dueDate = IdExists(initialBillData.billId) ? GetInputDate(initialBillData.dueDate) : "";
            initialBillData.billTotal = GetInputDecimalStr(initialBillData.billTotal);
            initialBillData.nonDiscAmt = GetInputDecimalStr(initialBillData.nonDiscAmt);
            initialBillData.amtDisc = GetInputDecimalStr((initialBillData.billTotal ?? 0) - (initialBillData.nonDiscAmt ?? 0));
            initialBillData.discRate = GetInputDecimalStr(initialBillData.discRate);
            initialBillData.discAmt = GetInputDecimalStr(initialBillData.discAmt);
            initialBillData.adjustedTotal = GetInputDecimalStr(initialBillData.adjustedTotal);
            initialBillData.additionalCharges = GetInputDecimalStr(initialBillData.additionalCharges);
            initialBillData.storeId = IdExists(initialBillData.billId) ? initialBillData.storeId : 0;
            //console.log('Setting localFormData:', initialBillData);
            setLocalFormData(initialBillData);
        }
    }, [initialBillData]);

    return (
        <div className="">
            <div className="max-h-[90vh]">
                <h2 className="header-act">{isEditMode ? 'Update Non-Supplier Invoice' : 'Create Non-Supplier Invoice'}</h2>
                <div className="">
                    <form onSubmit={handleFormSubmit} className="">
                        <h4 className="sub-header-act">Invoice Details</h4>

                        <div className="p-2">
                            <div className="grid grid-cols-5 gap-5 pr-2">
                                <div className="form-control">
                                    <label className="label !pb-[2px]">
                                        <span className="label-text">Vendor</span>
                                        <span className="">
                                            <button type="button" onClick={e => openAddVendorModal(e)}>
                                                <svg class="w-5 h-5 text-gray-800" aria-hidden="true"
                                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                                        stroke-width="2" d="M12 7.757v8.486M7.757 12h8.486M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                                    </svg>
                                            </button>
                                        </span>
                                    </label>                                    
                                    <EntityAutocomplete name="vendorId" id="vendorId"
                                        ref={vendorAcRef}
                                        onSelect={handleSelectVendor}
                                        onChange={null}
                                        initialEntityId={localFormData.vendorId}
                                        entityType="Vendor"
                                        required
                                    />
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Invoice Number</span>
                                    </label>
                                    <input type="text" placeholder="" name="billNumber"
                                        value={localFormData.billNumber}
                                        onChange={handleChange}
                                        className="input input-bordered"
                                        required
                                        onBlur={(e) => { billNumberExists(e.target.value) }}
                                    />
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Invoice Date</span>
                                    </label>
                                    <input
                                        type="date"
                                        name="billDate"
                                        value={localFormData.billDate}
                                        onChange={handleChange}
                                        className="input input-bordered"
                                        required
                                    />
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Due Date</span>
                                    </label>
                                    <input
                                        type="date"
                                        name="dueDate"
                                        value={localFormData.dueDate}
                                        onChange={handleChange}
                                        className="input input-bordered"
                                        required
                                    />
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Status</span>
                                    </label>
                                    <select name="billStatus"
                                        value={localFormData.billStatus}
                                        onChange={handleChange}
                                        className="select select-bordered"
                                    >
                                        <option value={0}>-- Select --</option>
                                        <option value={1}>Ready for Payment</option>
                                        <option value={2}>Payment Pending</option>
                                        <option value={3}>Paid</option>
                                        <option value={4}>Hold</option>
                                    </select>
                                </div>
                            </div>

                            <div className="grid grid-cols-7 gap-6 pt-2">
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Total</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="billTotal" type="number" step="0.01"
                                            className="input input-bordered !w-[95%]"
                                            value={localFormData.billTotal}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                const formattedValue = Number(e.target.value).toFixed(2);
                                                setLocalFormData(prev => ({
                                                    ...prev,
                                                    billTotal: formattedValue
                                                }));
                                            }}
                                            required
                                        />
                                    </label>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Non-Discount Amount</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="nonDiscAmt" type="number" step="0.01" placeholder=""
                                            className="input input-bordered !w-[95%]"
                                            value={localFormData.nonDiscAmt}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                const formattedValue = Number(e.target.value).toFixed(2);
                                                setLocalFormData(prev => ({
                                                    ...prev,
                                                    nonDiscAmt: formattedValue
                                                }));
                                            }}
                                            required
                                        />
                                    </label>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Discount Amount</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="amtDisc" type="number" step="0.01"
                                            className="input input-bordered !w-[95%]"
                                            value={localFormData.amtDisc}                                            
                                            readOnly
                                        />
                                    </label>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Discount Rate</span>
                                    </label>
                                    <label className="input-group">
                                        <input name="discRate" type="number" step="0.01"
                                            className="input input-bordered !w-[95%]"
                                            value={localFormData.discRate}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                const formattedValue = Number(e.target.value).toFixed(2);
                                                setLocalFormData(prev => ({
                                                    ...prev,
                                                    discRate: formattedValue
                                                }));
                                            }}                                            
                                            required
                                        />
                                        <span>%</span>
                                    </label>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Discount Total</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="discAmt" type="number" step="0.01"
                                            className="input input-bordered !w-[95%]"
                                            value={localFormData.discAmt}                                            
                                            readOnly
                                        />
                                    </label>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Additional Charges</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="additionalCharges" type="number" step="0.01" placeholder=""
                                            className="input input-bordered !w-[95%]"
                                            value={localFormData.additionalCharges}
                                            onChange={handleChange}
                                            onBlur={(e) => {
                                                const formattedValue = GetInputDecimalStr(e.target.value);
                                                setLocalFormData(prev => ({
                                                    ...prev,
                                                    additionalCharges: formattedValue
                                                }));
                                            }}
                                            required
                                        />
                                    </label>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Adjusted Total</span>
                                    </label>
                                    <label className="input-group">
                                        <span>$</span>
                                        <input name="adjustedTotal" type="number" step="0.01"
                                            className="input input-bordered !w-[95%]"
                                            value={localFormData.adjustedTotal}                                            
                                            readOnly
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="grid grid-cols-4 gap-4 pt-2">
                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Store</span>
                                    </label>
                                    <select
                                        name="storeId"
                                        value={localFormData.storeId}
                                        onChange={handleChange}
                                        className="select select-bordered"
                                        required
                                    >
                                        <option value={0}>-- Select --</option>
                                        {NotEmpty(stores) && stores.map((store) => (
                                            <option key={store.storeId} value={store.storeId}>
                                                {"#" + store.storeNumber + " - " + store.name}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">Store Allocations</span>
                                    </label>
                                    <select
                                        name="allocationGroupId"
                                        value={localFormData.allocationGroupId}
                                        onChange={handleChange}
                                        className="select select-bordered"
                                        required
                                    >
                                        <option value={0}>-- Select --</option>
                                        {NotEmpty(allocationGroups) && allocationGroups.map((ag) => (
                                            <option key={ag.groupId} value={ag.groupId}>
                                                {ag.groupName}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="form-control">
                                    <label className="label">
                                        <span className="label-text">GL Account</span>
                                    </label>
                                    <EntityAutocomplete name="chartAccountId" id="chartAccountId"
                                        ref={chartAccountAcRef}
                                        onSelect={handleSelectGlAccount} onChange={null}
                                        initialEntityId={chartAccount != null ? chartAccount.chartAccountId : 0}
                                        entityType="GL Account"
                                        required
                                    />
                                </div>
                            </div>

                            <div className="mt-8">
                                <h4 className="text-xl font-bold underline float-left">Line Item Details</h4>
                                <div className="float-right">
                                    <button type="button" onClick={addNewRow} className="btn-submit">
                                        Add New Line Item
                                    </button>
                                </div>
                            </div>

                            <div className="ag-theme-alpine w-full ag-grid-act clear pt-2" style={{ height: '300px' }}>
                                <AgGridReact
                                    rowData={localFormData.billLineItems || []}
                                    defaultColDef={defaultColDef}
                                    columnDefs={columnDefs}
                                    onGridReady={onGridReady}
                                    onCellValueChanged={onCellValueChanged}
                                    editType="fullRow"
                                    enterNavigatesVerticallyAfterEdit={true}
                                    stopEditingWhenCellsLoseFocus={true}
                                />
                            </div>
                            <div className="flex justify-end mt-1 pb-2 pr-2">
                                <span className="pr-1">Line Total Amount:</span>
                                <span className={"font-bold " + getLineTotalCss()}>
                                    {GetDisplayCurrencyStr(getLineTotalAmount())}</span>
                            </div>
                        </div>

                        <div className="modal-action my-2">
                            <button type="button" onClick={onHide} className="btn-cancel">Cancel</button>
                            <button type="submit" className="btn-submit">
                                {isEditMode ? 'Update Invoice' : 'Create Invoice'}
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            {/*** Add Vendor Modal ***/}
            {IsTrue(isAddVendorOpen) &&
                <div className="modal-wrapper">
                    <div className="modal-content">
                        <h2 className="header-act pb-4">Create New Vendor</h2>
                        <form onSubmit={handleAddVendorFormSubmit} className="">

                            <div className="details-form-field !pt-2">
                                <label for="name" className="lbl-text1 !w-[110px]">Vendor Name:</label>
                                <input type="text" className="inp-text"
                                    id="name" name="name"
                                    value={vendorFormData.name}
                                    onChange={handleVendorFormChange}
                                    required
                                    onBlur={(e) => {
                                        setVendorFormData(prev => ({
                                            ...prev,
                                            payToName: e.target.value
                                        }));
                                    }}
                                />
                            </div>
                            <div className="details-form-field">
                                <label for="payToName" className="lbl-text1 !w-[110px]">Pay to Name:</label>
                                <input type="text" className="inp-text" 
                                    id="payToName" name="payToName"
                                    value={vendorFormData.payToName}
                                    onChange={handleVendorFormChange}
                                />
                            </div>
                            <div className="details-form-field">
                                <label for="address1" className="lbl-text1 !w-[110px]">Address1:</label>
                                <input type="text" className="inp-text" placeholder=""
                                    required
                                    id="address1" name="address1"
                                    value={vendorFormData.address1}
                                    onChange={handleVendorFormChange}
                                />
                            </div>
                            <div className="details-form-field">
                                <label for="address2" className="lbl-text1 !w-[110px]">Address2:</label>
                                <input type="text" className="inp-text" placeholder=""
                                    id="address2" name="address2"
                                    value={vendorFormData.address2}
                                    onChange={handleVendorFormChange}
                                />
                            </div>
                            <div className="details-form-field">
                                <div className="details-form-field !w-[55%] float-left">
                                    <label for="city" className="lbl-text1 !w-[110px]">City:</label>
                                    <input type="text" className="inp-text" placeholder=""
                                        required
                                        id="city" name="city"
                                        value={vendorFormData.city}
                                        onChange={handleVendorFormChange}
                                    />
                                </div>
                                <div className="details-form-field !w-[20%] float-left">
                                    <label for="state" className="lbl-text1 lbl-state-zip">State:</label>
                                    <input type="text" className="inp-text" placeholder=""
                                        required
                                        id="state" name="state"
                                        value={vendorFormData.state}
                                        onChange={handleVendorFormChange}
                                    />
                                </div>
                                <div className="details-form-field !w-[25%] float-left">
                                    <label for="zip" className="lbl-text1 lbl-state-zip">Zip:</label>
                                    <input type="text" className="inp-text" placeholder=""
                                        required
                                        id="zip" name="zip"
                                        onKeyDown={e => enforceZipFormat(e)}
                                        value={vendorFormData.zip}
                                        onChange={handleVendorFormChange}
                                    />
                                </div>                                
                            </div>

                            <div className="details-form-field">
                                <label for="termsDays" className="lbl-text1 !w-[110px]">Terms (Days):</label>
                                <input type="number" className="inp-text" placeholder=""
                                    id="termsDays" name="termsDays"
                                    value={vendorFormData.termsDays ?? 0}
                                    onChange={handleVendorFormChange}
                                />
                            </div>
                            <div className="details-form-field">
                                <label for="discountPercent" className="lbl-text1 !w-[110px]">Discount %:</label>
                                <input type="number" className="inp-text" placeholder=""
                                    id="discountPercent" name="discountPercent"
                                    value={vendorFormData.discountPercent ?? (0).toFixed(2)}
                                    onChange={handleVendorFormChange}
                                />
                            </div>
                            <div>
                                <label htmlFor="chartAccountId" className="lbl-text1 pb-1 !w-[110px]">GL Account:</label>
                                <EntityAutocomplete name="chartAccountId" id="chartAccountId"
                                    onSelect={handleSelectVendorGlAccount} onChange={null} initialEntityId={null}
                                    entityType="GL Account"
                                    required
                                />
                            </div>
                            <div className="modal-action mt-6">
                                <button type="button" onClick={e => setIsAddVendorOpen(false)} className="btn-cancel">Cancel</button>
                                <button type="submit" className="btn-submit">Create Vendor</button>
                            </div>
                        </form>
                    </div>
                </div>
            }

            {/*** Add Line GL Account ***/}
            {IsTrue(isSetLineChartAccountOpen) &&
                <div className="modal-wrapper">
                    <div className="modal-content top-30">
                        <h2 className="header-act pb-4">Select Line GL Account</h2>
                        <div>                            
                            <EntityAutocomplete name="chartAccountId" id="chartAccountId"                                
                                onSelect={handleSelectLineGlAccount} onChange={null} initialEntityId={null}
                                entityType="GL Account"
                                required
                            />
                        </div>
                        <div className="modal-action mt-6">
                            <button type="button" onClick={e => setIsSetLineChartAccountOpen(false)} className="btn-cancel">Cancel</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}