import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import BankAccountForm from './BankAccountForm';
import EditBankAccountForm from './EditBankAccountForm';

import { SetPageTitle } from '../../js/helpers.js';
import { SetDocumentTitle } from '../_reactHelpers/ReactHelpers';
import { get } from '../../services/apiService';
import { IsTrue } from '../../js/formHelpers';

export const BankAccounts = () => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Bank Accounts");

    const [gridApi, setGridApi] = useState(null);
    const [gridColumnApi, setGridColumnApi] = useState(null);

    const [bankAccounts, setBankAccounts] = useState([]);
    const [isAddModalOpen, setAddModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [currentAccount, setCurrentAccount] = useState(null);

    const [loading, setLoading] = useState(true);

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        setGridColumnApi(params.columnApi);
    }, []);

    const onFilterTextBoxChanged = useCallback(() => {
        gridApi.setQuickFilter(
            document.getElementById('filter-text-box').value
        );
    }, [gridApi]);

    const defaultColDef = useMemo(() => ({
        minWidth: 100,
        flex: 1,
        sortable: true,
        resizable: true,
        filter: true,
        suppressMovable: true,
        suppressMenu: false,
        cellClass: ["no-border"],
        menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
    }), []);

    const columnDefs = useMemo(() => [
        { field: 'bankAccountName', headerName: 'Account Name' },
        { field: 'chartAccountName', headerName: 'GL Account' },
        { field: 'bankName', headerName: 'Bank Name' },                
        {
            field: 'bankRegisterBalance', headerName: 'Register Balance', maxWidth: 250,
            valueFormatter: params => `$${params.value.toFixed(2)}`
        },
        {
            headerName: '', maxWidth: 175,
            cellRenderer: params => (
                <div className="flex justify-center items-center space-x-2 pt-2">
                    <button onClick={() => openEditModal(params.data)}
                        className="bg-primary hover:opacity-50 text-white font-bold py-1 px-2 rounded text-sm"
                    >Edit
                    </button>
                    <a href={`/BankRegisters/${params.data.bankId}`}
                       className="bg-green-500 hover:opacity-50 text-white font-bold py-1 px-2 rounded text-sm"
                    >Transactions
                    </a>
                </div>
            ),
        },
    ], []);

    const fetchBankAccounts = async () => {
        try {
            const response = await get('bankaccount/GetAllBankAccounts');
            console.log('Bank Accounts:', response);
            const data = response.map(item => ({
                ...item,
                id: item.bankId
            }));
            setBankAccounts(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching bank accounts:', error);
            setBankAccounts([]);
            setLoading(false);
        }
    };

    const openAddModal = () => setAddModalOpen(true);
    const closeAddModal = () => setAddModalOpen(false);

    const openEditModal = (account) => {
        setCurrentAccount(account);
        setEditModalOpen(true);
    };
    const closeEditModal = () => {
        setCurrentAccount(null);
        setEditModalOpen(false);
    };

    const handleAddBankAccount = () => {
        closeAddModal();
        fetchBankAccounts();
    };

    const handleBankAccountUpdated = () => {
        closeEditModal();
        fetchBankAccounts();
    };


    useEffect(() => {
        SetPageTitle("Bank Accounts");
        fetchBankAccounts();
    }, []);

    if (IsTrue(loading)) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }

    return (
        <div className="mx-auto py-2 px-6">            
            <div className="flex-wrapper mb-2">
                <div className="flex-grow">
                    <input type="text" id="filter-text-box" placeholder="Filter..."
                        className="grid-filter"
                        onChange={onFilterTextBoxChanged}                        
                    />
                </div>
                <button onClick={openAddModal} className="btn-small">
                    Add Bank Account
                </button>
            </div>

            <div className="ag-theme-alpine" style={{ height: 600, width: '100%' }}>
                <AgGridReact
                    rowData={bankAccounts}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onGridReady={onGridReady}
                    pagination={true}
                    paginationPageSize={10}
                    //domLayout='autoHeight'
                />
            </div>

            {isAddModalOpen && (
                <div className="modal-wrapper">
                    <div className="modal-content">
                        <BankAccountForm onBankAccountAdded={handleAddBankAccount} onClose={closeAddModal} />
                    </div>
                </div>
            )}

            {isEditModalOpen && (
                <div className="modal-wrapper">
                    <div className="modal-content">
                        <EditBankAccountForm bankAccountData={currentAccount}
                            onBankAccountUpdated={handleBankAccountUpdated}
                            onClose={closeEditModal}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}