import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal } from 'react-daisyui'

import { StandardDataAndResponsePOST, StandardIdAndResponseGET } from '../../js/dataHelpers';
import {
    Exists,
    handleFieldChange,
    IdExists,
    IsTrue,
    NotExists,
    NotStringEmpty,
    ParseIntSafe,
    PreventDefault,
    SetFocusById,
    Timeout
} from '../../js/formHelpers';

//import { createAccountModel } from './_accountHelpers.js';
//import {
//    handleFieldChange, isSelectedDropdown, isValidDate, GetStoreSelectList
//} from '../../js/formHelpers.js';
import { ListData } from '../_reactHelpers/ListData.js';
import { FileUploader } from '../_reactHelpers/FileUploader.js';
import { ListDataForOrderCreditNote } from "./ListDataForOrderCreditNote";
import { FileUploaderOrder } from "./FileUploaderOrder";

export const OrderCreditNotes = (props) => {
    const location = useLocation();
    const navigate = useNavigate();

    const [orderId, setOrderId] = useState(getOrderId());
    const [creditNotesList, setCreditNotesList] = useState([]);
    const [attList, setAttList] = useState([]);

    const [newNoteData, setNewNoteData] = useState(newNoteModel());
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [newAttData, setNewAttData] = useState(newAttModel());
    const [isAttModalOpen, setIsAttModalOpen] = useState(false);

    const [redirectSubmit, setRedirectSubmit] = useState(false);

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");

    const updateMsg = " updated successfully.";
    const updateErrMsg = "There was an issue updating the order. Please try again or contact support.";
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    function getOrderId() {
        let orderId = 0;
        try {
            orderId = location.state.orderId;
            if (!orderId) {
                orderId = 0;
            }
        } catch (error) {
            orderId = 0;
        }
        //console.log("getAccountId: ", accountId);
        return orderId;
    }


    function newNoteModel() {
        return {
            orderId: orderId,
            note: "",
            pinned: false,
            important: false
        };
    }

    function newAttModel() {
        return {
            accountId: orderId,
            accountAttachmentId: 0,
            description: "",
            name: ""
        };
    }

    function backToClick(accountStatus, accountMessage) {
        navigate('/Orders', {
            replace: true,
            state: {useSavedFiltersAccount: true, accountStatus, accountMessage}
        });
    }

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (NotExists(msg)) {
            msg = "";
        }
        if (NotExists(cssClass)) {
            cssClass = "";
        }

        if (errorMsg) {
            console.error(errorMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }


    const handleNewNoteChange = (e) => {
        updateMessage();

        var data = handleFieldChange(e);
        if (data) {

            setNewNoteData((newNoteData) => ({
                ...newNoteData,
                [data.name]: data.value
            }));
        } else {
            updateMessage(defaultErrMsg, "text-red", "null handleNewNoteChange data");
        }
    };

    //const handleNewAttChange = (e) => {
    //    updateMessage();

    //    var data = handleFieldChange(e);
    //    if (data) {

    //        setNewAttData((newAttData) => ({
    //            ...newAttData,
    //            [data.name]: data.value
    //        }));
    //    }
    //    else {
    //        updateMessage(defaultErrMsg, "text-red", "null handleNewAttChange data");
    //    }
    //};

    //----- Load Account Credit Notes Data -------

    const handleCreditNotesLoad = async () => {

        // CreditNotes tab should only load for existing Account
        if (IdExists(orderId)) {
            var data = await StandardIdAndResponseGET(orderId, "orders/GetOrderCreditNotes/", "handleCreditNotesLoad()");
            console.log("data: ", data);
            if (Exists(data)) {
                setCreditNotesList(data);
            } else {
                updateMessage(defaultErrMsg, "text-red");
            }
        } else {
            updateMessage(defaultErrMsg, "text-red");
        }
    };

    //----- POST -------

    //function btnSubmitClick(e, redirectSubmit) {
    //    setRedirectSubmit(redirectSubmit);
    //}

    async function saveOrderCreditNote() {
        var response = null;

        if (Exists(newNoteData)) {

            if (NotStringEmpty(newNoteData.note)) {
                var postModel = JSON.stringify(newNoteData);
                var endpoint = "orders/SaveOrderCreditNotes";
                var typeStr = "SaveOrderCreditNotesPost";
                response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
            } else {
                updateMessage("Note is required", "text-red");
            }
        } else {
            response = "No POST data for SaveOrderCreditNotesPost().";
        }
        return response;
    }

    async function DeleteOrderCreditNotePost(noteId) {
        var response = null;
        if (IdExists(noteId)) {
            var model = newNoteModel();
            model.orderNoteId = noteId;

            var postModel = JSON.stringify(model);
            var endpoint = "orders/DeleteOrderNote";
            var typeStr = "DeleteCreditOrderNotePOST";
            response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
        } else {
            response = "No POST data for DeleteCreditOrderNotePOST()";
        }
        return response;
    }

    const handleOpenModal = () => setIsModalOpen(true);

    function openModal() {
        Timeout(200, SetFocusById("creditNotesTextArea"));
        handleOpenModal();
    }

    async function handleCloseModal(saveNote) {
        var resp = null;

        if (IsTrue(saveNote)) {
            resp = await saveOrderCreditNote();
            //var success = IsSuccessStandardResponseAndDataPOST(resp);
            var id = ParseIntSafe(resp);

            if (IdExists(id)) {
                setNewNoteData(newNoteModel());
                setIsModalOpen(false);
                handleCreditNotesLoad();
            }
        } else {
            setNewNoteData(newNoteModel());
            setIsModalOpen(false);
        }
    }

    async function deleteNote(e, noteId) {
        PreventDefault(e);

        if (window.confirm("Are you sure you want to delete this Note?")) {
            var resp = await DeleteOrderCreditNotePost(noteId);
            //var success = IsSuccessStandardResponseAndDataPOST(resp);
            handleCreditNotesLoad();
        }
    }

    //----- Load Account Attachments Data -------

    const handleAttachmentsLoad = async () => {

        // CreditNotes tab should only load for existing Account
        if (IdExists(orderId)) {
            var data = await StandardIdAndResponseGET(orderId, "orders/GetOrderAttachments/", "handleCreditNotesLoad()");
            if (Exists(data)) {
                setAttList(data);
            } else {
                updateMessage(defaultErrMsg, "text-red");
            }
        } else {
            updateMessage(defaultErrMsg, "text-red");
        }
    };

    const handleOpenAttModal = () => setIsAttModalOpen(true);

    async function handleCloseAttModal() {
        handleAttachmentsLoad();
        setIsAttModalOpen(false);
    }

    async function deleteAttachment(e, attId) {
        PreventDefault(e);

        if (window.confirm("Are you sure you want to delete this attachment?")) {
            var resp = await DeleteAttachmentsPOST(attId);
            handleAttachmentsLoad();
        }
    }

    async function DeleteAttachmentsPOST(attId) {
        var response = null;

        if (IdExists(attId)) {
            var model = newAttModel();
            model.orderAttachmentId = attId;

            var postModel = JSON.stringify(model);
            var endpoint = "data/DeleteOrderAttachment";
            var typeStr = "DeleteOrderAttachmentPOST";
            response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
        } else {
            response = "No POST data for DeleteCreditOrderNotePOST()";
        }
        return response;
    }

    // ON LOAD
    useEffect(() => {
        handleCreditNotesLoad();
        handleAttachmentsLoad();
    }, []);

    return (
        <div className="p-2 mt-3 bg-white">
            {IdExists(orderId) &&
                <div>
                    <div className="act-form">
                        <div className="flex justify-content-between gap-5  forms-page clear">
                            <div className={"w-1/2"}>

                                <button type="button" className="btn-search !pt-0 !pb-0"
                                        onClick={(e) => openModal()}>
                                    + Note
                                </button>
                                <div className="clear   pt-2">
                                    <div
                                        className="min-h-[45px] rounded border-gray-200 border w-full overflow-x-hidden overflow-y-auto">
                                        {creditNotesList &&
                                            <ListDataForOrderCreditNote dataLines={creditNotesList} type="account-notes"
                                                                        deleteFunc={deleteNote}></ListDataForOrderCreditNote>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className={"w-1/2"}>

                                <div className="">
                                    <button type="button" className="btn-search !pt-0 !pb-0"
                                            onClick={(e) => handleOpenAttModal()}>
                                        + Attachment
                                    </button>
                                </div>
                                <div className="clear  pt-2">
                                    <div
                                        className="min-h-[45px] rounded border-gray-200 border w-full  overflow-x-hidden overflow-y-auto">
                                        {attList &&
                                            <ListDataForOrderCreditNote dataLines={attList} type="account-attachments"
                                                                        deleteFunc={deleteAttachment}></ListDataForOrderCreditNote>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

            <Modal open={isModalOpen} className="min-w-[800px] py-2">
                <Modal.Header
                    className="font-bold mb-0 text-center pb-2 !text-secondary !text-underline">
                    + Note
                </Modal.Header>
                <Modal.Body>
                    <div className="min-h-[200px]">
                        <div className="w-full mb-4">
                            <div className="border-textarea">
                                    <textarea className="inp-textarea acct-notes-textarea !overflow-clip !px-2 !py-1"
                                              id="creditNotesTextArea" name="note" rows="6"
                                              value={newNoteData.note}
                                              onChange={handleNewNoteChange}
                                              autoFocus>
                                    </textarea>
                            </div>
                        </div>

                        <div className="details-form-field">
                            <label for="pinned" className="lbl-cb pr-4">
                                Pin Note to Top
                                <input id="pinned" name="pinned" type="checkbox"
                                       className="input-cb ml-2"
                                       checked={newNoteData.pinned}
                                       onChange={handleNewNoteChange}
                                />
                            </label>
                        </div>

                        <div className="details-form-field">
                            <label for="important" className="lbl-cb pr-4">
                                Mark As Important
                                <input id="important" name="important" type="checkbox"
                                       className="input-cb ml-2"
                                       checked={newNoteData.important}
                                       onChange={handleNewNoteChange}
                                />
                            </label>
                        </div>

                        <div className={"h-[30px] text-center " + messageClass}>
                            {message}
                        </div>
                        <div className="text-right pt-2 pb-2">
                            <button type="button" className="btn-cancel" onClick={(e) => handleCloseModal(false)}>
                                Cancel
                            </button>
                            <button type="button" id="btnSubmit" className="btn-submit"
                                    onClick={(e) => handleCloseModal(true)}>
                                Save & Close
                            </button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <div>
                <Modal open={isAttModalOpen} className="min-w-[500px] py-2 px-4">
                    <Modal.Header
                        className="font-bold mb-0 text-center pb-2 !text-secondary !underline">
                        + Attachment
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <FileUploaderOrder accountId={orderId}
                                               handleClose={handleCloseAttModal}></FileUploaderOrder>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    );
}

