import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Label, Radio, Checkbox } from 'flowbite-react';

import { SetDocumentTitle, GetLocationStateId } from "../_reactHelpers/ReactHelpers";
import { GetEnumSelectList, GetStoreSelectList, GetUserSelectList, StandardDataAndResponsePOST } from '../../js/dataHelpers.js';
import { GetInputValuesCheckedByClass, SetPageTitle } from '../../js/helpers.js';
import {
    handleFieldChange, IsEmpty, enforcePhoneFormat, formatToPhone, IdExists,
    IsEmail, NotEmpty, IsFalse, PreventDefault, NotExists, ParseIntSafe, IsTrue, Exists
} from '../../js/formHelpers.js';
import { get } from '../../services/apiService.js';
import { Equals } from '../../js/calculationHelpers';

//import { createVendorModel } from './_vendorHelpers.js';

export const SampleCheckoutAddEdit = (props) => {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina Sample Checkout");
    const location = useLocation();
    const navigate = useNavigate();

    //TODO: remove when use token
    const [user, setUser] = useState(null);
    const [userId, setUserId] = useState(null);

    const [sampleCheckoutId, setSampleCheckoutId] = useState(GetLocationStateId("sampleCheckoutId"));
    const [sampleData, setSampleData] = useState(null);

    const [userList, setUserList] = useState([]);
    const [storeList, setStoreList] = useState([]);
    const [categories, setCategories] = useState([]);
    

    const [message, setMessage] = useState("");
    const [messageClass, setMessageClass] = useState("");
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const createMsg = "Sample created successfully.";
    //const updateMsg = "Vendor updated successfully.";
    //const createErrMsg = "There was an issue creating the vendor. Please try again or contact support.";
    //const updateErrMsg = "There was an issue updating the vendor. Please try again or contact support.";
    const defaultErrMsg = "An error occurred. Please try again or contact support.";

    /** Methods **/

    function backToClick(e, create) {
        PreventDefault(e);

        var statusMsg = "";
        if (IsTrue(create)) {
            statusMsg = createMsg;
        }

        navigate('/SampleCheckout', {
            replace: true,
            state: { statusMsg }
        });
    }

    function EnterSave(e) {
        if (e.key === "Enter") {
            btnSubmitClick(e);
        }
    }

    /****** Data Methods ******/

    async function createStoreList() {
        var list = [];
        list.push(
            <option key="0" value="0">None</option>
        );

        var dataList = await GetStoreSelectList(2);
        dataList.forEach((item, index) => {
            list.push(
                <option key={index + 1} value={item.value}>{item.text}</option>
            );
        });
        setStoreList(list);
    }

    async function createUserList() {
        var list = [];
        list.push(
            <option key="0" value="0">None</option>
        );

        var dataList = await GetUserSelectList("sales");
        dataList.forEach((item, index) => {
            list.push(
                <option key={index + 1} value={item.value}>{item.text}</option>
            );
        });
        setUserList(list);
    }

    function createCategories(data) {
        var categories = ['Carpet', 'Hardwood', 'Laminate', 'Lvt', 'Countertops', 'Tile', 'Cabinetry'];
        
        var list = [];
        categories.forEach((item, index) => {            
            var cbDisabled = IdExists(data.sampleCheckoutId);
            var defChecked = data.categoriesList.find((el) => el === item.toLowerCase()) ?? false; 
            
            var rbId = "rbSC-" + item;
            list.push(
                <div className="table-cell pb-2 pr-3">
                    <div className="table-cell">
                        <Label htmlFor={rbId} className="pr-1 text-[15px] align-bottom">{item}</Label>
                    </div>
                    <div className="table-cell">
                        <Checkbox id={rbId} name="sampleCheckoutCategories" value={item}
                            defaultChecked={defChecked} disabled={cbDisabled}
                            className="sampleCheckoutCategories text-blue-500 focus:ring-blue-500 cursor-pointer"/>
                    </div>
                    
                </div>
            );
        });
        setCategories(list);
    }

    const handleChange = (e) => {

        var data = handleFieldChange(e);
        if (data) {           

            if (data.name === "phone") {
                data.value = formatToPhone(e);
            }
            
            setSampleData((sampleData) => ({
                ...sampleData,
                [data.name]: data.value
            }));
        }
        else {
            //updateMessage(defaultErrMsg, "text-red", "null handleChange data");
            console.error("handleChange() setSampleData() error");
        }
    };

    function updateMessage(msg, cssClass, errorMsg, logMsg) {
        if (errorMsg) {
            console.error(logMsg);
        }
        if (logMsg) {
            console.log(logMsg);
        }
        setMessage(msg);
        setMessageClass(cssClass);
    }

    function validateData(categoriesStr) {
        var message = "";
        var valid = true;
        var phoneOrEmail = false;

        if (IsEmpty(sampleData.customerName)) {
            valid = false;
            message += "Customer Name is required.  ";
        }

        if (NotEmpty(sampleData.email)) {
            if (IsEmail(sampleData.email)) {
                phoneOrEmail = true;
            }
            else {                
                message += "Email must be valid.  ";
                valid = false;
            }
        }

        if (NotEmpty(sampleData.phone)) {
            phoneOrEmail = true;
        }

        if (IsFalse(phoneOrEmail)) {
            valid = false;
            message += "Phone or Email is required.  ";
        }

        if (IsEmpty(categoriesStr)) {
            valid = false;
            message += "Categories are required.  ";
        }

        if (IsFalse(valid)) {
            updateMessage(message, "text-red");
        }
        return valid;
    }

    function hideValidationMessages() {
        updateMessage("", "");
    }

    function btnSubmitClick(e) {
        handleSubmit(e);
    };

    const handleSubmit = async (e) => {
        PreventDefault(e);
        hideValidationMessages();

        var categoriesStr = "";
        var categories = GetInputValuesCheckedByClass("sampleCheckoutCategories");
        if (NotEmpty(categories)) {
            categories.forEach((catStr, index) => {
                categoriesStr += catStr + ", ";
            });
        }

        var valid = validateData(categoriesStr);
        if (valid === true) {
            try {
                var response = await SaveSampleCheckoutPost(categoriesStr);
                var rId = ParseIntSafe(response);
                if (IdExists(rId)) {
                    //redirect to search
                    sampleData.sampleCheckoutId = rId;
                    updateMessage(createMsg, "text-green");
                    backToClick(null, true);
                }
                else {
                    console.error(JSON.stringify(response));
                    alert(defaultErrMsg);
                }

            } catch (error) {
                updateMessage(defaultErrMsg, "text-red", error);
            }
        }
    }

    async function SaveSampleCheckoutPost(categoriesStr) {
        var response = null;

        if (sampleData) {
            sampleData.categories = categoriesStr;
            sampleData.checkedInDate = null;
            var postModel = JSON.stringify(sampleData);
            var endpoint = "samplecheckout/SaveSampleCheckout";
            var typeStr = "SaveSampleCheckoutPost";
            response = await StandardDataAndResponsePOST(postModel, endpoint, typeStr);
        }
        else {
            response = "No POST data for SaveSampleCheckoutPost().";
        }
        return response;
    }
        
    async function handleLoadDetails(cUserId) {

        if (location.state && Exists(location.state.sampleCheckoutData) &&
            IdExists(location.state.sampleCheckoutData.sampleCheckoutId)) {

            createCategories(location.state.sampleCheckoutData);
            setSampleData(location.state.sampleCheckoutData);
            setLoading(false);
        }
        else {
            const endpoint = 'samplecheckout/GetSampleCheckoutModel/' + cUserId;
            try {
                const response = await get(endpoint);
                //console.log('Response: ' + JSON.stringify(response));

                createCategories(response);
                setSampleData(response);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
                setError(true);
            }
        }
    }

    function setUserFields() {
        const userData = JSON.parse(localStorage.getItem('userData'));
        var cUserId = 0;
        if (userData) {
            cUserId = userData.userId;
            setUserId(userData.userId);
            setUser(userData);
        }
        return cUserId;
    }

    // ON LOAD
    useEffect(() => {
        var cUserId = setUserFields();

        createStoreList();
        createUserList();        
        handleLoadDetails(cUserId);
    }, []);

    if (loading === true) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (error === true)
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;

    return (
        <div className="product-page">
            <div className="details-page-container w-[800px] m-auto">
                <div className="">
                    {/*** Form ***/}                    
                    <form className="sample-checkout-form" onSubmit={handleSubmit}>
                                            
                        <div className="flex flex-wrap forms-page clear">
                            <div class="form-break">
                                <hr class="form-break-line" />
                                <span class="form-break-title">
                                    Sample Checkout
                                </span>
                            </div>

                            {/*** Form Fields ***/}
                            <div className="form-col-1 w-full">

                                <div className="details-form-field !pb-2">
                                    <label for="enteredDateStr" className="lbl-text1 w-[140px]">Date:</label>
                                    <span>{sampleData.enteredDateStr}</span>                                    
                                </div>

                                <div className="details-form-field !pb-2">
                                    <label for="storeId" className="lbl-text1 w-[140px]">Store:</label>
                                    <select id="storeId" name="storeId"
                                        className="sp-sel1 pb-1 bg-none"
                                        onKeyDown={(e) => EnterSave(e)}
                                        value={sampleData.storeId}
                                        onChange={handleChange}
                                        disabled={IdExists(sampleData.sampleCheckoutId)}
                                    >
                                        {storeList}
                                    </select>
                                </div>

                                <div className="details-form-field !pb-2">
                                    <label for="salespersonId" className="lbl-text1 w-[140px]">Salesperson:</label>
                                    <select id="salespersonId" name="salespersonId"
                                        className="sp-sel1 mark pb-1"
                                        onKeyDown={(e) => EnterSave(e)}
                                        value={sampleData.salespersonId}
                                        onChange={handleChange}
                                        disabled={IdExists(sampleData.sampleCheckoutId)}
                                    >
                                        {userList}
                                    </select>
                                </div>

                                <div className="details-form-field pb-2">
                                    <label for="customerName" className="lbl-text1 w-[140px]">Customer Name:</label>
                                    <input type="text" className="inp-text mark" 
                                        id="customerName" name="customerName"
                                        required autoFocus
                                        onKeyDown={(e) => EnterSave(e)}
                                        value={sampleData.customerName}
                                        onChange={handleChange}
                                        disabled={IdExists(sampleData.sampleCheckoutId)}
                                    />
                                </div>

                                <div className="details-form-field pb-2">
                                    <label for="phone" className="lbl-text1 w-[140px]">Phone:</label>
                                    <input type="text" className="inp-text frmPhoneInput mark"
                                        id="phone" name="phone"
                                        onKeyDown={e => enforcePhoneFormat(e)}
                                        value={sampleData.phone}
                                        onChange={handleChange}
                                        disabled={IdExists(sampleData.sampleCheckoutId)}
                                    />
                                </div>

                                <div className="details-form-field pb-2">
                                    <label for="email" className="lbl-text1 w-[140px]">Email:</label>
                                    <input type="email" className="inp-text mark"
                                        id="email" name="email"
                                        onKeyDown={(e) => EnterSave(e)}
                                        value={sampleData.email}
                                        onChange={handleChange}
                                        disabled={IdExists(sampleData.sampleCheckoutId)}
                                    />
                                </div>

                                <div className="details-form-field pb-2">
                                    <label className="lbl-text1 w-[140px]">Categories:</label>
                                    {/* Categories checkboxes */}                                    
                                    {categories}
                                </div>

                                <div className="w-full mb-4">
                                    <label for="notes" class="lbl-text1 table-cell !align-top w-[140px]">Notes:</label>
                                    <div className="border-textarea table-cell w-[550px]">
                                        <textarea className="inp-textarea !px-[.5rem] !py-[.25rem]"
                                            id="notes" name="notes" rows="3"
                                            onKeyDown={(e) => EnterSave(e)}
                                            value={sampleData.notes}
                                            onChange={handleChange}
                                            disabled={IdExists(sampleData.sampleCheckoutId)}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>

                            <div className="w-full text-right">
                                <div className={"inline-block mr-1 p-1 " + messageClass}>
                                    {message}
                                </div>
                                <div className="inline-block">
                                    {sampleData.sampleCheckoutId === 0 &&
                                        <button id="btnSampleCheckout" type="submit" className="btn-submit"
                                            onClick={(e) => btnSubmitClick(e, false)} //disabled={submitDisabled}                                        
                                        >
                                            Save Sample
                                        </button>
                                    }
                                    <button onClick={e => backToClick(e)} type="button" className="btn-cancel">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}