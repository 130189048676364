import React, { useState, useCallback, useEffect } from 'react';
import { Modal, Button } from 'react-daisyui';

import { getPaymentsForOrder } from '../../../services/paymentService.js';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import { GetDisplayDateStr, IsTrue } from '../../../js/formHelpers.js';
//import { get } from '../../../services/apiService.js';


export function ReceiptsModal({ isOpen, onClose, orderId, orderNum, openAdjustModal, setCurrentAmountReceipt }) {

    const [rowData, setRowData] = useState([]);
    const [gridApi, setGridApi] = useState(null);
    const [quickFilterText, setQuickFilterText] = useState('');

    const [isLoading, setIsLoading] = useState(false);

    const valueFormatter = (params) => {
        return params.value != null && params.value !== '' ? params.value : '--';
    };

    const amountFormatter = (params) => {
        return params.value != null ? `$${params.value.toFixed(2)}` : '--';
    };

    const setAdjustInfo = (amount) => {
        setCurrentAmountReceipt(amount);
        openAdjustModal(true)
    }

    // amountFormatter
    const columnDefs = [
        { headerName: 'Date', field: 'paymentDate', flex: 1, valueFormatter: params => GetDisplayDateStr(params.value) },
        { headerName: 'Name', field: 'paymentName', flex: 1.5, valueFormatter: valueFormatter },
        { headerName: 'Type', field: 'paymentType', flex: 1, valueFormatter: valueFormatter },
        { headerName: 'Last 4 of Card', field: 'last4Digits', flex: 0.8, valueFormatter: valueFormatter },
        { headerName: 'Check #', field: 'checkNumber', flex: 1, valueFormatter: valueFormatter },
        { headerName: 'Processed By', field: 'processedBy', flex: 1, valueFormatter: valueFormatter },
        { headerName: 'Amount', field: 'amount', flex: 1, valueFormatter: amountFormatter },
        { headerName: 'Orders Paid', field: 'ordersPaid', flex: 1, valueFormatter: valueFormatter },
        {
            headerName: '', flex: 1.1,
            cellRenderer: params => {
                return (
                    <div className="">
                        {IsTrue(params.data.allowAdjustPayment) &&
                            <Button size="sm"
                                className="btn btn-sm text-[14px] bg-green-500 hover:bg-green-600 text-white cursor-pointer !p-2 mr-1"
                                onClick={() => setAdjustInfo(params.data.amount)}>Adjust</Button>
                        }
                        <Button size="sm" className="btn-small" onClick={() => downloadReceiptPDF(params.data.paymentDetailId)}
                        >Print</Button>
                    </div>
                )
            },
        }
    ];

    const defaultColDef = {
        sortable: true,
        filter: true,
        resizable: true,
        tooltipComponent: 'customTooltip',
        tooltipValueGetter: (params) => params.value,
    };

    const onGridReady = useCallback((params) => {
        setGridApi(params.api);
        params.api.sizeColumnsToFit();
    }, []);

    //const onQuickFilterChanged = useCallback((event) => {
    //    setQuickFilterText(event.target.value);
    //    gridApi?.setQuickFilter(event.target.value);
    //}, [gridApi]);
        
    //const CustomTooltip = (props) => {
    //    return (
    //        <div className="custom-tooltip bg-white p-2 shadow-lg rounded">
    //            {props.value && props.value !== '--' ? props.value : 'No data available'}
    //        </div>
    //    );
    //};

    const downloadReceiptPDF = async (paymentDetailId) => {
        try {
            setIsLoading(true);
            const apiUrl = process.env.REACT_APP_API_URL + 'payments/GetPaymentReceipt/' + paymentDetailId;
            //console.log('API URL: ' + apiUrl);

            const response = await fetch(apiUrl);

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `Receipt-${paymentDetailId}.pdf`;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error('Error downloading PDF:', error);
        }
    };

    const onRowDoubleClicked = (params) => {
        downloadReceiptPDF(params.data.paymentDetailId);
    };

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getPaymentsForOrder(orderId);
                setRowData(response);
                console.log(response);
            } catch (error) {
                console.error('Error fetching payment receipts:', error);
            }
            setIsLoading(false);
        }

        if (isOpen) {
            fetchData();
        }
    }, [orderId, isOpen]);

    return (
        <Modal open={isOpen} className="w-11/12 max-w-[1400px] h-auto p-4 pt-2">
            <div style={{ padding: "20px" }}>
                {isLoading && (
                    <div className="loading loading-spinner loading-lg text-primary"></div>
                )}
                <div className="flex justify-between items-center pb-2">
                    <Modal.Header className="font-bold m-0">
                        Receipts - Order #{orderNum}
                    </Modal.Header>
                    <Button size="sm" color="ghost" shape="circle" onClick={e => onClose(e)}
                        className="absolute right-4 top-2 min-h-[1.5rem] h-[1.5rem] w-[1.5rem]"
                    >x</Button>
                </div>
                <Modal.Body className="p-0">
                    {/*<div style={{marginBottom: "30px"}}>*/}
                    {/*    <label htmlFor="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>*/}
                    {/*    <div className="relative">*/}
                    {/*        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">*/}
                    {/*            <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">*/}
                    {/*                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />*/}
                    {/*            </svg>*/}
                    {/*        </div>*/}
                    {/*        <input autoFocus onChange={onQuickFilterChanged} type="search" id="search" placeholder="Filter..."*/}
                    {/*               className="orders-filter-search block p-2 mb-2 pl-10 text-sm text-gray-900 */}
                    {/*            border border-gray-300 rounded-lg !focus:ring-blue-500 !focus:border-blue-500;" />*/}
                    {/*        <div id="selectedRows" />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className="ag-theme-alpine" style={{ height: '100%', width: '100%' }}>
                        <AgGridReact
                            columnDefs={columnDefs}
                            rowData={rowData}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                            domLayout='autoHeight'
                            //tooltipShowDelay={0}
                            //tooltipHideDelay={2000}
                            //quickFilterText={quickFilterText}
                            //pagination={true}
                            //paginationPageSize={30}
                            //components={{
                            //customTooltip: CustomTooltip,
                            //}}
                            onRowDoubleClicked={onRowDoubleClicked}
                        />
                    </div>
                </Modal.Body>
            </div>
        </Modal>
    );
}