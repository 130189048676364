import React, { useState, useRef, useMemo, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SetDocTitle } from "../_reactHelpers/ReactHelpers";

import { formatCurrencyDecimal, FormatNumberDecimal, NotEmpty } from '../../js/formHelpers.js';
import { SetPageTitle } from '../../js/helpers.js';
import { get } from '../../services/apiService.js';

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
//import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../../css/ag-theme-lumina.css'; // local ag-theme-alpine.css

export function StoresGrid() {
    const navigate = useNavigate();
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const defaultErrMsg = "An error occurred. Please try again or contact support.";
    //const [storeMessage, setStoreMessage] = useState(getStoreMessage());

    const gridRef = useRef();
    const containerStyle = useMemo(() => ({ width: '100%', height: '100%' }), []);
    const [rowData, setRowData] = useState([]);

    const columnDefs = [
        { headerName: "Id", field: "storeId", hide: true },
        {
            headerName: 'Name', field: 'name', //width: 300, cellClass: ["text-wrap"],                        
        },
        {
            headerName: 'Display Name', field: 'displayName', //width: 300, cellClass: ["text-wrap"],
        },
        {
            headerName: "Store #", field: "storeNumber", maxWidth: 100,
        },
        {
            headerName: 'Address', field: 'addressId', autoHeight: true, //width: 300,
            cellClass: ["text-wrap"],
            cellRenderer: params => {
                return RenderStoreAddress(params.data);
            },
            getQuickFilterText: params => {
                var address = "";
                if (params.data && params.data.address) {
                    var addr = params.data.address;
                    address += ", " + params.data.name;
                    address += ", " + addr.phone1;
                    address += ", " + addr.fax;
                    address += ", " + addr.address1;
                    address += ", " + addr.address2;
                    address += ", " + addr.city;
                    address += ", " + addr.state;
                    address += ", " + addr.zip;
                }
                return address;
            },
        },
        {
            headerName: "Sales Tax %", field: "salesTaxPercent", maxWidth: 140,
            cellRenderer: params => {
                var perc100 = 100 * (params.data.salesTaxPercent ?? 0);
                return <span>{FormatNumberDecimal(perc100)}%</span>;
            }
        },
        {
            headerName: "Prefix", field: "invoicePrefix", maxWidth: 100,
        },
        {
            headerName: "Inactive", field: "inactive", maxWidth: 100, cellDataType: 'boolean',
        },
        {
            headerName: "", field: "actions", maxWidth: 80,
            cellRenderer: params => {
                return (
                  <button className="btn-grid"
                          onClick={() => {
                              onRowDoubleClicked(params.data);
                          }}>
                      Edit
                  </button>
                );
            }
        }

    ];

    const gridOptions = {
        defaultColDef: {
            flex: 1,
            sortable: true,
            resizable: true,
            filter: true,
            suppressMovable: true,
            cellClass: ["no-border"]
        }
    };

    function RenderStoreAddress(data) {
        if (data != null && data.address != null) {
            var addr = data.address;
            return (
                <div className="store-addr-div">
                    <div>
                        <div>{addr.address1}</div>
                        {
                            addr.address2 && addr.address2 !== "" &&
                            <div>{addr.address2}</div>
                        }
                    </div>
                    <div>
                        {
                            addr.city && <span>{addr.city}</span>
                        }
                        {
                            addr.state && <span>, {addr.state}</span>
                        }
                        {
                            addr.zip && <span> {addr.zip}</span>
                        }
                    </div>
                    <div>
                        {
                            addr.phone1 && <span>{addr.phone1}</span>
                        }
                        {
                            addr.fax && <span> | {addr.fax}</span>
                        }
                    </div>
                </div>
            );
        }
        else {
            return;
        }
    }

    async function loadStoresData() {
        showLoading();

        try {
            const response = await get('data/GetAllStores');
            //console.log('Response: ' + JSON.stringify(response));

            if (NotEmpty(response)) {
                hideOverlay();                
            }
            else {
                showNoRows();
            }
            setRowData(response);

        } catch (error) {
            setError(error);
            console.error('loadVendorsData Error:', error);
        }
    };

    const onGridReady = useCallback((params) => {

        loadStoresData();
    }, []);

    //const handleOnAdd = (event) => {
    //    navigate('/CreateVendor');
    //}

    function handleSearch(e) {
        //console.log('handleSearch Start');

        var api = gridRef.current.api;
        const value = e.target.value.toLowerCase();
        api.setQuickFilter(value);
    };

    function showLoading() {
        gridRef.current.api.showLoadingOverlay();
    }
    function showNoRows() {
        gridRef.current.api.showNoRowsOverlay();
    }
    function hideOverlay() {
        gridRef.current.api.hideOverlay();
    }

    function onRowDoubleClicked(data) {
        if (data) {
            var storeId = data.storeId;
            navigate('/Store', { replace: true, state: { storeId } });
        }
    }

    function getMessage() {
        var message = "";
        if (location.state && NotEmpty(location.state.storeMessage)) {
            message = location.state.userMessage;
        }
        return message;
    }

    // ON LOAD
    useEffect(() => {
        setLoading(false);

        SetDocTitle("Lumina - Stores");
        SetPageTitle("Stores");
    }, []);

    if (loading === true) {
        return <div className="text-center text-xl mt-[10%]">
            <span className="pr-1">Loading...</span>
            <span className="loading loading-spinner text-blue-600"></span>
        </div>;
    }
    if (error === true)
        return <div className="text-red-500 text-center mt-[25%] text-xl">{defaultErrMsg}</div>;

    return (
        <div style={containerStyle} className="p-2 pt-0 ag-grid-center">
            <div className="ag-theme-alpine ag-grid-center" style={{ height: '750px', }}>

                <div className="pt-2">
                    <div className="float-left">
                        <div className="table-cell">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                                    </svg>
                                </div>
                                <input id="search" type="search" placeholder="Filter..."                                    
                                    className=" w-[400px] block p-2 mb-4 pl-10 text-sm 
                                        text-gray-900 border border-gray-300 rounded-lg 
                                        focus:ring-blue-500 focus:border-blue-500"
                                    onChange={handleSearch}
                                    autoFocus
                                />
                                <div id="selectedRows" />
                            </div>
                        </div>
                    </div>
                    <div className="float-right">
                        <div className="mb-2 pointer-events-hand pl-3">
                            <a href="/Store" className="btn-submit !m-0">Create Store</a>
                        </div>
                    </div>
                </div>
                <div className="clear"></div>

                <AgGridReact
                    masterDetail={true}
                    rowData={rowData}
                    ref={gridRef}
                    gridOptions={gridOptions}
                    columnDefs={columnDefs}
                    onGridReady={onGridReady}
                    onRowDoubleClicked={(e) => onRowDoubleClicked(e.data)}
                />
            </div>
        </div>
    );
}
