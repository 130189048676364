import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {
    GetDisplayDecimalStr, IsTrue, NotEmpty, AgDateMxWdSm,
    NotStringEmpty, Exists, GetInputDecimalStr, handleFieldChange, AgCurrencyMxWdSm,
    GetDisplayCurrencyStr, IsFalse, GetDateNow, defaultErrMsg, PreventDefault,
    ParseIntSafe, StringEmpty, FormatNumberDecimal, IdExists, GetInputDate, IsEmpty, GetInputDateEmpty, EnforceNumDecimalPlaces
} from '../../../js/formHelpers';

import { ClickById, GetValueById, SetHrefById, SetPageTitle, SetValueById } from '../../../js/helpers';
import { SetDocumentTitle } from '../../_reactHelpers/ReactHelpers';
import { get, post } from '../../../services/apiService';
import { Equals, GreaterThan, NotEquals } from '../../../js/calculationHelpers';

//import { EntityAutocomplete } from '../../_reactHelpers/EntityAutocomplete';
import { ViewFile } from '../../_reactHelpers/ViewFile';

export function ContentCentralOrder() {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Create Payable");
    const { documentId, orderId, vendorId } = useParams();
    const navigate = useNavigate();

    const initialFormData = {
        documentId: "",
        orderId: 0,
        storeId: 0,
        vendorId: 0,
        billType: 0,
        billDate: GetDateNow(),
        billStatus: 0,
        dueDate: '',
        poNumber: '',
        billNumber: '',
        billTotal: 0,
        amtDisc: 0,
        discRate: 0,
        discAmt: 0,
        nonDiscAmt: 0,
        additionalCharges: 0,
        adjustedTotal: 0,
        allocationGroupId: 0,
        totalLineCount: 0,
        viewFilePath: "",
        billLineItems: []
    };

    const [formData, setFormData] = useState(initialFormData);
    const [ccData, setCcData] = useState(null);
    const [canSuperCut, setCanSuperCut] = useState(false);

    const [orderNotMatched, setOrderNotMatched] = useState(false);
    const [loadPage, setLoadPage] = useState(false);
    const [dueDateCss, setDueDateCss] = useState(false);

    const gridRef = useRef();
    const columnDefs = [
        {
            headerName: 'Supplier', field: 'vendorName', cellClass: ["text-wrap"], autoHeight: true,
            maxWidth: 250,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px]">{params.value}</div>;
            },
        },
        {
            headerName: 'SC', field: 'warehouseStoreNumber', maxWidth: 60,
        },
        {
            headerName: 'PC', field: 'productCodeStr', maxWidth: 60
        },
        {
            headerName: 'PO #', field: 'poLineNumber', maxWidth: 140
        },
        {
            headerName: 'Style', field: 'description',
        },
        {
            headerName: "Color", field: 'details', cellClass: ["text-wrap"], autoHeight: true,
            maxWidth: 200,
            cellRenderer: params => {
                return <div className="leading-[20px] pt-[11px]">{params.value}</div>;
            },
        },
        {
            headerName: 'Qty', field: 'quantity', maxWidth: AgCurrencyMxWdSm(),
            editable: params => IsTrue(params.data.canProcessCcPayable),
            valueFormatter: params => GetDisplayDecimalStr(params.value),
            //cellRenderer: params => {
            //    return <span className="">{GetDisplayDecimalStr(params.value)}</span>;
            //}
        },
        {
            headerName: 'Cost', field: 'cost', maxWidth: AgCurrencyMxWdSm(),
            editable: params => IsTrue(params.data.canProcessCcPayable),
            valueFormatter: params => GetDisplayCurrencyStr(params.value),
        },
        //{
        //    headerName: 'Freight', field: 'freight', maxWidth: AgCurrencyMxWdSm(),
        //    editable: params => IsTrue(params.data.canProcessCcPayable),
        //    valueFormatter: params => GetDisplayCurrencyStr(params.value),
        //},
        {
            headerName: 'Cost Total', field: 'total', maxWidth: AgCurrencyMxWdSm(),
            editable: params => IsTrue(params.data.canProcessCcPayable),
            valueFormatter: params => GetDisplayCurrencyStr(params.value),
            //cellRenderer: params => {
            //    return  <span>${GetInputDecimalStr(params.value)}</span>
            //},
            //cellEditor: 'agNumberCellEditor',
            //cellEditorParams: {
            //    precision: 2,
            //    step: 0.01,
            //    showStepperButtons: false
            //}
        },
        {
            headerName: 'Promise Date', field: 'promiseDateStr', maxWidth: AgDateMxWdSm(),
            cellRenderer: params => {
                return <span className="">{NotStringEmpty(params.value) ? params.value : "--"}</span>;
            }
        },
        {
            headerName: 'SuperCut', field: 'supercutMarked', maxWidth: 90,
            editable: params => IsFalse(params.data.canProcessCcPayable),
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
        },
        {
            headerName: 'Marked', field: 'processCcMarked', maxWidth: 70,
            editable: params => IsTrue(params.data.canProcessCcPayable),
            cellRenderer: 'agCheckboxCellRenderer',
            cellEditor: 'agCheckboxCellEditor',
        },
    ];

    //const handleRowChange = (e, params) => {
    //    var data = handleFieldChange(e);
    //    if (data) {            
    //        gridRef.current.api.forEachNode((rowNode) => {

    //            if (Equals(rowNode.rowIndex, params.rowIndex)) {
    //                rowNode.setDataValue("costTotal", data.value);
    //            }
    //        });
    //    }
    //};

    const defaultColDef = {
        minWidth: 100,
        flex: 1,
        editable: false,
        sortable: true,
        resizable: true,
        filter: false,
        suppressMovable: true,
        suppressMenu: true,
        cellClass: ["no-border"],
        menuTabs: ["filterMenuTab", "columnsMenuTab", "generalMenuTab",],
    };

    const updateSupplierBillData = useCallback((updatedLines, discountRate) => {
        var billTotal = parseFloat(0);
        var nonDiscAmt = parseFloat(0);
        discountRate = parseFloat(discountRate) ?? 0;

        updatedLines = updatedLines ?? [];
        updatedLines.forEach((ol, index) => {
            if (IsTrue(ol.processCcMarked)) {
                billTotal += parseFloat(ol.total);

                if (IsTrue(ol.isFreightLine)) {
                    nonDiscAmt += parseFloat(ol.total);
                }
            }
        });

        // calculate
        var amtDisc = parseFloat((billTotal - nonDiscAmt));
        var discAmt = amtDisc * parseFloat(discountRate / 100);
        var additionalCharges = parseFloat(formData.additionalCharges ?? 0);
        //format        
        var adjustedTotal = GetInputDecimalStr(billTotal - discAmt + additionalCharges);
        billTotal = GetInputDecimalStr(billTotal);
        additionalCharges = GetInputDecimalStr(additionalCharges);
        nonDiscAmt = GetInputDecimalStr(nonDiscAmt);
        amtDisc = GetInputDecimalStr(amtDisc);
        discAmt = GetInputDecimalStr(discAmt);

        setFormData((prevData) => ({
            ...prevData,
            billTotal: billTotal,
            nonDiscAmt: nonDiscAmt,
            amtDisc: amtDisc,
            discAmt: discAmt,
            adjustedTotal: adjustedTotal,
            additionalCharges: additionalCharges
        }));
    }, [setFormData, formData.additionalCharges]);

    const onCellValueChanged = useCallback((event) => {
        const updatedLineItems = [...formData.billLineItems];
        const updatedRow = { ...updatedLineItems[event.rowIndex] };
        updatedRow[event.colDef.field] = event.newValue;

        // update line values
        if (event.colDef.field === "quantity" || event.colDef.field === "cost") {
            let qty = parseFloat(updatedRow["quantity"] ?? 0);
            let cost = parseFloat(updatedRow["cost"] ?? 0);
            let total = parseFloat(qty * cost);
            total = FormatNumberDecimal(total);
            updatedRow["total"] = total;
        }
        else if (event.colDef.field === "total") {
            let qty = parseFloat(updatedRow["quantity"] ?? 0);
            let total = parseFloat(updatedRow["total"] ?? 0);

            let cost = 0;
            if (GreaterThan(qty, 0)) {
                cost = parseFloat(total / qty);
            }
            cost = FormatNumberDecimal(cost);
            updatedRow["cost"] = cost;
        }
        updatedLineItems[event.rowIndex] = updatedRow;

        // update bill values
        var billTotal = parseFloat(0);
        var nonDiscAmt = parseFloat(0);
        var discountRate = parseFloat(formData.discRate) ?? 0;

        updatedLineItems.forEach((ol, index) => {
            if (IsTrue(ol.processCcMarked)) {
                billTotal += parseFloat(ol.total);

                if (IsTrue(ol.isFreightLine)) {
                    nonDiscAmt += parseFloat(ol.total);
                }
            }
        });

        // calculate
        var amtDisc = parseFloat((billTotal - nonDiscAmt));
        var discAmt = amtDisc * parseFloat(discountRate / 100);
        var additionalCharges = parseFloat(formData.additionalCharges ?? 0);
        //format                
        var adjustedTotal = GetInputDecimalStr(billTotal - discAmt + additionalCharges);
        billTotal = GetInputDecimalStr(billTotal);
        additionalCharges = GetInputDecimalStr(additionalCharges);
        nonDiscAmt = GetInputDecimalStr(nonDiscAmt);
        amtDisc = GetInputDecimalStr(amtDisc);
        discAmt = GetInputDecimalStr(discAmt);

        // update form data
        setFormData(prevData => ({
            ...prevData,
            billTotal: billTotal,
            nonDiscAmt: nonDiscAmt,
            amtDisc: amtDisc,
            discAmt: discAmt,
            adjustedTotal: adjustedTotal,
            additionalCharges: additionalCharges,
            billLineItems: updatedLineItems
        }));
    }, [formData.billLineItems, setFormData, formData.discRate, formData.additionalCharges]);


    function focusCostTotal(rowIndex) {
        gridRef.current.api.setFocusedCell(rowIndex, "total");
        gridRef.current.api.startEditingCell({ rowIndex: rowIndex, colKey: "total" });
    }

    const onCellKeyDown = async (e) => {
        // mark and go to next row on enter
        if (Exists(e) && Exists(e.event) && e.event.key === "Enter") {
            //const updatedLineItems = [...orderLines];
            //const updatedRow = { ...updatedLineItems[e.rowIndex] };
            //updatedRow.processCcMarked = true;            
            //updatedLineItems[e.rowIndex] = updatedRow;
            //setOrderLines(updatedLineItems);

            gridRef.current.api.forEachNode((rowNode) => {
                if (Equals(rowNode.rowIndex, e.rowIndex)) {
                    rowNode.data.processCcMarked = true;
                    refreshRow(rowNode);
                }
            });
            focusCostTotal(ParseIntSafe(e.rowIndex) + 1);
        }
        else {
            //setCurrRowIndex(null);
        }
    }

    function refreshRow(node) {
        var rowNodes = [node]; // params needs an array
        var params = {
            force: false,
            suppressFlash: true,
            rowNodes: rowNodes,
        };
        gridRef.current.api.refreshCells(params);
    }

    const onFirstDataRendered = () => {
        focusCostTotal(0);
    };

    //function EditNextRow(rowIndex, colId) {
    //    gridRef.current.api.setFocusedCell(rowIndex, costTotal);
    //    gridRef.current.api.startEditingCell({
    //        rowIndex: rowIndex,
    //        colKey: colId
    //    });
    //}

    async function billNumberExists(billNumber, alert) {
        var billNumberUsed = false;
        if (!IdExists(formData.billId) && NotStringEmpty(billNumber) && NotStringEmpty(formData.vendorId)) {
            try {
                const billNumberExists = await get("/bills/BillNumberExists/" + billNumber + "/" + formData.vendorId);
                if (IsTrue(billNumberExists)) {
                    billNumberUsed = true;
                    if (IsTrue(alert)) {
                        alert("Invoice Number has already been used for Vendor.")
                    }
                }
            } catch (error) {
                console.error('billNumberExists() error - ', error);
            } finally {
            }
        }
        return billNumberUsed;
    }

    const handleChange = (e) => {
        var data = handleFieldChange(e);
        if (data) {
            var name = data.name;
            var value = data.value;

            var adjustedTotal = formData.adjustedTotal;
            var discAmt = formData.discAmt;
            var amtDisc = formData.amtDisc;
            var discRate = formData.discRate;
            var billTotal = formData.billTotal;
            var additionalCharges = formData.additionalCharges;

            if (name === 'discRate') {
                discRate = EnforceNumDecimalPlaces(value, 2);
            }
            if (name === 'additionalCharges') {
                additionalCharges = EnforceNumDecimalPlaces(value, 2);
            }

            if (name === 'billTotal' || name === 'nonDiscAmt' || name === 'discRate' || name === 'additionalCharges') {                
                discRate = parseFloat(name === 'discRate' ? discRate : formData.discRate) || 0;
                additionalCharges = parseFloat(name === 'additionalCharges' ? additionalCharges : formData.additionalCharges) || 0;
                billTotal = parseFloat(name === 'billTotal' ? value : formData.billTotal) || 0;
                const nonDiscAmt = parseFloat(name === 'nonDiscAmt' ? value : formData.nonDiscAmt) || 0;                
                // calculate
                amtDisc = parseFloat(billTotal - nonDiscAmt);
                discAmt = amtDisc * parseFloat(discRate / 100);                
                // format
                adjustedTotal = GetInputDecimalStr(billTotal - discAmt + additionalCharges);
                billTotal = GetInputDecimalStr(billTotal);
                amtDisc = GetInputDecimalStr(amtDisc);
                discAmt = GetInputDecimalStr(discAmt);
            }

            setFormData((prevData) => ({
                ...prevData,
                [data.name]: data.value,
                amtDisc: amtDisc,
                discAmt: discAmt,
                discRate: discRate,
                adjustedTotal: adjustedTotal,
                billTotal: billTotal,
                additionalCharges: additionalCharges
            }));
        }
    };

    const showAllLines = async () => {
        var blis = [];

        var allLines = ccData.allBillLineItems ?? [];
        allLines.forEach((hiddenLine, index) => {

            const bli = formData.billLineItems.find(line => line.orderLineId === hiddenLine.orderLineId);
            if (Exists(bli)) {
                // add existing lines
                blis.push(bli);
            }
            else {
                // add hidden lines                
                blis.push(hiddenLine);
            }
        });

        setFormData(prevData => ({
            ...prevData,
            billLineItems: blis
        }));
    }

    const deliverSuperCut = async (e) => {
        PreventDefault(e);

        var superCutLines = [];
        formData.billLineItems.forEach((bli, index) => {
            if (IsTrue(bli.supercutMarked)) {
                superCutLines.push(bli);
            }
        });

        if (IsEmpty(superCutLines)) {
            alert("No lines selected to SuperCut.")
            return;
        }
        else {
            handleSuperCutSubmit(superCutLines);
        }
    }

    async function handleSuperCutSubmit(superCutLines) {

        try {
            if (IsEmpty(superCutLines)) {
                alert("No lines selected to SuperCut.")
                return;
            }
            else {
                const formDataToSubmit = initialFormData;
                // set dummy values for validation
                formDataToSubmit.documentId = formData.documentId;
                formDataToSubmit.orderId = parseInt(formData.orderId, 10);
                formDataToSubmit.billDate = GetInputDate("1/1/1111");
                formDataToSubmit.dueDate = GetInputDate("1/1/1111");

                formDataToSubmit.billLineItems = superCutLines.map(item => ({
                    ...item,
                    lineNumber: 0,
                    quantity: 0,
                    cost: 0,
                    total: 0,
                    vendorId: 0,
                }));
                //console.log('Submitting form data:', formDataToSubmit);

                var response = await post('/contentcentral/DeliverSupercutLines', formDataToSubmit);
                var iResp = ParseIntSafe(response);

                if (IdExists(iResp)) {
                    var gDocumentId = documentId ?? "";
                    //var iOrderId = ParseIntSafe(orderId);
                    //var iVendorId = ParseIntSafe(vendorId);
                    //var href = "/CreatePayable/" + gDocumentId + "/" + iOrderId + "/" + iVendorId;
                    var href = "/CreatePayable/" + gDocumentId + "/0/0";
                    SetHrefById("lnkLoadCcCreatePayable", href);
                    ClickById("lnkLoadCcCreatePayable");
                }
            }

        } catch (error) {
            console.error('Error creating bill:', error);

            if (error.response) {
                console.error('Error response:', error.response.data);
                alert(defaultErrMsg());
            } else {
                console.error('Error response:', error.message);
                alert(defaultErrMsg());
            }
        }
    }

    function DisableEnterSubmit(e) {
        if (e.key === "Enter") {
            PreventDefault(e);
            // do not allow post on enter key
            // only click to stop unwanted bill creation
        }
    }

    const submitMarkToFollowUp = async (e) => {
        PreventDefault(e);
        SetValueById("hfMarkToFollowUp", "0");

        if (StringEmpty(formData.billNote)) {
            alert("A note is required to mark for follow up.")
            return;
        }
        else {
            SetValueById("hfMarkToFollowUp", "1");
            ClickById("btnCreateCcPayable");
        }
    }

    const handleFormSubmit = async (e) => {
        PreventDefault(e);

        try {
            var billNumberUsed = await billNumberExists(formData.billNumber, false);
            if (IsTrue(billNumberUsed)) {
                alert("Invoice Number has already been used for this Supplier.");
                return;
            }
            else {
                var formDataToSubmit = { ...formData };

                // no bill created for markToFollowUp == true
                // submit data to get bill note
                const hfmfup = GetValueById("hfMarkToFollowUp");
                formDataToSubmit.markToFollowUp = hfmfup === "1";

                if (IsFalse(formDataToSubmit.markToFollowUp)) {
                    var billTotal = parseFloat(formDataToSubmit.billTotal);
                    if (billTotal <= 0) {
                        alert("Payable Total must be greater than $0.00");
                        return;
                    }
                }

                formDataToSubmit.vendorId = parseInt(formDataToSubmit.vendorId, 10);
                formDataToSubmit.storeId = parseInt(formDataToSubmit.storeId, 10);
                formDataToSubmit.billStatus = parseInt(formDataToSubmit.billStatus, 10);

                ['billTotal', 'discRate', 'discAmt', 'nonDiscAmt', 'adjustedTotal', 'additionalCharges'].forEach(field => {
                    formDataToSubmit[field] = parseFloat(formDataToSubmit[field]);
                });

                formDataToSubmit.billLineItems = formDataToSubmit.billLineItems.map(item => ({
                    ...item,
                    lineNumber: parseInt(item.lineNumber, 10),
                    quantity: parseFloat(item.quantity),
                    cost: parseFloat(item.cost),
                    total: parseFloat(item.total),
                    vendorId: item.vendorId
                }));
                //console.log('Submitting form data:', formDataToSubmit);

                var markedLines = [];
                formDataToSubmit.billLineItems.forEach((bl, index) => {
                    if (IsTrue(bl.processCcMarked)) {
                        markedLines.push(bl);
                    }
                });
                formDataToSubmit.billLineItems = markedLines;

                if (!IdExists(formDataToSubmit.vendorId)) {
                    formDataToSubmit.vendorId = formDataToSubmit.billLineItems[0].vendorId;
                }

                var response = await post('/contentcentral/CreateContentCentralBill', formDataToSubmit);
                var iResp = ParseIntSafe(response);
                if (Equals(iResp, -1)) {
                    onCreateSuccess("followup");
                }
                else if (IdExists(iResp)) {
                    setSavedSupplierDates();
                    onCreateSuccess("created");
                }
                else {
                    alert(defaultErrMsg());
                }
            }
        } catch (error) {
            console.error('Error creating bill:', error);

            if (error.response) {
                console.error('Error response:', error.response.data);
                alert(defaultErrMsg());
            } else {
                console.error('Error response:', error.message);
                alert(defaultErrMsg());
            }
        }
    };

    function getNextDocId() {
        var nextDocId = "";
        var docIds = JSON.parse(localStorage.getItem('ccDocIdQueue'));

        if (NotEmpty(docIds)) {
            var gDocumentId = documentId ?? "";
            var stopNext = false;

            docIds.forEach((docId, index) => {
                if (IsTrue(stopNext)) {
                    stopNext = false;
                    nextDocId = docId;
                }
                if (Equals(docId, gDocumentId)) {
                    stopNext = true;
                }
            });
        }
        return nextDocId;
    }

    function onCreateSuccess(type) {
        var nextDocId = getNextDocId();
        if (NotStringEmpty(nextDocId)) {
            nextInvoice(nextDocId);
        }
        else {
            navigate('/CreatePayables/' + type, { replace: true });
        }
    }

    function nextInvoice(nextDocId) {
        if (StringEmpty(nextDocId)) {
            nextDocId = getNextDocId();
        }

        if (NotStringEmpty(nextDocId)) {
            var href = "/CreatePayable/" + nextDocId + "/0/0";
            SetHrefById("lnkLoadCcCreatePayable", href);
            ClickById("lnkLoadCcCreatePayable");
        }
        else {
            if (alert("No next Invoice available.")) {
                navigate('/CreatePayables/invoices', { replace: true });
            }
            else {
                navigate('/CreatePayables/invoices', { replace: true });
            }
        }
    }

    function onBack() {
        navigate('/CreatePayables/invoices', { replace: true });
    }

    function getSavedSupplierDates(ccSupplier, currentDateStr) {
        var ccSupplierDates = null;
        try {
            var ccPayablesCurrentDateStr = "";
            var ccPayablesCurrentDate = localStorage.getItem("ccPayablesCurrentDateStr") ?? ""
            if (NotStringEmpty(ccPayablesCurrentDate)) {
                ccPayablesCurrentDateStr = JSON.parse(ccPayablesCurrentDate);
            }
            // do not autofill if saved dates not from current day
            if (Equals(currentDateStr, ccPayablesCurrentDateStr) && NotStringEmpty(ccSupplier)) {
                // get saved dates for supplier
                ccSupplierDates = JSON.parse(localStorage.getItem("ccPayablesDates-" + ccSupplier));
            }
        } catch (error) {
            console.error("setSavedSupplierDates() error: ", error);
        }
        return ccSupplierDates;
    }

    function setSavedSupplierDates() {
        try {
            // set current date for check
            localStorage.setItem("ccPayablesCurrentDateStr", JSON.stringify(ccData?.currentDateStr));
            if (NotStringEmpty(ccData?.supplier)) {
                var ccSupplierDates = {
                    billDateStr: formData?.billDate ?? "",
                    dueDateStr: formData?.dueDate ?? "",
                };
                // save dates by supplier
                localStorage.setItem("ccPayablesDates-" + ccData.supplier, JSON.stringify(ccSupplierDates));
            }
        } catch (error) {
            console.error("setSavedSupplierDates() error: ", error);
        }
    }

    const fetchContentCentralOrderVendorLines = async () => {
        try {
            var gDocumentId = documentId ?? "";
            //var iOrderId = ParseIntSafe(orderId);
            //var iVendorId = ParseIntSafe(vendorId);

            //"contentcentral/GetContentCentralOrder/" + gDocumentId + "/" + iOrderId + "/" + iVendorId;
            const data = await get("contentcentral/GetContentCentralOrder/" + gDocumentId + "/0/0");
            if (Exists(data)) {
                setLoadPage(true);

                if (Exists(data.orderModel) && IdExists(data.orderModel.orderId)) {
                    // set up bill data
                    let billData = setupBillData(data.billData, data);
                    setCanSuperCut(IsTrue(billData.canSuperCut));
                    setFormData(billData);
                    // set cc invoice data
                    setCcData(data);
                    updateSupplierBillData(billData.billLineItems, billData.discRate);
                }
                else if (IsTrue(data.orderNotMatched)) {
                    setOrderNotMatched(true);
                    // set up bill data
                    let billData = setupBillData(data.billData, data);
                    setFormData(billData);
                    // set cc invoice data
                    setCcData(data);
                    alert("Invoice does not match Lumina Order.");
                }
                else {
                    setOrderNotMatched(true);
                    alert(defaultErrMsg());
                }
            }
            else {
                setOrderNotMatched(true);
                alert(defaultErrMsg());
            }
        } catch (error) {
            console.error("fetchContentCentral() error: ", error);
            setOrderNotMatched(true);
            alert(defaultErrMsg());
        }
    };

    function setupBillData(billData, data) {
        if (Exists(billData)) {
            billData.discRate = GetDisplayDecimalStr(billData.discRate);
            billData.billDate = GetInputDate(billData.billDateStr);

            // allow blank dueDate if not set
            let dueDateStr = GetInputDateEmpty(billData.dueDateStr);
            billData.dueDate = dueDateStr;

            // check for saved supplier dates
            let supplierDates = getSavedSupplierDates(data.supplier ?? "", data.currentDateStr ?? "");
            if (Exists(supplierDates)) {

                let savedDueDateStr = supplierDates.dueDateStr ?? "";
                if (StringEmpty(dueDateStr)) {
                    // always set to savedDueDateStr if no billData.dueDateStr                            
                    billData.dueDate = savedDueDateStr;
                }
                else if (StringEmpty(savedDueDateStr)) {
                    // always set to billData.dueDateStr if no savedDueDateStr
                    billData.dueDate = dueDateStr;
                }
                else {
                    // if both set use billData.dueDateStr
                    billData.dueDate = dueDateStr;
                    if (NotEquals(savedDueDateStr, dueDateStr)) {
                        // if dates not equal show red border to note change from using saved
                        setDueDateCss(" !border-red-500 ");
                    }
                }
            }
        }
        return billData;
    }

    useEffect(() => {
        SetPageTitle("Create Payable");
        fetchContentCentralOrderVendorLines();
    }, []);

    return (
        <div className="page-wrapper !pt-1 !pb-4 !px-0">
            {loadPage &&
                <form onSubmit={handleFormSubmit} className="">
                    <div className="w-full">
                        <div className="table w-full">
                            <div className="table-cell w-[60%] !align-top h-[100%]">
                                <ViewFile path={formData.viewFilePath} zoom="40"></ViewFile>
                            </div>
                            <div className="table-cell w-[40%] !align-top pl-4 h-[100%]">
                                {/*{IsTrue(orderNotMatched) &&*/}
                                {/*    <div className="text-center">*/}
                                {/*        <div className="pt-4 text-lg text-black font-bold">*/}
                                {/*            Invoice does not match Lumina Order.*/}
                                {/*        </div>*/}
                                {/*        <div className="table-cell pr-8">*/}
                                {/*            <button type="button" className="btn-small"*/}
                                {/*                onClick={e => onBack()}>*/}
                                {/*                Back to List*/}
                                {/*            </button>*/}
                                {/*        </div>*/}
                                {/*        <div className="table-cell pr-8">*/}
                                {/*            <button type="button" className="btn-small"*/}
                                {/*                onClick={e => nextInvoice()}>*/}
                                {/*                Next Invoice*/}
                                {/*            </button>*/}
                                {/*        </div>*/}
                                {/*    </div>*/}
                                {/*}*/}
                                {/*{IsFalse(orderNotMatched) &&*/}
                                <div>
                                    <div className="flex-table">
                                        <div className="">
                                            <div className="form-control w-1/3 pr-2">
                                                <label className="label">
                                                    <span className="label-cc">PO Number</span>
                                                </label>
                                                <div>
                                                    <div className="sales-input w-full !leading-normal">
                                                        {IsTrue(orderNotMatched) &&
                                                            <span className="">{formData.poNumber}</span>
                                                        }
                                                        {IsFalse(orderNotMatched) &&
                                                            <a href={"/Redirect?route=EditOrder&orderId=" + formData.orderId}
                                                                target="_blank" rel="noreferrer" className="text-primary underline">
                                                                {formData.poNumber}
                                                            </a>
                                                        }                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-control w-1/3 pr-2">
                                                <label className="label">
                                                    <span className="label-cc">Payable Supplier</span>
                                                </label>
                                                <div>
                                                    <input
                                                        type="text" name="payee" placeholder=""
                                                        className="sales-input w-full"
                                                        value={formData.payee}
                                                        onChange={handleChange}
                                                        onKeyDown={(e) => DisableEnterSubmit(e)}
                                                        required
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-control w-1/3">
                                                <label className="label">
                                                    <span className="label-cc">Invoice Number</span>
                                                </label>
                                                <input
                                                    type="text" name="billNumber"
                                                    className="sales-input w-full"
                                                    value={formData.billNumber}
                                                    onChange={handleChange}
                                                    onBlur={(e) => { billNumberExists(e.target.value, true) }}
                                                    onKeyDown={(e) => DisableEnterSubmit(e)}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="form-control w-1/3 pr-2">
                                                <label className="label">
                                                    <span className="label-cc">Invoice Date</span>
                                                </label>
                                                <input
                                                    type="date" name="billDate"
                                                    className="sales-input w-full"
                                                    value={formData.billDate}
                                                    onChange={handleChange}
                                                    onKeyDown={(e) => DisableEnterSubmit(e)}
                                                    required
                                                />
                                            </div>

                                            <div className="form-control w-1/3 pr-2">
                                                <label className="label">
                                                    <span className="label-cc">Due Date</span>
                                                </label>
                                                <input
                                                    type="date" name="dueDate"
                                                    className={"sales-input w-full " + dueDateCss}
                                                    value={formData.dueDate}
                                                    onChange={handleChange}
                                                    onKeyDown={(e) => DisableEnterSubmit(e)}
                                                    required
                                                />
                                            </div>
                                            <div className="w-1/3"></div>
                                        </div>

                                        <div className="">
                                            <div className="form-control w-1/3 pr-2">
                                                <label className="label">
                                                    <span className="label-cc">Discount Amount</span>
                                                </label>
                                                <label className="input-group">
                                                    <span>$</span>
                                                    <input
                                                        name="amtDisc" type="number" step="0.01"
                                                        className="sales-input w-full"
                                                        value={formData.amtDisc}
                                                        readOnly
                                                    />
                                                </label>
                                            </div>
                                            <div className="form-control w-1/3 pr-2">
                                                <label className="label">
                                                    <span className="label-cc">Discount Rate</span>
                                                </label>
                                                <label className="input-group">
                                                    <input
                                                        name="discRate" type="number" step="0.01"
                                                        className="sales-input input-perc w-full"
                                                        value={formData.discRate}
                                                        onChange={handleChange}
                                                        onBlur={(e) => {
                                                            const formattedValue = Number(e.target.value).toFixed(2);
                                                            setFormData(prev => ({
                                                                ...prev,
                                                                discRate: formattedValue
                                                            }));
                                                        }}
                                                        onKeyDown={(e) => DisableEnterSubmit(e)}
                                                        required
                                                    />
                                                    <span>%</span>
                                                </label>
                                            </div>
                                            <div className="form-control w-1/3">
                                                <label className="label">
                                                    <span className="label-cc">Discount Total</span>
                                                </label>
                                                <label className="input-group">
                                                    <span>$</span>
                                                    <input
                                                        name="discAmt" type="number" step="0.01"
                                                        value={formData.discAmt}
                                                        className="sales-input w-full"
                                                        readOnly
                                                    />
                                                </label>
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="form-control w-1/3 pr-2">
                                                <label className="label">
                                                    <span className="label-cc">Non-Discount Amount</span>
                                                </label>
                                                <label className="input-group">
                                                    <span>$</span>
                                                    <input
                                                        name="nonDiscAmt" type="number" step="0.01"
                                                        className="sales-input w-full"
                                                        value={formData.nonDiscAmt}
                                                        readOnly
                                                    />
                                                </label>
                                            </div>
                                            <div className="form-control w-1/3 pr-2">
                                                <label className="label">
                                                    <span className="label-cc">Additional Charges</span>
                                                </label>
                                                <label className="input-group">
                                                    <span>$</span>
                                                    <input
                                                        name="additionalCharges" type="number" step="0.01"
                                                        className="sales-input w-full"
                                                        value={formData.additionalCharges}
                                                        onChange={handleChange}
                                                        onKeyDown={(e) => DisableEnterSubmit(e)}
                                                        onBlur={(e) => {
                                                            const formattedValue = GetInputDecimalStr(e.target.value);
                                                            setFormData(prev => ({
                                                                ...prev,
                                                                additionalCharges: formattedValue
                                                            }));
                                                        }}
                                                    />
                                                </label>
                                            </div>
                                            <div className="form-control w-1/3 pr-2">
                                                <label className="label">
                                                    <span className="label-cc">Adjusted Payable Total</span>
                                                </label>
                                                <label className="input-group">
                                                    <span>$</span>
                                                    <input
                                                        name="adjustedTotal" type="number" step="0.01"
                                                        className="sales-input w-full"
                                                        value={formData.adjustedTotal}
                                                        readOnly
                                                    />
                                                </label>
                                            </div>                                            
                                        </div>

                                        <div className="!pb-1">
                                            <div className="form-control w-1/3 pr-2">
                                                <label className="label">
                                                    <span className="label-cc">Payable Total</span>
                                                </label>
                                                <label className="input-group">
                                                    <span>$</span>
                                                    <input
                                                        name="billTotal" type="number" step="0.01"
                                                        className="sales-input w-full"
                                                        readOnly
                                                        value={formData.billTotal}
                                                        required
                                                    />
                                                </label>
                                            </div>
                                            <div className="form-control w-2/3">
                                                <div>
                                                    <label className="label">
                                                        <span className="label-cc">Follow Up / Remit Note</span>
                                                    </label>
                                                    <textarea name="billNote"
                                                        className="sales-input w-full"
                                                        value={formData.remitNote}
                                                        onChange={handleChange}
                                                        maxLength="250"
                                                        rows="4"
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="">
                                            <div className="form-control w-1/3 pr-2">
                                                <button type="button" className="btn-small"
                                                    onClick={e => onBack()}>
                                                    Back to List
                                                </button>
                                            </div>
                                            <div className="form-control w-2/3">
                                                <div className="flex-wrapper">
                                                    <div className="flex-grow">
                                                        <button type="button" className="btn-small-red"
                                                            onClick={e => submitMarkToFollowUp(e)}>
                                                            Mark to Follow Up
                                                        </button>
                                                    </div>
                                                    <div className="">
                                                        {IsTrue(orderNotMatched) &&
                                                            <button type="button" className="btn-small"
                                                                onClick={e => nextInvoice()}>
                                                                Next Invoice
                                                            </button>
                                                        }
                                                        {IsFalse(orderNotMatched) &&
                                                            <button type="submit" id="btnCreateCcPayable" className="btn-small-green">
                                                                Create Payable
                                                            </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex-wrapper pt-[25px]">
                                        <div className="flex-grow"></div>
                                        {IsTrue(canSuperCut) &&
                                            <div className="pr-4">
                                                <button type="button" className="btn-small"
                                                    onClick={e => deliverSuperCut(e)}>
                                                    Receive SuperCut
                                                </button>
                                            </div>
                                        }
                                        <div className="">
                                            <button type="button" className="link-primary underline"
                                                onClick={showAllLines}>
                                                {"See all Order lines (" + ParseIntSafe(formData?.totalLineCount ?? 0) + ")"}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {/*}*/}
                            </div>
                        </div>
                        <div className="clear">
                            <div className="ag-theme-alpine ag-grid-act w-full pt-2" style={{ height: '275px' }}>
                                <AgGridReact
                                    ref={gridRef}
                                    rowData={formData.billLineItems ?? []}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    onFirstDataRendered={onFirstDataRendered}
                                    singleClickEdit={true}
                                    enterNavigatesVertically={true}
                                    enterNavigatesVerticallyAfterEdit={true}
                                    onCellKeyDown={(e) => onCellKeyDown(e)}
                                    editType="fullRow"
                                    onCellValueChanged={onCellValueChanged}
                                //onCellEditingStarted={onCellEditingStarted}
                                //stopEditingWhenCellsLoseFocus={false}
                                //domLayout='autoHeight'
                                //onGridReady={onGridReady}
                                //rowSelection={'single'}
                                />
                            </div>
                        </div>
                    </div>
                    <input id="hfMarkToFollowUp" type="hidden" value=""></input>
                </form>
            }
            <a href="/" id="lnkLoadCcCreatePayable" className="hidden">Create CC Payable</a>
        </div>
    );
}