import React, { useState, useEffect } from 'react';
import { Button } from 'react-daisyui';

import { get } from '../../services/apiService'; 

import { JournalEntriesList } from './JournalEntriesList';
import { AddJournalEntryForm } from './AddJournalEntryForm';
import { SetDocumentTitle } from '../_reactHelpers/ReactHelpers';
import { SetPageTitle } from '../../js/helpers';

export function JournalEntries() {
    const [docTitle, setDocTitle] = SetDocumentTitle("Lumina - Journal Batches");

    const [journalEntryBatches, setJournalEntryBatches] = useState([]);

    const [loading, setLoading] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const [startDate, setStartDate] = useState('');

    const fetchJournalEntryBatches = async () => {
        setLoading(true);

        try {
            const response = await get(`/journalentrybatch/GetJournalEntryBatches?date=${startDate}`);
            const data = response.map(item => ({
                ...item
            }));

            setJournalEntryBatches(data);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching journalEntryBatches:', error);
            setJournalEntryBatches([]);
            setLoading(false);
        }
    };

    const openModal = () => setModalOpen(true);
    const closeModal = () => setModalOpen(false);

    const handleInputChange = (e) => {
        let { name, value } = e.target;
        if (name === 'startDate') {
            setStartDate(value);
        }
    };

    const handleAddJournalEntryBatch = () => {
        closeModal();
        fetchJournalEntryBatches();
    };

    function EnterSearch(e) {
        if (e.key === "Enter") {
            fetchJournalEntryBatches();
        }
    }

    useEffect(() => {
        SetPageTitle("Journal Batches");

        //fetchJournalEntryBatches();
    }, []);

    return (
        <div className="page-wrapper">
            <div className="flex-wrapper mb-2">
                <div className="flex-1">
                    <div className="table-cell">
                        <label className="form-label">
                            Start Date:
                        </label>
                        <input className="form-label-input"
                            type="date" id="startDate" name="startDate"
                            value={startDate}
                            onChange={handleInputChange}
                            onKeyDown={(e) => EnterSearch(e)}                        
                        />
                    </div>
                    <div className="table-cell pl-4">
                        <button className="btn-small" onClick={e => fetchJournalEntryBatches()}>Search Journal Entries</button>
                    </div>
                </div>
                <div className="flex-end">
                    <button className="btn-small" onClick={openModal}>
                        Add Journal Entry Batch
                    </button>
                </div>
            </div>

            <JournalEntriesList
                isParent={true}
                journalEntries={journalEntryBatches}
                onJournalEntryUpdated={fetchJournalEntryBatches}
                loading={loading}
            />

            {modalOpen &&
                <div className="modal-wrapper" id="my-modal">
                    <div className="modal-content !max-w-[1470px] !w-[1470px]">
                        <Button size="sm" color="ghost" shape="circle"
                            className="absolute right-4 top-2 min-h-[1.5rem] h-[1.5rem] w-[1.5rem]"
                            onClick={e => closeModal()}
                        >x</Button>
                        <AddJournalEntryForm onJournalEntryAdded={handleAddJournalEntryBatch} />                        
                    </div>
                </div>
            }
        </div>
    );
}