import React from 'react';

export const ManualEntryContent = ({
    cardholderName,
    setCardholderName,
    cardNumber,
    setCardNumber,
    expiryDate,
    handleExpiryChange,
    saveCard,
    setSaveCard,
    cvc,
    setCvc,
    zipCode,
    setZipCode,
    getCardType,
    formatCardNumber
}) => (
    <div className="space-y-3">
        <div className="flex items-center gap-4">
            <div className="flex-1">
                <input
                    type="text"
                    placeholder="Cardholder Name"
                    value={cardholderName}
                    onChange={(e) => setCardholderName(e.target.value)}
                    className="payment-input w-3/4"
                    required
                />
            </div>
            <label className="flex items-center gap-2 cursor-pointer">
                <input type="checkbox" id="saveCard"
                    checked={saveCard}
                    onChange={(e) => setSaveCard(e.target.checked)}
                    className="input-cb ml-1 mb-[2px]"
                />
                <label htmlFor="saveCard" className="text-sm font-semibold cursor-pointer">
                    Save Card
                </label>
            </label>
        </div>
        <div className="relative">
            <input
                type="text"
                placeholder="Card Number"
                value={cardNumber}
                onChange={(e) => setCardNumber(formatCardNumber(e.target.value))}
                className="payment-input w-full pr-44"
                maxLength={getCardType(cardNumber) === 'amex' ? 17 : 19}
                required
            />
            <div className="absolute right-2 top-1/2 -translate-y-1/2 flex gap-1">
                <div className={`card-icon visa-icon ${getCardType(cardNumber) === 'visa' ? 'opacity-100' : 'opacity-40'}`}></div>
                <div className={`card-icon mastercard-icon ${getCardType(cardNumber) === 'mastercard' ? 'opacity-100' : 'opacity-40'}`}></div>
                <div className={`card-icon amex-icon ${getCardType(cardNumber) === 'amex' ? 'opacity-100' : 'opacity-40'}`}></div>
                <div className={`card-icon discover-icon ${getCardType(cardNumber) === 'discover' ? 'opacity-100' : 'opacity-40'}`}></div>
            </div>
        </div>
        <div className="grid grid-cols-3 gap-3">
            <input
                type="text"
                placeholder="MM / YY"
                value={expiryDate}
                onChange={handleExpiryChange}
                className="payment-input"
                maxLength={7}
                required
            />
            <input
                type="text"
                placeholder="CVV"
                value={cvc}
                onChange={(e) => setCvc(e.target.value.replace(/\D/g, '').slice(0, getCardType(cardNumber) === 'amex' ? 4 : 3))}
                className="payment-input"
                required
            />
            <input
                type="text"
                placeholder="Billing Zip"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value.replace(/\D/g, '').slice(0, 5))}
                className="payment-input"
                maxLength={5}
                required
            />
        </div>
    </div>
);
