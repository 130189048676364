import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import authService from '../../services/authService';
import { Exists, NotStringEmpty, PreventDefault } from '../../js/formHelpers';
import { ClickById, SetHrefById } from '../../js/helpers';

export function UserLogin({ onLogin }) {
    const navigate = useNavigate();

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    //const [loading, setLoading] = useState(true);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // always log user out when hit login page for fresh credentials
        handleLogout();

        //checkADUser();
    }, []);

    const checkADUser = async () => {
        try {
            const adUser = await authService.getADUser();
            console.log('AD User response:', adUser);
            setLoading(false);

            if (Exists(adUser)) {                

                // TODO: test and uncomment
                //if (NotStringEmpty(user.home)) {
                if (false) {
                    //console.log('Login successful, redirecting to:', user.home);
                    SetHrefById("lnkUserHome", adUser.home);
                    ClickById("lnkUserHome");
                }
                else {
                    var hlUrl = authService.GetDefaultUserLink(adUser);
                    //console.log('Login successful, redirecting to:', hlUrl);
                    // link in LuminaMenu.js
                    SetHrefById("homeImageLink", hlUrl);
                    ClickById("homeImageLink");
                }
            } else {
                console.log('No AD user found');
            }
        } catch (err) {
            console.error('AD check failed:', err);
            setLoading(false);
        }
    };

    const handleLogin = async (e) => {
        PreventDefault(e);
        setError("");
        //setLoading(true);

        try {
            const user = await authService.login(username, password);
            if (Exists(user)) {

                // TODO: test and uncomment
                //if (NotStringEmpty(user.home)) {
                if (false) {
                    //console.log('Login successful, redirecting to:', user.home);
                    SetHrefById("lnkUserHome", user.home);
                    ClickById("lnkUserHome");
                }
                else {
                    var hlUrl = authService.GetDefaultUserLink(user);
                    //console.log('Login successful, redirecting to:', hlUrl);
                    // link in LuminaMenu.js
                    SetHrefById("homeImageLink", hlUrl);
                    ClickById("homeImageLink");
                }
            }
            else {
                setError('Invalid credentials');
                setLoading(false);
            }
        } catch (err) {
            console.error('Login error:', err);
            setError('Invalid credentials');
            setLoading(false);
        }
    };

    const handleLogout = () => {        
        try {
            localStorage.removeItem('token');
            localStorage.removeItem('userData');
        } catch (error) {
            console.error('Logout failed:', error);
        }
    };

    if (loading) {
        return (
            <div className="flex flex-col items-center justify-center px-4 py-8 mx-auto">
                <div className="p-6 w-[500px] max-w-[500px] bg-white rounded-lg shadow">
                    <div className="text-center">
                        <div className="pb-4">Checking authentication...</div>
                        <div className="text-sm text-gray-500">
                            If you're not redirected within a few seconds, please refresh the page.
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="flex flex-col items-center justify-center px-4 py-8 mx-auto text-white">
            <div className="p-6 w-[500px] max-w-[500px] bg-white rounded-lg shadow">
                <div>
                    <h1 className="text-xl text-secondary font-bold leading-tight tracking-tight text-center pb-4">
                        Lumina Login
                    </h1>
                    <form className="space-y-4 md:space-y-6" action="#" onSubmit={handleLogin}>
                        <div className="leading-tight tracking-tight text-gray-900 text-center">
                            <div className="pb-1">We could not log you in to Lumina automatically.</div>
                            <div className="pb-1">Please log in with your credentials below.</div>
                            <div className="pb-1">If you continue to experience issues please contact support.</div>
                        </div>
                        <div>
                            <label htmlFor="username" className="block mb-2 text-sm font-medium text-gray-900">
                                Username
                            </label>
                            <input
                                type="username"
                                name="username"
                                id="username"
                                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg 
                                        focus:ring-primary-600 focus:border-primary-600
                                        block w-full p-2.5"
                                required
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                            />
                        </div>
                        <div>
                            <label htmlFor="password" className="block mb-2 text-sm font-medium text-gray-900">
                                Password
                            </label>
                            <input
                                type="password"
                                name="password"
                                id="password"
                                className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg 
                                        focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                                required
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <button
                            type="submit"
                            className="w-full bg-primary hover:bg-primary bg-blue-600 hover:text-white focus:ring-4 
                                    focus:outline-none focus:text-white-400 focus:ring-primary-300 font-medium rounded-lg
                                    text-sm px-5 py-2.5 text-center"
                        >
                            Sign in
                        </button>
                    </form>
                </div>
            </div>
            <div className="text-red p-4">{error}</div>
            <a href="/" id="lnkUserHome" className="hidden">User Home</a>
        </div>
    );
}